import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,ViewStyle} from 'react-native';

export default class RolePopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "row", 
        justifyContent : "space-between",
        paddingHorizontal: 50, 
        paddingVertical: 50
    }

    centerPos : StyleProp<ViewStyle> =
    {
        display: "flex", 
        justifyContent : "center",
    }
     

    text: StyleProp<any> = {
        color: '#565656', 
        fontWeight: '400', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '15px', 
        lineHeight: '20px'
    }

    textError: StyleProp<any> = {
        color: 'red', 
        fontWeight: '400', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '15px', 
        lineHeight: '20px'
    }



}