import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import EmailsViewState from './EmailsViewState';
import EmailsViewProps from './EmailsViewProps';
import PaymentInformationOperation from '../../../../../../../../../../Transfer/PaymentInformationOperation';
import CerificatesPopUpView from '../investors/projectinvestors/modules/cerificatespopup/CerificatesPopUpView';
import React from 'react';
import PopUpSendNotificationView from '../../../../../../../../../../Components/PopUpSendNotification/PopUpSendNotificationView';

export default class EmailsViewModel extends ContentViewModel<EmailsViewState, EmailsViewProps>
{
    paymentInformationOperation = new PaymentInformationOperation();

      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleFileBankInformationEndEmail = this.handleFileBankInformationEndEmail.bind(this)
        this.showCerificatesPopUp = this.showCerificatesPopUp.bind(this);
        this.senEmailToPorjectInvestors = this.senEmailToPorjectInvestors.bind(this);

    }

    handleFileBankInformationEndEmail(test:boolean) {
        var model = {
            "ProjectCode" : this.props().projectCode,
            "SendTest" : test,
        }
        this.paymentInformationOperation.sendPaymentConfirmationEmail(model, () => {
            alert("Les émails ont été envoyés");
        });
    }

    showCerificatesPopUp(){
        this.showPopUp(
            <CerificatesPopUpView projectCode = {this.props().projectCode}/>
        );
    }

    senEmailToPorjectInvestors() {
        const users: { id: string }[] = [];
        let invets = this.props().investments.filter((x:any) => !x.isWaiting && x.isValidated);
        invets.forEach((user: any) => {
            if(!users.some(x => x.id === user.userId))
                users.push({ id: user.userId });
        });
        this.showPopUp(
          <PopUpSendNotificationView users={users}></PopUpSendNotificationView>,
        );
    }
}