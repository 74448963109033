import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class LegalEntityService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);   

    async getSearchPagination(status: any, page: number, itemPage: number, searchText: string, callback: Function = () => { }) {
        if (!searchText)
            searchText = "placeholder";
        this.httpClient
            .get('api/LegalEntity/Pagination/' + status + "/" + page + '/' + itemPage + '/' + searchText)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        callback(response.data.data);
                    }
                }
            });
    }

    async getLegalEntityById(legalEntityId: any, callback: Function)
    {
        this.httpClient.get("api/LegalEntity/GetLegalEntityById/" + legalEntityId, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
    
    async export(status:any, searchText: string, callback: Function = () => { }) {
        if (!searchText)
            searchText = "placeholder";
        this.httpClient
            .get('api/LegalEntity/Export/' + status + "/" + searchText)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        callback(response.data.data);
                    }
                }
            });
    }

    async updateLegalEntityStatus(updateLegalEntityStatus: any, callback: Function) {
        this.httpClient.put("api/LegalEntity/UpdateStatus", [], updateLegalEntityStatus).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async updateLegalEntity(legalEntity: any, callback: Function) {
        this.httpClient.put("api/LegalEntity/UpdateLegalEntity", [], legalEntity).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }
}