import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import TextAreaViewState from './TextAreaViewState';
import TextAreaViewProps from './TextAreaViewProps';

export default class TextAreaViewModel extends ContentViewModel<TextAreaViewState, TextAreaViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        // Initial state
        this.initialState({ error: false, value: '', passwordVisible: undefined });

        // Bindings
        this.valueChanged = this.valueChanged.bind(this);
        this.isFieldInError = this.isFieldInError.bind(this);
        this.handleEnterPressed = this.handleEnterPressed.bind(this);
        this.handleEyeClicked = this.handleEyeClicked.bind(this);
    }

    /* COMPONENT EVENTS */
    componentDidMount()
    {
        if (this.props().enterPressed)
        {
            window.addEventListener('keypress', this.handleEnterPressed);
        }
        this.props().subscribe({ id: this.props().label, error: this.props().required && (this.props().value == undefined || this.props().value == '') });
    }

    componentWillUnmount()
    {
        if (this.props().enterPressed)
        {
            window.removeEventListener("keyPress", this.handleEnterPressed);
        }
        this.props().subscribe({ id: this.props().label, error: false });
    }

    /* HANDLERS */
    handleEnterPressed(e: any)
    {
        // Key 13 is ENTER
        if (e.keyCode == 13)
        {
            this.isFieldInError(this.props().enterPressedCallback);
        }
    }

    handleEyeClicked()
    {
        if (this.state().passwordVisible == undefined)
        {
            this.setField({ passwordVisible: true });
        }
        else 
        {
            this.setField({ passwordVisible: !this.state().passwordVisible });
        }
    }

    /* METHODS */
    isFieldInError(callback: Function = () => { })
    {
        if ((this.props().value == '' && this.props().required) || (this.props().checkFunc != undefined && this.props().value != '' && !(this.props().checkFunc as Function)()))
        {
            this.setField({ error: true }, () =>
            {
                this.props().subscribe({ id: this.props().label, error: true });
                if (this.props().enterPressed)
                {
                    callback();
                }
            });
        }
        else
        {
            this.setField({ error: false }, () =>
            {
                this.props().subscribe({ id: this.props().label, error: false });
                if (this.props().enterPressed)
                {
                    callback();
                }
            });
        }
    }

    valueChanged(event: any)
    {
        this.setField({ value: event.target.value });
        this.props().onChange(event.target.value);
        if (this.state().error)
        {
            this.setField({ error: false });
        }
    }
}
    