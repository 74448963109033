import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import LegalEntityInfosViewState from './LegalEntityInfosViewState';
import LegalEntityInfosViewProps from './LegalEntityInfosViewProps';
import LegalEntityInfosViewStyle from './LegalEntityInfosViewStyle';
import LegalEntityInfosViewModel from './LegalEntityInfosViewModel';
import { TranslationView, LottieView, ButtonView } from '@levelapp/softfabric-ui';
import InputView from '../../../../../../../../../Components/Input/input/InputView';
import Select from '../../../../../../../../../Components/Select/Select';
import DropDownView from '../../../../../../../../../Components/Dropdown/DropDownView';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import moment from 'moment';
import TextAreaView from '../../../../../../../../../Components/TextArea/textArea/TextAreaView';
import DateSelect from '../../../../../../../../../Components/Select/DateSelect';
const CrossIcon = require('./assets/cross.svg');

export default class LegalEntityInfosView extends ContentView<LegalEntityInfosViewProps, LegalEntityInfosViewState, LegalEntityInfosViewModel, LegalEntityInfosViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LegalEntityInfosViewProps) {
    super(props);

    // Binding
    this.bind(new LegalEntityInfosViewModel(this), new LegalEntityInfosViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.Box}>
        {this.state.isLoading &&
          <LottieView style={{ justifyContent: 'flex-start' }} height={150} width={150} source={require('./assets/horizontalLoading.json')} />
        }
        {!this.state.isLoading &&
          <div style={{ display: "flex" }}>
            <div style={{ flex: "50%" }}>
              {/* INFORMATION */}
              <TranslationView style={this.style.Title}>LegalEntityInformation</TranslationView>
              <View>
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("CorporateNameLabel")} labelClass="col-lg-7" onChange={this.binding.handleCorporateNameChanged} value={this.state.legalEntity.corporateName} type="text" />
                <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("LegalFormLabel")} labelClass="col-lg-7" options={this.state.forms.map((form, key) => { return { label: form.fullName, value: form.id, key } })} required={false} selectClass="col-lg-7" placeholder="" onChange={this.binding.handleLegalFormObjectChanged} value={this.state.legalEntity.legalFormObject.fullName} />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("LegalFormLabelFreeText")} labelClass="col-lg-7" onChange={this.binding.handleLegalFormChanged} value={this.state.legalEntity.legalForm} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("ConstitutionDateLabel")} labelClass="col-lg-7" onChange={this.binding.handleConstitutionDateChanged} value={this.state.legalEntity.constitutionDate} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("MonitorParutionDateLabel")} labelClass="col-lg-7" onChange={this.binding.handleMonitorParutionDateChanged} value={this.state.legalEntity.monitorParutionDate} type="text" />
                <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("ActivitySectorLabel")} labelClass="col-lg-7" options={this.state.activityAreas.map((civ, key) => { return { label: civ, value: civ, key } })} required={false} selectClass="col-lg-7" placeholder="" onChange={this.binding.handleActivityAreaChanged} value={this.state.legalEntity.activityArea} />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NaceCodeLabel")} labelClass="col-lg-7" onChange={this.binding.handleNaceCodeChanged} value={this.state.legalEntity.naceCode} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BceNumberLabel")} labelClass="col-lg-7" onChange={this.binding.handleBceNumberChanged} value={this.state.legalEntity.bceNumber} type="text" />
                <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} selectClass="col-lg-7" label={this.binding.translate("AssujettiTvaLabel")} labelClass="col-lg-7" onChange={this.binding.handleAssujettiTvaChanged} value={this.state.legalEntity.assujettiTva} options={['Oui', 'Non'].map((civ, key) => { return { label: civ, value: civ, key } })} required={false} />

                <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} selectClass="col-lg-7" label={this.binding.translate("AssujettiIsImpLabel")} labelClass="col-lg-7" onChange={this.binding.handleassujettiIsImpChanged} value={this.state.legalEntity.assujettiIsImp} options={['Oui', 'Non'].map((civ, key) => { return { label: civ, value: civ, key } })} required={false} />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("TvaNumberLabel")} labelClass="col-lg-7" onChange={this.binding.handleTvaNumberChanged} value={this.state.legalEntity.tvaNumber} type="text" />
              </View>

              {/* ADDRESS & Coordonate*/}


              <View>
                <TranslationView style={this.style.Title2}>AdressAndCoordinates</TranslationView>
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Street")} labelClass="col-lg-7" onChange={this.binding.handleStreetChanged} required={false} value={this.state.legalEntity.address.street} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NumberLabel")} labelClass="col-lg-7" onChange={this.binding.handleNumberChanged} required={false} value={this.state.legalEntity.address.number} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Box")} labelClass="col-lg-7" onChange={this.binding.handleBoxChanged} required={false} value={this.state.legalEntity.address.box} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PostalCode")} labelClass="col-lg-7" onChange={this.binding.handlePostCodeChanged} required={false} value={this.state.legalEntity.address.postCode} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Locality")} labelClass="col-lg-7" onChange={this.binding.handleLocalityChanged} required={false} value={this.state.legalEntity.address.locality} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Country")} labelClass="col-lg-7" onChange={this.binding.handleCountryChanged} required={false} value={this.state.legalEntity.address.country} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PhoneNumberLabel")} labelClass="col-lg-7" onChange={this.binding.handlePhoneNumberChanged} value={this.state.legalEntity.phoneNumber} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Email")} labelClass="col-lg-7" onChange={this.binding.handleEmailChanged} value={this.state.legalEntity.email} type="text" />

              </View>
              {/* other Coordonnate*/}

              <View>
                <TranslationView style={this.style.Title2}>LegalEntityCoord</TranslationView>
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("IBANLabel")} labelClass="col-lg-7" onChange={this.binding.handleIbanChanged} required={false} value={this.state.legalEntity.iban} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BICLabel")} labelClass="col-lg-7" onChange={this.binding.handleBicChanged} required={false} value={this.state.legalEntity.bic} type="text" />
                <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("LeiCodeLabel")} labelClass="col-lg-7" onChange={this.binding.handleLeiCodeChanged} required={false} value={this.state.legalEntity.leiCode} type="text" />
                <DateSelect subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} selectClass="col-lg-7" label={this.binding.translate("LeiValidityLabel")} labelClass="col-lg-7" onChange={this.binding.handleLeiValidityChanged} required={false} value={this.state.legalEntity.leiValidity} startYear={moment().year()} endYear={moment().year() + 10} />
                <InputView disabled={true} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NumberOfLeadersLabel")} labelClass="col-lg-7" onChange={this.binding.handleNumberOfLeaderChanged} required={false} value={this.state.legalEntity.numberOfLeader} type="text" />
                {this.state.legalEntity.legalEntityLeaders && this.state.legalEntity.legalEntityLeaders.map((el: any, key: any) => {
                  if (!el.ubo) {
                    return (<div key={key} className="row" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 30 }}>
                      <InputView subscribe={() => { }} shouldCheck={false} inputClass="col-lg-12" label={"Prénom"} labelClass="col-lg-7" placeholder={"Prénom"} onChange={(value: any) => { this.binding.handleDynamicFirstNameChanged(value, key) }} required={false} value={el.firstName} type="text" />
                      <InputView subscribe={() => { }} shouldCheck={false} inputClass="col-lg-12" label={"Nom"} labelClass="col-lg-7" placeholder={"Nom"} onChange={(value: any) => { this.binding.handleDynamicLastNameChanged(value, key) }} required={false} value={el.lastName} type="text" />
                      <InputView subscribe={() => { }} shouldCheck={false} inputClass="col-lg-12" label={"Fonction"} labelClass="col-lg-7" placeholder={"Fonction"} onChange={(value: any) => { this.binding.handleDynamicFunctionChanged(value, key) }} required={false} value={el.function} type="text" />
                      <InputView subscribe={() => { }} shouldCheck={false} inputClass="col-lg-12" label={"Email"} labelClass="col-lg-7" placeholder={"Email"} onChange={(value: any) => { this.binding.handleDynamicEmailChanged(value, key) }} required={false} value={el.email} type="text" />
                      <InputView subscribe={() => { }} shouldCheck={false} inputClass="col-lg-12" label={"Registre national"} labelClass="col-lg-7" placeholder={"Registre national"} onChange={(value: any) => { this.binding.handleDynamicNationalNumberChanged(value, key) }} required={false} value={el.nationalNumber} type="text" />
                      <View style={{ justifyContent: "flex-end" }}><TouchableWithoutFeedback onPress={() => { this.binding.deleteLeader(key) }}><Image style={{ marginLeft: 15, height: 30, width: 30, marginBottom: 4 }} source={CrossIcon}></Image></TouchableWithoutFeedback></View>
                    </div>)
                  } else {
                    return;
                  }
                })

                }
                {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                  <View>
                    <ButtonView animation={'None'} borders={'Little'} style={this.style.ValidUserButton} backgroundColor='#32CD32' isLoading={false} handleClick={this.binding.addLeader}>
                      <TranslationView upperCase>AddLeaderButton</TranslationView>
                    </ButtonView>

                    <ButtonView animation={'None'} borders={'Little'} isLoading={this.state.isUpdating} handleClick={this.binding.handleUpdate} backgroundColor="#FFB243" style={this.style.UpdateButton}><TranslationView>UpdateButtonText</TranslationView></ButtonView>

                  </View>
                }
              </View>
            </div>

            <div style={{ flex: "10%" }}></div>
            <div style={{ flex: "40%" }}>
              <Text style={this.style.Title}><TranslationView>UserStatut</TranslationView> {this.state.legalEntity.legalEntityStatus.title}</Text>
              <br></br><br></br><br></br>
              {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                <View>
                  <View style={{
                    flex: 1,
                    flexDirection: 'row',
                  }}>
                    <Text style={this.style.Title}><TranslationView>ModifyStatus</TranslationView></Text>
                    <DropDownView selectedValue={this.state.selectedStatus} values={this.state.statuses} onValueChange={(value: any) => this.binding.handleStatusChanged(value)}></DropDownView>
                  </View>


                  <ButtonView animation={'None'} borders={'Little'} style={this.style.ValidUserButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={() => { this.binding.ChangeStatus() }}>
                    <TranslationView upperCase>ChangeStatus</TranslationView>
                  </ButtonView>
                </View>
              }

              <View style={{ marginTop: 30 }}>
                <TextAreaView subscribe={() => { }} label={"Commentaire Administrateur"} labelClass={"col-lg-7"} value={this.state.legalEntity.adminComment} onChange={this.binding.handleAdminCommentChanged}></TextAreaView>
                {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                  <ButtonView animation={'None'} borders={'Little'} style={this.style.UpdateButton2} backgroundColor='#FFB243' isLoading={this.state.isUpdating} handleClick={this.binding.changeComment}>
                    <TranslationView upperCase>UpdateButtonText</TranslationView>
                  </ButtonView>
                }
              </View>

              <View>
                <TranslationView style={this.style.PicturesTitle}>BceFileLabel</TranslationView>
                <img onClick={() => { if (this.state.legalEntity.bceRegistrationUrl != "") window.open(this.state.legalEntity.bceRegistrationUrl, "_blank") }} style={{ marginTop: "50px" }} width="150" height="100" src={this.state.legalEntity.bceRegistrationImage} />
              </View>
              <View>
                <TranslationView style={this.style.PicturesTitle}>LeadersFileLabel</TranslationView>
                <img onClick={() => { if (this.state.legalEntity.leadersNominationUrl != "") window.open(this.state.legalEntity.leadersNominationUrl, "_blank") }} style={{ marginTop: "50px" }} width="150" height="100" src={this.state.legalEntity.leadersNominationImage} />
              </View>
              <View>
                <TranslationView style={this.style.PicturesTitle}>BankFileLabel</TranslationView>
                <img onClick={() => { if (this.state.legalEntity.bankIdUrl != "") window.open(this.state.legalEntity.bankIdUrl, "_blank") }} style={{ marginTop: "50px" }} width="150" height="100" src={this.state.legalEntity.bankIdImage} />
              </View>

              <View>
                <TranslationView style={this.style.PicturesTitle}>UboLabel</TranslationView>
                <img onClick={() => { if (this.state.legalEntity.uboUrl != "") window.open(this.state.legalEntity.uboUrl, "_blank") }} width="150" height="100" src={this.state.legalEntity.uboImage} />
              </View>
            </div>



          </div>
        }
      </View>

    );
  }
}
