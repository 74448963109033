import * as React from 'react';
import { ContentView } from '@levelapp/softfabric';
import FileInputViewState from './FileInputViewState';
import FileInputViewProps from './FileInputViewProps';
import FileInputViewStyle from './FileInputViewStyle';
import FileInputViewModel from './FileInputViewModel';

export default class FileInputView extends ContentView<FileInputViewProps, FileInputViewState, FileInputViewModel, FileInputViewStyle>
{
    constructor(props: FileInputViewProps) {
        super(props);
        // Binding
        this.bind(new FileInputViewModel(this), new FileInputViewStyle(this));
    }

    public render() {
        return (
            <div style={this.props.style}>
                <div className="row" >
                    <div className="col-lg-12" style={{ padding: 0 }} >
                        <label style={{ color: '#565656',  fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px', width: '100%', textAlign: 'start' }}>{this.props.label}</label>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 8, display: 'flex' }}>
                    <div className="col-lg-9" style={{ padding: 0 }}>
                        <div style={{ height: 40, width: '100%', border: '1px solid #d5d5d5', borderRadius: '3px', display: 'flex', alignItems: 'center' }}>
                            <span style={{ fontFamily: 'Montserrat', color: this.props.file != undefined ? '#000000' : '#d5d5d5', fontSize: '16px',  lineHeight: '24px', border: 'none', paddingLeft: 10, userSelect: 'none',padding : 5 }}>{this.props.file != undefined ? this.props.file.name : this.props.placeholder}</span>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ padding: 0 }}>
                        <div style={{ height: 40, width: '100%', backgroundColor: '#FFB243',border: '1px solid #d5d5d5', borderRadius: '0 3px 3px 0', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={this.binding.handleClick}>
                            <span style={{ fontFamily: 'Montserrat', color: '#FFFFFF', fontSize: '13px', fontWeight: 'bold', lineHeight: '24px', userSelect: 'none', cursor: 'pointer' ,padding : 5}}>{this.props.btnLabel.toUpperCase()}</span>
                        </div>
                    </div>
                    <input type="file" id="file" ref="fileUploader" accept={this.props.accept} onChange={(file: React.ChangeEvent<HTMLInputElement>) => { if (file.target.files != null) { this.binding.handleFileChanged(file.target.files[0]) } }} style={{ display: "none" }} />
                </div>
            </div >
        );
    }
}