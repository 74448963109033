import DashboardshellViewState from './DashboardshellViewState';
import DashboardshellViewProps from './DashboardshellViewProps';
import { ContentViewModel, ContentView, Application, Managers, NavigationManager } from '@levelapp/softfabric';
import ExtendedManagers from '../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../Common/Managers/RolesManager';
import UsersOperation from '../../../Transfer/UsersOperation';
import UserInfos from '../../../DTO/UserInfos';

export default class DashboardshellViewModel extends ContentViewModel<DashboardshellViewState, DashboardshellViewProps>
{
    NavigationManager : NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager : RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER);
    

    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */       
        this.initialState({
            isLoadingRoles : true,
        }); 
        /* BINDING */
        this.getHeaderTitle = this.getHeaderTitle.bind(this);

        new UsersOperation().getUserInfos((data: UserInfos) => {
            Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER).setRoles(data.roles) 
            this.setField({ isLoadingRoles: false });
        });
    }

    componentDidMount()
    {
        
    }
   


    /* METHODS */
    getHeaderTitle()
    {
       return "";
    }
}