import React from 'react';
import { View, Text } from 'react-native';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';
import UsersViewProps from './UsersViewProps';
import UsersViewState from './UsersViewState';
import UsersViewModel from './UsersViewModel';
import UsersViewStyle from './UsersViewStyle';
import TableView from '../../../../../Components/table/TableView';
import ResearchInputView from '../../../../../Components/researchinput/ResearchInputView';
import DropDownView from '../../../../../Components/Dropdown/DropDownView';


export default class UsersView extends ContentView<UsersViewProps, UsersViewState, UsersViewModel, UsersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UsersViewProps) {
    super(props);

    // Binding
    this.bind(new UsersViewModel(this), new UsersViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>


          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>
              Investors
            </TranslationView>

            <TranslationView style={this.style.subTitle}>
              ManageYourInvestors
            </TranslationView>
          </View>
          <View style={this.style.bigBoxContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
              {(this.state.isExport || this.state.isAdmin) &&
                <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.exportUsers() }}>
                  <TranslationView style={this.style.exportButtonText}>
                    ExportYourUsers
                  </TranslationView>
                </ButtonView>}

              {(this.state.isExport || this.state.isAdmin) &&
                <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButtonVBOUT} handleClick={() => { this.binding.exportUsersVBOUT() }}>
                  <TranslationView style={this.style.exportButtonText}>
                  ExportYourUsersVBOUT
                  </TranslationView>
                </ButtonView>}

              {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                <ButtonView animation={'Bounce'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isCheckingValidty} style={this.style.exportButton} handleClick={() => { this.binding.checkIdentityValidity(false) }}>
                  <TranslationView style={this.style.exportButtonText}>
                    VerifyIDValidity
                  </TranslationView>
                </ButtonView>
              }
            </View>
            <br></br>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
              {(this.state.isExport || this.state.isAdmin) &&
                <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButtonfsma} handleClick={() => { this.binding.exportAvertiNullUsers() }}>
                  <TranslationView style={this.style.exportButtonText}>
                    ExportYourAvertisNullUsers
                  </TranslationView>
                </ButtonView>}

              {(this.state.isExport || this.state.isAdmin) &&
                <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportNonavertisButton} handleClick={() => { this.binding.exportNonAvertisUsers() }}>
                  <TranslationView style={this.style.exportButtonText}>
                    ExportYourUsersNonAvertis
                  </TranslationView>
                </ButtonView>}
            </View>

            {this.state.vbexport  &&
            <Text style={this.style.exportText}>Export.. {this.state.exportPercent}% {this.state.exportPage}/{this.state.nbPage}</Text>
            }


            <View style={this.style.boxContainer}>
              {/* Research Input */}
              < View style={this.style.containerFilter}>
                <TranslationView style={{ marginRight: '20px' }}>Search</TranslationView>
                <DropDownView style={{ marginRight: '20px' }} selectedValue={this.state.selectedStatus} values={this.state.statuses} onValueChange={(value: any) => this.binding.handleStatusChanged(value)}></DropDownView>
                <ResearchInputView
                  textInFront=""
                  placeholder="search"
                  handlerResearching={(text: any) => this.binding.handleResearching(text)}
                  handlingClose={() => { this.binding.handlingClose() }}
                  text={this.state.text}
                />

              </View >
            </View>

            <TableView
              handleResetStepView={this.binding.handleResetStepView}
              resetStepView={this.state.resetStepView}
              headerData={this.state.tableHeaders}
              rows={this.state.rows}
              numberOfRows={20}
              isLoading={this.state.isLoading}
              lottie={require('./assets/loading.json')}
              isSortable={true}
              resetTable={this.binding.resetTable}
              datas={this.state.datas}
              fillTables={(value: any[]) => { this.binding.pushRows(value) }}
              keyDatas={["id", "aspNetUserId", "creationDate", "name", "clientNumber", "email", "userStatusTitle"]}
              headerIndex={[0, 1, 2, 3, 4, 5, 6]}
              headerTextStyle={this.style.headerText}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              styleOfChoosenButton={this.style.stepColor}
            ></TableView>
          </View>
        </View>
      </View>
    );
  }
}