import {ContentViewModel, NavigationManager, Application, Managers, ContentView} from '@levelapp/softfabric';
import ReservationViewState from './ReservationViewState';
import ReservationViewProps from './ReservationViewProps';
import StateManager from '../../../../../Common/Managers/StateManager';
import { View, Text } from 'react-native';

import React from 'react';
import AddReservationPopupView from './modules/addreservationpopup/AddReservationPopupView';
import InvestmentReservation from '../../../../../DTO/InvestmentReservation';
import InvestmentReservationOperation from '../../../../../Transfer/InvestmentReservationOperation';
import StateManagerConst from '../../../../../Common/Managers/StateManagerConst';
import ReservationViewStyle from './ReservationViewStyle';
import { ButtonView } from '@levelapp/softfabric-ui';
import TransferReservationPopUpView from './modules/transferreservationpopup/TransferReservationPopUpView';
import InvestmentsOperation from '../../../../../Transfer/InvestmentsOperations';
import RoleManager from '../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../Common/Managers/ExtendedManagers';

export default class ReservationViewModel extends ContentViewModel<ReservationViewState, ReservationViewProps, ReservationViewStyle>{

    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    InvestmentReservationOperation = new InvestmentReservationOperation();
    InvestmentsOperation = new InvestmentsOperation();
    textResearch: string = "";
    interval: any;

    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.INVESTMENT_RESERVATION);
        this.interval = null;

        /* Initial State */
        this.initialState({
            isLoading: true,
            statuses: [{ id: 0, title: "ALL", reservations: null },{ id: 1, title: "TRANSFERER", reservations: null }, { id: 2, title: "NON-TRANSFERER", reservations: null }],
            reservations: [],
            resetStepView: false,
            rows: [],
            initialRow: [],
            selectedStatus: previousState != undefined ? previousState.selectedStatus : { id: 0, title: "ALL", reservations: null },
            initialData: [],
            tableHeaders: [],
            datas: [],
            isAdmin: this.RoleManager.isAdmin,
            text: previousState != undefined ? previousState.text : '',
            nbPage: 0,
            currentPage: 1,
        });

        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.showReservationPopUp = this.showReservationPopUp.bind(this);
        this.addReservation = this.addReservation.bind(this);
        this.handleSuccessReservationCallback = this.handleSuccessReservationCallback.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handlerResearching = this.handleResearching.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.showTransferPopUp = this.showTransferPopUp.bind(this);
        this.transferReservation = this.transferReservation.bind(this);
    }

    componentDidMount(){
        this.refresh()
    }

    refresh(){
        this.InvestmentReservationOperation.getSearchPagination(this.state().selectedStatus.title, this.state().currentPage, 20, this.textResearch, this.handleSuccessReservationCallback);
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value }, () => {
            this.handleResearching(this.state().text);
        });
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true, text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handleSuccessReservationCallback(ret: any){
        let reservations: any[] = ret.objects;
        let datas: any[] = [];
        let rows: any[] = [];

        if (ret.totalPage == 0) {
            this.setField({ datas: [] });
            this.setField({ rows: [] });
            this.setField({ initialData: [] });
            this.setField({ initialRow: [] });
            this.setField({ tableHeaders: ['ID', 'NOM', 'PRENOM', 'EMAIL', 'TELEPHONE', 'MONTANT', 'PROJET', 'STATUS', 'DATE DE CREATION'] });
            this.setField({ users: undefined });
            this.setField({ isLoading: false });
            this.setField({ nbPage: ret.totalPage });
            return;
        }

        reservations.forEach((reservation: any) => {
            var date = new Date(reservation.creationDate);
            var dateAsString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " ;
                //date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
            datas.push(reservation);
            rows.push(
                <View>
                    <Text style={this.style().text}>{reservation.id}</Text>
                    <Text style={this.style().text}>{reservation.lastName}</Text>
                    <Text style={this.style().text}>{reservation.firstName}</Text>
                    <Text style={this.style().text}>{reservation.email}</Text>
                    <Text style={this.style().text}>{reservation.phone}</Text>
                    <Text style={this.style().text}>{reservation.amount}</Text>
                    <Text style={this.style().text}>{reservation.project.projectName}</Text>
                    {reservation.hasBeenTransferred ?
                        <Text style={this.style().text}>{"Transfert effectué"}</Text>
                        :
                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showTransferPopUp(reservation) }}>
                            <Text style={this.style().text} >Transférer</Text>
                        </ButtonView>
                    }
                    
                    <Text style={this.style().text}>{dateAsString}</Text>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ tableHeaders: ['ID', 'NOM', 'PRENOM', 'EMAIL', 'TELEPHONE', 'MONTANT', 'PROJET', 'STATUS', 'DATE DE CREATION'] });
        this.setField({ reservations: reservations });
        this.setField({ isLoading: false });
        this.setField({ nbPage: ret.totalPage });
    }

    showTransferPopUp(reservation: any){
        this.showPopUp(<TransferReservationPopUpView reservation={reservation} callBack={this.transferReservation}/>);
    }

    showReservationPopUp() {
        this.showPopUp(<AddReservationPopupView callBack={this.addReservation}></AddReservationPopupView>);
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    addReservation(investmentReservation: InvestmentReservation) {
        investmentReservation.project = null;
        this.InvestmentReservationOperation.addReservation(investmentReservation, ()=>{
            this.handleResearching("");
        });
    }

    transferReservation(reservation: any) {
        this.InvestmentsOperation.transferReservation(reservation, ()=>{
            this.handleResearching("");
        });
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    resetTable() {
        if (this.state().text == "")
            this.handlingClose();
        else (this.handlerResearching(this.state().text))
    }

    handlerResearching(txt: string) {
        let rows: any[] = [];

        let datas = this.state().initialData.filter(reservation => {
            let contains = false;

            for (let key in reservation) {
                contains = JSON.stringify(reservation[key]).toLocaleLowerCase().includes(txt.toLocaleLowerCase());
                if (contains) {
                    this.pushRow(reservation, rows);
                    return contains;
                }
            }
            return contains;

        });
        this.setField({ text: txt, datas: datas, rows: rows, resetStepView: true });
    }

    handlingClose() {
        this.setField({ text: "", datas: this.state().initialData, rows: this.state().initialRow });
    }

    pushRow(reservation: any, rowsToPush: any[]) {

        var date = new Date(reservation.creationDate);
        var dateAsString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " ";
        rowsToPush.push(
            <View>
                <Text style={this.style().text}>{reservation.id}</Text>
                <Text style={this.style().text}>{reservation.lastName}</Text>
                <Text style={this.style().text}>{reservation.firstName}</Text>
                <Text style={this.style().text}>{reservation.email}</Text>
                <Text style={this.style().text}>{reservation.phone}</Text>
                <Text style={this.style().text}>{reservation.amount}</Text>
                <Text style={this.style().text}>{reservation.project.projectName}</Text>
                {reservation.hasBeenTransferred ?
                    <Text style={this.style().text}>{"Transfert effectué"}</Text>
                    :
                    <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showTransferPopUp(reservation) }}>
                        <Text style={this.style().text} >Transférer</Text>
                    </ButtonView>
                }
                <Text style={this.style().text}>{dateAsString}</Text>
        </View>
        )
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(reservation => {
            this.pushRow(reservation, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }
}