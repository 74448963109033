import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import UserDocumentsViewState from './UserDocumentsViewState';
import UserDocumentsViewProps from './UserDocumentsViewProps';
import Profile from '../../../../../../../../../../../../DTO/Profile';
import DocumentsOperation from '../../../../../../../../../../../../Transfer/DocumentsOperations';

export default class UserDocumentsViewModel extends ContentViewModel<UserDocumentsViewState, UserDocumentsViewProps>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    fileUploaded = 0;

    constructor(props: ContentView) {
        super(props);
        var usr = this.parameters<Profile>();

        this.initialState({
            profile: usr,
            isLoading: false,
            domiciliation: undefined,
            identityPieceRecto: undefined,
            identityPieceVerso: undefined,
            proofOfBank: undefined,
            identificationCertificateUrl: undefined,
            isIdentificationLoading: false,
            error: false,
            errorUpload: false
        })

        this.handleIdentityPieceRectoChange = this.handleIdentityPieceRectoChange.bind(this);
        this.handleIdentityPieceVersoChange = this.handleIdentityPieceVersoChange.bind(this);
        this.handleDomiciliationChange = this.handleDomiciliationChange.bind(this);
        this.handleProofOfBankChange = this.handleProofOfBankChange.bind(this);
        this.handleIdentificationCertificateUrlChange = this.handleIdentificationCertificateUrlChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    /* HANDLERS */
    handleSave() {
        this.setField({ isLoading: true });
        this.saveRectoId();
        this.saveVersoId();
        this.saveDomiciliation();
        this.saveProofOfBank();
        this.saveIdentificationCertificateUrl();
    }

    handleIdentificationCertificateUrlChange(file: File) {
        if (this.state().error) {
            this.setField({ identificationCertificateUrl: file, error: false });
        }
        else {
            this.setField({ identificationCertificateUrl: file, });
        }
    }

    handleProofOfBankChange(file: File) {
        if (this.state().error) {
            this.setField({ proofOfBank: file, error: false });
        }
        else {
            this.setField({ proofOfBank: file, });
        }
    }

    handleIdentityPieceRectoChange(file: File) {
        if (this.state().error) {
            this.setField({ identityPieceRecto: file, error: false });
        }
        else {
            this.setField({ identityPieceRecto: file, });
        }
    }

    handleIdentityPieceVersoChange(file: File) {
        if (this.state().error) {
            this.setField({ identityPieceVerso: file, error: false });
        }
        else {
            this.setField({ identityPieceVerso: file });
        }
    }

    handleDomiciliationChange(file: File) {
        if (this.state().error) {
            this.setField({ domiciliation: file, error: false });
        }
        else {
            this.setField({ domiciliation: file });
        }
    }

    /* METHODS */
    saveProofOfBank() {
        if (this.state().proofOfBank != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().proofOfBank as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: this.state().profile.id,
                    Type: "ProofOfBank"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                }, () => {
                    this.setField({ errorUpload: true }); this.checkIfAllFilesHaveBeenUploaded();
                }
                );
            };
            reader.readAsDataURL((this.state().proofOfBank as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveIdentificationCertificateUrl() {
        if (this.state().identificationCertificateUrl != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().identificationCertificateUrl as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: this.state().profile.id,
                    Type: "AvertiForm"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                }, () => {
                    this.setField({ errorUpload: true }); this.checkIfAllFilesHaveBeenUploaded();
                }
                );
            };
            reader.readAsDataURL((this.state().identificationCertificateUrl as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveRectoId() {
        if (this.state().identityPieceRecto != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().identityPieceRecto as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: this.state().profile.id,
                    Type: "RectoId"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                }, () => {
                    this.setField({ errorUpload: true }); this.checkIfAllFilesHaveBeenUploaded();
                }
                );
            };
            reader.readAsDataURL((this.state().identityPieceRecto as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveVersoId() {
        if (this.state().identityPieceVerso != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().identityPieceVerso as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: this.state().profile.id,
                    Type: "VersoId"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                }, () => {
                    this.setField({ errorUpload: true }); this.checkIfAllFilesHaveBeenUploaded();
                }
                );
            };
            reader.readAsDataURL((this.state().identityPieceVerso as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveDomiciliation() {
        if (this.state().domiciliation != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().domiciliation as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: this.state().profile.id,
                    Type: "Domiciliation"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                }, () => {
                    this.setField({ errorUpload: true }); this.checkIfAllFilesHaveBeenUploaded();
                }
                );
            };
            reader.readAsDataURL((this.state().domiciliation as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }


    checkIfAllFilesHaveBeenUploaded() {
        this.fileUploaded++;
        if (this.fileUploaded == 4) {
            this.setField({ isLoading: false });
            if (this.state().errorUpload) {
                alert("Une erreur est survenue lors de l'upload, veuillez ré essayer");
                this.NavigationManager.navigate('/dashboard/users');

            }
            else {
                alert("Vos documents ont été téléchargé");
                this.NavigationManager.navigate('/dashboard/users/edit/profile', true, this.state().profile);
            }
        }
    }
}