import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class DocumentsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);  

    async uploadDocuments(documents: any, callback: Function, error: Function)
    {
        try {
            this.httpClient.handleSomethingWentWrong = () => {};
            this.httpClient.handleError = () => {};
            this.httpClient.put("api/Documents/UploadDocuments", [], documents).then((response: any) => 
            {
                if (response != undefined)
                {
                    callback(response.data.data);
                }
                else {
                    error();
                }
            });
        } catch(e) {
            error();
        }
       
    }

    async getByProject(id: string,callback: Function)
    {
        this.httpClient.get("api/Documents/GetByProjectId/"+id, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async insertDocument(document: any, callback: Function)
    {
        this.httpClient.post("api/Documents/Insert", [], document).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async deleteDocument(id: string,callback: Function)
    {
        this.httpClient.delete("api/Documents/Delete/"+id, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}