import React from 'react';
import { ContentView, Application } from '@levelapp/softfabric';
import BlockViewState from './BlockViewState';
import BlockViewProps from './BlockViewProps';
import BlockViewStyle from './BlockViewStyle';
import BlockViewModel from './BlockViewModel';
import { View, TextInput, Text } from 'react-native';
import { TranslationView, ButtonView, StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import ReactQuill from 'react-quill';

export default class BlockView extends ContentView<BlockViewProps, BlockViewState, BlockViewModel, BlockViewStyle>
{
  MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  /* CONSTRUCTOR */
  constructor(props: BlockViewProps) {
    super(props);

    // Binding
    this.bind(new BlockViewModel(this), new BlockViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.box}>
        <View style={{ padding: 50 }}>


          <View style={this.style.InputRow}>
            <TranslationView style={this.style.title}>State</TranslationView>
            <TranslationView style={this.style.status} >{this.props.afficherStatus(this.props.currentBlock.publishingStateId)}</TranslationView>
          </View>

          <View style={this.style.buttonList}>
            {/* REMOVE TAB */}
            {this.props.currentBlock != null &&
              <View style={this.style.button}>
                <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.removeBlock} textColor="white" isLoading={this.state.isLoading} backgroundColor="#FF392C">
                  <TranslationView>
                    Delete
                    </TranslationView>
                </ButtonView>
              </View>
            }
            {/* SEND BUTTON */}
            <View style={this.style.button}>
              <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.submit} textColor="white" isLoading={this.state.isLoading} backgroundColor="#4ECF6C">
                <TranslationView>
                  Save
                    </TranslationView>
              </ButtonView>
            </View>
            {/* DEPUBLISH*/}
            {(this.props.currentBlock.publishingStateId == 2 || this.props.currentBlock.publishingStateId == 4) && this.props.currentBlock.id != 0 &&
              <View style={this.style.button}>
                <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.dePublish} textColor="white" isLoading={this.state.isLoading} backgroundColor="grey">
                  <TranslationView>
                    Depublish
                  </TranslationView>
                </ButtonView>
              </View>
            }
            {/* PUBLISH STAGING */}
            {this.props.currentBlock.publishingStateId != 2 && this.props.currentBlock.publishingStateId != 4 && this.props.currentBlock.id != 0 &&
              <View style={this.style.button}>
                <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.publishStaging} textColor="white" isLoading={this.state.isLoading} backgroundColor="#615D8A">
                  <TranslationView>
                    PublishStaging
                    </TranslationView>
                </ButtonView>
              </View>
            }
            {/* PUBLISH PROD */}
            {this.props.currentBlock.publishingStateId != 3 && this.props.currentBlock.publishingStateId != 4 && this.props.currentBlock.id != 0 &&
              <View style={this.style.button}>
                <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.publishProd} textColor="white" isLoading={this.state.isLoading} backgroundColor={this.MyStyleManager.primaryColor}>
                  <TranslationView>
                    PublishProd
                    </TranslationView>
                </ButtonView>
              </View>
            }
          </View>
          <View style={this.style.orderView}>
            <View style={this.style.InputRow}>
              <Text style={this.style.title}>Block Order:</Text>
              <TextInput style={{ display: 'flex', fontSize: 20, width: 50, borderWidth: 1, borderColor: 'grey', }} placeholder="?" onChangeText={(order: string) => this.binding.handleOrderBlockChange(order)} value={this.props.currentBlock.order} />
            </View>
          </View>
          <View style={{ paddingBottom: 100 }}>
            <TranslationView style={this.style.title} >fr</TranslationView>
            <View style={{ display: 'flex', flex: 1, flexDirection: 'row' }} >
              <ReactQuill modules={this.binding.wysiwygModule()} theme={"snow"} value={this.props.currentBlock.content.fr} onChange={(blockContent: string) => { this.binding.handleContentChanged(blockContent, "fr") }} />
            </View>
          </View>
          <View style={{ paddingBottom: 100 }}>
            <TranslationView style={this.style.title} >nl</TranslationView>
            <View style={{ display: 'flex', flex: 1, flexDirection: 'row' }} >
              <ReactQuill modules={this.binding.wysiwygModule()} theme={"snow"} value={this.props.currentBlock.content.nl} onChange={(blockContent: string) => { this.binding.handleContentChanged(blockContent, "nl") }} />
            </View>
          </View>
          <View style={{ paddingBottom: 100 }}>
            <TranslationView style={this.style.title} >en</TranslationView>
            <View style={{ display: 'flex', flex: 1, flexDirection: 'row' }} >
              <ReactQuill modules={this.binding.wysiwygModule()} theme={"snow"} value={this.props.currentBlock.content.en} onChange={(blockContent: string) => { this.binding.handleContentChanged(blockContent, "en") }} />
            </View>
          </View>
        </View>
      </View>
    );
  }
}