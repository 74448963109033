import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import MailSendedPopUpViewState from './MailSendedPopUpViewState';
import MailSendedPopUpViewProps from './MailSendedPopUpViewProps';
import MailSendedPopUpViewStyle from './MailSendedPopUpViewStyle';
import MailSendedPopUpViewModel from './MailSendedPopUpViewModel';
import { View, TouchableWithoutFeedback, Image, Text } from 'react-native';

export default class MailSendedPopUpView extends ContentView<MailSendedPopUpViewProps, MailSendedPopUpViewState, MailSendedPopUpViewModel, MailSendedPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:MailSendedPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new MailSendedPopUpViewModel(this), new MailSendedPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
            <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20, justifyContent: 'flex-end' }}
              source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
          </TouchableWithoutFeedback>
        </View>

        <Text style={this.style.title}>
          L'email a été envoyé
         </Text>
      </View>
    );
  }
}