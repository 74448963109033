import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import SubscribePopUpViewProps from './SubscribePopUpViewProps';
import SubscribePopUpViewState from './SubscribePopUpViewState';
import SubscribePopUpViewModel from './SubscribePopUpViewModel';
import SubscribePopUpViewStyle from './SubscribePopUpViewStyle';

export default class SubscribePopUpView extends ContentView<SubscribePopUpViewProps, SubscribePopUpViewState, SubscribePopUpViewModel, SubscribePopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SubscribePopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new SubscribePopUpViewModel(this), new SubscribePopUpViewStyle(this));
  }


   /* RENDERING */
   render()
   {
     return (
       <View style={{ marginHorizontal: 50, minWidth: 600 }}>
 
         <TranslationView style={this.style.title}>
          SuccesfyllSub
         </TranslationView>
       </View>
     );
   }
 }