import { Operation } from '@levelapp/softfabric';
import PasymentInformationService from '../Proxy/PaymentInformationService';


export default class PaymentInformationOperation extends Operation
{
    _service : PasymentInformationService;

    constructor()
    {
        super();
        this._service = new PasymentInformationService();
    }       

    async sendPaymentConfirmationEmail(documents: any, callback: Function = () => { })
    {
        await (this._service as PasymentInformationService).sendPaymentConfirmationEmail(documents, callback);
    }
}