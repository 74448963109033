import { Operation } from '@levelapp/softfabric';
import NewsletterService from '../Proxy/NewsletterService';


export default class NewsletterOperation extends Operation
{
    _service : NewsletterService;

    constructor()
    {
        super();
        this._service = new NewsletterService();
    }   

    async subscribe(email: any, callback: Function = () => { })
    {
        await (this._service as NewsletterService).subscribe(email, callback);
    }
}