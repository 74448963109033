import { ContentViewModel, ContentView, NavigationManager, Managers, Application } from '@levelapp/softfabric';
import LegalEntityInfosViewState from './LegalEntityInfosViewState';
import LegalEntityInfosViewProps from './LegalEntityInfosViewProps';
import LegalEntityOperations from '../../../../../../../../../Transfer/LegalEntityOperations';
import StatusOperation from '../../../../../../../../../Transfer/StatusOperations';
import LegalEntity from '../../../../../../../../../DTO/LegalEntity';
import ProjectInvestementStatusUpdatedView from '../../../../../../../../../Components/projectinvestementstatusupdated/ProjectInvestementStatusUpdatedView';
import React from 'react';
import RoleManager from '../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../Common/Managers/ExtendedManagers';
import LegalFormsOperations from '../../../../../../../../../Transfer/LegalFormsOperations';

export default class LegalEntityInfosViewModel extends ContentViewModel<LegalEntityInfosViewState, LegalEntityInfosViewProps>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    legalEntityOperation = new LegalEntityOperations();
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    _legalFormsOperations = new LegalFormsOperations();

    constructor(props: ContentView) {
        super(props);
        var entity = this.parameters<LegalEntity>();

        entity.bceRegistrationImage = this.CheckImage(entity.bceRegistrationUrl);
        entity.leadersNominationImage = this.CheckImage(entity.leadersNominationUrl);
        entity.bankIdImage = this.CheckImage(entity.bankIdUrl);
        entity.uboImage = this.CheckImage(entity.uboUrl);

        this.initialState({
            activityAreas: [],
            isLoading: true,
            legalEntity: entity,
            legalEntityId: entity.id,
            statuses: [],
            selectedStatus: '',
            isUpdating: false,
            forms: []
        })

        this.CheckImage = this.CheckImage.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleSuccessLegalEntityCallback = this.handleSuccessLegalEntityCallback.bind(this);
        this.ChangeStatus = this.ChangeStatus.bind(this);
        this.formatBic = this.formatBic.bind(this);
        this.formatIban = this.formatIban.bind(this);

        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.handleCorporateNameChanged = this.handleCorporateNameChanged.bind(this);
        this.handleLegalFormChanged = this.handleLegalFormChanged.bind(this);
        this.handleBceNumberChanged = this.handleBceNumberChanged.bind(this);
        this.handleTvaNumberChanged = this.handleTvaNumberChanged.bind(this);
        this.handleStreetChanged = this.handleStreetChanged.bind(this);
        this.handleNumberChanged = this.handleNumberChanged.bind(this);
        this.handleBoxChanged = this.handleBoxChanged.bind(this);
        this.handlePostCodeChanged = this.handlePostCodeChanged.bind(this);
        this.handleLocalityChanged = this.handleLocalityChanged.bind(this);
        this.handlePhoneNumberChanged = this.handlePhoneNumberChanged.bind(this);
        this.handleIbanChanged = this.handleIbanChanged.bind(this);
        this.handleBicChanged = this.handleBicChanged.bind(this);
        this.handleLeiCodeChanged = this.handleLeiCodeChanged.bind(this);
        this.handleLeiValidityChanged = this.handleLeiValidityChanged.bind(this);
        this.handleNumberOfLeaderChanged = this.handleNumberOfLeaderChanged.bind(this);
        this.handleAssujettiTvaChanged = this.handleAssujettiTvaChanged.bind(this);
        this.handleActivityAreaChanged = this.handleActivityAreaChanged.bind(this);
        this.handleNaceCodeChanged = this.handleNaceCodeChanged.bind(this);
        this.handleMonitorParutionDateChanged = this.handleMonitorParutionDateChanged.bind(this);
        this.handleConstitutionDateChanged = this.handleConstitutionDateChanged.bind(this);
        this.handleAdminCommentChanged = this.handleAdminCommentChanged.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleCountryChanged = this.handleCountryChanged.bind(this);

        this.changeComment = this.changeComment.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleNumberOfUboChanged = this.handleNumberOfUboChanged.bind(this);
        this.handleDynamicFirstNameChanged = this.handleDynamicFirstNameChanged.bind(this);
        this.handleDynamicLastNameChanged = this.handleDynamicLastNameChanged.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleDynamicFunctionChanged = this.handleDynamicFunctionChanged.bind(this);
        this.handleDynamicPercentageChanged = this.handleDynamicPercentageChanged.bind(this);
        this.handleDynamicNationalNumberChanged = this.handleDynamicNationalNumberChanged.bind(this);
        this.addLeader = this.addLeader.bind(this);
        this.addUbo = this.addUbo.bind(this);
        this.deleteLeader = this.deleteLeader.bind(this);
        this.deleteUbo = this.deleteUbo.bind(this);
        this.handleLegalFormObjectChanged = this.handleLegalFormObjectChanged.bind(this);
        this.handleassujettiIsImpChanged = this.handleassujettiIsImpChanged.bind(this);
    }

    componentDidMount() {
        let state = this.state();
        for (let i = 1; i < 30; i++) {
            const element = this.translate(`ActivityAreas${i}`);
            state.activityAreas.push(element);
        }
        this.setField(state);
        this.loadLegalForms();
        this.refresh();
    }

    handleUpdate() {
        this.setField({ isUpdating: true });
        let legalEntities = this.state().legalEntity;
        legalEntities.assujettiIsImp = legalEntities.assujettiIsImp =='Oui' ? true : false;
        this.legalEntityOperation.updateLegalEntity(this.state().legalEntity, () => {
            this.setField({ isUpdating: false });
            this.refresh();
        })
    }

    addLeader() {
        let state = this.state().legalEntity;
        let leaderDto = { id: 0, firstName: "", email: "", function: "", ubo: false, lastName: "", nationalNumber: "" }
        state.legalEntityLeaders.push(leaderDto);
        state.numberOfLeader += 1;
        this.setField(state);
    }

    addUbo() {
        let state = this.state().legalEntity;
        let leaderDto = { id: 0, firstName: "", email: "", percentage: "", ubo: true, lastName: "", nationalNumber: "" }
        state.legalEntityLeaders.push(leaderDto);
        state.numberOfUbo += 1;
        this.setField(state);
    }

    deleteLeader(key: number) {
        let state = this.state().legalEntity;
        state.legalEntityLeaders.splice(key, 1);
        state.numberOfLeader -= 1;
        this.setField(state);
    }

    deleteUbo(key: number) {
        let state = this.state().legalEntity;
        state.legalEntityLeaders.splice(key, 1);
        state.numberOfUbo -= 1;
        this.setField(state);
    }


    changeComment() {
        this.setField({ isUpdating: true });
        this.legalEntityOperation.updateLegalEntity({ id: this.state().legalEntity.id, adminComment: this.state().legalEntity.adminComment }, () => {
            this.setField({ isUpdating: false });
            this.refresh();
        })
    }

    handleAdminCommentChanged(adminComment: string) {
        let state = this.state();
        state.legalEntity.adminComment = adminComment;
        this.setField(state);
    }

    handleNaceCodeChanged(naceCode: string) {
        let state = this.state();
        state.legalEntity.naceCode = naceCode;
        this.setField(state);
    }

    handleAssujettiTvaChanged(assujettiTva: any) {
        let state = this.state();
        state.legalEntity.assujettiTva = assujettiTva.value;
        this.setField(state);
    }

    handleassujettiIsImpChanged(assujettiIsImp: any) {
        let state = this.state();
        state.legalEntity.assujettiIsImp = assujettiIsImp.value;
        this.setField(state);
    }

    handleActivityAreaChanged(activityArea: any) {
        let state = this.state();
        state.legalEntity.activityArea = activityArea.value;
        this.setField(state);
    }

    handleLegalFormObjectChanged(form: any) {
        let legalForm = this.state().forms.find(x => x.id == form.value);
        let state = this.state();
        state.legalEntity.legalFormObject.fullName = legalForm.fullName;
        state.legalEntity.legalFormObject.id = form.value;
        this.setField(state);
    }

    handleNumberOfLeaderChanged(numberOfLeader: string) {
        let state = this.state();
        state.legalEntity.numberOfLeader = numberOfLeader;
        this.setField(state);
    }

    handleDynamicFirstNameChanged(value: any, i: any) {
        let state = this.state();
        state.legalEntity.legalEntityLeaders[i].firstName = value;
        this.setField(state);
    }

    handleDynamicLastNameChanged(value: any, i: any) {
        let state = this.state();
        state.legalEntity.legalEntityLeaders[i].lastName = value;
        this.setField(state);
    }

    handleDynamicFunctionChanged(value: any, i: any) {
        let state = this.state();
        state.legalEntity.legalEntityLeaders[i].function = value;
        this.setField(state);
    }

    handleDynamicEmailChanged(value: any, i: any) {
        let state = this.state();
        state.legalEntity.legalEntityLeaders[i].email = value;
        this.setField(state);
    }

    handleDynamicNationalNumberChanged(value: any, i: any) {
        let state = this.state();
        state.legalEntity.legalEntityLeaders[i].nationalNumber = value;
        this.setField(state);
    }

    handleDynamicPercentageChanged(value: any, i: any) {
        let state = this.state();
        state.legalEntity.legalEntityLeaders[i].percentage = value;
        this.setField(state);
    }


    handleNumberOfUboChanged(numberOfUbo: string) {
        let state = this.state();
        state.legalEntity.numberOfUbo = numberOfUbo;
        this.setField(state);
    }

    handleLeiValidityChanged(leiValidity: string) {
        let state = this.state();
        state.legalEntity.leiValidity = leiValidity;
        this.setField(state);
    }

    handleConstitutionDateChanged(constitutionDate: string) {
        let state = this.state();
        state.legalEntity.constitutionDate = constitutionDate;
        this.setField(state);
    }

    handleMonitorParutionDateChanged(monitorParutionDate: string) {
        let state = this.state();
        state.legalEntity.monitorParutionDate = monitorParutionDate;
        this.setField(state);
    }

    handleLeiCodeChanged(leiCode: string) {
        let state = this.state();
        state.legalEntity.leiCode = leiCode;
        this.setField(state);
    }

    handleBicChanged(bic: string) {
        let state = this.state();
        state.legalEntity.bic = this.formatBic(bic);
        this.setField(state);
    }

    formatBic(bicToCheck: string) {
        bicToCheck = bicToCheck.replace(/\s/g, '');
        if (bicToCheck.length === 8) {
            let bicCopy = "";
            bicCopy += bicToCheck.substring(0, 4) + " " + bicToCheck.substring(4, 6) + " " + bicToCheck.substring(6, 8);
            return bicCopy.toLocaleUpperCase();
        } else if (bicToCheck.length === 11) {
            let bicCopy = "";
            bicCopy += bicToCheck.substring(0, 4) + " " + bicToCheck.substring(4, 6) + " " + bicToCheck.substring(6, 8) + " " + bicToCheck.substring(8, 11);
            return bicCopy.toLocaleUpperCase();
        }
        return bicToCheck;
    }

    handleIbanChanged(iban: string) {
        let state = this.state();
        state.legalEntity.iban = this.formatIban(iban);
        this.setField(state);
    }

    formatIban(iban: string) {
        iban = iban.replace(/\s/g, '');
        if (iban.length > 4) {
            let ibanCopy = "";
            for (let i = 0; i < iban.length; i++) {
                if (i !== 0 && i % 4 === 0) {
                    ibanCopy += " "
                }
                ibanCopy += iban[i]

            }
            return ibanCopy.toLocaleUpperCase();
        }
        return iban;
    }

    handlePhoneNumberChanged(phoneNumber: string) {
        let state = this.state();
        state.legalEntity.phoneNumber = phoneNumber;
        this.setField(state);
    }

    handleEmailChanged(email: string) {
        let state = this.state();
        state.legalEntity.email = email;
        this.setField(state);
    }

    handleLocalityChanged(locality: string) {
        let state = this.state();
        state.legalEntity.address.locality = locality;
        this.setField(state);
    }

    handleCountryChanged(country: string) {
        let state = this.state();
        state.legalEntity.address.country = country;
        this.setField(state);
    }

    handlePostCodeChanged(postCode: string) {
        let state = this.state();
        state.legalEntity.address.postCode = postCode;
        this.setField(state);
    }

    handleBoxChanged(box: string) {
        let state = this.state();
        state.legalEntity.address.box = box;
        this.setField(state);
    }

    handleNumberChanged(number: string) {
        let state = this.state();
        state.legalEntity.address.number = number;
        this.setField(state);
    }

    handleStreetChanged(street: string) {
        let state = this.state();
        state.legalEntity.address.street = street;
        this.setField(state);
    }

    handleTvaNumberChanged(tvaNumber: string) {
        let state = this.state();
        state.legalEntity.tvaNumber = tvaNumber;
        this.setField(state);
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value });
    }

    handleCorporateNameChanged(corporateName: string) {
        let state = this.state();
        state.legalEntity.corporateName = corporateName;
        this.setField(state);
    }

    handleLegalFormChanged(legalForm: string) {
        let state = this.state();
        state.legalEntity.legalForm = legalForm;
        this.setField(state);
    }

    handleBceNumberChanged(bceNumber: string) {
        let state = this.state();
        state.legalEntity.bceNumber = bceNumber;
        this.setField(state);
    }

    ChangeStatus() {
        this.parameters<any>().legalEntityStatus = this.state().selectedStatus;
        this.legalEntityOperation.updateLegalEntityStatus({
            "legalEntityId": this.state().legalEntity.id,
            "statusId": this.state().selectedStatus.id
        }).then(() => {
            this.showPopUp(
                <ProjectInvestementStatusUpdatedView />
            );
        }).then(() => {
            setTimeout(() => { this.NavigationManager.navigate('/dashboard/legalEntities', true) }, 1000);
            setTimeout(() => { this.closePopUp() }, 1500);
        });
    }


    refresh() {
        this.legalEntityOperation.getLegalEntityById(this.state().legalEntityId, this.handleSuccessLegalEntityCallback);
    }

    handleSuccessLegalEntityCallback(legalEntity: any) {
        new StatusOperation().getStatuses("M", (statusList: any) => {
            var statuses = [{ id: 0, title: "", users: null }, ...statusList.filter((x: any) => x.title != "PENDING" && x.title != "NEW" && x.title != legalEntity.legalEntityStatus.title)];
            var ordering = {} as any, // map for efficient lookup of sortIndex
                sortOrder = ['VALIDATED', 'DOC MISSING - ALL', 'DOC MISSING - BCE', 'DOC MISSING - NOMINATION', 'DOC MISSING - BANK ID', 'DOC MISSING - UBO', 'BLOCKED', 'REFUSED'];
            for (var i = 0; i < sortOrder.length; i++) {
                ordering[sortOrder[i]] = i;
            }
            statuses.sort(function (a, b) {
                return (ordering[a.title] - ordering[b.title]) || a.title.localeCompare(b.title);
            });
            this.setField({ statuses: statuses });
        });

        legalEntity.bceRegistrationImage = this.CheckImage(legalEntity.bceRegistrationUrl);
        legalEntity.leadersNominationImage = this.CheckImage(legalEntity.leadersNominationUrl);
        legalEntity.bankIdImage = this.CheckImage(legalEntity.bankIdUrl);
        legalEntity.uboImage = this.CheckImage(legalEntity.uboUrl);

        if (legalEntity.legalFormObject)
            legalEntity.legalFormObject.fullName = legalEntity.legalFormObject.code + " - " + legalEntity.legalFormObject.title.fr;
        else
            legalEntity.legalFormObject = {
                "fullName": ""
            };

        let state = this.state();

        state.legalEntity = legalEntity;
        state.isLoading = false;
        state.oldLegalEntity = JSON.parse(JSON.stringify(legalEntity));

        this.setField(state);
    }

    CheckImage(url: any) {
        if (url == undefined || url == null || url == "")
            return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/no-picture.jpg";

        var urlLower = url.toLowerCase();
        if (urlLower.includes("png") || urlLower.includes("jpg") || urlLower.includes("jpeg"))
            return url;

        else if (urlLower.includes("pdf") || urlLower.includes("doc"))
            return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/pdf.png";

        return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/no-picture.jpg";
    }

    loadLegalForms() {
        this._legalFormsOperations.get((forms: any[]) => {
            forms.forEach((f: any) => {
                f.fullName = f.code + " - " + f.title.fr;
            });
            this.setField((previousState) => previousState.forms = forms);
        })
    }

}
