import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import TableViewState from './TableViewState';
import TableViewProps from './TableViewProps';
import TableViewStyle from './TableViewStyle';
import TableViewModel from './TableViewModel';
import { View } from 'react-native';
import StepView from '../step/StepView';
import { LottieView } from '@levelapp/softfabric-ui';

export default class TableView extends ContentView<TableViewProps, TableViewState, TableViewModel, TableViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TableViewProps)
  {
    super(props);

    // Binding
    this.bind(new TableViewModel(this), new TableViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    {/* TABLE CREATION */}
    var table = this.binding.getCurrentTable();
    this.binding.getResetStepView();
    return (
      <View style={this.props.style}> 
        {/* PAGINATION */}
        {this.props.isLoading ? <LottieView height={75} width={75} source={this.props.lottie}/> : table}
        <View>
        <StepView styleOfChoosenButton={this.props.styleOfChoosenButton} currentPage={this.binding.getCurrentPage()} numberDisplays={this.binding.getNumberTotPages()} handleChangingPage={this.binding.changePage} minimumPage={2} />
        
        </View>
      </View>
    );
  }
}