import { ContentView, ContentViewModel } from "@levelapp/softfabric";
import PopUpSendNotificationViewProps from "./PopUpSendNotificationViewProps";
import PopUpSendNotificationViewState from "./PopUpSendNotificationViewState";
import UserNotificationOperation from "../../Transfer/UserNotificationOperation";

export default class PopUpSendNotificationViewModel extends ContentViewModel<
  PopUpSendNotificationViewState,
  PopUpSendNotificationViewProps
> {
  constructor(props: ContentView) {
    super(props);
    this.initialState({
      titleFR: "",
      titleNL: "",
      titleEN: "",
      messageFR: "",
      messageNL: "",
      messageEN: "",
      mailTest: "",
      isLoading: false
    });
    this.handleSendNotification = this.handleSendNotification.bind(this);
    this.onTitleFRChange = this.onTitleFRChange.bind(this);
    this.onEmailTestChanged = this.onEmailTestChanged.bind(this);
  }

  onTitleFRChange = (title: string, field: string) => {
    if (field === "titleFR")
      this.setField((previousState) => (previousState.titleFR = title));
    else if (field === "titleNL")
      this.setField((previousState) => (previousState.titleNL = title));
    else if (field === "titleEN")
      this.setField((previousState) => (previousState.titleEN = title));
    else if (field === "messageFR")
      this.setField((previousState) => (previousState.messageFR = title));
    else if (field === "messageNL")
      this.setField((previousState) => (previousState.messageNL = title));
    else if (field === "messageEN")
      this.setField((previousState) => (previousState.messageEN = title));
  };

  onEmailTestChanged = (mailTest: string) => {
    this.setField((previousState) => (previousState.mailTest = mailTest));
  }

  handleSendNotification = async (sendTest: boolean) => {
    const Title = {
      FR: this.state().titleFR,
      NL: this.state().titleNL,
      EN: this.state().titleEN,
    };
    const Message = {
      FR: this.state().messageFR,
      NL: this.state().messageNL,
      EN: this.state().messageEN,
    };
    if (
      !Title.FR ||
      !Title.NL ||
      !Title.EN ||
      !Message.FR ||
      !Message.NL ||
      !Message.EN
    ) {
      alert("Il faut remplir tous les champs");
      return;
    }

    const data = {
      CategoryId: 1,
      Title,
      Message,
      Users: this.props().users,
      Sender: localStorage.getItem("user"),
      TestEmail: "",
    };

    if (sendTest) {
      data.TestEmail = this.state().mailTest;
    }

    this.setField({ isLoading: true });
    // Send the notification and wait for the response
    const response = await new UserNotificationOperation().sendNotification(
      data,
    );
    // If the response status is 200, close the popup
    if (sendTest && response.status === 200) {
      alert("Test envoyé")
    }
    else if (response.status === 200) {
      let numberEmails = data.Users.length;
      alert(numberEmails+" emails ont été envoyés");
      this.closePopUp();
    } else {
      alert("Une erreur est survenue");
    }

    this.setField({ isLoading: false });
  };
}
