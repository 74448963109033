import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, ViewStyle, TextStyle, ImageStyle, Dimensions } from 'react-native';

export default class BlockViewStyle extends ContentViewStyle{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  
    container: StyleProp<ViewStyle> = {
      display :'flex',
      padding : 20,
    }
  
    InputRow: StyleProp<ViewStyle> = {
      display :'flex',
      flexDirection : 'row',
      alignItems:'flex-start',
    }
    
    status : StyleProp<TextStyle> = {
      display : 'flex',
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 16,
      color : 'grey',
      justifyContent : 'flex-start',
      alignSelf :'flex-start',
      paddingRight : 10,
      paddingBottom : 10,
    }
  
    label: StyleProp<TextStyle> = {
      display : 'flex',
      paddingRight : 10,
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 20,
      alignItems : 'flex-end',
      color : '#615D8A',
    }
  
    title : StyleProp<TextStyle> = {
      display : 'flex',
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 20,
      color : this.MyStyleManager.primaryColor,
      paddingRight : 10,
      paddingBottom : 10,
    }
    
    TabSquare: StyleProp<ViewStyle> = {
      display : 'flex',
      justifyContent : 'center',
      alignItems :'center',
      width : '50px',
      height :'50px',
      backgroundColor : 'white',
      borderRadius : 10,
      shadowRadius : 4
    }
  
    TabSquareImage : StyleProp<ImageStyle> = {
      display : 'flex',
      justifyContent : 'center',
      alignItems :'center',
      width : '50px',
      height :'50px',
      borderRadius : 10,
    }
  
    DeleteTabSquareImage : StyleProp<ImageStyle> = {
      display : 'flex',
      justifyContent : 'center',
      alignItems :'center',
      width : '20px',
      height :'20px',
      borderRadius : 10,
    }
  
    tabContainer: StyleProp<ViewStyle> = {
      display :'flex',
      flex : 1,
      flexDirection : 'row',
    }
    
    tableHeader: StyleProp<ViewStyle> = {
      display :'flex',
      flexDirection : 'row',
      paddingTop : 20,
      paddingRight : 20,
      paddingBottom : 20,
      justifyContent : 'space-between',
      flex : 1,
    }
  
    orderView: StyleProp<ViewStyle> = {
      display : 'flex',
      flexDirection : 'row',
    }
  
      box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 40.65,
        elevation: 60,
      }
  
      PurpleMenu: StyleProp<ViewStyle> =
      {
          backgroundColor : '#615D8A',
          height: '60px',
          paddingTop: '20px',
          paddingLeft : '78px',
      }       
  
      PurpleMenuText: StyleProp<TextStyle> = {
          color: 'white',
          fontSize: 17,
      }
  
      delete: StyleProp<ViewStyle> = {
        display : 'flex',
        alignItems : 'flex-end',
      }
  
      lottie: StyleProp<ViewStyle> = {
        display :'flex',
        height : Dimensions.get('window').height,
        justifyContent : 'center',
        alignItems : 'center',
        flexDirection : 'row',
        alignContent : 'center'
      }
  
      tabActive: StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'center',
        alignItems :'center',
        width : '50px',
        height :'50px',
        backgroundColor : this.MyStyleManager.primaryColor,
        borderRadius : 10,
      }

      button : StyleProp<ViewStyle> = {
        display : 'flex',
        alignItems : 'flex-end',
        justifyContent : 'flex-end',
        paddingLeft : 20,
      }

      buttonList: StyleProp<ViewStyle> = {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'flex-end',
        alignItems : 'center',
        padding : 20,
      }
}