import { Operation } from '@levelapp/softfabric';
import BankTransactionsModel from '../DTO/BankTransactionsModel';
import InvestmentsService from '../Proxy/InvestmentsService';


export default class InvestmentsOperation extends Operation
{
    _service : InvestmentsService;

    constructor()
    {
        super();
        this._service = new InvestmentsService();
    }   

    async validUser(usr: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).validInvestment(usr, callback);
    }

    async updateInvestmentStatus(investment: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).updateInvestmentStatus(investment, callback);
    }
    async updateMultipleInvestmentStatus(investments: any[], callback: Function = () => { })
    {
        await (this._service as InvestmentsService).updateMultipleInvestmentStatus(investments, callback);
    }
    async updateInvestmentStatusFromExcel(model: BankTransactionsModel, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).updateInvestmentStatusFromExcel(model, callback);
    }

    async sendEmailCouponCourant(investment: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).sendEmailCouponCourant(investment, callback);
    }

    async addInvestment(investment: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).addInvestment(investment, callback);
    }

    async deleteInvestment(investmentId: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).deleteInvestment(investmentId, callback);
    }


    async transferAmount(investment: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).transferAmount(investment, callback);
    }

    async transferReservation(reservation: any, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).transferReservation(reservation, callback);
    }

    async get(status: any, projectId: any, page : number, itemPage : number, searchText : string, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).get(status, projectId, page, itemPage, searchText, callback);
    }  

    async getList(searchText : string, callback: Function = () => { })
    {
        await (this._service as InvestmentsService).getList(searchText, callback);
    }  
}