import { Operation } from '@levelapp/softfabric';
import DocumentsService from '../Proxy/DocumentsService';


export default class DocumentsOperation extends Operation
{
    _service : DocumentsService;

    constructor()
    {
        super();
        this._service = new DocumentsService();
    }       

    async uploadDocuments(documents: any, callback: Function = () => { }, error: Function = () => { })
    {
        await (this._service as DocumentsService).uploadDocuments(documents, callback, error);
    }

    async getByProject(id: string, callback: Function = () => { })
    {
        await (this._service as DocumentsService).getByProject(id, callback);
    }

    async insertDocument(document: any, callback: Function = () => { })
    {
        await (this._service as DocumentsService).insertDocument(document, callback);
    }

    async deleteDocument(id: string, callback: Function = () => { })
    {
        await (this._service as DocumentsService).deleteDocument(id, callback);
    }
}