import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import CerificatesPopUpViewState from './CerificatesPopUpViewState';
import CerificatesPopUpViewProps from './CerificatesPopUpViewProps';
import CerificatesPopUpViewStyle from './CerificatesPopUpViewStyle';
import CerificatesPopUpViewModel from './CerificatesPopUpViewModel';
import {ButtonView, LottieView, TranslationView} from '@levelapp/softfabric-ui';
import { TouchableOpacity, View } from 'react-native';
import ButtonImportFileView from '@levelapp/softfabric-ui/components/Web/buttonimportfile/ButtonImportFileView';
import PopupHeaderView from '../../../../../../../../../../components/popopheader/PopupHeaderView';

export default class CerificatesPopUpView extends ContentView<CerificatesPopUpViewProps, CerificatesPopUpViewState, CerificatesPopUpViewModel, CerificatesPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:CerificatesPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new CerificatesPopUpViewModel(this), new CerificatesPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container,text,button ,lineContainer,loadingContainer,linkContainer,textLink,} = this.style;
    return (
      <View style={{ minWidth: 300,height: 500, width: 800, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={this.binding.handleBackpressed}
          title={"certificate.header"}
          width={800}/> 



        <View style={container}>  
          <View style={lineContainer}> 
            <View style={{flex : 1, justifyContent : 'center'}}>
              {this.state.file?
              <div style={text}>
                {this.state.file.name}
              </div>
              :
              <TranslationView style={text}>
                  {'import.file'}
              </TranslationView>}
            </View>
            <View style={{flex : 2}}>
              <ButtonImportFileView style={{flex : 2}} buttonColor="#FFB243" textColor="white" accepted=".json , .xlsx" action={this.binding.handleFileUpload} />        

            </View>
            <View style={{flex : 1}}>
              <ButtonView animation={'Bounce'} borders={'Little'} isLoading={this.state.isLoading} handleClick={this.binding.handleDownloadButtonPressed} backgroundColor="#FFB243" style={button}>
                <TranslationView >
                  {'certificate.create'}
                </TranslationView>
              </ButtonView>
            </View>
            <View style={{flex : 1}}>
              <ButtonView animation={'Bounce'} borders={'Little'} isLoading={this.state.isSendLoading} handleClick={this.binding.handlSendEmailsButtonPressed} backgroundColor="#FFB243" style={button}>
                <TranslationView >
                  {'certificate.send'}
                </TranslationView>
              </ButtonView>
            </View>
          </View>
          {this.state.isCreationLinkLoading &&  
          <View style={loadingContainer}>
            <LottieView height={75} width={75} source={require("../../../../../../../assets/loading.json")}/>
            <TranslationView style={text}>{'certificate.loading'}</TranslationView>
          </View>}
          {this.state.downloadLink !== "" && 
          <View style={linkContainer}>
            <TouchableOpacity onPress={() => window.open(this.state.downloadLink)}>
              <TranslationView style={textLink}>{'certificate.link'}</TranslationView>
            </TouchableOpacity>
          </View>}
        </View>
 
        
      </View>
      
    );
  }
}