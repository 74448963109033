import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import { View, } from 'react-native';
import LegalEntitiesViewState from './LegalEntitiesViewState';
import LegalEntitiesViewProps from './LegalEntitiesViewProps';
import LegalEntitiesViewStyle from './LegalEntitiesViewStyle';
import LegalEntitiesViewModel from './LegalEntitiesViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import ResearchInputView from '../../../../../Components/researchinput/ResearchInputView';
import DropDownView from '../../../../../Components/Dropdown/DropDownView';
import TableView from '../../../../../Components/table/TableView';

export default class LegalEntitiesView extends ContentView<LegalEntitiesViewProps, LegalEntitiesViewState, LegalEntitiesViewModel, LegalEntitiesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LegalEntitiesViewProps)
  {
    super(props);

    // Binding
    this.bind(new LegalEntitiesViewModel(this), new LegalEntitiesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
    <View style={this.style.container}>
    <View style={this.style.box}>


      {/* Color BOX */}
      <View style={this.style.boxTitle}>
        <TranslationView style={this.style.title}>
          Investors
        </TranslationView>

        <TranslationView style={this.style.subTitle}>
          ManageYourInvestors
        </TranslationView>
      </View>
      <View style={this.style.bigBoxContainer}>
        {(this.state.isExport|| this.state.isAdmin ) &&
          <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.exportLegalEntities() }}>
          <TranslationView style={this.style.exportButtonText}>
          ExportLegalEntities
        </TranslationView>
        </ButtonView>
        }
        <View style={this.style.boxContainer}>
          {/* Research Input */}
          < View style={this.style.containerFilter}>
            <TranslationView style={{marginRight: '20px'}}>Search</TranslationView>
            <DropDownView style={{marginRight: '20px'}} selectedValue={this.state.selectedStatus} values={this.state.statuses} onValueChange={(value: any) => this.binding.handleStatusChanged(value)}></DropDownView>
            <ResearchInputView
              textInFront=""
              placeholder="search"
              handlerResearching={(text: any) => this.binding.handleResearching(text)}
              handlingClose={() => { this.binding.handlingClose() }}
              text={this.state.text}
            />

          </View >
        </View>

        <TableView
          handleResetStepView={this.binding.handleResetStepView}
          resetStepView={this.state.resetStepView}
          headerData={this.state.tableHeaders}
          rows={this.state.rows}
          numberOfRows={20}
          isLoading={this.state.isLoading}
          lottie={require('./assets/loading.json')}
          isSortable={true}
          resetTable={this.binding.resetTable}
          datas={this.state.datas}
          fillTables={(value: any[]) => { this.binding.pushRows(value) }}
          keyDatas={["id", "corporateName", "creationDate","usersPending", "name", "legalEntityStatusTitle"]}
          headerIndex={[0, 1, 2, 3, 4, 5, 6]}
          headerTextStyle={this.style.headerText}
          onPageChange={(page: number) => this.binding.onPageChange(page)}
          nbPage={this.state.nbPage}
          currentPage={this.state.currentPage - 1}
        ></TableView>
      </View>
    </View>
  </View>
    );
  }
}