import React from 'react';
import { View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import {ButtonView , TranslationView} from '@levelapp/softfabric-ui';
import ValidationPopUpViewProps from './ValidationPopUpViewProps';
import ValidationPopUpViewState from './ValidationPopUpViewState';
import ValidationPopUpViewModel from './ValidationPopUpViewModel';
import ValidationPopUpViewStyle from './ValidationPopUpViewStyle';

export default class ValidationPopUpView extends ContentView<ValidationPopUpViewProps, ValidationPopUpViewState, ValidationPopUpViewModel, ValidationPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ValidationPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new ValidationPopUpViewModel(this), new ValidationPopUpViewStyle(this));
  }

   /* RENDERING */
   render()
   {
     return (
       <View style={{ marginHorizontal: 50, minWidth: 600 }}>
 
         <TranslationView style={this.style.title}>
           {this.props.text}
         </TranslationView>
 
         <View style={this.style.footer}>
           <ButtonView  animation={'None'} borders={'Little'}isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
             <TranslationView upperCase style={this.style.NoButtonText}>
               No
             </TranslationView>
           </ButtonView>
           <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
             <TranslationView upperCase>
               Yes
             </TranslationView>
           </ButtonView>
         </View>
       </View>
     );
   }
 }