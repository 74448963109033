import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import QuestionsViewState from './QuestionsViewState';
import QuestionsViewProps from './QuestionsViewProps';
import ProjectQuestionDTO from '../../../../../../../../../../../../DTO/ProjectQuestionDTO';

export default class QuestionsViewModel extends ContentViewModel<QuestionsViewState, QuestionsViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            indexQuestionsToShow:[]
        }); // Initialize your state here

        // Bindings
        this.handleShowHideQuestions = this.handleShowHideQuestions.bind(this)
        this.handleAddClick = this.handleAddClick.bind(this)

    }

    componentDidMount(){

    }

    handleShowHideQuestions(index : number){
        let tempIndexListToShow = this.state().indexQuestionsToShow;
        if(this.state().indexQuestionsToShow.includes(index))
            tempIndexListToShow.splice(this.state().indexQuestionsToShow.indexOf(index),1);
        else
            tempIndexListToShow.push(index);
        this.setField((previousState) => previousState.indexQuestionsToShow = tempIndexListToShow);
    }

    handleAddClick(){
        let newQuestions : ProjectQuestionDTO ={
            id : 0,
            projectID : this.props().project.id,
            order : this.props().project.projectQuestions.length+1 ,
            titleId : 0,
            title : { 
                fr : '',
                nl : '',
                en : '',
            },
            responseId : 0,
            response : { 
                fr : '',
                nl : '',
                en : '',
            },
        }
        this.props().project.projectQuestions.push(newQuestions)
        this.handleShowHideQuestions(this.props().project.projectQuestions.length-1)
    }

}