import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import InformationsViewState from './InformationsViewState';
import InformationsViewProps from './InformationsViewProps';
import InformationsViewStyle from './InformationsViewStyle';
import InformationsViewModel from './InformationsViewModel';
import { ButtonView, LottieView, TranslationView } from '@levelapp/softfabric-ui';
import {View, Text} from 'react-native';
import CompanyInfosView from './components/companyinfos/CompanyInfosView';
import FinanceInfosView from './components/financeinfos/FinanceInfosView';
import ProjectInfosView from './components/projectinfos/ProjectInfosView';
import FIELDS from './fields/Fields';
import DescriptionView from './components/description/DescriptionView';
import MultimediaView from './components/multimedia/MultimediaView';
import BenefitsView from './components/benefits/BenefitsView';
import QuestionsView from './components/questions/QuestionsView';
import { TouchableWithoutFeedback } from 'react-native';
import ReadExcelView from "../../../../../../../../../../Components/ReadExcel/ReadExcelView";

export default class InformationsView extends ContentView<InformationsViewProps, InformationsViewState, InformationsViewModel, InformationsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: InformationsViewProps) {
    super(props);

    // Binding
    this.bind(new InformationsViewModel(this), new InformationsViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { PageTitle, Menu, MenuText, box, container, footer, saveButton, editButton, overlayLoading, selectedTab, tab } = this.style
    return (

      <View>
        {this.state.isNewProject && <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div style={{ display: "flex", marginLeft: "26px", marginTop: "20px", marginBottom: "20px" }}>
            <div style={{ cursor: "pointer" }}>
              <img onClick={() => this.binding.navigate('/dashboard/projects')} height="31" width="31" src="https://beebondsstorage.blob.core.windows.net/files/consoleImages/close.png"></img>
            </div>
            <div>
              <Text style={PageTitle}>Nouveau projet</Text>

            </div>
          </div>
        </View>}

        <View style={box}>
          {this.state.isLoading && <View style={overlayLoading}><LottieView height={200} width={200} source={require('../../../../assets/loading.json')} /></View> }
          {this.state?.isEditable ? <ReadExcelView callBack={this.binding.callback} project={this.state.project}/> : null}
          <View style={Menu}>

            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.PROJECT_INFOS)}>
              <View style={this.state.currentTab === FIELDS.PROJECT_INFOS ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.infos</TranslationView>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.FINANCE_INFOS)}>
              <View style={this.state.currentTab === FIELDS.FINANCE_INFOS ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.financial</TranslationView>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.COMPANY_INFOS)}  >
              <View style={this.state.currentTab === FIELDS.COMPANY_INFOS ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.companyinfos</TranslationView>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.DESCRIPTION)}  >
              <View style={this.state.currentTab === FIELDS.DESCRIPTION ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.description</TranslationView>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.MULTIMEDIA)}  >
              <View style={this.state.currentTab === FIELDS.MULTIMEDIA ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.multimedia</TranslationView>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.BENEFITS)}  >
              <View style={this.state.currentTab === FIELDS.BENEFITS ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.benefits</TranslationView>
              </View>
            </TouchableWithoutFeedback>
            <TouchableWithoutFeedback onPress={() => this.binding.handleTabChange(FIELDS.QUESTIONS)}  >
              <View style={this.state.currentTab === FIELDS.QUESTIONS ? selectedTab : tab}>
                <TranslationView style={MenuText}>project.questions</TranslationView>
              </View>
            </TouchableWithoutFeedback>


          </View>
          <View style={container}>

            {this.state.currentTab == FIELDS.PROJECT_INFOS && <ProjectInfosView
              isEditable={this.state.isEditable}
              project={this.state.project}

              onCodeChange={this.binding.handleCodeChange}
              onProjectNameChange={this.binding.handleProjectNameChange}
              onPublishedChange={this.binding.handleIsPublishedChange}
              onTypeChange={this.binding.handleTypeChange}

              onProjectRiskCategoryChange={this.binding.handleProjectRiskCategoryChange}

              onCoverPictureChange={this.binding.handleCoverPictureChange}

              onIntroductionFRChange={this.binding.handleIntroductionFRChange}
              onIntroductionNLChange={this.binding.handleIntroductionNLChange}
              onIntroductionENChange={this.binding.handleIntroductionENChange}

              onSafetyDescriptionFRChange={this.binding.handleSafetyDescriptionFRChange}
              onSafetyDescriptionNLChange={this.binding.handleSafetyDescriptionNLChange}
              onSafetyDescriptionENChange={this.binding.handleSafetyDescriptionENChange}

              onProjectNameTranslatedFRChange={this.binding.handleProjectNameTranslatedFRChange}
              onProjectNameTranslatedNLChange={this.binding.handleProjectNameTranslatedNLChange}
              onProjectNameTranslatedENChange={this.binding.handleProjectNameTranslatedENChange}

              onVisibleChange={this.binding.handleVisibleChange}
              onIsSafety={this.binding.handleIsSafety}
              onSafetyTypeChange={this.binding.handleSafetyTypeChange}
              onCommentChange={this.binding.handleCommentChange}

            />}

            {this.state.currentTab == FIELDS.FINANCE_INFOS && <FinanceInfosView
              isNewProject={this.state.isNewProject}
              isEditable={this.state.isEditable}
              projet={this.state.project}

              onMinimumInvestChange={this.binding.handleMinimumInvestChange}
              onMaximumInvestChange={this.binding.handleMaximumInvestChange}
              onCurrentInvestChange={this.binding.handleCurrentInvestChange}
              onMinimumTicketChange={this.binding.handleMinimumTicketChange}
              onMaximumTicketChange={this.binding.handleMaximumTicketChange}
              onDurationChange={this.binding.handleDurationChange}
              onIssueDateChange={this.binding.handleIssueDateChange}
              onDueDateChange={this.binding.handleDueDateChange}
              onRepaymentDateChange={this.binding.handleRepaymentDateChange}
              onOpeningDateChange={this.binding.handleOpeningDateChange}
              onOpeningHoursChange={this.binding.handleOpeningHoursChange}
              onAnnualInterestRateChange={this.binding.handleAnnualInterestRateChange}
              onOverbookingRateChange={this.binding.handleOverbookingRateChange}
              onISINChange={this.binding.handleISINChange}
              onOfferEndDateChange={this.binding.handleOfferEndDateChange}
              onPlacementTypeChange={this.binding.handlePlacementTypeChange}
              onObligationFormTypeChange={this.binding.handleObligationFormTypeChange}
            />}

            {this.state.currentTab == FIELDS.COMPANY_INFOS &&
              <CompanyInfosView
                isEditable={this.state.isEditable}
                selectedBank={this.state.selectedBank}
                project={this.state.project}

                onActivityDropdownChange={this.binding.handleActivityDropdownChange}
                onActivityFRChange={this.binding.handleActivityFRChange}
                onActivityNLChange={this.binding.handleActivityNLChange}
                onActivityENChange={this.binding.handleActivityENChange}
                onstartOfActivityChange={this.binding.handlestartOfActivityChange}

                onStreetChange={this.binding.handleStreetChange}
                onNumberChange={this.binding.handleNumberChange}
                onBoxChange={this.binding.handleBoxChange}
                onPostCodeChange={this.binding.handlePostCodeChange}
                onLocalityChange={this.binding.handleLocalityChange}
                onCountryChange={this.binding.handleCountryChange}
                onWebsiteURLChange={this.binding.handleWebsiteURLChange}
                onFullAddressFRChange={this.binding.handleFullAddressFRChange}
                onFullAddressNLChange={this.binding.handleFullAddressNLChange}

                onIBANChange={this.binding.handleIBANChange}
                onBICChange={this.binding.handleBICChange}
                onBCEChange={this.binding.handleBCEChange}
                onBankChange={this.binding.handleBankChange}
                onLeaderChange={this.binding.handleLeaderChange}
                onLegalFormChange={this.binding.onLegalFormChange}

                onNameFRChange={this.binding.handleNameFRChange}
                onNameNLChange={this.binding.handleNameNLChange}
                onNameENChange={this.binding.handleNameENChange}
              />}

            {this.state.currentTab == FIELDS.DESCRIPTION &&
              <DescriptionView
                isEditable={this.state.isEditable}
                project={this.state.project}

                onDescriptionFRChange={this.binding.handleDescriptionFRChange}
                onDescriptionNLChange={this.binding.handleDescriptionNLChange}
                onDescriptionENChange={this.binding.handleDescriptionENChange}
                onHeaderFRChange={this.binding.handleHeaderFRChange}
                onHeaderNLChange={this.binding.handleHeaderNLChange}
                onHeaderENChange={this.binding.handleHeaderENChange}
                onAboutLegalTextFRChange={this.binding.handleAboutLegalTextFRChange}
                onAboutLegalTextNLChange={this.binding.handleAboutLegalTextNLChange}
                onAboutLegalTextENChange={this.binding.handleAboutLegalTextENChange}
                onDocumentLegalTextFRChange={this.binding.handleDocumentLegalTextFRChange}
                onDocumentLegalTextNLChange={this.binding.handleDocumentLegalTextNLChange}
                onDocumentLegalTextENChange={this.binding.handleDocumentLegalTextENChange}
              />}
            {this.state.currentTab == FIELDS.MULTIMEDIA &&
              <MultimediaView
                isEditable={this.state.isEditable}
                project={this.state.project}

                onLeftMenuPictureUrlChange={this.binding.handleLeftMenuPictureUrlChange}
                onPreviewPictureChange={this.binding.handlePreviewPictureChange}
                onLogoPictureChange={this.binding.handleLogoPictureChange}
                onVideoUrlChange={this.binding.handleVideoUrlChange}
                onPdfFRChange={this.binding.handlePdfFRChange}
                onPdfNLChange={this.binding.handlePdfNLChange}
                onCertificateLogoFileChange={this.binding.handleCertificateLogoFileChange}
                onCertificateSignatureFileChange={this.binding.handleCertificateSignatureFileChange}
              />}
            {this.state.currentTab == FIELDS.BENEFITS &&
              <BenefitsView
                isEditable={this.state.isEditable}
                project={this.state.project}

                onOrderChange={this.binding.handleBenefitsOrderChange}
                onTitleFRChange={this.binding.handleBenefitsTitleFRChange}
                onTitleNLChange={this.binding.handleBenefitsTitleNLChange}
                onTitleENChange={this.binding.handleBenefitsTitleENChange}

                onSubtitleFRChange={this.binding.handleBenefitsSubtitleFRChange}
                onSubtitleNLChange={this.binding.handleBenefitsSubtitleNLChange}
                onSubtitleENChange={this.binding.handleBenefitsSubtitleENChange}
              />}
            {this.state.currentTab == FIELDS.QUESTIONS &&
              <QuestionsView
                isEditable={this.state.isEditable}
                project={this.state.project}

                onOrderChange={this.binding.handleQuestionsOrderChange}
                onTitleFRChange={this.binding.handleQuestionsTitleFRChange}
                onTitleNLChange={this.binding.handleQuestionsTitleNLChange}
                onTitleENChange={this.binding.handleQuestionsTitleENChange}

                onResponseFRChange={this.binding.handleQuestionsResponseFRChange}
                onResponseNLChange={this.binding.handleQuestionsResponseNLChange}
                onResponseENChange={this.binding.handleQuestionsResponseENChange}
              />}

          </View>

          {this.state.canEdit && <View style={footer}>
            {!this.state.isNewProject && <ButtonView animation={'None'} borders={'Little'} style={editButton} isLoading={false} handleClick={this.binding.handleEditButtonPressed} >
              <TranslationView>
                {'project.edit'}
              </TranslationView>
            </ButtonView>}
            <ButtonView animation={'None'} borders={'Little'} style={saveButton} isLoading={false} handleClick={this.binding.handleSaveButtonPressed} >
              <TranslationView>
                {'project.save'}
              </TranslationView>
            </ButtonView>
          </View>}

        </View>


      </View>
    );
  }
}
