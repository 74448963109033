import React from 'react';
import {ContentView, Application} from '@levelapp/softfabric';
import NewsletterViewState from './NewsletterViewState';
import NewsletterViewProps from './NewsletterViewProps';
import NewsletterViewStyle from './NewsletterViewStyle';
import NewsletterViewModel from './NewsletterViewModel';
import { View, CheckBox } from 'react-native';
import {TranslationView, StyleManager, UiManagers, ButtonView } from '@levelapp/softfabric-ui';
import InputView from '../../../../../Components/Input/input/InputView';

export default class NewsletterView extends ContentView<NewsletterViewProps, NewsletterViewState, NewsletterViewModel, NewsletterViewStyle>
{
  MyManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  /* CONSTRUCTOR */
  constructor(props:NewsletterViewProps)
  {
    super(props);

    // Binding
    this.bind(new NewsletterViewModel(this), new NewsletterViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>Newsletter</TranslationView>
            <TranslationView style={this.style.subTitle}>Subscribe</TranslationView>
          </View>
        <View style={this.style.content}>
          {/* TITRE */}
          <TranslationView style={this.style.bigTitle}>
            SubscribeNewsLetter
          </TranslationView>

          {/* FORMULAIRE */}
          <InputView required subscribe={() => {}} value={this.state.email} onChange={this.binding.handleEmailChanged} inputClass='col-lg-12' label="Email*" placeholder="Email"/>
          <View style={{marginTop: 20}} ><InputView subscribe={() => {}} value={this.state.firstName} onChange={this.binding.handleFirstNameChanged} inputClass='col-lg-12' label="Prénom" placeholder="Prénom"/></View>
          <View style={{marginTop: 20}} ><InputView style={{marginTop: 30}} subscribe={() => {}} value={this.state.lastName} onChange={this.binding.handleLastNameChanged} inputClass='col-lg-12' label="Nom" placeholder="Nom"/></View>

          {/* CHECKBOXS */}
          <View style={{display: "flex", flexDirection: 'row', marginTop: 15}}>
            <CheckBox onValueChange={this.binding.handleAcceptNewsLetterhanged} value={this.state.acceptNewsLetter}/>
            <TranslationView style={{marginLeft: 5}}>SubscribeNewsLetterGeneral</TranslationView>
          </View>

          <View style={{display: "flex", flexDirection: 'row', marginTop: 15}}>
            <CheckBox onValueChange={this.binding.handleCampaignNewsLetterhanged} value={this.state.campaignNewsLetter}/>
            <TranslationView style={{marginLeft: 5}}>SubscribeNewsLetterCampaign</TranslationView>
          </View>

          {/* BUTTON */}
          <View style={{ marginTop: 25}}>
            <ButtonView handleClick={this.binding.submit} animation='None' isLoading={this.state.isLoading} borders='Little' backgroundColor= '#FFB243'>
              <TranslationView>Send</TranslationView>
            </ButtonView>
          </View>


        </View>
        </View>
      </View>
    );
  }
}