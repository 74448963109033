import { ContentViewModel, Application, LanguageManager, Managers, ContentView } from '@levelapp/softfabric';
import BlockViewState from './BlockViewState';
import BlockViewProps from './BlockViewProps';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import TabOperation from '../../../../../../../Transfer/TabOperation';
import BlockOperation from '../../../../../../../Transfer/BlockOperation';
import ProjectOperation from '../../../../../../../Transfer/ProjectsOperations';
import { Quill } from 'react-quill';
import ShowTextPopUpView from '../../../../../../../Common/PopUp/showTextPopUpView/ShowTextPopUpView';
import React from 'react';
import ValidationPopUpView from '../../../../../../../Common/PopUp/validationPopUpView/ValidationPopUpView';

var Font = Quill.import('formats/font');
Font.whitelist = ['mirza', 'roboto', 'montserrat'];
Quill.register(Font, true);

export default class BlockViewModel extends ContentViewModel<BlockViewState, BlockViewProps>{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    MyLanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    projectOperation = new ProjectOperation();
    tabOperation = new TabOperation();
    blockOperation = new BlockOperation();
    countLanguage: number;
    

    constructor(props: ContentView) {

        super(props);

        this.countLanguage = 0;
        /* Initial State */
        this.initialState({
            currentLanguage: this.MyLanguageManager.language,
            isLoading: false,
        });

        /* BINDING */
        this.handleContentChanged = this.handleContentChanged.bind(this);
        this.wysiwygModule = this.wysiwygModule.bind(this);
        this.handleOrderBlockChange = this.handleOrderBlockChange.bind(this);
        this.submit = this.submit.bind(this);
        this.publishProd = this.publishProd.bind(this);
        this.publishStaging = this.publishStaging.bind(this);
        this.dePublish = this.dePublish.bind(this);
        this.removeBlock = this.removeBlock.bind(this);
    }

    submit() {
        if (isNaN(parseInt(this.props().currentBlock.order))) {
            this.showPopUp(
                <ShowTextPopUpView text={"isBlockNumber"}></ShowTextPopUpView>
            )
            return;
        } else if (this.props().currentBlock.content.fr == "" || this.props().currentBlock.content.nl == "" || this.props().currentBlock.en == "") {
            this.showPopUp(
                <ShowTextPopUpView text={"NoBlockTab"}></ShowTextPopUpView>
            )
            return;
        }

        //Si il n'est pas return avant on envoie la requete
        if (this.props().currentBlock.id == 0) {
            //insert
            this.showPopUp(

                <ValidationPopUpView text={"SaveBlock"} callBack={() => this.blockOperation.insertBlock(this.props().currentBlock, (response: any) => {
                    this.props().setCurrentBlockId(response.id);
                    this.props().setIsSavedBlock(true);
                })} />
            );
        } else {
            //update
            this.showPopUp(
                <ValidationPopUpView text={"UpdateBlock"} callBack={() => this.blockOperation.updateBlock(this.props().currentBlock, () => {
                    this.props().setIsSavedBlock(true);
                })} />
            );
        }
    }

    publishStaging() {
        if (!this.props().isSavedBlock) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedBlockError"}></ShowTextPopUpView>
            )
            return;
        }
        this.showPopUp(

            <ValidationPopUpView text={"ValidatePublishStaging"} callBack={() => this.blockOperation.publishStaging(this.props().currentBlock, () => {
                this.props().setCurrentBlockState(2);
            })} />
        );
    }

    publishProd() {
        if (!this.props().isSavedBlock) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedBlockError"}></ShowTextPopUpView>
            )
            return;
        }
        this.showPopUp(

            <ValidationPopUpView text={"ValidatePublishProd"} callBack={() => this.blockOperation.publishProd(this.props().currentBlock, () => {
                this.props().setCurrentBlockState(3);
            })} />
        );
    }

    dePublish() {
        this.showPopUp(

            <ValidationPopUpView text={"ValidateDepublish"} callBack={() => this.blockOperation.depublish(this.props().currentBlock, () => {
                this.props().setCurrentBlockState(1);
            })} />
        );
    }

    removeBlock() {
        this.showPopUp(
            <ValidationPopUpView text={"DeleteBlock"} callBack={() => {
                this.props().removeBlock();
            }} />
        );
    }

    handleContentChanged(content: string, lang: string) {
        switch (lang) {
            case "fr":
                if (this.props().currentBlock.content.fr.replace(/(\r\n|\n|\r|\\n)/gm,'').trim() != content.replace(/(\r\n|\n|\r|\\n)/gm,'').trim()) {
                    this.props().setBlockContent(content, 'fr');
                    this.props().setIsSavedBlock(false);
                }
                break;
            case "nl":
                if (this.props().currentBlock.content.nl.replace(/(\r\n|\n|\r|\\n)/gm,'').trim() != content.replace(/(\r\n|\n|\r|\\n)/gm,'').trim()) {
                    this.props().setBlockContent(content, 'nl');
                    this.props().setIsSavedBlock(false);
                }
                break;
            case "en":
                if (this.props().currentBlock.content.en.replace(/(\r\n|\n|\r|\\n)/gm,'').trim() != content.replace(/(\r\n|\n|\r|\\n)/gm,'').trim()) {
                    this.props().setBlockContent(content, 'en');
                    this.props().setIsSavedBlock(false);
                }
                break;
        }

    }

    wysiwygModule() {

        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['12px', '16px', '18px'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': Font.whitelist }],
                [{ 'align': [] }],
                ['clean'],                                         // remove formatting button
                ['link', 'image', 'video'],
                ['showHtml']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        }
    }

    handleOrderBlockChange(order: string) {
        if (this.props().currentBlock.order != order) {
            this.props().setBlockOrder(order);
            this.props().setIsSavedBlock(false);
        }
    }

}