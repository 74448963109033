import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import UsersPopUpViewViewState from './UsersPopUpViewViewState';
import UsersPopUpViewViewProps from './UsersPopUpViewViewProps';

export default class UsersPopUpViewViewModel extends ContentViewModel<UsersPopUpViewViewState, UsersPopUpViewViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
    }

    /* COMPONENT EVENTS */
    componentWillMount(){}

    /* HANDLERS */
    handleBackPressed()
    {
        this.closePopUp();
    }

    handleSavePressed(){        
        this.closePopUp();
        this.props().callBack();
    }
}