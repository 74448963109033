import { Operation } from '@levelapp/softfabric';
import UsersService from '../Proxy/UsersService';


export default class UsersOperation extends Operation
{
    _service : UsersService;

    constructor()
    {
        super();
        this._service = new UsersService();
    }   

    async get(callback: Function = () => { })
    {
        await (this._service as UsersService).get(callback);
    }

    async getUserById(userId: any, callback: Function = () => { })
    {
        await (this._service as UsersService).getUserById(userId, callback);
    }    

    async validUser(usr: any, callback: Function = () => { })
    {
        await (this._service as UsersService).validUser(usr, callback);
    }

    async updateUserStatus(userStatus: any, callback: Function = () => { })
    {
        await (this._service as UsersService).updateUserStatus(userStatus, callback);
    }
    async resetUserAvertiStatus(userId: any, callback: Function = () => { })
    {
        await (this._service as UsersService).resetUserAvertiStatus(userId, callback);
    }
    async updateUser(user: any, callback: Function = () => { })
    {
        await (this._service as UsersService).updateUser(user, callback);
    }    

    async getUserInfos(callback: Function = () => { })
    {
        await (this._service as UsersService).getUserInfos(callback);
    }

    async getSearchPagination(status: any, page : number, itemPage : number, searchText : string, getInvestors: boolean, callback : Function) {
        await (this._service as UsersService).getSearchPagination(status, page, itemPage, searchText, getInvestors, callback);
    }

    async export(status:any, searchText : string, getInvestors: any, callback : Function) {
        await (this._service as UsersService).export(status, searchText, getInvestors, callback);
    }

    async exportAll(callback : Function) {
        await (this._service as UsersService).exportAll(callback);
    }

    async exportVBout(page : number, itemPage : number, status:any, searchText : string, callback : Function) {
        await (this._service as UsersService).exportVBout(page, itemPage, status, searchText, callback);
    }

    async exportNotAvertis(callback : Function) {
        await (this._service as UsersService).exportNotAvertis(callback);
    }

    async exportAvertisNull(callback : Function) {
        await (this._service as UsersService).exportAvertisNull(callback);
    }

    async checkIdentityValidity(sendEmail : boolean,callback: Function = () => { })
    {
        await (this._service as UsersService).checkIdentityValidity(sendEmail,callback);
    }
}