import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import DocumentsViewState from './DocumentsViewState';
import DocumentsViewProps from './DocumentsViewProps';
import DocumentsViewStyle from './DocumentsViewStyle';
import DocumentsViewModel from './DocumentsViewModel';
import { View } from 'react-native';
import DocumentView from './component/document/DocumentView';
import DropppableBoxView from './component/droppableBox/DroppableBoxView';
export default class DocumentsView extends ContentView<DocumentsViewProps, DocumentsViewState, DocumentsViewModel, DocumentsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DocumentsViewProps) {
    super(props);

    // Binding
    this.bind(new DocumentsViewModel(this), new DocumentsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.box}>
        <View style={this.style.elementContainer}>
          <DropppableBoxView insertDocument ={(document : any) => this.binding.insertDocument(document)} projectId={this.state.projectId}></DropppableBoxView>
          <View style={this.style.documents}>
            {
              this.state.projectDocuments.map((document: any,index : number) => {
                return (
                  <View style={this.style.document}>
                    <DocumentView document={document} index={index} removeFromList = {() => this.binding.removeFromList(index)}></DocumentView>
                  </View>
                )
              })
            }
          </View>
        </View>
      </View>
    );
  }
}