import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import BankPopUpViewState from './BankPopUpViewState';
import BankPopUpViewProps from './BankPopUpViewProps';
import BankPopUpViewStyle from './BankPopUpViewStyle';
import BankPopUpViewModel from './BankPopUpViewModel';
import {ButtonView, TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import PopupHeaderView from '../../../../../../../../../../components/popopheader/PopupHeaderView';
import InputView from '../../../../../../../../../../../../../Components/Input/input/InputView';

export default class BankPopUpView extends ContentView<BankPopUpViewProps, BankPopUpViewState, BankPopUpViewModel, BankPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:BankPopUpViewProps)
  { 
    super(props);
 
    // Binding
    this.bind(new BankPopUpViewModel(this), new BankPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container,footer,NoButton,YesButton,NoButtonText,error} = this.style;

    return (
      <View style={{ minWidth: 300, minHeight: 700, width: 1200, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={this.binding.handleBackPressed}
          title={"NewBankName"}
          width={1200}
        />   
 
        <View style={container}>  
        <View>
          <InputView  
          subscribe={() => { }} 
          shouldCheck={false} 
          Style={{ marginTop: 30 }} 
          inputClass="col-lg-7" 
          label={"Nom FR"} 
          labelClass="col-lg-7" 
          onChange={this.binding.handleBankFRChanged} 
          required={true} 
          type="text" />
        </View>
        <View>
          <InputView 
          subscribe={() => { }} 
          shouldCheck={false} 
          Style={{ marginTop: 30 }} 
          inputClass="col-lg-7" 
          label={"Nom NL"} 
          labelClass="col-lg-7"  
          onChange={this.binding.handleBankNLChanged} 
          required={true} 
          type="text" />
        </View>
        <View>
          <InputView 
          subscribe={() => { }} 
          shouldCheck={false} 
          Style={{ marginTop: 30 }} 
          inputClass="col-lg-7" 
          label={"Nom EN"} 
          labelClass="col-lg-7"  
          onChange={this.binding.handleBankENChanged} 
          required={true} 
          type="text" />
        </View>
     
        <View style={footer}>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={NoButton}>
            <TranslationView  style={NoButtonText}>
              Cancel
             </TranslationView>
          </ButtonView>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={this.state.isLoading} handleClick={this.binding.handleConfirmPressed} backgroundColor="#FFB243" style={YesButton}>
            <TranslationView >
              Confirm
             </TranslationView>
          </ButtonView>
        </View>
        {this.state.showErrors && 
            <TranslationView style ={error}>
              {"error.bank"}
            </TranslationView>}
        </View>
        

      </View>
    );
  }
}