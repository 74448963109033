import {ContentViewModel, NavigationManager, Managers, Application, ContentView} from '@levelapp/softfabric';
import TabProjectsViewState from './TabProjectsViewState';
import TabProjectsViewProps from './TabProjectsViewProps';
import { View , Text, Image, TouchableWithoutFeedback} from 'react-native';
import StateManager from '../../../../../Common/Managers/StateManager';
import ProjectsOperation from '../../../../../Transfer/ProjectsOperations';
import StateManagerConst from '../../../../../Common/Managers/StateManagerConst';
import React from 'react';
import TabProjectsViewStyle from './TabProjectsViewStyle';

export default class TabProjectsViewModel extends ContentViewModel<TabProjectsViewState, TabProjectsViewProps, TabProjectsViewStyle>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager : StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    projectsOperation = new ProjectsOperation();

    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.PROJECT_TAB);

        /* Initial State */
        this.initialState({ 
            resetStepView : false,  
            rows : [], 
            initialRow : [] ,
            initialData : [] , 
            projects : [] , 
            tableHeaders: [], 
            datas : [] , 
            isLoading: true, 
            text : previousState != undefined ? previousState.text : '',
            nbPage : 0,
            currentPage : 1
        });

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.handleSuccessProjectCallback = this.handleSuccessProjectCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.setField({isLoading : true});
        this.projectsOperation.getSearchPagination(1, 50, "", this.handleSuccessProjectCallback);
    }

    pushRows(datasToPush : any[]){
        let rows : any[] = [];
        datasToPush.forEach(project =>
            {
                this.pushRow(project,rows);
            });
            this.setField({ datas : datasToPush , rows : rows , resetStepView : true});
    }

    pushRow(project : any, rowsToPush : any[]){
        rowsToPush.push(
            <View>
                <Text style={this.style().text} >{project.name}</Text>
                <TouchableWithoutFeedback onPress = {() => {this.editField(project)}} >
                    <View style={{display : 'flex' , flex : 1 ,  alignItems: 'center'  }} >
                        <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                    </View>
                </TouchableWithoutFeedback>       
            </View>
        )
    }
 
    handleSuccessProjectCallback(ret:any)
    {
         let projects = ret.objects;
         let datas :any[] = [];
         let rows : any[] = [];
         projects.forEach((project:any) => {
            let projectDTO = {
                "name" : project.consoleTitle.toUpperCase(),
            }
            datas.push(projectDTO);
            rows.push(
                 <View>
                     <Text style={this.style().text} >{projectDTO.name}</Text>
                     <TouchableWithoutFeedback onPress = {() => {this.editField(projectDTO)}} >
                         <View style={{display : 'flex' , flex : 1 ,  alignItems: 'center'  }} >
                             <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                         </View>
                     </TouchableWithoutFeedback>       
                 </View>
             )
         });
         this.setField({ datas : datas, rows : rows,initialData : datas,initialRow : rows});
         this.setField({ tableHeaders : ['NAME','CREE ONGLET'],
        });
        this.setField({isLoading : false, projects : projects});

        if(this.state().text != ''){
            this.handlerResearching(this.state().text)
        }
        this.setField({nbPage : ret.totalPage})
    }

    handleResetStepView()
    {
        this.setField({resetStepView : false});
    }

    resetTable(){
        if(this.state().text == "")
            this.handlingClose();
        else(this.handlerResearching(this.state().text))
    }

    handlerResearching(txt : string)
    {
            let rows : any[] = [];
            let datas = this.state().initialData.filter(project =>
                {
                    let contains = false;
                    for(let key in project)
                    { 
                        contains = JSON.stringify(project[key]).toLocaleLowerCase().includes(txt.toLocaleLowerCase());
                        if(contains) {
                            this.pushRow(project, rows);
                            return contains;
                        }
                    }
                    return contains;
        
                });
                this.setField({text : txt, datas : datas , rows : rows , resetStepView : true});
    }

    handlingClose()
    {
        this.setField({ text : "", datas : this.state().initialData, rows : this.state().initialRow });
    }
    componentWillUnmount(){
        this.StateManager.addState(StateManagerConst.PROJECT,this.state());
    }

    editField(project: any) {
        let myProject = this.state().projects.find((projectFull) => projectFull.consoleTitle.toUpperCase() === project.name);
        this.NavigationManager.navigate('/dashboard/projectTab/tabCreation', true, myProject.code);
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    refresh() {
        this.setField({isLoading : true});
        this.projectsOperation.getSearchPagination(1, 50, "", this.handleSuccessProjectCallback);
    }
}