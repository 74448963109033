import { ContentViewModel, NavigationManager, Application, ContentView, Managers } from '@levelapp/softfabric';
import ProjectsViewState from './ProjectsViewState';
import ProjectsViewProps from './ProjectsViewProps';
import ProjectsOperation from '../../../../../../Transfer/ProjectsOperations';
import { Text, View, TouchableWithoutFeedback, Image } from 'react-native';
import React from 'react';
import ProjectsViewStyle from './ProjectsViewStyle';
import StateManager from '../../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../../Common/Managers/StateManagerConst';
import RoleManager from '../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../Common/Managers/ExtendedManagers';
import Helpers from '../../../../../../Components/Helpers/Helpers';


export default class ProjectsViewModel extends ContentViewModel<ProjectsViewState, ProjectsViewProps, ProjectsViewStyle>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    projectsOperation = new ProjectsOperation();
    textResearch: string = "";
    interval: any;

    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.PROJECT);


        /* Initial State */
        this.initialState({
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            projects: [],
            tableHeaders: [],
            datas: [],
            isLoading: true,
            text: previousState != undefined ? previousState.text : '',
            currentPage: 1,
            isResearching: false,
            nbPage: 0,
            isAdmin: this.RoleManager.isAdmin,
            isExport: this.RoleManager.isExport,
            isExporting: false,
        });

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.editFieldByCode = this.editFieldByCode.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRow = this.pushRow.bind(this);

        this.createNewProject = this.createNewProject.bind(this);
        this.refresh = this.refresh.bind(this);
        this.handleSuccessCallback = this.handleSuccessCallback.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    ExportNumberInvestment() {
        this.setField({ isExporting: true });
        this.projectsOperation.sendExportInvestmentNumber(() => {
            this.setField({ isExporting: false });
        });
    }

    componentDidMount() {
        this.refresh();
        if (Helpers.getQueryVariable('code')) {
            this.projectsOperation.getByCode(Helpers.getQueryVariable('code'), this.editFieldByCode)
        }
    }

    createNewProject() {
        let project = {
            "newProject": true,
        }
        this.navigate('/dashboard/projects/newProject', true, project);
    }

    refresh() {
        this.setField({ isLoading: true });
        this.projectsOperation.getSearchPagination(this.state().currentPage, 10, this.textResearch, this.handleSuccessCallback);
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(project => {
            this.pushRow(project, rows);
        });
        this.setField({ datas: datasToPush, rows: rows, resetStepView: true });
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    pushRow(project: any, rowsToPush: any[]) {
        let nb = project.NbTotalInvest;
        let tot = project.TotalInvest;
        rowsToPush.push(
            <View>
                <Text style={this.style().text} >{project.name}</Text>
                <Text style={this.style().text}>{nb}</Text>
                <Text style={this.style().text}>{tot}</Text>
                <Text style={this.style().text}>{project.nbInvestiseurValide}</Text>
                <Text style={this.style().text}>{project.montantTotValid}</Text>
                <Text style={this.style().text}>{project.nbInvestiseurAttente}</Text>
                <Text style={this.style().text}>{project.montantTotAttente}</Text>
                <TouchableWithoutFeedback onPress={() => { this.editField(project) }} >
                    <View style={{ display: 'flex', flex: 1, alignItems: 'center' }} >
                        <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        )
    }

    handleSuccessCallback(ret: any) {
        let projects: any[] = ret.objects;
        let datas: any[] = [];
        let rows: any[] = [];
        projects.forEach((project) => {
            let totalValidated = 0;
            let totalNotValidated = 0;
            let projectDTO = {
                "id": project.id,
                "type": project.projectType.title,
                "name": project.consoleTitle.toUpperCase(),
                "nbInvestiseurValide": 0,
                "montantTotValid": totalValidated,
                "nbInvestiseurAttente": 0,
                "montantTotAttente": totalNotValidated,
                "NbTotalInvest": project.nbTotalInvest,
                "TotalInvest": project.totalInvest,
                "NbTotalInvestValidated": project.nbTotalInvestValidated,
                "TotalInvestValidated": project.totalInvestValidated,
                "NbTotalInvestWaiting": project.nbTotalInvestWaiting,
                "TotalInvestWaiting": project.totalInvestWaiting,
            }
            let nb = projectDTO.NbTotalInvest;
            let tot = projectDTO.TotalInvest;
            datas.push(projectDTO);
            rows.push(
                <View>
                    <Text style={this.style().text} >{projectDTO.name}</Text>
                    <Text style={this.style().text} >{projectDTO.type}</Text>
                    <Text style={this.style().text}>{nb}</Text>
                    <Text style={this.style().text}>{tot}</Text>
                    <Text style={this.style().text}>{projectDTO.NbTotalInvestValidated}</Text>
                    <Text style={this.style().text}>{projectDTO.TotalInvestValidated}</Text>
                    <Text style={this.style().text}>{projectDTO.NbTotalInvestWaiting}</Text>
                    <Text style={this.style().text}>{projectDTO.TotalInvestWaiting}</Text>
                    <TouchableWithoutFeedback onPress={() => { this.editField(projectDTO) }} >
                        <View style={{ display: 'flex', flex: 1, alignItems: 'center' }} >
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ datas: datas, rows: rows, initialData: datas, initialRow: rows });
        this.setField({ tableHeaders: ['NAME', 'STATUS', 'INVEST. TOT', 'MONTANT TOT', 'INVEST. VALIDÉS', 'MONTANT VALIDÉ', 'INVEST. ATTENTE', 'MONTANT ATTENTE', 'EDIT'], });
        this.setField({ nbPage: ret.totalPage });
        this.setField({ isLoading: false, projects: projects });
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    resetTable() {
        if (this.state().text == "")
            this.handlingClose();
        else (this.handleResearching(this.state().text))
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true, text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handlingClose() {
        this.state().isResearching = false;
        this.textResearch = "";
        this.onPageChange(0);
    }
    componentWillUnmount() {
        this.StateManager.addState(StateManagerConst.PROJECT, this.state());
    }

    editField(project: any) {
        this.setField({ isLoading: true });
        let myProject = this.state().projects.find((projectFull) => projectFull.id === project.id);
        this.projectsOperation.getByCode(myProject.code, (ret: any) => {
            this.NavigationManager.navigate('/dashboard/projects/edit', true, ret);
        });
    }

    editFieldByCode(project: any) {
        this.setField({ isLoading: true });
        this.NavigationManager.navigate('/dashboard/projects/edit', true, project);      
    }
}