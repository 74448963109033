import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import ProjectInvestementStatusUpdatedViewState from './ProjectInvestementStatusUpdatedViewState';
import ProjectInvestementStatusUpdatedViewProps from './ProjectInvestementStatusUpdatedViewProps';

export default class ProjectInvestementStatusUpdatedViewModel extends ContentViewModel<ProjectInvestementStatusUpdatedViewState, ProjectInvestementStatusUpdatedViewProps>{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
        });

        this.handleBackPressed = this.handleBackPressed.bind(this);
    }

    handleBackPressed() {
        this.closePopUp();
    }

}