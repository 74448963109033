import * as React from 'react';
import { ContentView } from '@levelapp/softfabric';
import StepViewState from './StepViewState';
import StepViewProps from './StepViewProps';
import StepViewStyle from './StepViewStyle';
import StepViewModel from './StepViewModel';
import { View, TouchableWithoutFeedback, Text } from 'react-native';
export default class StepView extends ContentView<StepViewProps, StepViewState, StepViewModel, StepViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: StepViewProps)
  {
    super(props);

    // Binding
    this.bind(new StepViewModel(this), new StepViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    if (this.state.showing)
    {
      this.binding.getCurrentPage();
    }

    var displayPage = [];

    var start = (this.state.currentPage + 1) - this.props.minimumPage

    if(this.props.numberDisplays > this.props.minimumPage * 2)
    {
      if (start <= 0)
      {
        start = 0;
      } else
      {
        if (start > this.props.numberDisplays - ((this.props.minimumPage * 2) + 1))
        {
          start = this.props.numberDisplays - ((this.props.minimumPage * 2) + 1);
        }
        //start;
      }
      var end = (this.state.currentPage + 1) + this.props.minimumPage
  
      if (end >= this.props.numberDisplays)
      {
        end = this.props.numberDisplays;
      } else
      {
        end = (this.props.minimumPage * 2) + 1 + start;
      }
    }else
    {
      start = 0;
      end = this.props.numberDisplays;
    }    

    if (start != 0 && start - 1 != 0)
    {
      displayPage.push(
        <TouchableWithoutFeedback onPress={() => this.binding.onPressPoint('back')} style={this.style.circle}>
          <View style={this.style.circle}>
            <Text style={this.style.text} >...</Text>
          </View>
        </TouchableWithoutFeedback>
      )
    }

    for (let i = start; i < end; i++)
    {

      displayPage
        .push(
          <TouchableWithoutFeedback onPress={() => this.binding.onPress(i)} key={i} style={[this.style.circle]}>
            <View style={[this.state.currentPage == i && this.state.showing ? [this.style.choose, this.props.styleOfChoosenButton] : this.style.circle]}>
              <Text style={[this.state.currentPage == i && this.state.showing ? this.style.chooseText : this.style.text]}> {i + 1}</Text>
            </View>
          </TouchableWithoutFeedback>
        )
    }


    if (end != this.props.numberDisplays && end + 1 != this.props.numberDisplays)
    {
      displayPage.push(
        <TouchableWithoutFeedback onPress={() => this.binding.onPressPoint('front')} style={this.style.circle}>
          <View style={this.style.circle}>
            <Text style={this.style.text} >...</Text>
          </View>
        </TouchableWithoutFeedback>
      )
    }






    return (
      <View style={[this.style.container, this.props.style]}>

        {/* Left Arrow */}
        {start != 0 &&
          <TouchableWithoutFeedback onPress={() => this.binding.onPress(0)} style={this.style.circle}>
            <View style={this.style.circle}>
              <Text style={this.style.text}>1</Text>
            </View>
          </TouchableWithoutFeedback>
        }

        {/* Number of pages */}
        {displayPage}


        {/* Right Arrow */}
        {end < this.props.numberDisplays &&
          <TouchableWithoutFeedback onPress={() => this.binding.onPress(this.props.numberDisplays - 1)} style={this.style.circle}>
            <View style={this.style.circle} >
              <Text style={this.style.text}>{this.props.numberDisplays}</Text>
            </View>
          </TouchableWithoutFeedback>
        }
      </View>

    );
  }
}