const RoleConstants = {

    ADMIN: "ADMIN",
    EDITOR: "EDITOR",
    READER: "READER",
    EXPORT: "EXPORT",
    CONSULT: "CONSULT",
    CONSOLE: "CONSOLE",
    NEWSLETTER : "NEWSLETTER",
    MARKETINGEMAIL : "MARKETINGEMAIL"
}

export default RoleConstants;