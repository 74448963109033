import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class TabService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    
    async getProjectTabOrders(code : string,callback: Function)
    {
        this.httpClient.get("api/Tab/GetProjectTabOrders/"+code, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async getTabById(id : number,callback: Function)
    {
        this.httpClient.get("api/Tab/GetTabById/"+id, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async insertTab(tab : any,callback: Function)
    {

        this.httpClient.post("api/Tab/InsertTab", [], tab).then((response: any) => 
        {        
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async updateTab(tab : any,callback: Function)
    {
        this.httpClient.put("api/Tab/UpdateTab", [], tab).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async softDelete(tab : any,callback: Function)
    {
        this.httpClient.put("api/Tab/SoftDelete", [], tab).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async depublish(tab : any,callback: Function)
    {
        this.httpClient.put("api/Tab/Depublish", [], tab).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async publishStaging(tab : any,callback: Function)
    {
        this.httpClient.put("api/Tab/PublishStaging", [], tab).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async publishProd(tab : any,callback: Function)
    {
        this.httpClient.put("api/Tab/PublishProd", [], tab).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}