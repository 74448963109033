import {
  Application,
  HttpManager,
  Managers,
  Service,
} from "@levelapp/softfabric";

export default class UserNotificationServices extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async sendNotification(notification: any, callback: Function) {
    return this.httpClient
        .post("api/notifications", [], notification)
        .then((response: any) => {
          if (response != undefined) {
            callback(response.data.data);
            return response;
          }
        });
  }
}
