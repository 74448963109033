import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import MultimediaViewState from './MultimediaViewState';
import MultimediaViewProps from './MultimediaViewProps';
import { Image, View } from 'react-native';
import React from 'react';


export default class MultimediaViewModel extends ContentViewModel<MultimediaViewState, MultimediaViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleLogoFileChanged = this.handleLogoFileChanged.bind(this)
        this.handlePreviewFileChanged = this.handlePreviewFileChanged.bind(this)
        this.showPic = this.showPic.bind(this)
    }

    showPic(pictureName : string){
        switch(pictureName){
            case 'preview' : this.showPopUp(
                <View style={{ height: 700, width: 900}}>
                    <Image source={{ uri: this.props().project.previewPictureUrl }} style={{ height: "100%", width: "100%",resizeMode: 'contain'}}/>
                </View>);
                break;
            case 'logo' : this.showPopUp(
                <View style={{ height: 700, width: 900}}>
                    <Image source={{ uri: this.props().project.logoPictureUrl }} style={{ height: "100%", width: "100%",resizeMode: 'contain'}}/>
                </View>);
                break;
        } 
        
    }
    

    handleLogoFileChanged(file : File){
        this.setField((previousState) => previousState.logoFile = file);
        this.props().onLogoPictureChange(file);
    }

    handlePreviewFileChanged(file : File){
        this.setField((previousState) => previousState.previewFile = file);
        this.props().onPreviewPictureChange(file);
    }

    handlePdfFileFRChanged(file : File){
        this.setField((previousState) => previousState.PdfFileFR = file);
        this.props().onPdfFRChange(file);
    }
    handlePdfFileNLChanged(file : File){
        this.setField((previousState) => previousState.PdfFileNL = file);
        this.props().onPdfNLChange(file);
    }

    handleFileLogoChanged(file : File) {
        this.setField((previousState) => previousState.certificateLogoFile = file);
        this.props().onCertificateLogoFileChange(file);
    }

    handleFileSignatureChanged(file : File) {
        this.setField((previousState) => previousState.certificateSignatureFile = file);
        this.props().onCertificateSignatureFileChange(file);
    }
}