import RoleConstants from '../../Constants/Constant';

export default class RoleManager 
{
    isAdmin = false;
    isReader = false;
    isExport = false;
    isEditor = false;
    isNewsletter = false;
    isMarketingEmail = false;
    constructor() {
    }

    setRoles(roles: any[])
    {
        this.isAdmin = roles.indexOf(RoleConstants.ADMIN)>=0
        this.isReader = roles.indexOf(RoleConstants.READER)>=0
        this.isExport = roles.indexOf(RoleConstants.EXPORT)>=0
        this.isEditor = roles.indexOf(RoleConstants.EDITOR)>=0
        this.isNewsletter = roles.indexOf(RoleConstants.NEWSLETTER)>=0
        this.isMarketingEmail = roles.indexOf(RoleConstants.MARKETINGEMAIL)>=0
    }

}