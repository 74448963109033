import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import MyStyleManager from '../../../../../../../../../../Common/Managers/MyStyleManager';
import { UiManagers } from '@levelapp/softfabric-ui';

export default class DocumentsViewStyle extends ContentViewStyle{
  MyStyleManager = Application.current.resolve<MyStyleManager>(UiManagers.STYLE_MANAGER);

  box: StyleProp<ViewStyle> = {
      display : 'flex',
      flex : 1,
      backgroundColor : 'white',
      marginTop : '70px',
      marginRight :'47px',
      marginLeft : '47px',
      borderRadius : 20,
      shadowColor: "#0000006b",
      shadowOffset: {
          width: 1,
          height: 1,
      },
      shadowOpacity: 0.30,
      shadowRadius: 40,
  }

  elementContainer: StyleProp<ViewStyle> = {
    display :'flex',
    flex:1,
    marginTop : '29px',
    marginLeft : '156px',
    marginRight : '156px',

  }
  dropContainer: StyleProp<ViewStyle> = {
    display : 'flex',
    height : '179px',
    marginBottom : '17px',
    borderStyle : 'dashed',
    borderWidth : 1,
    borderColor : 'grey',
    justifyContent : 'center',
    alignItems : 'center',
    }

    document: StyleProp<ViewStyle> = {
      display : 'flex',
      flex : 1,
      width : '100%',
      flexDirection : 'row',
      justifyContent : 'flex-start',
      backgroundColor : 'white',
      marginBottom : '15px',
    }
  
    documents: StyleProp<ViewStyle> = {
      display :'flex',
      flex : 1,
      alignItems : 'center',
    }
  
    title: StyleProp<TextStyle> = {
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 25,
      color : this.MyStyleManager.boldFontColor,
      fontWeight : '500',
    }

    clearTitle: StyleProp<TextStyle> = {
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 19,
      color : "#DDDCDC",
    }
    
}