import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import BankTransactionsModel from '../DTO/BankTransactionsModel';

export default class InvestmentsService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);


    async validInvestment(usr: any, callback: Function)
    {
        this.httpClient.post("api/Investments/ValidateInvestment", [], usr).then((response: any) => 
        {          
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async updateInvestmentStatus(investment: any, callback: Function)
    {      
        this.httpClient.put("api/investment/updateStatus", [], investment).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async updateMultipleInvestmentStatus(investments: any[], callback: Function)
    {      
        this.httpClient.put("api/investment/updateMultipleStatus", [], {investments}).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async updateInvestmentStatusFromExcel(model : BankTransactionsModel, callback: Function)
    {      
        this.httpClient.put("api/investment/updateStatusFromExcel", [], model).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }



    async sendEmailCouponCourant(investment: any, callback: Function)
    {              
        this.httpClient.put("api/investment/sendEmailCouponCourant", [], investment).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async addInvestment(investment: any, callback: Function) {
        this.httpClient.post("api/investment/add", [], investment).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async deleteInvestment(investmentId: any, callback: Function) {
        this.httpClient.delete("api/investment/"+investmentId, []).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async transferAmount(investment: any, callback: Function) {       
        this.httpClient.post("api/investment/transfer", [], investment).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async transferReservation(reservation: any, callback: Function) {
        this.httpClient.post("api/investment/transferReservation", [], reservation).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async get(status: any, projectId: any, page: number, itemPage: number, searchText: string, callback: Function) {
        if (!searchText)
            searchText = "placeholder";
        this.httpClient.get("api/investment/GetByProject/" + status + "/"+projectId + "/" + page + '/' + itemPage + '/' + searchText).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async getList(searchText: string, callback: Function) {
        if (!searchText)
            searchText = "placeholder";
        this.httpClient.get("api/investment/transferList/"+searchText).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }
}