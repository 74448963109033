import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class PrimeUsersListViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        margin : 50,
        paddingBottom : 50 
    }
    headerText: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontWeight: 'bold',
        fontSize : 12,
        textAlign : 'center'
    }

 

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }
}