import { ContentViewModel, ContentView, Application, NavigationManager, Managers } from '@levelapp/softfabric';
import LegalEntitiesViewState from './LegalEntitiesViewState';
import LegalEntitiesViewProps from './LegalEntitiesViewProps';
import StateManager from '../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../Common/Managers/StateManagerConst';
import moment from 'moment';
import { Text, View, TouchableWithoutFeedback, Image } from 'react-native';
import LegalEntitiesViewStyle from './LegalEntitiesViewStyle';
import React from 'react';
import StatusOperation from '../../../../../Transfer/StatusOperations';
import LegalEntityOperations from '../../../../../Transfer/LegalEntityOperations';
import * as XLSX from 'xlsx';
import ExtendedManagers from '../../../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../../../Common/Managers/RolesManager';

export default class LegalEntitiesViewModel extends ContentViewModel<LegalEntitiesViewState, LegalEntitiesViewProps, LegalEntitiesViewStyle>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    legalEntityOperation = new LegalEntityOperations();
    textResearch: string = "";
    interval: any;

    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.LEGAL_ENTITY);

        this.initialState({
            resetStepView: false,
            tableHeaders: [],
            statuses: [],
            selectedStatus: previousState != undefined ? previousState.selectedStatus : { id: 0, title: "ALL", legalEntities: null },
            text: previousState != undefined ? previousState.text : '',
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            rows: [],
            initialRow: [],
            initialData: [],
            isLoading: true,
            legalEntities: [],
            datas: [],
            isExporting: false,
            isExport: this.RoleManager.isExport,
            isAdmin: this.RoleManager.isAdmin
        })

        if (previousState != undefined) {
            this.textResearch = previousState.text;
        }

        this.handleSuccessCallback = this.handleSuccessCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.setStatuses = this.setStatuses.bind(this);
        this.handleResearching = this.handleResearching.bind(this);
        this.handlingClose = this.handlingClose.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.resetResearch = this.resetResearch.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.editField = this.editField.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.setStatuses();
    }

    componentWillUnmount() {
        this.StateManager.addState(StateManagerConst.LEGAL_ENTITY, this.state());
    }

    refresh() {
        this.setField({ isLoading: true });
        this.legalEntityOperation.getSearchPagination(this.state().selectedStatus.title, this.state().currentPage, 20, this.textResearch, this.handleSuccessCallback);
    }

    handleSuccessCallback(ret: any) {
        let legalEntities: any[] = ret.objects;
        let datas: any[] = [];
        let rows: any[] = [];
        let state = this.state();

        if (ret.totalPage == 0) {
            state.datas = [];
            state.rows = [];
            state.initialData = [];
            state.initialRow = [];
            state.tableHeaders = ['ID', 'NOM', 'NUM', 'DATE CRÉATION', 'LIAISON EN ATTENTE', 'STATUS', 'EDIT'];
            state.legalEntities = [];
            state.isLoading = false;
            state.nbPage = ret.totalPage;
            this.setField(state);
            return;
        }
        legalEntities.forEach((legalEntity) => {
            let legalEntityDTO = {
                "id": legalEntity.id,
                "creationDate": legalEntity.formCompletionDate,
                "corporateName": legalEntity.corporateName,
                "legalEntityStatusTitle": legalEntity.legalEntityStatus.title,
                "usersPending": legalEntity.usersPending
            }
            var date = moment(legalEntity.formCompletionDate);
            var dateAsString = date.format("DD-MM-YYYY");
            datas.push(legalEntityDTO);
            rows.push(
                <View>
                    <Text style={this.style().text} >{legalEntityDTO.id}</Text>
                    <Text style={this.style().text}>{legalEntityDTO.corporateName}</Text>
                    <Text style={this.style().text}>{legalEntity.clientNumber}</Text>
                    <Text style={this.style().text}>{dateAsString}</Text>
                    <Text style={this.style().text}>{legalEntityDTO.usersPending ? "OUI" : "NON"}</Text>
                    <Text style={this.style().text}>{legalEntityDTO.legalEntityStatusTitle}</Text>
                    <TouchableWithoutFeedback onPress={() => { this.editField(legalEntity) }} >
                        <View style={{ alignItems: 'center' }} >
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        state.datas = datas;
        state.rows = rows;
        state.initialData = datas.slice(0);
        state.initialRow = rows;
        state.tableHeaders = ['ID', 'NOM', 'NUM', 'DATE CRÉATION', 'LIAISON EN ATTENTE', 'STATUS', 'EDIT'];
        state.legalEntities = legalEntities;
        state.isLoading = false;
        state.nbPage = ret.totalPage;
        this.setField(state);
    }


    setStatuses() {
        let statuses: any[] = [];
        statuses.push({ id: 0, title: "ALL", legalEntities: null });
        statuses.push({ id: 999, title: "EN ATTENTE DE LIAISON", legalEntities: null });
        new StatusOperation().getStatuses("M", (statusList: any) => {
            statusList.forEach((status: any) => {
                statuses.push(status);
            });            
            this.setField({ statuses: statuses });
        });
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value }, () => {
            this.handleResearching(this.state().text);
        });
    }

    handlingClose() {
        this.state().isResearching = false;
        this.textResearch = "";
        this.onPageChange(0);
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true, text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    resetTable() {
        if (this.state().selectedStatus.title == "ALL" && this.state().text == "") {
            this.resetResearch();
        }
    }

    resetResearch() {
        let state = this.state();
        state.datas = state.initialData.slice(0);
        state.rows = state.initialRow.slice(0);
        state.selectedStatus = "ALL";
        this.setField(state);
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(legalEntity => {
            this.pushRow(legalEntity, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(legalEntity: any, rowsToPush: any[]) {
        let date = moment(legalEntity.formCompletionDate);
        let dateAsString = date.format("DD-MM-YYYY");
        rowsToPush.push(
            <View>
                <Text style={this.style().text}>{legalEntity.id}</Text>
                <Text style={this.style().text}>{legalEntity.corporateName}</Text>
                <Text style={this.style().text}>{legalEntity.clientNumber}</Text>
                <Text style={this.style().text}>{dateAsString}</Text>
                <Text style={this.style().text}>{legalEntity.usersPending ? "OUI" : "NON"}</Text>
                <Text style={this.style().text}>{legalEntity.legalEntityStatusTitle}</Text>

                <TouchableWithoutFeedback onPress={() => { this.editField(legalEntity) }} >
                    <View>
                        <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                    </View>
                </TouchableWithoutFeedback>


            </View>
        )
    }

    editField(legalEntity: any) {
        //if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            let myLegalEntity = this.state().legalEntities.find((legalEntityFull) => legalEntityFull.id === legalEntity.id);
            this.NavigationManager.navigate('/dashboard/legalentities/edit', true, myLegalEntity);
        //} else {
       //     alert("No permission")
        //}

    }

    exportLegalEntities() {
        this.setField({ isExporting: true });
        this.legalEntityOperation.export(this.state().selectedStatus.title, this.textResearch, (legalEntitiesToExport: any) => {
            var headers = ['id', 'Date de création', 'Dénomination Sociale', 'Forme Juridique', 'Rue', 'Numéro', 'Boite', 'Code Postal', 'Localité', 'Pays', 'Secteur d\'activité', 'Code NACE', 'Numéro de TVA', 'Assujetti TVA', 'Numéro de Téléphone', 'IBAN', 'BIC', 'Code LEI', 'Validité LEI', 'Nombre dirigeants', 'Lien BCE', 'Lien Nomination Leader', 'Lien Relevé Bancaire'];
            var jsonContent: any[] = [];
            legalEntitiesToExport.forEach((value: any) => {
                var element = "{";
                var row = "";
                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.id);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.formCompletionDate);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(value.corporateName);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.legalForm);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.address.street);
                row += ",";
                row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.address.number);
                row += ",";
                row += JSON.stringify(headers[6]) + ":" + JSON.stringify(value.address.box);
                row += ",";
                row += JSON.stringify(headers[7]) + ":" + JSON.stringify(value.address.postCode);
                row += ",";
                row += JSON.stringify(headers[8]) + ":" + JSON.stringify(value.address.locality);
                row += ",";
                row += JSON.stringify(headers[9]) + ":" + JSON.stringify(value.address.country.toUpperCase());
                row += ",";
                row += JSON.stringify(headers[10]) + ":" + JSON.stringify(value.activityArea);
                row += ",";
                row += JSON.stringify(headers[11]) + ":" + JSON.stringify(value.naceCode);
                row += ",";
                row += JSON.stringify(headers[12]) + ":" + JSON.stringify(value.tvaNumber);
                row += ",";
                row += JSON.stringify(headers[13]) + ":" + JSON.stringify(value.assujettiTva);
                row += ",";
                row += JSON.stringify(headers[14]) + ":" + JSON.stringify(value.phoneNumber);
                row += ",";
                row += JSON.stringify(headers[15]) + ":" + JSON.stringify(value.iban);
                row += ",";
                row += JSON.stringify(headers[16]) + ":" + JSON.stringify(value.bic);
                row += ",";
                row += JSON.stringify(headers[17]) + ":" + JSON.stringify(value.leiCode);
                row += ",";
                row += JSON.stringify(headers[18]) + ":" + JSON.stringify(value.leiValidity);
                row += ",";
                row += JSON.stringify(headers[19]) + ":" + JSON.stringify(value.numberOfLeader);
                row += ",";
                row += JSON.stringify(headers[20]) + ":" + JSON.stringify(value.bceRegistrationUrl);
                row += ",";
                row += JSON.stringify(headers[21]) + ":" + JSON.stringify(value.leadersNominationUrl);
                row += ",";
                row += JSON.stringify(headers[22]) + ":" + JSON.stringify(value.bankIdUrl);
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, "Users");
            XLSX.writeFile(wb, "BeeBondsLegalEntities.xlsx");
            this.setField({ isExporting: false });
        });
    }
}