import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import EditProjectLeaderViewState from './EditProjectLeaderViewState';
import EditProjectLeaderViewProps from './EditProjectLeaderViewProps';
import EditProjectLeaderViewStyle from './EditProjectLeaderViewStyle';
import EditProjectLeaderViewModel from './EditProjectLeaderViewModel';
import { View, Text } from 'react-native';
import InputView from '../../../../../../../Components/Input/input/InputView';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';

export default class EditProjectLeaderView extends ContentView<EditProjectLeaderViewProps, EditProjectLeaderViewState, EditProjectLeaderViewModel, EditProjectLeaderViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: EditProjectLeaderViewProps) {
        super(props);

        // Binding
        this.bind(new EditProjectLeaderViewModel(this), new EditProjectLeaderViewStyle(this));
    }

    render() {
        return (

            <View>
                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", marginLeft: "26px", marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ cursor: "pointer" }}>
                            <img onClick={() => this.binding.NavigationManager.navigate('/dashboard/projectLeaders')} height="31" width="31" src="https://beebondsstorage.blob.core.windows.net/files/consoleImages/close.png"></img>
                        </div>
                        <div>
                            <Text style={this.style.PageTitle}>Administrateur :  {this.state.leader.firstName}  {this.state.leader.lastName}</Text>
                        </div>
                    </div>
                </View>
                <View style={this.style.Box}>
                    <InputView disabled={true} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={"Id"} labelClass="col-lg-7" onChange={() => { }} required={false} value={this.state.leader.id} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Civility")} labelClass="col-lg-7" onChange={this.binding.handleCivilityChanged} required={false} value={this.state.leader.civility} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("FirstNameLabel")} labelClass="col-lg-7" onChange={this.binding.handleFirstNameChanged} required={false} value={this.state.leader.firstName} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NameLabel")} labelClass="col-lg-7" onChange={this.binding.handleLastNameChanged} required={false} value={this.state.leader.lastName} type="text" />
                    {/* COORDINATES */}
                    <div className="row" style={{ marginTop: 50 }}>
                        <div className="col-lg-10">
                            <span style={this.style.headersYellow}>{this.binding.translate("AdressAndCoordinates")}</span>
                        </div>
                    </div>
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Street")} labelClass="col-lg-7" onChange={this.binding.handleStreetChanged} required={false} value={this.state.leader.address.street} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NumberLabel")} labelClass="col-lg-7" onChange={this.binding.handleNumberChanged} required={false} value={this.state.leader.address.number} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Box")} labelClass="col-lg-7" onChange={this.binding.handleBoxChanged} required={false} value={this.state.leader.address.box} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PostalCode")} labelClass="col-lg-7" onChange={this.binding.handlePostalCodeChanged} required={false} value={this.state.leader.address.postCode} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Locality")} labelClass="col-lg-7" onChange={this.binding.handleLocalityChanged} required={false} value={this.state.leader.address.locality} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Country")} labelClass="col-lg-7" placeholder="" onChange={this.binding.handleCountryChange} required={false} value={this.state.leader.address.country} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Email")} labelClass="col-lg-7" onChange={this.binding.handleEmailChanged} required={false} value={this.state.leader.email} type="text" />
                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PhoneNumberLabel")} labelClass="col-lg-7" onChange={this.binding.handlePhoneChanged} required={false} value={this.state.leader.phoneNumber} type="text" />
                                   
                    <ButtonView animation={'None'} borders={'Little'} style={this.style.saveButton} isLoading={false} handleClick={this.binding.save} >
                        <TranslationView>
                            {'save'}
                        </TranslationView>
                    </ButtonView>
                </View>

            </View>
        );
    }
}