import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import TabProjectsViewState from './TabProjectsViewState';
import TabProjectsViewProps from './TabProjectsViewProps';
import TabProjectsViewStyle from './TabProjectsViewStyle';
import TabProjectsViewModel from './TabProjectsViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import ResearchInputView from '../../../../../Components/researchinput/ResearchInputView';
import { View } from 'react-native';
import TableView from '../../../../../Components/table/TableView';


export default class TabProjectsView extends ContentView<TabProjectsViewProps, TabProjectsViewState, TabProjectsViewModel, TabProjectsViewStyle>
{
/* CONSTRUCTOR */
constructor(props:TabProjectsViewProps)
{
  super(props);

  // Binding
  this.bind(new TabProjectsViewModel(this), new TabProjectsViewStyle(this));
}

/* RENDERING */
render()
{
 return (
   <View style={this.style.container}>
     <View style={this.style.box}>


       {/* Color BOX */}
       <View style={this.style.boxTitle}>
         <TranslationView style={this.style.title}>
           Projects
         </TranslationView>

         <TranslationView  style={this.style.subTitle}>
         ManageYourProjects
         </TranslationView>
       </View>
       <View style={this.style.boxContainer}>
            {/* Research Input */}
            < View style = {this.style.containerResearch}>
            <ResearchInputView     
              textInFront = "Recherche : "
              placeholder = "search"
              handlerResearching = {(text : string) => this.binding.handlerResearching(text)}
              handlingClose = {() => {this.binding.handlingClose()}}
              text = {this.state.text}
              />
          </View > 

          <View style = {{display :"flex"}}>
            <TableView
              isLoading = {this.state.isLoading}
              lottie = {require('./assets/loading.json')}
              handleResetStepView = {this.binding.handleResetStepView}
              resetStepView = {this.state.resetStepView}
              headerData = { this.state.tableHeaders }
              rows  = {this.state.rows}
              numberOfRows = {5}
              isSortable = {true}
              resetTable = {this.binding.resetTable}
              datas = {this.state.datas}
              fillTables = {(value : any[] ) => {this.binding.pushRows(value)}}
              keyDatas = {["name"]}
              headerIndex = {[0]}
              headerTextStyle = {this.style.headerText}
              currentPage={this.state.currentPage -1}
              nbPage={this.state.nbPage}
              onPageChange= {(page: number) => this.binding.onPageChange(page)}
            ></TableView>
          </View>
        </View>
     </View>
   </View>
 );
}
}