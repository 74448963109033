import React from 'react';
import { ContentView, Application, Managers, NavigationManager, LanguageManager } from '@levelapp/softfabric';
import { TranslationView } from '@levelapp/softfabric-ui';
import MenuViewState from './MenuViewState';
import MenuViewProps from './MenuViewProps';
import MenuViewStyle from './MenuViewStyle';
import MenuViewModel from './MenuViewModel';

import { View, TouchableWithoutFeedback, Image, Text } from 'react-native';
import UrlConstants from '../../../../../Constants/UrlConstants';

export default class MenuView extends ContentView<MenuViewProps, MenuViewState, MenuViewModel, MenuViewStyle> {
  NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
  LanguageManager: LanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);

  /* CONSTRUCTOR */
  constructor(props: MenuViewProps) {
    super(props);

    // Binding
    this.bind(new MenuViewModel(this), new MenuViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.menu}>
        <View style={this.style.logoContainer}>
          <Image style={{ height: 100, resizeMode: 'contain', display: 'flex', flex: 1 }} source={require('./assets/logo.svg')} />
        </View>
        <View style={this.style.linksContainer}>

          <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/users')}>
            <View style={this.NavigationManager.route.indexOf('/users') != -1 ? this.style.linkActive : this.style.link}>
              <TranslationView style={this.NavigationManager.route.indexOf('/users') != -1 ? this.style.linkTextActive : this.style.linkText}>
                Investors
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/nonInvestors')}>
            <View style={this.NavigationManager.route.indexOf('/nonInvestors') != -1 ? this.style.linkActive : this.style.link}>
              <TranslationView style={this.NavigationManager.route.indexOf('/nonInvestors') != -1 ? this.style.linkTextActive : this.style.linkText}>
                users
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/legalentities')}>
            <View style={this.NavigationManager.route.indexOf('/legalentities') != -1 ? this.style.linkActive : this.style.link}>
              <TranslationView style={this.NavigationManager.route.indexOf('/legalentities') != -1 ? this.style.linkTextActive : this.style.linkText}>
                LegalEntities
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>

          <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/projects')}>
            <View style={this.NavigationManager.route.indexOf('/projects') != -1 ? this.style.linkActive : this.style.link}>
              <TranslationView style={this.NavigationManager.route.indexOf('/projects') != -1 ? this.style.linkTextActive : this.style.linkText}>
                Projects
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>

          {(this.state.isNewsletter || this.state.isAdmin) &&
            <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/newsletter')}>
              <View style={this.NavigationManager.route.indexOf('/newsletter') != -1 ? this.style.linkActive : this.style.link}>
                <TranslationView style={this.NavigationManager.route.indexOf('/newsletter') != -1 ? this.style.linkTextActive : this.style.linkText}>
                  Newsletter
                </TranslationView>
              </View>
            </TouchableWithoutFeedback>
          }

          {/* 
           <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/reservation')}>
            <View style={this.NavigationManager.route.indexOf('/reservation') != -1 ? this.style.linkActive : this.style.link}>
              <TranslationView style={this.NavigationManager.route.indexOf('/reservation') != -1 ? this.style.linkTextActive : this.style.linkText}>
               Reservation
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>
          */}


          {this.state.isAdmin &&
            <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/projectTab')}>
              <View style={this.NavigationManager.route.indexOf('/projectTab') != -1 ? this.style.linkActive : this.style.link}>
                <TranslationView style={this.NavigationManager.route.indexOf('/projectTab') != -1 ? this.style.linkTextActive : this.style.linkText}>
                  ProjectsDetails
                </TranslationView>
              </View>
            </TouchableWithoutFeedback>
          }

          {/* 
          <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/emails')}>
            <View style={this.NavigationManager.route.indexOf('/emails') != -1 ? this.style.linkActive : this.style.link}>
              <TranslationView style={this.NavigationManager.route.indexOf('/emails') != -1 ? this.style.linkTextActive : this.style.linkText}>
                Emails
              </TranslationView>
            </View>
</TouchableWithoutFeedback> */}

          {this.state.isAdmin &&
            <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/roles')}>
              <View style={this.NavigationManager.route.indexOf('/roles') != -1 ? this.style.linkActive : this.style.link}>
                <TranslationView style={this.NavigationManager.route.indexOf('/roles') != -1 ? this.style.linkTextActive : this.style.linkText}>
                  roles
                </TranslationView>
              </View>
            </TouchableWithoutFeedback>
          }

          {(this.state.isEditor || this.state.isAdmin) &&
            <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/projectLeaders')}>
              <View style={this.NavigationManager.route.indexOf('/projectLeaders') != -1 ? this.style.linkActive : this.style.link}>
                <TranslationView style={this.NavigationManager.route.indexOf('/projectLeaders') != -1 ? this.style.linkTextActive : this.style.linkText}>
                  ProjectLeaders
                </TranslationView>
              </View>
            </TouchableWithoutFeedback>

          }

          {(this.state.isExport || this.state.isAdmin) &&
            <TouchableWithoutFeedback onPress={() => this.NavigationManager.navigate('/dashboard/export')}>
              <View style={this.NavigationManager.route.indexOf('/export') != -1 ? this.style.linkActive : this.style.link}>
                <TranslationView style={this.NavigationManager.route.indexOf('/export') != -1 ? this.style.linkTextActive : this.style.linkText}>
                  Export
                </TranslationView>
              </View>
            </TouchableWithoutFeedback>
          }
          <TouchableWithoutFeedback onPress={() => this.binding.logout()}>
            <View style={this.style.link}>
              <TranslationView style={this.style.linkText}>
                Logout
              </TranslationView>
            </View>
          </TouchableWithoutFeedback>

          <Text style={{ marginTop: '50px', color: '#a9afbb' }}>
            {UrlConstants.LAST_UPDATE}
          </Text>

        </View>
      </View>
    );
  }
}