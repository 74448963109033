import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class SendEmailToPendingPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    tabsContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "row",  
        borderBottomWidth : 0.2
    }

    tabContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flex : 1,    
        alignItems : "center",
        padding : 10,
        
        borderTopEndRadius : 10,
        borderTopStartRadius : 10, 
        

    }
    currentTabContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flex : 1,
        alignItems : "center",
        backgroundColor : "#D5DBDB",
        padding : 10,
        

    }
    tabTitle : StyleProp<TextStyle> =
    {
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 18, 
        fontWeight : 'bold'
    }
    headerText: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontWeight: 'bold',
        fontSize : 12,
        textAlign : 'center'
    }
    tableContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        margin: 50,
        marginBottom : 0,
    }

    title : StyleProp<TextStyle> =
    {
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 18, 
        fontWeight : 'bold',
        
    }
    bottomLineContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'flex-start', 
        flexDirection : 'row',
        margin: 50,
    }
    topLineContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'flex-start', 
        flexDirection : 'row',
        margin: 50,
        marginTop: 15,
        marginBottom: 0,
    }

    YesButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        display: 'flex',
        height: 42,
        justifyContent : 'center',
        alignItems : 'center',
        padding : 10,
    } 
    

 

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }



    
}