import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import SendEmailToPendingPopUpViewState from './SendEmailToPendingPopUpViewState';
import SendEmailToPendingPopUpViewProps from './SendEmailToPendingPopUpViewProps';
import React from 'react';
import { View,Text } from 'react-native';
import ProjectsOperation from '../../../../../../../../../../../../../Transfer/ProjectsOperations';

export default class SendEmailToPendingPopUpViewModel extends ContentViewModel<SendEmailToPendingPopUpViewState, SendEmailToPendingPopUpViewProps>
{

    projectOperation = new ProjectsOperation();
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            showSucces : false,
            isSendLoading : false,
            isLoading : true,
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            datas: [],
            nbPage: 0,
            currentPage: 1,
            investPending : [],
            nbRows : 10
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSuccessCallback = this.handleSuccessCallback.bind(this);

        this.handleSendEmailPressed = this.handleSendEmailPressed.bind(this);

    }

    componentDidMount(){
        this.setField((previousState) => previousState.showSucces = false)
        this.projectOperation.getInvestmentsPending(this.props().project.id,(response : any)=> {
            this.setField((previousState) => previousState.nbPage = Math.ceil(response.length/this.state().nbRows));
            this.setField((previousState) => previousState.investPending = response); 
            this.handleSuccessCallback()
        })
    }

    handleSendEmailPressed(){
        this.setField((previousState) => previousState.isSendLoading = true);
        this.projectOperation.sendEmailToPending(this.props().project,()=>{
            this.setField((previousState) => previousState.isSendLoading = false)
            this.setField((previousState) => previousState.showSucces = true)
         })
    }

    handleBackPressed(){
        this.closePopUp()
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    async onPageChange(page: number) {
        this.setField((previousState) => previousState.currentPage = page + 1);
        await new Promise(resolve => setTimeout(resolve, 10));
        this.handleSuccessCallback()
    }

    async handleSuccessCallback() {
        let datas: any[] = [];
        let investments = this.state().investPending;
        let rows: any[] = [];

        investments.slice((this.state().currentPage-1)*this.state().nbRows,(this.state().currentPage)*this.state().nbRows).forEach((investment) => {
            datas.push(investment);
            rows.push(
                <View>
                    <Text >{investment.id}</Text>
                    <Text >{investment.userId}</Text>
                    <Text >{investment.legalEntity ? investment.legalEntity.corporateName : 'Aucune'}</Text>
                    <Text >{investment.user.lastName +" "+ investment.user.firstName}</Text>
                    <Text >{investment.user.email}</Text>
                    <Text >{investment.amount}</Text>
                    <Text >{investment.isWaiting ? "OUI" : "NON"}</Text>
                    <Text >{investment.isValidated ? "Validé" : "Non validé"}</Text>
      
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ isLoading: false });
    }

}