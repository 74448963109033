import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class InvestmentReservationService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async addReservation(reservation: any, callback: Function) {
        this.httpClient.post("api/investmentreservation/add", [], reservation).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async getSearchPagination(status: any, page: number, itemPage: number, searchText: string, callback: Function = () => { }) {
        if (!searchText)
            searchText = "placeholder";
        this.httpClient
            .get('api/investmentreservation/Pagination/' + status + "/" + page + '/' + itemPage + '/' + searchText)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        callback(response.data.data);
                    }
                }
            });
    }
}