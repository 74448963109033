import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DroppableBoxViewState from './DroppableBoxViewState';
import DroppableBoxViewProps from './DroppableBoxViewProps';
import ImagesOperation from '../../../../../../../../../../../Transfer/ImagesOperations';

export default class DroppableBoxViewModel extends ContentViewModel<DroppableBoxViewState, DroppableBoxViewProps>{
  Images = new ImagesOperation();

  constructor(props: ContentView) {
    super(props);

    /* Initial State */
    this.initialState({
      currentFile: null,
      imageToSend: {
        "projectId": this.props().projectId,
        "url": "",
      },
      isLoading : false,
    });


    /* BINDINGS */
    this.handleClick = this.handleClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.save = this.save.bind(this);
    this.removeCurrentFile = this.removeCurrentFile.bind(this);
    this.getBase64 = this.getBase64.bind(this);

  }


  handleClick() {
    this.setField({docByClick : true},() =>{
      (this._view.refs.fileUploader as any).click();
    })
  }

  handleDrop(file: any) {
    if (this.state().currentFile != null) return;
    this.setField({ currentFile: file[0] })
  }

  save() {
    this.setField({ isLoading: true })
    var file = this.state().currentFile;
    var send = this.state().imageToSend;
    this.getBase64(file, (result : any) => {
      send.file = result.split('base64,')[1];
      this.Images.insertImage(send, (response: any) => {
        var emptyImg = {
          "projectId": this.props().projectId,
          "url": "",
        }
        this.setField({ currentFile: null ,ImageToSend : emptyImg}, () => {
          this.props().insertImage(response);
          this.setField({ isLoading: false })
        });
      });
    });  
}

  getBase64(file: any, cb: Function) {
    let reader = new FileReader();
    reader.onload = function () {
      cb(reader.result)
    };
    reader.readAsDataURL(file);
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  removeCurrentFile() {
    var emptyImg = {
      "projectId": this.props().projectId,
      "url": "",
    }
    this.setField({ currentFile: null ,ImageToSend : emptyImg});
  }
}