import * as React from 'react';
import LoginViewState from './LoginViewState';
import LoginViewProps from './LoginViewProps';
import LoginViewStyle from './LoginViewStyle';
import LoginViewModel from './LoginViewModel';
import { View,  TextInput } from 'react-native';
import {ButtonView, UiManagers, StyleManager , TranslationView} from '@levelapp/softfabric-ui';
import { ContentView, Application } from '@levelapp/softfabric';


export default class LoginView extends ContentView<LoginViewProps, LoginViewState, LoginViewModel, LoginViewStyle>
{
  MyManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  /* CONSTRUCTOR */
  constructor(props: LoginViewProps)
  {
    super(props);

    // Binding
    this.bind(new LoginViewModel(this), new LoginViewStyle(this));

    
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>

        <View style={this.style.loginBox}>
        
          {/* RED BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView  style={this.style.title} >
              Title
            </TranslationView>
       
          </View>

          <View style={this.style.loginContainer}>
            <TextInput placeholder={this.binding.translate('EmailAddress')} onChangeText={(email: string) => this.binding.handleEmailChanged(email)} value={this.state.email} />
            <TextInput secureTextEntry={true} placeholder={this.binding.translate('Password')} onChangeText={(pwd: string) => this.binding.handlePasswordChanged(pwd)} value={this.state.password} />
            <ButtonView animation={'None'} borders={'Little'} handleClick={this.binding.login} isLoading={this.state.isLoading} textColor="white" backgroundColor={this.MyManager.primaryColor}>
              <TranslationView>
                Login
              </TranslationView>
            </ButtonView>
          </View>
        </View>
      </View>
    );
  }
}