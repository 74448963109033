import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import DroppableViewState from './DroppableViewState';
import DroppableViewProps from './DroppableViewProps';
import DroppableViewStyle from './DroppableViewStyle';
import DroppableViewModel from './DroppableViewModel';
import { View } from 'react-native';

export default class DroppableView extends ContentView<DroppableViewProps, DroppableViewState, DroppableViewModel, DroppableViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DroppableViewProps) {
    super(props);

    // Binding
    this.bind(new DroppableViewModel(this), new DroppableViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        <div ref={(r) => this.binding.divRef = r}>
          <View style={this.state.dragging ?
            { justifyContent: 'center', alignItems: 'center', backgroundColor: "rgb(240, 240, 240)"} :
            { justifyContent: 'center', alignItems: 'center'}}>
            {this.props.children}
          </View>
        </div>
      </View>
    );
  }
}