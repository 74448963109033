import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import DescriptionViewState from './DescriptionViewState';
import DescriptionViewProps from './DescriptionViewProps';

export default class DescriptionViewModel extends ContentViewModel<DescriptionViewState, DescriptionViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}