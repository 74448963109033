import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, TextStyle } from 'react-native';

export default class InvestementDeletedPopUpViewStyle extends ContentViewStyle {
    title: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 50,
        marginBottom: 50,
    };
}
