import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import PrimeUsersListViewState from './PrimeUsersListViewState';
import PrimeUsersListViewProps from './PrimeUsersListViewProps';
import PrimeUsersListViewStyle from './PrimeUsersListViewStyle';
import PrimeUsersListViewModel from './PrimeUsersListViewModel';
import { LottieView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import TableView from '../../../../../../../../../../../../../../Components/table/TableView';

export default class PrimeUsersListView extends ContentView<PrimeUsersListViewProps, PrimeUsersListViewState, PrimeUsersListViewModel, PrimeUsersListViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PrimeUsersListViewProps)
  {
    super(props);

    // Binding
    this.bind(new PrimeUsersListViewModel(this), new PrimeUsersListViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {container,headerText,stepColor} = this.style
    return (
      <View style={container}>
        {this.state.isLoading ?
        <LottieView height ={50} width = {50} source ={require('./assets/loading.json')}/>
        :
        <View>
          <TableView
          handleResetStepView={this.binding.handleResetStepView}
          resetStepView={this.state.resetStepView}
          headerData={["Email", "User ID","Nom","Prénom","PM ID","Nom de la PM","Delete"]}
          rows={this.state.rows}
          numberOfRows={this.state.nbRows}
          isLoading={false}
          lottie={require('./assets/loading.json')}
          isSortable={false}  
          resetTable={()=>{}}
          datas={this.state.datas} 
          fillTables={() => {}}
          keyDatas={["Email", "User ID","Nom","Prénom","PM ID","Nom de la PM","Delete"]}
          headerIndex={[0, 1, 2, 3, 4, 5, 6]}
          headerTextStyle={headerText}
          onPageChange={(page: number) => this.binding.onPageChange(page)}
          nbPage={this.state.nbPage}
          currentPage={this.state.currentPage - 1}
          styleOfChoosenButton={stepColor}/>
        </View>
       
        /*<TableView
          headers = {["Email", "User ID","Nom","Prénom","PM ID","Nom de la PM"]}
          numberItemsPerPage={10}
          data ={this.binding.fillTable()}
          isLastColumnActions={true}
          isLoading={false}
          textInFrontForResearch={''}
          placeholderInput={''}
          nothingToDisplayText={''}
        />*/}
      </View>
    );
  }
}