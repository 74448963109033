import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class StatusService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    
    async get(type: string, callback: Function)
    {
        this.httpClient.get("api/Status/byType/"+type, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}