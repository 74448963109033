import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp } from 'react-native';

export default class TableCellViewStyle extends ContentViewStyle{
    headerCell: StyleProp<any> = {
        color: 'black',
        fontWeight: 'bold',
        fontSize : 15,
    };

    cell: StyleProp<any> = {
        color: '#3f3f3f',
        fontWeight: '300',
        flexWrap : 'wrap',
    };
}