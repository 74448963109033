import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import TableRowViewProps from './TableRowViewProps';
import TableRowViewModel from './TableRowViewModel';
import TableRowViewState from './TableRowViewState';
import TableRowViewStyle from './TableRowViewStyle';
import { View } from 'react-native';

export default class TableRowView extends ContentView<TableRowViewProps, TableRowViewState, TableRowViewModel, TableRowViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TableRowViewProps)
  {
    super(props);

    // Binding
    this.bind(new TableRowViewModel(this), new TableRowViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.props.style}>
        {this.binding.getCellViews()}
      </View>
    );
  }
}