import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class BankPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "column",  
        justifyContent : "space-between",
        paddingHorizontal: 50, 
        paddingVertical: 60,

        
    }
 

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        justifyContent:'flex-end',
        flexDirection: 'row',
        zIndex: 10,
        marginTop: 50
    };

    NoButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        height: 42,
        width: 119,
        marginRight : '5px'
    }  

   YesButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        height: 42,
        width: 165,
        marginLeft : '5px'
    } 

    NoButtonText: StyleProp<TextStyle> = {
       color: '#9C9C9C',
    };

    input: StyleProp<any> = {
        width: 140,
        paddingHorizontal: 15,
        borderRadius : 8,
        boxShadow: '0 5px 14px 0 rgba(0,0,0,0.1)',
    };
    error : StyleProp<any> = {
        justifyContent: 'center',
        marginTop: 30, 
        color: 'red', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '20px', 
        lineHeight: '20px',
        width: '100%'
    }

}