import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PrimePopUpViewState from './PrimePopUpViewState';
import PrimePopUpViewProps from './PrimePopUpViewProps';
import ProjectsOperation from '../../../../../../../../../../../../../Transfer/ProjectsOperations';

export default class PrimePopUpViewModel extends ContentViewModel<PrimePopUpViewState, PrimePopUpViewProps>
{
    projectOperation = new ProjectsOperation();
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            currentTab : 'addPrime',
            emailList : {
                'unknown' : [],
                'duplicate' : []
            },
            showError : false,
            showSucces : false,
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this)
        this.handleResponseDataReceived = this.handleResponseDataReceived.bind(this)


    }
    componentDidUpdate(){
    }

    handleBackPressed(){
        this.closePopUp()
    }
    handleTabChange(tabName : string){
        this.setField((previousState) => previousState.currentTab = tabName);
    }
    handleResponseDataReceived(data : any){
        if( data.unknown.length == 0 && data.duplicate.length == 0) {
            this.setField((previousState) => previousState.showSucces = true);
            this.setField((previousState) => previousState.showError = false); 
        }
        else {
            this.setField((previousState) => previousState.showSucces= false);
            this.setField((previousState) => previousState.emailList.unknown = data.unknown);
            this.setField((previousState) => previousState.emailList.duplicate = data.duplicate);
            this.setField((previousState) => previousState.showError = true);
        }
    }

}