import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import MyStyleManager from '../../../../../../../../../../../../Common/Managers/MyStyleManager';
import { UiManagers } from '@levelapp/softfabric-ui';

export default class DroppableBoxViewStyle extends ContentViewStyle {

    MyStyleManager = Application.current.resolve<MyStyleManager>(UiManagers.STYLE_MANAGER);
    
    title: StyleProp<TextStyle> = {
        fontFamily: this.MyStyleManager.fontFamily,
        fontSize: 25,
        color: this.MyStyleManager.boldFontColor,
        fontWeight: '500',
        paddingTop : 21
    }

    clearTitle: StyleProp<TextStyle> = {
        fontFamily: this.MyStyleManager.fontFamily,
        fontSize: 19,
        color: "#DDDCDC",
    }

    dropContainer: StyleProp<ViewStyle> = {
        height: '179px',
        marginBottom: '17px',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: 'grey',
        justifyContent: 'center',
        alignItems: 'center',
    }

    button: StyleProp<ViewStyle> = {
        display : 'flex',
        height : 30,
        width : 150,
        justifyContent : 'center',
        alignItems : 'center',
        backgroundColor : 'white',
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 1,
        },
        marginTop : 10,
        marginBottom : 20,
        shadowOpacity: 0.30,
        shadowRadius: 30,
        borderRadius : 10,
    }

    inputs: StyleProp<ViewStyle> = {
        flex : 1,
        flexDirection : 'row',
        justifyContent : 'space-evenly',
        alignItems : 'center',
        marginBottom : 28,
    }

    input: StyleProp<TextStyle> = {
        width : '233px',
        height : '32px',
        fontFamily : this.MyStyleManager.fontFamily,
        borderWidth : 1,
        borderColor : '#D5D5D5',   
    }

    label: StyleProp<TextStyle> = {
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize : 19,
        fontWeight : '600',
    }
  
    inputContainer: StyleProp<ViewStyle> = {
        flex : 1,
        justifyContent :'flex-start',
        alignItems :'center',
        marginRight : 40,
        marginLeft : 40,
    }
  
    fileName: StyleProp<TextStyle> = {
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize : 21,
        paddingTop : 27,
        paddingBottom : 27,
    }

    saveText : StyleProp<TextStyle> = {
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize : 15,
        color : 'white',
    }

    buttonSave: StyleProp<ViewStyle> = {
        width : "81.6px",
        height : "30.4px",
        backgroundColor :'#6DC663',
        borderRadius : 5,
        marginBottom : 22.6,
    }

    littleIcon: StyleProp<ViewStyle> = {
        marginRight : 5,
        marginLeft : 5,
    }

    name: StyleProp<ViewStyle> = {
        flex : 1,
        flexDirection : 'row',
        justifyContent :'center',
        alignItems :'center',
    }
}