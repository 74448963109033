import { ContentViewModel, NavigationManager, Application, Managers, ContentView } from '@levelapp/softfabric';
import EditProjectViewState from './EditProjectViewState';
import EditProjectViewProps from './EditProjectViewProps';
import RoleManager from '../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../Common/Managers/ExtendedManagers';
import ProjectDTO from "../../../../../../../../DTO/ProjectDTO";

export default class EditProjectViewModel extends ContentViewModel<EditProjectViewState, EditProjectViewProps>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)

    constructor(props: ContentView) {
        super(props);
        var project = this.parameters<any>();
        let total = this.getAmountNotValidated(project.investments)

        this.initialState({
            project: project,
            total : total.toLocaleString()+"€",
            isAdmin: this.RoleManager.isAdmin,
            isEditor : this.RoleManager.isEditor,
            isMarketingEmail: this.RoleManager.isMarketingEmail,
        })

        ///dashboard/projects/edit/investors"

    }

    componentDidMount()
    {
        var project = this.parameters<any>();
        this.NavigationManager.navigate('/dashboard/projects/edit/investors', true, project);
    }


    getAmountNotValidated(investments: any) {
        let total = 0;
        if(investments != undefined){
            var arrayLength = investments.length;
            for (var i = 0; i < arrayLength; i++) {
                if(investments[i].isValidated){
                    total += investments[i].amount;
                }
            }
        }

        return total;
    }
    callback(project: ProjectDTO) {
        this.setField((previousState) => previousState.project = project);
    }
}
