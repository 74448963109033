import React from "react";
import {ContentView} from "@levelapp/softfabric";
import ReadExcelViewProps from "./ReadExcelViewProps";
import ReadExcelViewState from "./ReadExcelViewState";
import ReadExcelViewModel from "./ReadExcelViewModel";
import ReadExcelViewStyle from "./ReadExcelViewStyle";
import ButtonImportFileView from "@levelapp/softfabric-ui/components/Web/buttonimportfile/ButtonImportFileView";

export default class ReadExcelView extends ContentView<ReadExcelViewProps, ReadExcelViewState, ReadExcelViewModel, ReadExcelViewStyle> {
    constructor(props: ReadExcelViewProps) {
        super(props);
        this.bind(new ReadExcelViewModel(this), new ReadExcelViewStyle(this));

    }

    render() {
        return (
            <div style={{margin:20}}>
                <ButtonImportFileView buttonColor="#FFB243" action={this.binding.handleFile} accepted={'XLS'} />
            </div>
        );
    }
}
