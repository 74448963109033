import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import UserInvestmentsViewState from './UserInvestmentsViewState';
import UserInvestmentsViewProps from './UserInvestmentsViewProps';
import Profile from '../../../../../../../../../../../DTO/Profile';
import React from 'react';
import { Text, TouchableWithoutFeedback, Image, View } from 'react-native';
import DropDownView from '../../../../../../../../../../../Components/Dropdown/DropDownView';
import InvestmentsOperation from '../../../../../../../../../../../Transfer/InvestmentsOperations';
import ProjectInvestementStatusUpdatedView from '../../../../../../../../../../../Components/projectinvestementstatusupdated/ProjectInvestementStatusUpdatedView';
import UserInvestmentsViewStyle from './UserInvestmentsViewStyle';
import StateManager from '../../../../../../../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../../../../../../../Common/Managers/StateManagerConst';
import AddInvestmentPopUpView from './modules/addinvestmentpopup/AddInvestmentPopUpView';
import InvestementAddedPopUpView from './modules/investementaddedpopup/InvestementAddedPopUpView';
import TransferAmountPopUpView from './modules/transferamountpopup/TransferAmountPopUpView';
import { ButtonView } from '@levelapp/softfabric-ui';
import SendEmailCouponCourantPopUpView from './modules/sendemailcouponcourantpopup/SendEmailCouponCourantPopUpView';
import MailSendedPopUpView from './modules/mailsendedpopup/MailSendedPopUpView';
import ExtendedManagers from '../../../../../../../../../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../../../../../../../../../Common/Managers/RolesManager';
import moment from 'moment';
import DeleteInvestmentPopUpView from './modules/deleteinvestmentpopup/DeleteInvestmentPopUpView';

export default class UserInvestmentsViewModel extends ContentViewModel<UserInvestmentsViewState, UserInvestmentsViewProps, UserInvestmentsViewStyle>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    public _user: any;

    dropdownValues = [
        {
            title: "Pending",
            value: false
        },
        {
            title: "Validated",
            value: true
        },
    ];

    constructor(props: ContentView) {
        super(props);

        let usr = this.parameters<Profile>();
        let previousState = this.StateManager.retrieveState(StateManagerConst.INVESTMENT_USER);

        this._user = localStorage.getItem("user");

        /* Initial State */
        this.initialState({
            profile: usr,
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            isAdmin: this.RoleManager.isAdmin,
        });
        this.updateInvestmentStatus = this.updateInvestmentStatus.bind(this);
        this.updateSelectStatuses = this.updateSelectStatuses.bind(this);
        this.getSelectedValue = this.getSelectedValue.bind(this);
        this.handleSuccessInvestmentCallback = this.handleSuccessInvestmentCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.showInvestPopUp = this.showInvestPopUp.bind(this);
        this.addInvestment = this.addInvestment.bind(this);
        this.transferAmount = this.transferAmount.bind(this);
        this.sendEmailCouponCourantPopUp = this.sendEmailCouponCourantPopUp.bind(this);
    }

    componentDidMount() {
        let investments = this.state().profile.investments;
        this.handleSuccessInvestmentCallback(investments);
    }

    handleSuccessInvestmentCallback(investments: any[]) {
        let datas: any[] = [];
        let rows: any[] = [];
        investments.sort((a, b) => b.id - a.id);
        investments.forEach((investment) => {
            let investmentDTO = {
                "id": investment.id,
                "amount": investment.amount,
                "interests": investment.interests,
                "project": investment.project.consoleTitle,
                "projectCode": investment.project.code,
                "creationDate": investment.creationDate.replace('T', ' '),
                "isValidatedString": this.getSelectedValue(investment).title,
                "isCouponCourus": investment.isCouponCourus,
                "LastModificationDate": investment.lastModificationDate,
                "isWaiting": investment.isWaiting
            }

            var momentDate = moment.utc(investmentDTO.creationDate);
            var dateAsString = momentDate.local().format('DD-MM-YYYY  H:mm:ss ')

            var momentModifDate = moment.utc(investmentDTO.LastModificationDate);
            var modifDateAsString = momentModifDate.local().format('DD-MM-YYYY  H:mm:ss ')

            datas.push(investmentDTO);

            if (this.state().isAdmin || this._user == 'vincent' || this._user == 'pierre-marie') {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <TouchableWithoutFeedback onPress={() => { this.showProject(investmentDTO.projectCode) }} >
                            <View style={this.style().text} >
                                <Text style={this.style().text}>{investmentDTO.project}</Text>
                            </View>
                        </TouchableWithoutFeedback>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text}>{modifDateAsString}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI' : 'NON'}</Text>
                        <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, investment) }} selectedValue={this.getSelectedValue(investment)} values={this.dropdownValues}></DropDownView>
                        <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(investment) }} >
                            <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                        </TouchableWithoutFeedback>
                        {investment.project.projectTypeId != 4 ?
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showTransferAmountPopUp(investmentDTO) }}>
                                <Text style={this.style().text} >Transférer</Text>
                            </ButtonView>
                            : <></>
                        }

                        {this.getSelectedValue(investment).title != "Validated" ?
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.deleteInvestment(investment) }}>
                                <Text style={this.style().text} >Supprimer</Text>
                            </ButtonView>
                            : <></>
                        }

                        {(investmentDTO.isCouponCourus && investmentDTO.interests == 0 && !investment.isValidated) ?
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showSendEmailCouponCourantPopUp(investment) }}>
                                <Text style={this.style().text} >Envoyer email</Text>
                            </ButtonView>
                            : <></>
                        }
                    </View>
                )
            }
            else if (this.getSelectedValue(investment).title == "Validated") {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <TouchableWithoutFeedback onPress={() => { this.showProject(investmentDTO.projectCode) }} >
                            <View style={this.style().text} >
                                <Text style={this.style().text}>{investmentDTO.project}</Text>
                            </View>
                        </TouchableWithoutFeedback>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text}>{modifDateAsString}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI' : 'NON'}</Text>
                        <Text style={this.style().text}>{this.getSelectedValue(investment).title}</Text>
                        <Text style={this.style().text}>X</Text>
                        <Text style={this.style().text}></Text>
                    </View>
                )
            }
            else {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <TouchableWithoutFeedback onPress={() => { this.showProject(investmentDTO.projectCode) }} >
                            <View style={this.style().text} >
                                <Text style={this.style().text}>{investmentDTO.project}</Text>
                            </View>
                        </TouchableWithoutFeedback>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text}>{modifDateAsString}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI' : 'NON'}</Text>
                        <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, investment) }} selectedValue={this.getSelectedValue(investment)} values={this.dropdownValues}></DropDownView>
                        <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(investment) }} >
                            <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                        </TouchableWithoutFeedback>
                        {investmentDTO.isCouponCourus && investmentDTO.interests == 0 && !investment.isValidated &&
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showSendEmailCouponCourantPopUp(investment) }}>
                                <Text style={this.style().text} >Envoyer email</Text>
                            </ButtonView>
                        }
                    </View>
                )
            }
        });

        this.setField({ datas: datas, rows: rows, initialData: datas.slice(0), initialRow: rows });
        if (this.state().isAdmin) {
            this.setField({ tableHeaders: ['ID', 'AMOUNT', 'PROJET', 'DATE', 'MODIFICATION', 'IS WAITING', 'STATUT', 'CHANGER STATUT', 'TRANSFERER', 'SUPPRIMER', 'COUPON COURU'] });
        }
        else {
            this.setField({ tableHeaders: ['ID', 'AMOUNT', 'PROJET', 'DATE', 'MODIFICATION', 'IS WAITING', 'STATUT', 'CHANGER STATUT', 'COUPON COURU'] });
        }
        if (this.state().text != '') {
            this.handlerResearching(this.state().text)
        }
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(investment => {
            this.pushRow(investment, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(investment: any, rowsToPush: any[]) {

        let myInvestment = this.state().profile.investments.find((investmentFull) => (investmentFull.projectId === investment.projectId));

        var momentDate = moment.utc(investment.creationDate);
        var dateAsString = momentDate.local().format('DD-MM-YYYY  H:mm:ss ')



        rowsToPush.push(
            <View>
                <Text style={this.style().text} >{investment.projectId}</Text>
                <Text style={this.style().text}>{investment.amount}</Text>
                <Text style={this.style().text}>{dateAsString}</Text>
                <Text style={this.style().text} >{investment.isWaiting ? 'OUI' : 'NON'}</Text>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, myInvestment) }} selectedValue={this.getSelectedValue(myInvestment)} values={this.dropdownValues}></DropDownView>
                </View>
                <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(myInvestment) }} >
                    <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback onPress={() => { this.showTransferAmountPopUp(myInvestment) }} >
                    <Text style={this.style().text} >Transfer</Text>
                </TouchableWithoutFeedback>
            </View>
        )
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    resetTable() {
        if (this.state().text == "")
            this.handlingClose();
        else (this.handlerResearching(this.state().text))
    }

    handlerResearching(txt: string) {
        let rows: any[] = [];

        let datas = this.state().initialData.filter(investment => {
            let contains = false;

            for (let key in investment) {
                contains = JSON.stringify(investment[key]).toLocaleLowerCase().includes(txt.toLocaleLowerCase());
                if (contains) {
                    this.pushRow(investment, rows);
                    return contains;
                }
            }
            return contains;

        });
        this.setField({ text: txt, datas: datas, rows: rows, resetStepView: true });
    }

    handlingClose() {
        this.setField({ text: "", datas: this.state().initialData, rows: this.state().initialRow });
    }

    updateSelectStatuses(value: any, invest: any) {
        let profile = this.state().profile;
        profile.investments.filter(inv => inv.id == invest.id)[0].isValidated = value.value;
        this.setField({ profile: profile });
    }

    updateInvestmentStatus(investment: any) {
        this.parameters<any>().profile = this.state().profile;
        new InvestmentsOperation().updateInvestmentStatus(investment)
            .then(() => {
                // tappfiliate
                if (investment.isValidated) {
                    let id = this.state().profile.clientNumber;
                    (window as any).tap('create', '41178-800dee', { integration: "javascript" });
                    (window as any).tap('conversion', 'InvestmentValidated-' + id + "-" + investment.id, investment.amount, { customer_id: id, program_group: 'partner-program' });

                }
                this.showPopUp(
                    <ProjectInvestementStatusUpdatedView />
                );
            }).then(() => {
                setTimeout(() => this.closePopUp(), 3000);
                this.NavigationManager.navigate('/dashboard/users/', true);
            });
    }

    getSelectedValue(invest: any) {
        return this.dropdownValues[invest.isValidated ? 1 : 0];
    }

    showInvestPopUp() {
        this.showPopUp(<AddInvestmentPopUpView callBack={this.addInvestment}></AddInvestmentPopUpView>);
    }

    showTransferAmountPopUp(invest: any) {
        this.showPopUp(<TransferAmountPopUpView investmentId={invest.id} maxAmount={invest.amount} callBack={this.transferAmount}></TransferAmountPopUpView>);
    }

    deleteInvestment(invest: any) {
        this.showPopUp(<DeleteInvestmentPopUpView investment={invest}></DeleteInvestmentPopUpView>);
    }

    showSendEmailCouponCourantPopUp(investment: any) {
        this.showPopUp(<SendEmailCouponCourantPopUpView project={investment.project} callBack={this.sendEmailCouponCourantPopUp} investment={investment}></SendEmailCouponCourantPopUpView>);
    }

    addInvestment(amount: any, project: any) {
        var investment = { amount: amount, UserId: this.state().profile.id, Project: { ConsoleTitle: project } };
        new InvestmentsOperation().addInvestment(investment)
            .then(() => {
                this.showPopUp(
                    <InvestementAddedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/users/', true);
            });
    }

    transferAmount(amount: any, investmentId: any, destinationUserId: any, isPm: boolean, PMId: any, sendEmail: boolean, changeIsWaiting: boolean) {
        var investment = { amount: amount, InvestorDestinationId: destinationUserId, InvestmentSourceId: investmentId, IsPm: isPm, PMId: PMId, SendEmail: sendEmail, changeIsWaiting: changeIsWaiting };

        new InvestmentsOperation().transferAmount(investment)
            .then(() => {
                this.showPopUp(
                    <InvestementAddedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/users/', true);
            });
    }

    sendEmailCouponCourantPopUp(investment: any) {
        new InvestmentsOperation().sendEmailCouponCourant(investment)
            .then(() => {
                this.showPopUp(
                    <MailSendedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/users/', true);
            });
    }

    showProject(project: any) {
        this.navigate('/dashboard/projects?code=' + project, true);
    }
}
