import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import CerificatesPopUpViewState from './CerificatesPopUpViewState';
import CerificatesPopUpViewProps from './CerificatesPopUpViewProps';
import CertificatesOperation from '../../../../../../../../../../../../../Transfer/CertificatesOperations';
import * as XLSX from 'xlsx';


export default class CerificatesPopUpViewModel extends ContentViewModel<CerificatesPopUpViewState, CerificatesPopUpViewProps>
{
    certificatesOperation = new CertificatesOperation();
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            isLoading : false,
            isSendLoading : false,
            certificatesInfos : [],
            isCreationLinkLoading : false,
            downloadLink :"",

        }); // Initialize your state here

        // Bindings
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleBackpressed = this.handleBackpressed.bind(this)
        this.handleDownloadButtonPressed = this.handleDownloadButtonPressed.bind(this)
        this.handlSendEmailsButtonPressed = this.handlSendEmailsButtonPressed.bind(this)
    }

    handleBackpressed(){
        this.closePopUp()
    }

    handlSendEmailsButtonPressed(){    
        this.setField((previousState)=>previousState.isSendLoading = true);
        let model = { "CertificatesList": this.state().certificatesInfos, "ProjectCode": this.props().projectCode };
        this.certificatesOperation.sendCertificates(model, () => {
            this.setField((previousState)=>previousState.isSendLoading = false);
        });
    }

    handleDownloadButtonPressed() {
        this.setField((previousState)=>previousState.isLoading = true);
        let model = { "CertificatesList": this.state().certificatesInfos.slice(0,1), "ProjectCode": this.props().projectCode };
        this.certificatesOperation.getCertificate(model, async (response: any) => {
            this.setField((previousState)=>previousState.isLoading = false);
            this.setField((previousState)=>previousState.isCreationLinkLoading = true);
            await new Promise(resolve => setTimeout(resolve, 5000));      
            this.setField((previousState)=>previousState.isCreationLinkLoading = false);   
            this.setField((previousState)=>previousState.downloadLink = response);   
        })
    }

    handleFileUpload(file: File) {
        this.setField((previousState)=>previousState.file = file);
        if (file != undefined) {

            //Read Excel File
            var fileReader = new FileReader();
            fileReader.onloadend = () => {
                // Leave that
                var binary = "";
                var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                this.TransformExcelToData(binary);
            }
            fileReader.readAsArrayBuffer(file);
        }
    }

    /* Importe registre oblig */
    TransformExcelToData(data: any) {
        var workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
        var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {range:18});
        var certificatesInfos: any[] = [];              
        json.forEach((v: any) => {
            let obligationNumber = v["N° du Certificat"];
            let salutation = v["Mr / Mme / Mlle"];
            let name = v["Nom"] + " " + v["Prénom "];
            let address = v["Adresse PP 1"] + " à " + v["Pays PP"] + " - " + v["Code Postal PP"] + " - " + v["Ville PP"];
            if (salutation == "Dhr & Mvr" || salutation == "Dhr." || salutation == "Mevr." || salutation == "Mvr" || salutation == "Mvr." || salutation == "Dhr" || salutation == "Mevrouw." ) {
                address = v["Adresse PP 1"] + " te " + v["Pays PP"] + " - " + v["Code Postal PP"] + " - " + v["Ville PP"];
            }
            let nbObligations = v["Nombre d'Obligation(s)"];
            let obligationStart = v["Numéro d’Ordre des Obligations"];
            let obligationEnd = v["__EMPTY"];
            let capital = v["Total Émis"];
            let email = v["Email"];
            let gender = v["Mr / Mme / Mlle"];

            //let date = v["Date de l’inscription"];
            let date = v["Date"];

            let certificatesInfo = {
                "Number": obligationNumber, "Salutation": salutation, "Name": name, "Address": address, "NbObligations": nbObligations,
                "ObligationStart": obligationStart, "ObligationEnd": obligationEnd, "Capital": capital, "Email": email, "Gender": gender,
                "Date": date
            };
            certificatesInfos.push(certificatesInfo);

        });
        this.setField((previousState)=>previousState.certificatesInfos = certificatesInfos);       
    }
}