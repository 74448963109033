import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class NewsletterService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER); 

    async subscribe(email: any, callback: Function)
    {
        this.httpClient.post("api/newsletter", [], email).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data);
            }
        });
    }
}