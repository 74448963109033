import LoginViewState from './LoginViewState';
import LoginViewProps from './LoginViewProps';
import { ContentViewModel, ContentView, Application, NavigationManager, Managers, AuthManager } from '@levelapp/softfabric'
import LoginOperation from '../../../Transfer/LoginOperation';
import User from '../../../DTO/User';
import ConnectObject from '../../../DTO/ConnectObject';
import UsersOperation from '../../../Transfer/UsersOperation';
import UserInfos from '../../../DTO/UserInfos';
import RoleManager from '../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../Common/Managers/ExtendedManagers';

export default class LoginViewModel extends ContentViewModel<LoginViewState, LoginViewProps>{

    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);

    constructor(props: ContentView) {
        super(props);
        /* Initial State */
        this.initialState({ email: '', password: '', isLogin: false, isLoading: false });
        let auth = Application.current.resolve<AuthManager>(Managers.AUTH_MANAGER);
        auth.clearToken();


        /* BINDING */
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.login = this.login.bind(this);
    }

    /* HANDLERS */
    handleEmailChanged(email: string) {
        this.setField({ email: email });
    }

    handlePasswordChanged(password: string) {
        this.setField({ password: password });
    }

    /* FUNCTIONS */
    login()
    {
        this.setField({isLoading : true});
        if (this.state().email !== '' && this.state().password !== '') {
            /* We call the login service */
            let credentials: User = { "email": this.state().email, "password": this.state().password };
            new LoginOperation().post(credentials, (data: ConnectObject) => {
                if (data.access_token !== "") {
                    new UsersOperation().getUserInfos((data: UserInfos) => {
                        if(data.roles != null ) {
                            this.setField({ isLogin: true }, () => {
                                localStorage.setItem("isLogged", "true");      
                                localStorage.setItem("user", data.email.replace("@beebonds.com","")); 
                                Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER).setRoles(data.roles) 
                                this.navigate('/dashboard', true, {});
                            });
                        }
                     
                        
                        else {
                            alert("Username or password incorect");
                        }
                        this.setField({isLoading : false});                      
                    });
                }
            }, () => {
                alert("Username or password incorect");
                this.setField({isLoading : false});   
            });
        }
        else {
            alert("Please enter your username and password");
            this.setField({isLoading : false});   
        }        
    }
}