import { Manager } from "@levelapp/softfabric";
 
export default class StateManager extends Manager
{
 private myStorageNotPersistent : any = {};
 
 constructor()
 {
 super();
 }
 
 addState(key : string , value : any)
 {
 this.myStorageNotPersistent[key] = value;
 }
 
 retrieveState(key : string)
 {
 return this.myStorageNotPersistent[key];
 }
 
}