import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import AddInvestmentPopUpViewState from './AddInvestmentPopUpViewState';
import AddInvestmentPopUpViewProps from './AddInvestmentPopUpViewProps';
import ProjectsOperation from '../../../../../../../../../../../../../Transfer/ProjectsOperations';

export default class AddInvestmentPopUpViewModel extends ContentViewModel<AddInvestmentPopUpViewState, AddInvestmentPopUpViewProps>
{
    projectsOperation = new ProjectsOperation();

    constructor(props: ContentView) {
        super(props);


        /* Initial State */
        this.initialState({
            amount: 0,
            project: '',
            projects: ''
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handleProjectChanged = this.handleProjectChanged.bind(this);
        this.handleSuccessProjectCallback = this.handleSuccessProjectCallback.bind(this);
    }

    componentDidMount() {
        this.setField({ isLoading: true });
        this.projectsOperation.getList(this.handleSuccessProjectCallback);
    }

    handleSuccessProjectCallback(projectsList: any[]) {
        let projects = new Array();
        projects.push({ title: "", value: "" });
        projectsList.filter(x => x.consoleTitle != "BLACKREIT" && x.consoleTitle != "GIA").forEach(project => {
            projects.push({ title: project.consoleTitle, value: project.consoleTitle });
        });
        this.setField({ projects });
    }

    /* HANDLERS */
    handleAmountChanged(amount: any) {
        this.setField({ amount });
    }

    handleProjectChanged(project: any) {
        this.setField({ project: project.title });
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleSavePressed() {
        if (!this.state().project)
            alert("Attention, vous n'avez pas sélectionner de projet");
        else {
            this.closePopUp();
            this.props().callBack(this.state().amount, this.state().project);
        }
    }
}