import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class UsersViewStyle extends ContentViewStyle {
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    }

    containerFilter: StyleProp<ViewStyle> =
        {
            flexDirection: 'row',
            display: 'flex',
            flex: 1,

            paddingTop: 30,
            paddingBottom: 15,
            justifyContent: 'flex-end',
        }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 40.65,
        elevation: 60,
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        backgroundColor: this.MyStyleManager.primaryColor,
        marginTop: -20,
        margin: 'auto',
        borderRadius: 3
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        padding: 30
    }

    popuptext: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 50
    };

    tableHeader: StyleProp<TextStyle> =
        {
            color: this.MyStyleManager.primaryColor,
        }


    subTitle: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        paddingTop: 2,
        marginLeft: 30,
        marginBottom: 10,
        fontWeight: '100'
    }

    headerText: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontWeight: 'bold',
        fontSize: 12,
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
    }

    text: StyleProp<TextStyle> = {
        color: '#3f3f3f',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        alignSelf: 'flex-start',
        textAlign: 'left',
    }

    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#FFB243',
        marginBottom: 10
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        width: '33%',
        alignSelf: 'flex-end',
        flexDirection: "column",

    }

    availableIn: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    subCategories: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    }

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#FFB243',
        marginLeft: 10
    }

    buttonBox: StyleProp<ViewStyle> =
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }

    stepColor: StyleProp<ViewStyle> =
        {
            backgroundColor: this.MyStyleManager.primaryColor,
        }

    exportButton: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        marginHorizontal: 10,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 250
    }

    exportButtonVBOUT: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        marginHorizontal: 10,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 350
    }

    exportButtonfsma: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        marginHorizontal: 10,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 550
    }

    exportNonavertisButton: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        marginHorizontal: 10,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 300
    }

    exportButtonText: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        fontWeight: '100',
        textAlign: 'center',
    }

    bigBoxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }

    exportText: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontSize: 17,
        fontWeight: '100',
        marginLeft: 10,
        marginTop: 10
    }
}