import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class InformationsViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    Menu: StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
        height: 60,
        borderTopLeftRadius:10,
        borderTopRightRadius:10,
        display : 'flex',
        justifyContent : 'space-evenly',
        flexDirection : 'row',
    }       

    MenuText: StyleProp<TextStyle> = {
        paddingTop : 15,
        color: 'white',
        fontSize: 17,
        
    }  
    exportButton: StyleProp<ViewStyle> = {       
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        width: '300px'        
    }

    exportButtonText: StyleProp<TextStyle> = {
        color: this.MyStyleManager.primaryColor,  
        fontSize: 17,
        fontWeight: '100',
        textAlign: 'center',  
    }

    PageTitle: StyleProp<TextStyle> = {
        color: '#32325D',
        fontSize: 20,
        paddingLeft : '26px',
        paddingTop: '24px',
        paddingBottom: '22px',
        fontWeight: 'bold',
    }

    box: StyleProp<ViewStyle> = {
        backgroundColor : 'white',
        margin: '30px',
        borderRadius:10,
    }  
    container: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }

    footer: StyleProp<ViewStyle> =
    {
        height: '60px',
        borderBottomLeftRadius:10,
        borderBottomRightRadius:10,
        display : 'flex',
        justifyContent : 'flex-end',
        flexDirection : 'row',
        alignItems : 'flex-end',
        marginTop : 100,
        zIndex : -1
    }  

    saveButton: StyleProp<ViewStyle> =
    {       
        backgroundColor : this.MyStyleManager.primaryColor,
        borderRadius: 8,
        display: 'flex',
        margin : 50,
        justifyContent: 'center',
        alignItems: 'center',  
        height: 50,
        width: 200,
    } 
    editButton: StyleProp<ViewStyle> =
    {       
        backgroundColor : this.MyStyleManager.textColor,
        borderRadius: 8,
        display: 'flex',
        margin : 50,
        marginRight : 0,
        justifyContent: 'center',
        alignItems: 'center',  
        height: 50,
        width: 150,
    }   
    overlayLoading: StyleProp<ViewStyle> =
    {       
        display : 'flex',
        justifyContent : 'center',
        alignItems : 'center',
        backgroundColor: '#e9e9e9',
        opacity : 0.6,
        width:'100%',
        height:'100%',
        position: 'absolute',
        zIndex : 2
     
    }  
    
    selectedTab: StyleProp<ViewStyle> =
    {
        borderBottomWidth : 3.5,
        borderColor : '#615D8A',
    } 

    tab: StyleProp<any> =
    { 
        cursor: "pointer"
    } 


   

   

    
}
