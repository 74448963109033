import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ReservationViewState from './ReservationViewState';
import ReservationViewProps from './ReservationViewProps';
import ReservationViewStyle from './ReservationViewStyle';
import ReservationViewModel from './ReservationViewModel';
import TableView from '../../../../../Components/table/TableView';
import {TranslationView, ButtonView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import DropDownView from '../../../../../Components/Dropdown/DropDownView';
import ResearchInputView from '../../../../../Components/researchinput/ResearchInputView';

export default class ReservationView extends ContentView<ReservationViewProps, ReservationViewState, ReservationViewModel, ReservationViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ReservationViewProps)
  {
    super(props);

    // Binding
    this.bind(new ReservationViewModel(this), new ReservationViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>


          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>
              Investors
            </TranslationView>

            <TranslationView style={this.style.subTitle}>
              ManageYourInvestors
            </TranslationView>
          </View>
          <View style={this.style.bigBoxContainer}>
            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} style={this.style.exportButton} handleClick={() => { this.binding.showReservationPopUp()}}>
              <TranslationView style={this.style.exportButtonText}>
                AddReservation
            </TranslationView>
            </ButtonView>
            <View style={this.style.boxContainer}>
              {/* Research Input */}
              < View style={this.style.containerFilter}>
                <TranslationView style={{marginRight: '20px'}}>Search</TranslationView>
                <DropDownView style={{marginRight: '20px'}} selectedValue={this.state.selectedStatus} values={this.state.statuses} onValueChange={(value: any) => this.binding.handleStatusChanged(value)}></DropDownView>
                <ResearchInputView
                  textInFront=""
                  placeholder="search"
                  handlerResearching={(text: any) => this.binding.handleResearching(text)}
                  handlingClose={() => { this.binding.handlingClose() }}
                  text={this.state.text}
                />

              </View >
            </View>

            <TableView              
              handleResetStepView={this.binding.handleResetStepView}
              resetStepView={this.state.resetStepView}
              headerData={this.state.tableHeaders}
              rows={this.state.rows}
              numberOfRows={20}
              isLoading={this.state.isLoading}
              lottie={require('./assets/loading.json')}
              isSortable={true}
              resetTable={this.binding.resetTable}
              datas={this.state.datas}
              fillTables={(value: any[]) => { this.binding.pushRows(value) }}
              keyDatas={['ID', 'NOM', 'PRENOM', 'EMAIL', 'TELEPHONE', 'MONTANT', 'PROJET', 'STATUS', 'DATE DE CREATION']}
              headerIndex={[0, 1, 2, 3, 4, 5, 6,7,8]}
              headerTextStyle={this.style.headerText}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              styleOfChoosenButton={this.style.stepColor}
            ></TableView>
          </View>
        </View>
      </View>
    );
  }
}