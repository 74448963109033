import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import EmailsViewState from './EmailsViewState';
import EmailsViewProps from './EmailsViewProps';
import EmailsViewStyle from './EmailsViewStyle';
import EmailsViewModel from './EmailsViewModel';
import {ButtonView, TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import ReactQuill from 'react-quill';
import { TextInput } from 'react-native';

export default class EmailsView extends ContentView<EmailsViewProps, EmailsViewState, EmailsViewModel, EmailsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:EmailsViewProps)
  {
    super(props);

    // Binding
    this.bind(new EmailsViewModel(this), new EmailsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>Emails</TranslationView>
            <TranslationView style={this.style.subTitle}>SendAnEmail</TranslationView>
          </View>
        <View style={this.style.content}>
          <View style={this.style.textInput}>
            <TextInput placeholder="Email" onChangeText={(email:string) => {this.binding.handleEmailChanged(email)}}></TextInput>
          </View>
          <View style={this.style.textInput}>
            <TextInput placeholder="Subject" onChangeText={(subject:string) => {this.binding.handleSubjectChanged(subject)}}></TextInput>
          </View>
          
          
          <ReactQuill modules={this.binding.wysiwygModule()} theme={"snow"} onChange={(message: string) => { this.binding.handleMessageChanged(message) }} />
          
          <View style={{ marginTop: 25, paddingTop: 100}}>
            <ButtonView handleClick={this.binding.sendMail} animation='None' isLoading={false} borders='Little' backgroundColor= '#FFB243'>
              <TranslationView>Send</TranslationView>
            </ButtonView>
          </View>
        </View>
        
        </View>
      </View>
    );
  }
}