import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import ProjectImageDTO from '../DTO/ProjectImageDTO';

export default class ImagesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);  

    async getByProject(id: string,callback: Function)
    {
        this.httpClient.get("api/ProjectImages/GetByProjectId/"+id, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async insertImage(image: ProjectImageDTO, callback: Function)
    {
        this.httpClient.post("api/ProjectImages/Insert", [], image).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async deleteImage(id: number,callback: Function)
    {
        this.httpClient.delete("api/ProjectImages/Delete/"+id, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}