import React from 'react';
import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DeleteInvestmentPopUpViewState from './DeleteInvestmentPopUpViewState';
import DeleteInvestmentPopUpViewProps from './DeleteInvestmentPopUpViewProps';
import InvestmentsOperation from '../../../../../../../../../../../../../Transfer/InvestmentsOperations';
import InvestementDeletedPopUpView from '../investementdeletedpopup/InvestementDeletedPopUpView';

export default class DeleteInvestmentPopUpViewModel extends ContentViewModel<DeleteInvestmentPopUpViewState, DeleteInvestmentPopUpViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleSavePressed() {
        let id = this.props().investment.id;
        new InvestmentsOperation().deleteInvestment(id)
            .then(() => {
                this.showPopUp(
                    <InvestementDeletedPopUpView />
                );
            }).then(() => {
                this.navigate('/dashboard/users/', true);
            });

        this.closePopUp();
    }
}