import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';

export default class FinanceInfosViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  
    title: StyleProp<TextStyle> = {
        display : 'flex',
        justifyContent : 'flex-start',
        color: this.MyStyleManager.primaryColor,
        fontSize: 25,
        fontWeight: 'bold',
        margin: 15,
    }
    containerSameLine : StyleProp<ViewStyle> = {
        display : 'flex',
        flexDirection : 'row', 
        justifyContent : 'flex-start'
    }

    inputInline : StyleProp<ViewStyle> = {
        display : 'flex',
         margin: "1%" ,
         width : '23%'
    }
    input : StyleProp<ViewStyle> = {
        display : 'flex',
        margin: "1%" ,
        width : '48%'
    }
    inputDate : StyleProp<ViewStyle> = {
        position: 'relative',
        display : 'flex',
        marginLeft: "1%" ,
        marginTop: "1%" ,
        marginBottom: "1%" ,
        marginRight: "3%" ,
        width : '30%',
    }
    inputHours : StyleProp<ViewStyle> = {
        position: 'relative',
        alignSelf : 'flex-end',
        display : 'flex',
        marginLeft: "1%" ,
        marginTop: "1%" ,
        marginBottom: "1%" ,
        marginRight: "3%" ,
        width : '15%',
    } 
}