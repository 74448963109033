import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import AddLinkPopUpViewState from './AddLinkPopUpViewState';
import AddLinkPopUpViewProps from './AddLinkPopUpViewProps';
import UsersOperation from '../../../../../../../../../../../Transfer/UsersOperation';

export default class AddLinkPopUpViewModel extends ContentViewModel<AddLinkPopUpViewState, AddLinkPopUpViewProps>
{
    usersOperation = new UsersOperation();

    constructor(props: ContentView) {
        super(props);

        this.initialState({
            userSearch: "",
            usersSearch: [],
            destinationUserId : 0,
            PMId : 0,
            isPm : false,
            sendEmail : false
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handleUserSearchChanged = this.handleUserSearchChanged.bind(this);
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.selectUserHandler = this.selectUserHandler.bind(this);
        this.handleChangeSendEmail = this.handleChangeSendEmail.bind(this);
    }

    /* HANDLERS */
    handleAmountChanged(amount: any) {
        this.setField({ amount });
    }

    handleUserSearchChanged(userSearch: any) {
        this.setField({ userSearch });
        this.usersOperation.getSearchPagination("ALL", 1, 10, userSearch, true, this.handleSuccessUserCallback); 
    }

    handleSuccessUserCallback(ret: any) {
        this.setField({ usersSearch : ret.objects });
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleChangeSendEmail() {
        var sendEmail = !this.state().sendEmail;
        this.setField({sendEmail});
    }

    selectUserHandler(user: any) {
        this.setField({ destinationUserId : user.id });
        this.setField({ isPm : user.isPM });
        this.setField({ PMId : user.pmId });
    }

    handleSavePressed() {
        if(this.state().destinationUserId == 0) {
            alert("Veuillez sélectionner un utilisateur à lier à cette personne morale");
            return;
        }
        
        this.closePopUp();
        this.props().callBack(this.state().destinationUserId, this.state().sendEmail);
    }
}