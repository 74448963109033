import { Operation } from '@levelapp/softfabric';
import ProjectImageDTO from '../DTO/ProjectImageDTO';
import ImagesService from '../Proxy/ImagesService';


export default class ImagesOperation extends Operation
{
    _service :ImagesService;

    constructor()
    {
        super();
        this._service = new ImagesService();
    }       

    async getByProject(id: string, callback: Function = () => { })
    {
        await (this._service as ImagesService).getByProject(id, callback);
    }

    async insertImage(image: ProjectImageDTO, callback: Function = () => { })
    {
        await (this._service as ImagesService).insertImage(image, callback);
    }

    async deleteImage(id: number, callback: Function = () => { })
    {
        await (this._service as ImagesService).deleteImage(id, callback);
    }
}