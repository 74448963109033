import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import FileInputViewState from './FileInputViewState';
import FileInputViewProps from './FileInputViewProps';

export default class FileInputViewModel extends ContentViewModel<FileInputViewState, FileInputViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

        // State init
        this.initialState({});

        // Bindings
        this.handleClick = this.handleClick.bind(this);
        this.handleFileChanged = this.handleFileChanged.bind(this);
    }

    /* COMPONENT EVENTS */
    componentDidMount()
    {

    }

    componentWillMount()
    {
    }

    componentWillUnmount()
    {

    }

    /* HANDLERS */
    handleClick()
    {
        (this._view.refs.fileUploader as any).click();
    }

    handleFileChanged(file: File)
    {
        this.props().onChange(file);
    }


    /* METHODS */
}