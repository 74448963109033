import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class BlockService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    
    async getTabBlockOrders(tabId : number,callback: Function)
    {
        this.httpClient.get("api/Block/GetTabBlockOrders/"+tabId, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async getBlockById(id : number,callback: Function)
    {
        this.httpClient.get("api/Block/GetBlockById/"+id, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    
    async inserBlock(block : any,callback: Function)
    {
        this.httpClient.post("api/Block/InsertBlock", [], block).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async updateBlock(block : any,callback: Function)
    {
        this.httpClient.put("api/Block/UpdateBlock", [], block).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async softDelete(block : any,callback: Function)
    {
        this.httpClient.put("api/Block/softDelete", [], block).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async depublish(block : any,callback: Function)
    {
        this.httpClient.put("api/Block/Depublish", [], block).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async publishStaging(block : any,callback: Function)
    {
        this.httpClient.put("api/Block/PublishStaging", [], block).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async publishProd(block : any,callback: Function)
    {
        this.httpClient.put("api/Block/PublishProd", [], block).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}