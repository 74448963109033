import { Application, ContentViewStyle } from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
export default class EditProjectLeaderViewStyle extends ContentViewStyle {

    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    PageTitle: StyleProp<TextStyle> = {
        color: '#32325D',
        fontSize: 20,
        paddingLeft: '26px',
        paddingTop: '24px',
        paddingBottom: '22px',
        fontWeight: 'bold',
    }

    Box: StyleProp<ViewStyle> =
        {
            backgroundColor: 'white',
            marginLeft: '30px',
            marginRight: '30px',
            paddingLeft: '32px',
            paddingRight: '150px',
            paddingBottom: '31px',
            borderRadius: 10,
        }

        saveButton: StyleProp<ViewStyle> =
        {       
            backgroundColor : this.MyStyleManager.primaryColor,
            borderRadius: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',  
            height: 50,
            width: 200,
            marginTop: 20
        } 

        headersYellow: React.CSSProperties = {
            color: '#FFB243',
            fontSize: '24px',
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            lineHeight: '37px'
        };
}