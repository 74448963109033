import React from 'react';
import MenuView from './modules/menu/MenuView';
import { View, ScrollView } from 'react-native';
import { Switch, Route, Redirect } from 'react-router';
import DashboardshellViewState from './DashboardshellViewState';
import DashboardshellViewProps from './DashboardshellViewProps';
import DashboardshellViewStyle from './DashboardshellViewStyle';
import DashboardshellViewModel from './DashboardshellViewModel';
import { ContentView ,  Application, LanguageManager, Managers  } from '@levelapp/softfabric';
import TraductionView from './modules/traduction/TraductionView';
import UsersView from './modules/users/UsersView';
import EditUserView from './modules/users/modules/edituser/EditUserView';
import ProjectsView from './modules/projects/projects/ProjectsView';
import EditProjectView from './modules/projects/projects/modules/editproject/EditProjectView';
import NewsletterView from './modules/newsletter/NewsletterView';
import TabProjectsView from './modules/tabprojects/TabProjectsView';
import TabCreationView from './modules/tabprojects/tabcreation/TabCreationView';
import InformationsView from './modules/projects/projects/modules/editproject/modules/informations/InformationsView';
import LegalEntitiesView from './modules/legalentities/LegalEntitiesView';
import EditLegalEntityView from './modules/legalentities/modules/editlegalentity/EditLegalEntityView'
import ReservationView from './modules/reservation/ReservationView';
import EmailsView from './modules/emails/EmailsView';
import RolesView from './modules/roles/RolesView';
import NonInvestorsView from './modules/nonInvestors/NonInvestorsView';
import ProjectLeadersView from './modules/projectleaders/ProjectLeadersView';
import EditProjectLeaderView from './modules/projectleaders/modules/editprojectleader/EditProjectLeaderView';
import ExportView from './modules/export/ExportView';

export default class DashboardshellView extends ContentView<DashboardshellViewProps, DashboardshellViewState, DashboardshellViewModel, DashboardshellViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DashboardshellViewProps)
  {
    super(props);

    // Binding
    this.bind(new DashboardshellViewModel(this), new DashboardshellViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
         {!this.state.isLoadingRoles && <MenuView />}
        {!this.state.isLoadingRoles && <ScrollView style={this.style.viewContainer}>         
          <Switch>
            <Route path="/:language/dashboard/legalentities/edit" render={() => <EditLegalEntityView />} />  
            <Route path="/:language/dashboard/legalentities" render={() => <LegalEntitiesView/> } /> 
            <Route path="/:language/dashboard/users/edit" render={() => <EditUserView />} />      
            <Route path="/:language/dashboard/users" render={() => <UsersView />} />   
            <Route path="/:language/dashboard/nonInvestors" render={() => <NonInvestorsView />} />   
            <Route path="/:language/dashboard/projects/newProject" render={() => <InformationsView />} />
            <Route path="/:language/dashboard/projects/edit" render={() => <EditProjectView />} />      
            <Route path="/:language/dashboard/projects" render={() => <ProjectsView />} />                   
            <Route path="/:language/dashboard/traduction" render={() => <TraductionView />} />
            <Route path="/:language/dashboard/newsletter" render={() => <NewsletterView />} />
            <Route path="/:language/dashboard/reservation" render={() => <ReservationView />} />  
            <Route path="/:language/dashboard/projectTab/tabCreation" render={() => <TabCreationView />} /> 
            <Route path="/:language/dashboard/projectTab" render={() => <TabProjectsView />} />
            <Route path="/:language/dashboard/emails" render={() => <EmailsView />} />
            <Route path="/:language/dashboard/roles" render={() => <RolesView />} />
            <Route path="/:language/dashboard/projectLeaders/edit" render={() => <EditProjectLeaderView />} />     
            <Route path="/:language/dashboard/projectLeaders" render={() => <ProjectLeadersView />} /> 
            <Route path="/:language/dashboard/export" render={() => <ExportView />} /> 
            <Route path="/:language/dashboard/" render={() => <Redirect to={"/" + Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER).language + "/dashboard/users"} />} />

          </Switch>
        </ScrollView>}
      </View>
    );
  }
}