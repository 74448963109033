import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import PopupHeaderViewState from './PopupHeaderViewState';
import PopupHeaderViewProps from './PopupHeaderViewProps';
import { LayoutChangeEvent } from 'react-native';

export default class PopupHeaderViewModel extends ContentViewModel<PopupHeaderViewState, PopupHeaderViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            { 
                width : this.props().width ,
                height : 100
            });

        // Bindings
        this.handleOnLayout = this.handleOnLayout.bind(this);
    }

    handleOnLayout(event : LayoutChangeEvent)
    {
        this.setField({width : event.nativeEvent.layout.width , height : event.nativeEvent.layout.height});
    }
}