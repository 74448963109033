import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import axios from 'axios';
import urlConstants from '../Constants/UrlConstants';
import PrimeUsersModel from '../DTO/PrimeUsersModel';

export default class ProjectsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    auth: any = Application.current.resolve(Managers.AUTH_MANAGER);

    async get(callback: Function)
    {
        this.httpClient.get("api/Project", []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async exportRegister(projectId: string, callback: Function)
    {        
        let token = await this.auth.getTokenFromDisk();

        axios({
            url: urlConstants.BB_BACKEND+"/api/ProjectFile/ExportRegister/"+projectId,
            method: 'GET',
            responseType: 'blob', // important
            headers:{
                'Authorization': 'Bearer ' + token.access_token
            }
        }).then((response) => {
            
            if (response != undefined)
            {
                callback(response.data);
            }
        });
    }
    async exportMoneyTrans(projectId: string, callback: Function)
    {        
        let token = await this.auth.getTokenFromDisk();

        axios({
            url: urlConstants.BB_BACKEND+"/api/ProjectFile/exportMoneyTrans/"+projectId,
            method: 'GET',
            //responseType: 'blob', // important
            headers:{
                'Authorization': 'Bearer ' + token.access_token
            }
        }).then((response) => {
            
            if (response != undefined)
            {
                callback(response.data);
            }
        });
    }

    async exportRegisterInterests(projectId: string, callback: Function)
    {        
        let token = await this.auth.getTokenFromDisk();
        
        axios({
            url: urlConstants.BB_BACKEND+"/api/ProjectFile/ExportRegisterInterests/"+projectId,
            method: 'GET',
            responseType: 'blob', // important
            headers:{
                'Authorization': 'Bearer ' + token.access_token
            }
        }).then((response) => {
            
            if (response != undefined)
            {
                callback(response.data);
            }
        });
    }

    async getByCode(code: string,callback: Function)
    {
        this.httpClient.get("api/Project/ByCode/"+code, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async insertProjectTabs(project: any,callback: Function)
    {
        this.httpClient.post("/api/project/insertTabs",[],project).then((response: any) => 
        {
          
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async getList(callback: Function) {
        this.httpClient.get("api/Project/GetProjectsList", []).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async insertProject(project: any, callback: Function) {
        this.httpClient.post("api/Project/InsertProject", [], project).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async getTypesList(callback: Function) {
        this.httpClient.get("api/project/GetTypesList", []).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async getBenefitsQuestions(id: number,callback: Function) {
        this.httpClient.get("api/project/benefitsQuestions/"+id, []).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async getActivitiesList(callback: Function) {
        this.httpClient.get("api/project/ActivitiesList", []).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async updateProject(project: any, callback: Function) {
        this.httpClient.put("api/Project/UpdateProject", [], project).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async deleteProject(project: any, callback: Function) {
        this.httpClient.put("api/Project/DeleteProject", [], project).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        })
    }

    async getSearchPagination(page: number, itemPage: number, searchText: string, callback: Function = () => { }) {
        if (!searchText)
            searchText = "placeholder";
        this.httpClient
            .get('api/Project/Pagination/' + page + '/' + itemPage + '/' + searchText)
            .then((response: any) => {
                if (response) {
                    if (response.data.data) {
                        callback(response.data.data);
                    }
                }
            });
    }
    
    async sendEmailToPending(project:any, callback: Function){
        this.httpClient
            .post('api/project/SendEmailToPending', [], project)
            .then((response: any) => {
                if (response != undefined) {
                    callback(response.data.data)
                }
            })
    }//TODO

    async addPrimeUsers(primeUsersModel : PrimeUsersModel, callback: Function){
        this.httpClient
            .post('api/project/AddPrimeUsers', [], primeUsersModel)
            .then((response: any) => {
                if (response != undefined) {
                    callback(response.data.data)
                }
            })
    }
    async getPrimeUsers(projectId : number, callback: Function){
        this.httpClient
            .get('api/project/PrimeUser/'+projectId)
            .then((response: any) => {
                if (response != undefined) {
                    callback(response.data.data)
                }
            })
    }
    async deletePrimeUser(primeId : number, callback: Function){
        this.httpClient
            .delete('api/project/DeletePrimeUser/'+primeId)
            .then((response: any) => {
                if (response != undefined) {
                    callback(response.data.data)
                }
            })
    }

    async getInvestmentsPending(projectId : number, callback: Function){
        this.httpClient
            .get('api/project/GetInvestmentsPending/'+projectId)
            .then((response: any) => {
                if (response != undefined) {
                    callback(response.data.data)
                }
            })
    }
    async sendExportInvestmentNumber(callback: Function){
        this.httpClient
            .get('api/ProjectFile/ExportInvestmentNumber')
            .then((response:any) =>{
                if (response != undefined) {
                    callback(response.data.data)
                }
            })
    }
}