import { Application, HttpManager, Managers, Service } from "@levelapp/softfabric";

export default class LegalFormsService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    

    async get(callback: Function)
    {
        this.httpClient.get("api/LegalForms", []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}