import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import TextAreaViewState from './TextAreaViewState';
import TextAreaViewProps from './TextAreaViewProps';
import TextAreaViewStyle from './TextAreaViewStyle';
import TextAreaViewModel from './TextAreaViewModel';
import UrlConstants from '../../../Constants/UrlConstants';

export default class TextAreaView<P = TextAreaViewProps> extends ContentView<P, TextAreaViewState, TextAreaViewModel, TextAreaViewStyle>
{
    constructor(props: P) {
        super(props);

        this.bind(new TextAreaViewModel(this), new TextAreaViewStyle(this));
    }

    render() {
        if ((this.props as unknown as TextAreaViewProps).shouldCheck) {
            this.binding.isFieldInError();
        }

        return (
            <div style={(this.props as unknown as TextAreaViewProps).Style}>
                <div className="row" >
                    <div className={(this.props as unknown as TextAreaViewProps).labelClass} style={{ display: 'flex', margin: (this.props as unknown as TextAreaViewProps).centered ? "auto" : "" }}>
                        {this.state.error && (<img src={UrlConstants.BB_STORAGE + '/error.svg'} alt="error" style={{ marginRight: 5 }} />)}
                        <label style={{ color: '#565656', fontWeight: 400, fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px', width: '100%', textAlign: 'start' }}>
                            {(this.props as unknown as TextAreaViewProps).label}{(this.props as unknown as TextAreaViewProps).required ? '' : ''}
                        </label>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 8 }}>
                    <div className={(this.props as unknown as TextAreaViewProps).inputClass} style={{ margin: (this.props as unknown as TextAreaViewProps).centered ? "auto" : "" }}>
                        <div style={{ height: 150, width: '100%',  background: (this.props as unknown as TextAreaViewProps).disabled ? 'url(' + UrlConstants.BB_STORAGE + '/lock.svg) no-repeat' : 'none', backgroundPosition: (this.props as unknown as TextAreaViewProps).disabled ? 'right 1em bottom 10px' : 'none'}}>
                            {(this.props as unknown as TextAreaViewProps).disabled &&
                                <label style={{ verticalAlign:"middle", width: '100%', height: 40, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300, lineHeight: '40px', border: 'none', paddingLeft: 10,  }} >{(this.props as unknown as TextAreaViewProps).value}</label>
                            }
                            {!(this.props as unknown as TextAreaViewProps).disabled &&
                                <textarea style={{borderWidth:1, borderRadius: 3, borderColor: this.state.error ? '#f25457' : '#d5d5d5',fontFamily: 'Montserrat', color: '#565656', width: '100%', height: '100%', paddingLeft: 10, fontSize: 16 }} placeholder={(this.props as unknown as TextAreaViewProps).placeholder} value={(this.props as unknown as TextAreaViewProps).value} onChange={this.binding.valueChanged}  onBlur={() => this.binding.isFieldInError()}  />
                            }
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}