import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import EditLegalEntityViewState from './EditLegalEntityViewState';
import EditLegalEntityViewProps from './EditLegalEntityViewProps';
import EditLegalEntityViewStyle from './EditLegalEntityViewStyle';
import EditLegalEntityViewModel from './EditLegalEntityViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';
import { Switch, Route } from 'react-router';
import LegalEntityInfosView from './modules/legalentityinfos/LegalEntityInfosView'
import LegalEntityDocumentsView from './modules/legalentitydocuments/LegalEntityDocumentsView';
import LegalEntityInvestmentsView from './modules/legalentityinvestments/LegalEntityInvestmentsView';
import UsersLinkedView from './modules/userslinked/UsersLinkedView';

export default class EditLegalEntityView extends ContentView<EditLegalEntityViewProps, EditLegalEntityViewState, EditLegalEntityViewModel, EditLegalEntityViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: EditLegalEntityViewProps) {
        super(props);

        // Binding
        this.bind(new EditLegalEntityViewModel(this), new EditLegalEntityViewStyle(this));
    }

    /* RENDERING */
    render() {
        return (
            <View>
                <View>
                    <div style={{ display: "flex", marginLeft: "26px", marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ cursor: "pointer" }}>
                            <img onClick={() => this.binding.NavigationManager.navigate('/dashboard/legalentities')} height="31" width="31" src="https://beebondsstorage.blob.core.windows.net/files/consoleImages/close.png"></img>
                        </div>
                        <div>
                            <Text style={this.style.PageTitle}>Personne Morale :  {this.state.legalEntity.corporateName} </Text>
                        </div>
                    </div>

                </View>
                <View style={this.style.PurpleMenu}>
                    <div style={{ display: "flex" }}>
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/legalentities/edit/information', true, this.state.legalEntity)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                            <TranslationView style={this.style.PurpleMenuText}>Information</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/information") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/legalentities/edit/investments', true, this.state.legalEntity)} style={{ cursor: "pointer" }}>
                            <TranslationView style={this.style.PurpleMenuText}>Investments</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/investments") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>
                        {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/legalentities/edit/documents', true, this.state.legalEntity)} style={{ cursor: "pointer", marginLeft: "115px" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Documents</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/documents") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>
                        }
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/legalentities/edit/linkedusers', true, this.state.legalEntity)} style={{ cursor: "pointer", marginLeft: "115px" }}>
                            <TranslationView style={this.style.PurpleMenuText}>Users</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/linkedusers") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>
                    </div>
                </View>
                <Switch>
                    <Route path="/:language/dashboard/legalentities/edit/information" render={() => <LegalEntityInfosView editLegalEntityCallBack={() => { }} />} />
                    <Route path="/:language/dashboard/legalentities/edit/investments" render={() => <LegalEntityInvestmentsView />} />
                    <Route path="/:language/dashboard/legalentities/edit/documents" render={() => <LegalEntityDocumentsView />} />
                    <Route path="/:language/dashboard/legalentities/edit/linkedusers" render={() => <UsersLinkedView />} />
                </Switch>
            </View>
        );
    }
}