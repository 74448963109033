import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import AddReservationPopupViewState from './AddReservationPopupViewState';
import AddReservationPopupViewProps from './AddReservationPopupViewProps';
import AddReservationPopupViewStyle from './AddReservationPopupViewStyle';
import AddReservationPopupViewModel from './AddReservationPopupViewModel';
import {TranslationView, ButtonView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import InputView from '../../../../../../../Components/Input/input/InputView';
import DropDownView from '../../../../../../../Components/Dropdown/DropDownView';

export default class AddReservationPopupView extends ContentView<AddReservationPopupViewProps, AddReservationPopupViewState, AddReservationPopupViewModel, AddReservationPopupViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AddReservationPopupViewProps)
  {
    super(props);

    // Binding
    this.bind(new AddReservationPopupViewModel(this), new AddReservationPopupViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={{ minWidth: 600, paddingBottom: 30 }}>
          <TranslationView style={this.style.title}>
          AddReservation
         </TranslationView>

        <View style={{display:'flex',flexDirection: 'row', justifyContent: 'space-evenly'}}>
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NameLabel")} labelClass="col-lg-7" onChange={this.binding.handleLastNameChanged} required={true} value={this.state.reservation.lastName} type="text" />
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("FirstNameLabel")} labelClass="col-lg-7" onChange={this.binding.handleFirstNameChanged} required={true} value={this.state.reservation.firstName} type="text" />
        </View>

        <View style={{display:'flex',flexDirection: 'row', justifyContent: 'space-evenly'}}>
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Email")} labelClass="col-lg-7" onChange={this.binding.handleEmailChanged} required={true} value={this.state.reservation.email} type="text" />
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PhoneNumberLabel")} labelClass="col-lg-7" onChange={this.binding.handlePhoneChanged} required={true} value={this.state.reservation.phone} type="text" />
        </View>

        <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30, marginLeft: 70, marginRight: 70 }} inputClass="col-lg-12" label={this.binding.translate("Amount")} labelClass="col-lg-7" onChange={this.binding.handleAmountChanged} required={true} value={this.state.reservation.amount} type="number" />

        <View style={{ marginTop: 30, marginLeft: 70, marginRight: 70 }}>
          <TranslationView style={this.style.subTitle}>
              Project
          </TranslationView> 
          <View style={{ marginTop: 8 }}><DropDownView selectedValue={this.state.reservation.project} values={this.state.projects} onValueChange={(value: any) => this.binding.handleProjectChanged(value)}></DropDownView></View>
        </View>

        <View style={this.style.footer}>
        <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Confirm
            </TranslationView>
          </ButtonView>

          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              Cancel
             </TranslationView>
          </ButtonView>
        </View>

      </View>
    );
  }
}