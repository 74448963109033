import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ImageStyle } from 'react-native';

export default class TableViewStyle extends ContentViewStyle{
    row: StyleProp<any> = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 12,
        paddingBottom: 12,
        borderColor: '#e0e0e0',
    };

    header : StyleProp<any> = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 12,
        paddingBottom: 12,
        borderColor: '#e0e0e0',
    };

    showImage : StyleProp<ImageStyle> =
    {
        opacity : 1,
        marginLeft:5,
        alignSelf:'center',
        height: 12,
        width: 12 
    }

    notShowingImage : StyleProp<ImageStyle> =
    {
        opacity : 0,
    }
}