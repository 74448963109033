enum FIELDS 
{
    DURATION = "duration",
    BANK = "bank",
    EMISSION = "emission",
    INTEREST_RATE = "interestRate",
    EMISSION_PRICE = "emissionPrice",
    MAX_AMOUNT = "maxAmount",
    OPENING_DATE = "openingDate",
    OPENING_DATE_HOUR = "openingDateHour",
    ISSUE_DATE = "issueDate",
    SUBSCRiPTION_END_DATE = "subscriptionEndate",
    ISIN = "isin",
    TRANSMITTER = "transmitter",
    ADDRESS = "address",
    NUMBER = "number",
    BOX = "box",
    POSTCODE = "postCode",
    LOCALITY = "locality",
    COUNTRY = "country",
    IBAN = "iban",
    BIC = "bic",
    BCE = "bce",
    TVA = "tva",
    LEI = "lei",
    AMOUNT = "amount",
    INTRODUCTION_FR = "introductionFr",
    DESCRIPTION_FR = "descriptionFr",
    HEADER_FR = "headerFr",
    ACTIVITY_FR = "activityFr",
    BEEHIVE_TEXT_FR = "beehiveTextNl",
    INTRODUCTION_NL = "introductionNl",
    DESCRIPTION_NL = "descriptionNl",
    HEADER_NL = "headerNl",
    ACTIVITY_NL = "activityNl",
    BEEHIVE_TEXT_NL = "beehiveTextNl",
    INTRODUCTION_EN = "introductionEn",
    DESCRIPTION_EN = "descriptionEn",
    HEADER_EN = "headerEn",
    ACTIVITY_EN = "activityEn",
    BEEHIVE_TEXT_EN = "beehiveTextEn",
    VIDEO = "video",
    PROJECT_INFOS = "projectInfo",
    FINANCE_INFOS = "financeInfos",
    COMPANY_INFOS = "companyInfos",
    DESCRIPTION = "description",
    MULTIMEDIA = "multimedia",
    BENEFITS = "benefits",
    QUESTIONS = "questions"
}

export default FIELDS;

