import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class PasymentInformationService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);  

    async sendPaymentConfirmationEmail(infos: any, callback: Function)
    {
        this.httpClient.post("api/PaymentInformation/SendPaymentConfirmationEmail", [], infos).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
        
        
    }
}