import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import PrimePopUpViewState from './PrimePopUpViewState';
import PrimePopUpViewProps from './PrimePopUpViewProps';
import PrimePopUpViewStyle from './PrimePopUpViewStyle';
import PrimePopUpViewModel from './PrimePopUpViewModel';
import { TouchableWithoutFeedback , View } from 'react-native';
import PopupHeaderView from '../../../../../../../../../../components/popopheader/PopupHeaderView';
import AddPrimeUsersView from './addprimeusers/AddPrimeUsersView';
import { TranslationView } from '@levelapp/softfabric-ui';
import PrimeUsersListView from './primeuserslist/PrimeUsersListView';

export default class PrimePopUpView extends ContentView<PrimePopUpViewProps, PrimePopUpViewState, PrimePopUpViewModel, PrimePopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PrimePopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new PrimePopUpViewModel(this), new PrimePopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {tabsContainer,tabContainer,tabTitle,currentTabContainer} = this.style
    return (
      <View style={{ minWidth: 300,height: 700, width: 1200, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={this.binding.handleBackPressed}
          title={"prime.header"}
          width={1200}/> 

          <View style={tabsContainer}>
          <TouchableWithoutFeedback   onPress={()=>this.binding.handleTabChange('addPrime')}>
            <View style={this.state.currentTab == 'addPrime' ? currentTabContainer : tabContainer}>
              <TranslationView style={tabTitle}>{'prime.tab.add'}</TranslationView>
            </View>
              
            </TouchableWithoutFeedback >
            <TouchableWithoutFeedback   onPress={()=>this.binding.handleTabChange('primeList')} >
              <View style={this.state.currentTab == 'primeList' ? currentTabContainer : tabContainer}>
              <TranslationView style={tabTitle}>{'prime.tab.list'}</TranslationView>
              </View>
              
            </TouchableWithoutFeedback >     
          </View>

          {this.state.currentTab == 'addPrime' && 
          <AddPrimeUsersView 
            projectId = {this.props.projectId}
            emailList = {this.state.emailList}
            showError ={this.state.showError}
            showSucces ={this.state.showSucces}
            onResponseDataReceived = {this.binding.handleResponseDataReceived}/>}
          {this.state.currentTab == 'primeList' && <PrimeUsersListView projectId = {this.props.projectId}/>  }
 
        
      </View>
    );
  }
}