import React from 'react';
import { ContentView, Application } from '@levelapp/softfabric';
import TabCreationViewState from './TabCreationViewState';
import TabCreationViewProps from './TabCreationViewProps';
import TabCreationViewStyle from './TabCreationViewStyle';
import TabCreationViewModel from './TabCreationViewModel';
import { StyleManager, UiManagers, TranslationView, LottieView } from '@levelapp/softfabric-ui';
import { Text, View, TouchableWithoutFeedback, TouchableHighlight, Image } from 'react-native';
import TabView from './tab/TabView';
import BlockView from './block/BlockView';
export default class TabCreationView extends ContentView<TabCreationViewProps, TabCreationViewState, TabCreationViewModel, TabCreationViewStyle>
{
  MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  /* CONSTRUCTOR */
  constructor(props: TabCreationViewProps) {
    super(props);

    // Binding
    this.bind(new TabCreationViewModel(this), new TabCreationViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View>
          <View style={this.style.box}>
            <View style={this.style.PurpleMenu}>
              <TranslationView style={this.style.PurpleMenuText}>NewTab</TranslationView>
            </View>
            <View style={{ padding: 50 }}>
              <View><TranslationView style={this.style.title}>Tabs</TranslationView></View>
              <View style={this.style.tabContainer}>
                {this.state.tabOrders != undefined && this.state.tabOrders.map((order: any, index: number) => {
                  return <View style={{paddingRight: 5}}><TouchableWithoutFeedback onPress={() => this.binding.setCurrentTabIndex(index)}><View style={this.state.currentTabIndex == index ? this.style.tabActive : this.style.TabSquare}><Text style={{ fontSize: 22 }}>{order.order == "" ? "?" : order.order + " | "+order.title }</Text></View></TouchableWithoutFeedback></View>
                })
                }
                <TouchableHighlight onPress={this.binding.addNewTab}>
                  <Image
                    style={this.style.TabSquareImage}
                    source={require('./assets/Add.svg')}
                  />
                </TouchableHighlight>
              </View>
              {/*BLOCK*/}
              <View><TranslationView style={this.style.title}>Blocks</TranslationView></View>
              <View style={this.style.tabContainer}>
                {this.state.blockOrders != undefined && this.state.blockOrders.map((blockOrder: any, index: number) => {
                  return <View style={{ paddingRight: 5 }}><TouchableWithoutFeedback onPress={() => this.binding.setCurrentBlockIndex(index)}><View style={this.state.currentBlockIndex == index ? this.style.blockActive : this.style.BlockSquare}><Text style={{ fontSize: 22 }}>{blockOrder.order == "" ? "?" : blockOrder.order}</Text></View></TouchableWithoutFeedback></View>
                })
                }
                {this.state.tabOrders.length != 0 &&
                  <TouchableHighlight onPress={() => this.binding.addNewBlock()}>
                    <Image
                      style={this.style.TabSquareImage}
                      source={require('./assets/Add.svg')}
                    />
                  </TouchableHighlight>
                }
              </View>
            </View>
          </View>

          {/* LOTTIE */}
          {this.state.isLoadingTab ?
            <View style={this.style.lottie}>
              <LottieView height={200} width={200} source={require('./assets/loading.json')} />
            </View>
            :
            this.state.currentTab != null &&
            <TabView
              tabOrders={this.state.tabOrders}
              blockOrders={this.state.blockOrders}
              setBlockOrders={this.binding.setBlockOrders}
              currentTabIndex={this.state.currentTabIndex}
              currentBlockIndex={this.state.currentBlockIndex}
              isSavedTab={this.state.isSavedTab}
              isSavedBlock={this.state.isSavedBlock}
              setTabOrders={this.binding.setTabOrders}
              projectCode={this.binding.projectCode}
              currentTab={this.state.currentTab}
              setTabTitle={this.binding.setTabTitle}
              setTabOrder={this.binding.setTabOrder}
              setCurrentTabId={this.binding.setCurrentTabId}
              removeTab={this.binding.removeTab}
              setCurrentTabState={this.binding.setCurrentTabState}
              setIsSavedTab={this.binding.setIsSavedTab}
              afficherStatus = {this.binding.afficherStatus}
            ></TabView>
          }
          {this.state.currentBlock != null && this.state.currentTab != null && 

            <BlockView
              currentBlockIndex={this.state.currentBlockIndex}
              blockOrders={this.state.blockOrders}
              isSavedBlock={this.state.isSavedBlock}
              setIsSavedBlock={this.binding.setIsSavedBlock}
              isSavedTab={this.state.isSavedTab}
              setTabOrders={this.binding.setTabOrders}
              tabId={this.state.currentTab.id}
              setBlockOrders={this.binding.setBlockOrders}
              currentBlock={this.state.currentBlock}
              setBlockContent={this.binding.setBlockContent}
              setBlockOrder={this.binding.setBlockOrder}
              removeBlock={this.binding.removeBlock}
              setCurrentBlockId={this.binding.setCurrentBlockId}
              setCurrentBlockState={this.binding.setCurrentBlockState}
              afficherStatus = {this.binding.afficherStatus}
            ></BlockView>
          }
        </View>
      </View>
    );
  }
}