import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import EditCouponPopUpViewState from './EditCouponPopUpViewState';
import EditCouponPopUpViewProps from './EditCouponPopUpViewProps';
import EditCouponPopUpViewStyle from './EditCouponPopUpViewStyle';
import EditCouponPopUpViewModel from './EditCouponPopUpViewModel';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import { Text, View } from 'react-native';
import PopupHeaderView from '../../../../../../../../../components/popopheader/PopupHeaderView';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import DateSelect from '../../../../../../../../../../../../Components/Select/DateSelect';
import moment from 'moment';
import Select from '../../../../../../../../../../../../Components/Select/Select';

export default class EditCouponPopUpView extends ContentView<EditCouponPopUpViewProps, EditCouponPopUpViewState, EditCouponPopUpViewModel, EditCouponPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: EditCouponPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new EditCouponPopUpViewModel(this), new EditCouponPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container, YesButton, } = this.style;
    return (
      <View style={{ minWidth: 30, minHeight: 700, width: 1200, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={() => this.binding.closePopUp()}
          title={(this.state.coupon.id > 0) ? "UpdateCoupon" : "Coupons.add"}
          width={1200} />

        <View style={container}>
          <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              Style={{ marginTop: 30, width: 100 }}
              inputClass="col-lg-1"
              label={"Numéro"}
              labelClass="col-lg-1"
              onChange={this.binding.handleNumberChanged}
              required={true}
              disabled={this.state.coupon.id > 0}
              value={this.state.coupon.number}
              type="text" />
            <View>
              <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={() => this.binding.confirm()} backgroundColor="#FFB243" style={YesButton}>
                <TranslationView >
                  {'roles.save'}
                </TranslationView>
              </ButtonView>
              <View style={{ height: 10 }} />
              {this.state.coupon.id > 0 &&
                <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={() => this.binding.deleteCoupon()} backgroundColor="#FFB243" style={YesButton}>
                  <Text>Supprimer ce coupon</Text>
                </ButtonView>
              }
            </View>

          </View>

          <View style={{ flexDirection: 'row', }} >
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              Style={{ marginTop: 30, width: 100 }}
              inputClass="col-lg-1"
              label={"Taux (%)"}
              labelClass="col-lg-1"
              onChange={this.binding.handleRateChanged}
              required={true}
              value={this.state.coupon.rate}
              type="text" />
          </View>

          <View style={{ marginTop: 30, flexDirection: 'row', zIndex: 10 }} >
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              Style={{ width: "200px" }}
              selectClass="col-lg-7"
              label={this.binding.translate("StatusLabel")}
              labelClass="col-lg-7"
              onChange={this.binding.handleStatusChanged}
              required={false}
              value={this.state.coupon.status}
              options={["WAITING", "PAID",].map((number, key) => { return { label: number, value: number, key } })} />
            <DateSelect
              subscribe={() => { }}
              shouldCheck={false}
              Style={{ width: "300px", marginLeft: 40 }}
              selectClass="col-lg-7"
              label={this.binding.translate("Coupons.date")}
              labelClass="col-lg-7"
              onChange={this.binding.handleDateCouponChanged}
              required={false}
              value={this.state.coupon.couponDate}
              disabled={this.state.coupon.id > 0}
              startYear={2020}
              endYear={moment().year() + 10} />
            <DateSelect
              subscribe={() => { }}
              shouldCheck={false}
              Style={{ width: "300px", marginLeft: 40 }}
              selectClass="col-lg-7"
              label={this.binding.translate("paymentDate")}
              labelClass="col-lg-7"
              onChange={this.binding.handleDateChanged}
              required={false}
              value={this.state.coupon.paymentDate}
              startYear={2020}
              endYear={moment().year() + 10} />
          </View>

          <View style={{ marginTop: 30, flexDirection: 'row', }} >
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              Style={{ width: "300px" }}
              selectClass="col-lg-10"
              label={this.binding.translate("CouponType")}
              labelClass="col-lg-10"
              onChange={this.binding.handleTypeChanged}
              required={false}
              value={this.state.coupon.type}
              options={[ "Intérêt ", "Intérêt partiel", "Remboursement", "Remboursement partiel", "Remboursement anticipé"].map((number, key) => { return { label: number, value: number, key } })} />
          </View>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </View>
      </View>
    );
  }
}