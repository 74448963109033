import { ContentViewModel, ContentView } from "@levelapp/softfabric";
import ExportViewState from "./ExportViewState";
import ExportViewProps from "./ExportViewProps";
import UsersOperation from "../../../../../Transfer/UsersOperation";
import * as XLSX from "xlsx";
import moment from "moment";
import PopUpSendNotificationView from "../../../../../Components/PopUpSendNotification/PopUpSendNotificationView";
import React from "react";
export default class ExportViewModel extends ContentViewModel<
  ExportViewState,
  ExportViewProps
> {
  _usersOperation = new UsersOperation();
  allData: any;

  /* CONSTRUCTOR */
  constructor(view: ContentView) {
    super(view);

    this.initialState({
      data: "",
      isLoading: false,
      isExporting: false,
      date: new Date(2000, 1, 1),
      statuses: [],
      selectedStatus: "Tous",
    }); // Initialize your state here

    // Bindings
    this.dateChanged = this.dateChanged.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
    this.filterData = this.filterData.bind(this);
    this.exportNotif = this.exportNotif.bind(this);
  }

  componentDidMount() {
    this.setField({ isLoading: true });
    this._usersOperation.exportAll((userToExport: any) => {
      this.allData = userToExport;
      this.setField({ data: userToExport, isLoading: false });
    });

    let statuses: any[] = [];

    statuses.push({ title: "Tous" });
    statuses.push({ title: "Investisseurs" });
    statuses.push({ title: "Utilisateurs" });

    this.setField({ statuses: statuses });
  }

  dateChanged(date: string) {
    this.setField(
      (previousState) => (previousState.date = moment(date, "DD-MM-YYYY")),
      () => {
        this.filterData();
      },
    );
  }

  handleStatusChanged(value: any) {
    this.setField({ selectedStatus: value.label }, () => {
      this.filterData();
    });
  }

  filterData() {
    let filteredData = this.allData;
    filteredData = filteredData.filter((obj: any) => {
      let creationDate = moment(obj.creationDate);
      if (this.state().selectedStatus == "Investisseurs") {
        return creationDate >= this.state().date && obj.formCompleted;
      } else if (this.state().selectedStatus == "Utilisateurs") {
        return creationDate >= this.state().date && !obj.formCompleted;
      } else {
        return creationDate >= this.state().date;
      }
    });
    this.setField({ data: filteredData });
  }

  exportUsers() {
    this.setField({ isExporting: true });
    let userToExport = this.state().data;

    var headers = [
      "Date de création du compte",
      "Email",
      "Prénom",
      "Nom",
      "Langue",
      "Civilité",
      "Nationalité",
      "Rue",
      "Numéro",
      "Boite",
      "Code postal",
      "Localité ",
      "Pays",
      "Date de naissance",
      "Téléphone",
      "Statut professionnel",
      "Secteur d activité",
      "Comment ais-je entendu parler de BeeBonds",
      "Averti",
    ];
    var jsonContent: any[] = [];
    userToExport.forEach((value: any) => {
      var element = "{";
      var row = "";

      var birthDateAsString = "";
      if (
        value.birthDate != null &&
        value.birthDate != "" &&
        !value.birthDate.includes("Na")
      ) {
        var parts = value.birthDate
          .replace("/", "-")
          .replace("/", "-")
          .split("-");
        var birthDate = new Date(
          parseInt(parts[2], 10),
          parseInt(parts[1], 10) - 1,
          parseInt(parts[0], 10),
        );
        var monthBirth = (birthDate.getMonth() + 1).toString();
        if (birthDate.getMonth() + 1 < 10) monthBirth = "0" + monthBirth;
        var dayBirth = birthDate.getDate().toString();
        if (birthDate.getDate() < 10) dayBirth = "0" + dayBirth;
        birthDateAsString =
          birthDate.getFullYear() + "" + monthBirth + "" + dayBirth;
      }

      var creaDate = new Date(value.formCompletionDate);
      var creaMonth = (creaDate.getMonth() + 1).toString();
      if (creaDate.getMonth() + 1 < 10) creaMonth = "0" + creaMonth;
      var creaDay = creaDate.getDate().toString();
      if (creaDate.getDate() < 10) creaDay = "0" + creaDay;
      var creaDateAsString =
        creaDate.getFullYear() + "" + creaMonth + "" + creaDay;

      if (!value.address)
        value.address = {
          street: "",
          number: "",
          box: "",
          postCode: "",
          locality: "",
          country: "",
        };

      row +=
        JSON.stringify(headers[0]) + ":" + JSON.stringify(creaDateAsString);
      row += ",";
      row += JSON.stringify(headers[1]) + ":" + JSON.stringify(value.email);
      row += ",";
      row += JSON.stringify(headers[2]) + ":" + JSON.stringify(value.firstName);
      row += ",";
      row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.lastName);
      row += ",";
      row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.language);
      row += ",";
      row += JSON.stringify(headers[5]) + ":" + JSON.stringify(value.civility);
      row += ",";
      row +=
        JSON.stringify(headers[6]) + ":" + JSON.stringify(value.nationality);
      row += ",";
      row +=
        JSON.stringify(headers[7]) + ":" + JSON.stringify(value.address.street);
      row += ",";
      row +=
        JSON.stringify(headers[8]) + ":" + JSON.stringify(value.address.number);
      row += ",";
      row +=
        JSON.stringify(headers[9]) + ":" + JSON.stringify(value.address.box);
      row += ",";
      row +=
        JSON.stringify(headers[10]) +
        ":" +
        JSON.stringify(value.address.postCode);
      row += ",";
      row +=
        JSON.stringify(headers[11]) +
        ":" +
        JSON.stringify(value.address.locality);
      row += ",";
      row +=
        JSON.stringify(headers[12]) +
        ":" +
        JSON.stringify(value.address.country);
      row += ",";
      row +=
        JSON.stringify(headers[13]) + ":" + JSON.stringify(birthDateAsString);
      row += ",";
      row +=
        JSON.stringify(headers[14]) + ":" + JSON.stringify(value.phoneNumber);
      row += ",";
      row +=
        JSON.stringify(headers[15]) +
        ":" +
        JSON.stringify(value.professionalStatus);
      row += ",";
      row +=
        JSON.stringify(headers[16]) +
        ":" +
        JSON.stringify(value.activitySector);
      row += ",";
      row +=
        JSON.stringify(headers[17]) +
        ":" +
        JSON.stringify(value.howDoIHeardAboutBeeBonds);
      row += ",";
      row += JSON.stringify(headers[18]) + ":" + JSON.stringify(value.isAverti);
      element = element + row;
      element += "}";
      jsonContent.push(JSON.parse(element));
    });
    var wb = XLSX.utils.book_new();
    var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
    XLSX.utils.book_append_sheet(wb, o, "Users");
    XLSX.writeFile(wb, "BeeBondsUsers.xlsx");
    this.setField({ isExporting: false });
  }

  exportNotif() {
    const users: { id: string }[] = [];
    this.state().data.forEach((user: any) => {
      users.push({ id: user.id });
    });
    this.showPopUp(
      <PopUpSendNotificationView users={users}></PopUpSendNotificationView>,
    );
  }
}
