import { Operation } from '@levelapp/softfabric';
import CouponsService from '../Proxy/CouponsService';


export default class CouponsOperation extends Operation
{
    _service : CouponsService;

    constructor()
    {
        super();
        this._service = new CouponsService();
    }    

    async get(projectId: any, callback: Function = () => { })
    {
        await (this._service as CouponsService).get(projectId, callback);
    }  

    async save(coupon: any, callback: Function = () => { })
    {
        await (this._service as CouponsService).save(coupon, callback);
    }  

    async delete(coupon: any, callback: Function = () => { })
    {
        await (this._service as CouponsService).delete(coupon, callback);
    }  
}