import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import UserProfileViewState from './UserProfileViewState';
import UserProfileViewProps from './UserProfileViewProps';
import UserProfileViewStyle from './UserProfileViewStyle';
import UserProfileViewModel from './UserProfileViewModel';
import { TranslationView, ButtonView, LottieView } from '@levelapp/softfabric-ui';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import Select from '../../../../../../../../../../../Components/Select/Select';
import InputView from '../../../../../../../../../../../Components/Input/input/InputView';
import DropDownView from '../../../../../../../../../../../Components/Dropdown/DropDownView';
import TextAreaView from '../../../../../../../../../../../Components/TextArea/textArea/TextAreaView';
import AddressForm from '../../../../../../../../../../../Components/AddressForm2/AddressForm2';
import moment from 'moment';
import 'moment/locale/fr';

export default class UserProfileView extends ContentView<UserProfileViewProps, UserProfileViewState, UserProfileViewModel, UserProfileViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: UserProfileViewProps) {
        super(props);

        // Binding
        this.bind(new UserProfileViewModel(this), new UserProfileViewStyle(this));
    }

    /* RENDERING */
    render() {
        return (
            <View style={this.style.Box}>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "50%" }}>
                        {this.state.isLoading &&
                            <LottieView style={{ justifyContent: 'flex-start' }} height={150} width={150} source={require('./assets/horizontalLoading.json')} />
                        }
                        <TranslationView style={this.style.Title}>PersonnalData</TranslationView>
                        <View>
                            <Select options={this.state.civilities.map((civ, key) => { return { label: civ, value: civ, key } })} onChange={this.binding.handleCivilityChange} shouldCheck={false} subscribe={() => ""} value={this.state.profile.civility} labelClass="col-lg-7" selectClass="col-lg-7" required={false} Style={{ marginTop: 30 }} label={this.binding.translate("Civility") + " (EDITABLE)"} />

                            {!this.state.civilityDisabled &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleCivilityCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleCivilitySaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleLastNameFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.lastNameDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NameLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleLastNameChanged} required={false} value={this.state.profile.lastName} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.lastNameDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleLastNameCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleLastNameSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleFirstNameFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.firstNameDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("FirstNameLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleFirstNameChanged} required={false} value={this.state.profile.firstName} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.firstNameDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleFirstNameCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleFirstNameSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>
                            <InputView subscribe={() => { }} disabled shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Email")} labelClass="col-lg-7" onChange={() => { }} required={false} value={this.state.profile.email} type="text" />
                            <InputView subscribe={() => { }} disabled shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("ClientNumber")} labelClass="col-lg-7" onChange={() => { }} required={false} value={this.state.profile.clientNumber} type="text" />

                            <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("NationalityLabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.eecCountries.map((civ, key) => { return { label: civ, value: civ, key } })} required={false} selectClass="col-lg-7" onChange={this.binding.handleNationalityChange} value={this.state.profile.nationality} />
                            {!this.state.nationalityDisabled &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleNationalityCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleNationalitySaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }

                            <Select disabled subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("LangRegimeLabel")} labelClass="col-lg-7" options={[]} required={false} selectClass="col-lg-7" onChange={() => ""} value={this.state.profile.language} />

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleBirthDateClicked}>
                                        <View>
                                            <InputView disabled={this.state.birthDateDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BornDateLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleBirthDateChange} required={false} value={this.state.profile.birthDate} />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.birthDateDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleBirthDateCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleBirthDateSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleBirthLocationClicked}>
                                        <View>
                                            <InputView disabled={this.state.birthLocationDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BornLocationLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleBirtLocationChange} required={false} value={this.state.profile.birthLocation} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.birthLocationDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleBirthLocationCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleBirthLocationSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleBirthCountryClicked}>
                                        <View>
                                            <InputView disabled={this.state.birthCountryDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BornCountryLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleBirthCountryChange} required={false} value={this.state.profile.birthCountry} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.birthCountryDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleBirthCountryCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleBirthCountrySaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* PART 2 : IDENTITY PIECES */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("IdentityPieces")}</span>
                                </div>
                            </div>


                            <Select disabled={false} subscribe={() => { }} options={this.state.identityPieceTypes.map((civ, key) => { return { label: civ, value: civ, key } })} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("IdentityPieceTypeLabel") + " (EDITABLE)"} labelClass="col-lg-7" required={false} selectClass="col-lg-7" onChange={this.binding.handleIdentityPieceTypeChange} value={this.state.profile.identityPieceType} />
                            {!this.state.identityPieceTypeDisabled &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleIdentityPieceTypeCancel}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleIdentityPieceTypeSaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleIdentityPieceNumberClicked}>
                                        <View>
                                            <InputView disabled={this.state.identityPieceNumberDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("IdentityPieceNumberLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleIdentityPieceNumberChange} required={false} value={this.state.profile.identityPieceNumber} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>


                                {!this.state.identityPieceNumberDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleIdentityPieceNumberCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleIdentityPieceNumberSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>


                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleIdentityPieceValidityClicked}>
                                        <View>
                                            <InputView disabled={this.state.identityPieceValidityDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("IdentityPieceValidityLabel") + ' (EDITABLE)'} labelClass="col-lg-7" onChange={this.binding.handleIdentityPieceValidityChange} required={false} value={this.state.profile.identityPieceValidity} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>


                                {!this.state.identityPieceValidityDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleIdentityPieceValidityCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleIdentityPieceValiditySaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            {true
                                &&
                                <div style={{ display: "flex" }}>
                                    <div style={{ flex: "80%" }}>
                                        <TouchableWithoutFeedback onPress={this.binding.handleNationalNumberClicked}>
                                            <View>
                                                <InputView disabled={this.state.nationalNumberDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NationalNumber") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleNationalNumberChange} required={false} value={this.state.profile.nationalNumber} type="text" />
                                            </View>
                                        </TouchableWithoutFeedback>
                                    </div>
                                    {!this.state.nationalNumberDisabled &&
                                        <div style={{ flex: "20%" }}>
                                            <div style={{ display: "flex" }}>
                                                <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleNationalNumberCancelClicked}>
                                                    <TranslationView upperCase>Cancel</TranslationView>
                                                </ButtonView>
                                                <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleNationalNumberSaved}>
                                                    <TranslationView upperCase>Edit</TranslationView>
                                                </ButtonView>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleIdentityPieceAuthorityClicked}>
                                        <View>
                                            <InputView disabled={this.state.identityPieceAuthorityDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("IdentityPieceAuthority") + ' (EDITABLE)'} labelClass="col-lg-7" onChange={this.binding.handleIdentityPieceAuthorityChange} required={false} value={this.state.profile.identityPieceAuthority} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.identityPieceAuthorityDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleIdentityPieceAuthorityCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleIdentityPieceAuthoritySaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* PART 3 : COORDINATES */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("AdressAndCoordinates")}</span>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleStreetFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.streetDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Street") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleStreetChanged} required={false} value={this.state.profile.address ? this.state.profile.address.street : ""} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.streetDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleStreetCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleStreetSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleNumberFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.numberDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("NumberLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleNumberChanged} required={false} value={this.state.profile.address.number} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.numberDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleNumberCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleNumberSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleBoxFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.boxDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Box") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleBoxChanged} required={false} value={this.state.profile.address.box} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.boxDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleBoxCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleBoxSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handlePostalCodeFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.postCodeDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PostalCode") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handlePostalCodeChanged} required={false} value={this.state.profile.address.postCode} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.postCodeDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handlePostalCodeCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handlePostalCodeSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleLocalityFieldClicked}>
                                        <View>
                                            <InputView disabled={this.state.localityDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Locality") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleLocalityChanged} required={false} value={this.state.profile.address.locality} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.localityDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleLocalityCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleLocalitySaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>


                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleCountryClicked}>
                                        <View>
                                            <InputView disabled={this.state.countryDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Country") + " (EDITABLE)"} labelClass="col-lg-7" placeholder="" onChange={this.binding.handleCountryChange} required={false} value={this.state.profile.address.country} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.countryDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleCountryCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleCountrySaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handlePhoneNumberClicked}>
                                        <View>
                                            <InputView disabled={this.state.phoneNumberDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("PhoneNumberLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handlePhoneNumberChange} required={false} value={this.state.profile.phoneNumber} type="tel" name="phone" autocomplete="tel" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.phoneNumberDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handlePhoneNumberCancel}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handlePhoneNumberSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* PART 4 : CIVIL STATUS */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("CivilStatus")}</span>
                                </div>
                            </div>
                            <Select disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("CivilStatusLabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.civilStatusArray.map(co => { return ({ label: co, value: co }) })} required={false} selectClass="col-lg-7" onChange={this.binding.handleCivilStatusChange} value={this.state.profile.civilStatus} />
                            {(this.state.profile.civilStatus == this.binding.translate("CivilStatus1") || this.state.profile.civilStatus == this.binding.translate("CivilStatus2")) && (<Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("MatrimornialRegimeLabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.matrimonialRegimes.map(co => { return ({ label: co, value: co }) })} required={false} selectClass="col-lg-7" placeholder={this.binding.translate("MatrimornialRegimePlaceholder")} onChange={this.binding.handleMatrimonialRegimeChange} value={this.state.profile.matrimonialRegime} />)}
                            {(this.state.profile.civilStatus == this.binding.translate("CivilStatus1") || this.state.profile.civilStatus == this.binding.translate("CivilStatus2")) && this.state.profile.matrimonialRegime == this.binding.translate("MatrimonialRegime4") && (<InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("OtherMatrimonialRegimeLabel") + " (EDITABLE)"} labelClass="col-lg-7" placeholder={this.binding.translate("OtherMatrimonialRegimePlaceholder")} onChange={this.binding.handleOtherMatrimonialRegimeChange} required={false} value={this.state.profile.otherMatrimonialRegime} type="text" />)}
                            {(this.state.profile.civilStatus == this.binding.translate("CivilStatus1") || this.state.profile.civilStatus == this.binding.translate("CivilStatus2")) && (<InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("ConjointNameLabel") + " (EDITABLE)"} labelClass="col-lg-7" placeholder={this.binding.translate("ConjointNamePlaceholder")} onChange={this.binding.handleConjointNameChange} required={false} value={this.state.profile.conjointName} type="text" />)}

                            {!this.state.civilStatusDisabled &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleCivilStatusCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleCivilStatusSaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }

                            {/* PART 5 : PROFESSIONAL ACTIVITY */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("ProfessionalActivity")}</span>
                                </div>
                            </div>
                            <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("ProfessionalStatusLabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.professionalStatuses.map(co => { return ({ label: co, value: co }) })} required={false} selectClass="col-lg-7" onChange={this.binding.handleProfessionalStatusChange} value={this.state.profile.professionalStatus} />
                            {this.state.profile.professionalStatus == this.binding.translate("ProfessionalStatus6") && (<InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("StatusLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleOtherStatusChange} required={false} value={this.state.profile.status} type="text" />)}
                            {this.state.profile.professionalStatus != this.binding.translate("ProfessionalStatus3")
                                && this.state.profile.professionalStatus != this.binding.translate("ProfessionalStatus4")
                                && this.state.profile.professionalStatus != this.binding.translate("ProfessionalStatus5")
                                && this.state.profile.professionalStatus != ''
                                && (<Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("ActivitySectorLabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.activities.map(co => { return ({ label: co, value: co }) })} required={false} selectClass="col-lg-7" placeholder="" onChange={this.binding.handleActivitySectorChange} value={this.state.profile.activitySector} />)}
                            {this.state.profile.professionalStatus == this.binding.translate("ProfessionalStatus2") && (<InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BceTvaLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleBceOrTvaChange} required={false} value={this.state.profile.bceOrTva} type="text" />)}

                            {!this.state.professionelStatusDisabled &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleProfessionalStatusCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleProfessionalStatusSaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }
                            {/* PART 6 : OTHER DATA */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("OtherData")}</span>
                                </div>
                            </div>
                            <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("OffshoreResidenceLabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.yesNo} required={false} selectClass="col-lg-7" onChange={this.binding.handleOffshoreResidenceChange} value={this.binding.getBoolean(this.state.profile.hasFiscalResidence)} />

                            {this.state.profile.hasFiscalResidence && (
                                <AddressForm
                                    subscribe={() => { }}
                                    labelBox={this.binding.translate("BoxLabel") + " (EDITABLE)"} placeholderBox={this.binding.translate("BoxPlaceholder")}
                                    labelLocality={this.binding.translate("LocalityLabel") + " (EDITABLE)"} placeholderLocality={this.binding.translate("LocalityPlaceholder")}
                                    labelPostCode={this.binding.translate("PostCodeLabel") + " (EDITABLE)"} placeholderPostCode={this.binding.translate("PostCodePlaceholder")}
                                    labelStreet={this.binding.translate("StreetLabel") + " (EDITABLE)"} placeholderStreet={this.binding.translate("StreetPlaceholder")}
                                    labelStreetNumber={this.binding.translate("NumberLabel") + " (EDITABLE)"} placeholderStreetNumber={this.binding.translate("NumberPlaceholder")}
                                    labelCountry={this.binding.translate("CountryLabel") + " (EDITABLE)"} placeholderCountry={this.binding.translate("CountryPlaceholder")}
                                    countries={this.state.countries}
                                    value={this.state.profile.fiscalResidence}
                                    shouldCheck={false}
                                    onChange={this.binding.handleFiscalResidenceChange} />
                            )}

                            {!this.state.offShoreResidenceDisabled &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleFiscalResidenceCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleFiscalResidenceSaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }



                            <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("PPELabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.yesNo} required={false} selectClass="col-lg-7" onChange={this.binding.handlePpeChange} value={this.binding.getBoolean(this.state.profile.ppe)} />
                            {this.state.profile.ppe &&
                                <TextAreaView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("PpeExplanation") + " (EDITABLE)"} labelClass="col-lg-7" required={false} inputClass="col-lg-7" placeholder={""} onChange={this.binding.handlePpeReasonChange} value={this.state.profile.ppeReason} />
                            }

                            {!this.state.ppeDisable &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handlePpeCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handlePpeSaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }


                            <Select subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} label={this.binding.translate("FATCALabel") + " (EDITABLE)"} labelClass="col-lg-7" options={this.state.yesNo} required={false} selectClass="col-lg-7" onChange={this.binding.handleFatcaChange} value={this.binding.getBoolean(this.state.profile.fatca)} />

                            {!this.state.fatcaDisable &&
                                <div className="row" style={{ marginTop: 20, display: 'flex', flexDirection: 'row' }}>
                                    <div>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleFatcaCancelClicked}>
                                            <TranslationView upperCase>Cancel</TranslationView>
                                        </ButtonView>
                                    </div>
                                    <div style={{ marginLeft: 10 }}>
                                        <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleFatcaSaved}>
                                            <TranslationView upperCase>Edit</TranslationView>
                                        </ButtonView>
                                    </div>
                                </div>
                            }

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleHowDidIHeardClicked}>
                                        <View>
                                            <InputView disabled={this.state.howDidIHearDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("HowDidIHearAboutBeebonds") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleHowDidIHeardChange} required={false} value={this.state.profile.howDoIHeardAboutBeeBonds} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>

                                {!this.state.howDidIHearDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleHowDidIHeardCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleHowDidIHeardSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>


                            {/* PART 7 : BANK DATA */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("BankData")}</span>
                                </div>
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleIbanClicked}>
                                        <View>
                                            <InputView disabled={this.state.ibanDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("IBANLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleIbanChanged} required={false} value={this.state.profile.iban} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.ibanDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleIbanCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleIbanSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div style={{ display: "flex" }}>
                                <div style={{ flex: "80%" }}>
                                    <TouchableWithoutFeedback onPress={this.binding.handleBicClicked}>
                                        <View>
                                            <InputView disabled={this.state.bicDisabled} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("BICLabel") + " (EDITABLE)"} labelClass="col-lg-7" onChange={this.binding.handleBicChanged} required={false} value={this.state.profile.bic} type="text" />
                                        </View>
                                    </TouchableWithoutFeedback>
                                </div>
                                {!this.state.bicDisabled &&
                                    <div style={{ flex: "20%" }}>
                                        <div style={{ display: "flex" }}>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#329B9F' isLoading={false} handleClick={this.binding.handleBicCancelClicked}>
                                                <TranslationView upperCase>Cancel</TranslationView>
                                            </ButtonView>
                                            <ButtonView animation={'None'} borders={'Little'} style={this.style.EditButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleBicSaved}>
                                                <TranslationView upperCase>Edit</TranslationView>
                                            </ButtonView>
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* PART 8 : TESTS */}
                            <div className="row" style={{ marginTop: 50 }}>
                                <div className="col-lg-10">
                                    <span style={this.style.headersYellow}>{this.binding.translate("tests")}</span>
                                </div>
                            </div>

                            {this.state.profile.forms && this.state.profile.forms.map((form: any) => {
                                return (
                                    <View>
                                        <InputView disabled={true} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={form.forms.title.fr} labelClass="col-lg-7" required={false} value={form.score} type="text" />
                                    </View>
                                )
                            })}



                            {/*<div className="row" style={{ marginTop: 50, marginBottom: 100 }}>
                      <div className="col-lg-2">
                          <ButtonView isLoading={false} handleClick={() => { }}>
                              <TranslationView upperCase>Edit</TranslationView>
                          </ButtonView>
                      </div>
                  </div>*/}


                        </View>
                    </div>
                    <div style={{ flex: "10%" }}></div>
                    <div style={{ flex: "40%" }}>

                        {!this.state.isLoading && this.state.profile.isAverti == null &&
                            <Text style={this.style.Title}>Profil non complété</Text>
                        }
                        {!this.state.isLoading && this.state.profile.isAverti != null && this.state.profile.isAverti &&
                            <Text style={this.style.Title}>Utilisateur averti</Text>
                        }

                        {!this.state.isLoading && this.state.profile.isAverti != null && !this.state.profile.identificationCertificateUrl &&
                            <span>
                                <Text style={this.style.Title}>Utilisateur non averti</Text>
                                {this.state.profile.hasSucceededTests &&
                                    <Text style={this.style.Title}>Test réussi</Text>
                                }
                                {!this.state.profile.hasSucceededTests &&
                                    <Text style={this.style.Title}>Test échoué</Text>
                                }
                            </span>
                        }

                        <br></br><br></br><br></br>
                        <Text style={this.style.Title}><TranslationView>UserStatut</TranslationView> {this.state.profile.userStatus.title}</Text>
                        <br></br><br></br><br></br>

                        {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                            <View>
                                <View style={{
                                    flex: 1,
                                    flexDirection: 'row',
                                }}>
                                    <Text style={this.style.Title}><TranslationView>ModifyStatus</TranslationView></Text>
                                    <DropDownView selectedValue={this.state.selectedStatus} values={this.state.statuses} onValueChange={(value: any) => this.binding.handleStatusChanged(value)}></DropDownView>
                                </View>

                                {this.state.selectedStatus.id == 35 &&
                                    <InputView disabled={false} subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 10 }} inputClass="col-lg-7" label={this.binding.translate("NonCompilantForm")} labelClass="col-lg-7" onChange={this.binding.handleDocumenTextChanged} required={false} value={this.state.changeStatusText} type="text" />
                                }

                                <ButtonView animation={'None'} borders={'Little'} style={this.style.ValidUserButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={() => { this.binding.ChangeStatus() }}>
                                    <TranslationView upperCase>ChangeStatus</TranslationView>
                                </ButtonView>
                            </View>
                        }

                        {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                            <View>
                                <ButtonView animation={'None'} borders={'Little'} style={this.style.ValidUserButton} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={() => { this.binding.ResetAvertiStatus() }}>
                                    <TranslationView upperCase>ResetAvertiStatus</TranslationView>
                                </ButtonView>
                            </View>
                        }

                        <View style={{ marginTop: 30 }}>
                            <TextAreaView subscribe={() => { }} label={"Commentaire Administrateur"} labelClass={"col-lg-7"} value={this.state.profile.adminComment} onChange={this.binding.handleAdminCommentChanged}></TextAreaView>
                            {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                                <ButtonView animation={'None'} borders={'Little'} style={this.style.UpdateButton2} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.changeComment}>
                                    <TranslationView upperCase>UpdateButtonText</TranslationView>
                                </ButtonView>
                            }
                        </View>

                        <View style={{ marginTop: 30 }}>
                            <div style={this.style.Text}>Dernière connexion : {this.state.profile.lastConnectionDate != null ? moment(this.state.profile.lastConnectionDate).locale("fr").format(" DD MMMM YYYY à HH:mm:ss") : " Jamais"}</div>
                        </View>

                        <View>
                            <TranslationView style={this.style.PicturesTitle}>IDCardRecto</TranslationView>
                            {this.state.profile.idRectoUrl ?
                                <img onClick={() => { if (this.state.profile.idRectoUrl != "") window.open(this.state.profile.idRectoUrl, "_blank") }} style={{ marginTop: 25 }} width="200" height="150" src={this.state.profile.idRectoUrlImage} />
                                :
                                <TranslationView style={this.style.Nodocs}>NoDocs</TranslationView>
                            }
                        </View>
                        <View>
                            <TranslationView style={this.style.PicturesTitle}>IDCardVerso</TranslationView>
                            {this.state.profile.idVersoUrl ?
                                <img onClick={() => { if (this.state.profile.idVersoUrl != "") window.open(this.state.profile.idVersoUrl, "_blank") }} style={{ marginTop: 25 }} width="200" height="150" src={this.state.profile.idVersoUrlImage} />
                                :
                                <TranslationView style={this.style.Nodocs}>NoDocs</TranslationView>
                            }
                        </View>
                        <View>
                            <TranslationView style={this.style.PicturesTitle}>DomiciliationProof</TranslationView>
                            {this.state.profile.domiciliationUrl ?
                                <img onClick={() => { if (this.state.profile.domiciliationUrl != "") window.open(this.state.profile.domiciliationUrl, "_blank") }} style={{ marginTop: 25 }} width="200" height="150" src={this.state.profile.domiciliationUrlImage} />
                                :
                                <TranslationView style={this.style.Nodocs}>NoDocs</TranslationView>
                            }
                        </View>
                        <View>
                            <TranslationView style={this.style.PicturesTitle}>ProofOfBankTitle</TranslationView>
                            {this.state.profile.proofOfBank ?
                                <img onClick={() => { if (this.state.profile.proofOfBank != "") window.open(this.state.profile.proofOfBank, "_blank") }} style={{ marginTop: 25 }} width="200" height="150" src={this.state.profile.proofOfBankImage} />
                                :
                                <TranslationView style={this.style.Nodocs}>NoDocs</TranslationView>
                            }
                        </View>
                        <View>
                            <TranslationView style={this.style.PicturesTitle}>identificationCertificateUrl</TranslationView>
                            {this.state.profile.identificationCertificateUrl ?
                                <img onClick={() => { if (this.state.profile.identificationCertificateUrl != "") window.open(this.state.profile.identificationCertificateUrl, "_blank") }} style={{ marginTop: 25 }} width="200" height="150" src={this.state.profile.identificationCertificateImage} />
                                :
                                <TranslationView style={this.style.Nodocs}>NoDocs</TranslationView>
                            }
                        </View>
                        {/*<View>
                            <TranslationView style={this.style.PicturesTitle}>BankIdentification</TranslationView>
                            <img onClick={() => { if (this.state.profile.bankIdentificationUrl != "") window.open(this.state.profile.bankIdentificationUrl, "_blank") }} style={{ marginTop: "50px" }} width="345" height="245" src={this.state.profile.bankIdentificationUrl} />
                        </View>*/}
                    </div>
                </div>
            </View >
        );
    }
}