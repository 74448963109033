import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import BenefitsViewState from './BenefitsViewState';
import BenefitsViewProps from './BenefitsViewProps';
import ProjectBenefitDTO from '../../../../../../../../../../../../DTO/ProjectBenefitDTO';

export default class BenefitsViewModel extends ContentViewModel<BenefitsViewState, BenefitsViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            indexBenefitsToShow: []
        }); // Initialize your state here

        // Bindings
        this.handleShowHideBenefits = this.handleShowHideBenefits.bind(this)
        this.handleAddClick = this.handleAddClick.bind(this)
        this.handleDeleteClick = this.handleDeleteClick.bind(this);

    }

    componentDidMount() {

    }

    handleShowHideBenefits(index: number) {
        let tempIndexListToShow = this.state().indexBenefitsToShow;
        if (this.state().indexBenefitsToShow.includes(index))
            tempIndexListToShow.splice(this.state().indexBenefitsToShow.indexOf(index), 1);
        else
            tempIndexListToShow.push(index);
        this.setField((previousState) => previousState.indexBenefitsToShow = tempIndexListToShow);
    }

    handleAddClick() {
        let newBenefits: ProjectBenefitDTO = {
            id: 0,
            projectID: this.props().project.id,
            order: this.props().project.projectBenefits.length + 1,
            titleId: 0,
            title: {
                fr: '',
                nl: '',
                en: '',
            },
            subtitleId: 0,
            subtitle: {
                fr: '',
                nl: '',
                en: '',
            },
        }
        this.props().project.projectBenefits.push(newBenefits)
        this.handleShowHideBenefits(this.props().project.projectBenefits.length - 1)
    }

    handleDeleteClick(id: number) {
        let benefits = this.props().project.projectBenefits;
        benefits = benefits.filter(objet => objet.id !== id);
        this.props().project.projectBenefits = benefits;
    }

}
