import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ImagesViewState from './ImagesViewState';
import ImagesViewProps from './ImagesViewProps';
import ImagesOperation from '../../../../../../../../../../Transfer/ImagesOperations';

export default class ImagesViewModel extends ContentViewModel<ImagesViewState, ImagesViewProps>
{
    Images = new ImagesOperation();

    constructor(props: ContentView) {
        super(props);
        var project = this.parameters<any>();
        this.initialState({
            projectId: project.id,
            projectImages : [],
            isLoading : true,
        })
        this.insertImage = this.insertImage.bind(this);
        this.removeFromList = this.removeFromList.bind(this);

    }

    componentDidMount(){
        this.Images.getByProject(this.state().projectId,(Images : any[]) => {
            this.setField({projectImages : Images});
            this.setField({isLoading : false});
        });
    }

    removeFromList(index: number){
        var list = this.state().projectImages;
        list.splice(index,1);
        this.setField({projectImages : list })
    }

    insertImage(Image : any){
        var list = this.state().projectImages;
        list.push(Image);
        this.setField({projectImages : list});
    }
}