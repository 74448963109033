import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,ViewStyle} from 'react-native';

export default class EditCouponPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "column",  
        justifyContent : "space-between",
        paddingHorizontal: 50, 
        paddingVertical: 60,

    }

   
   YesButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        height: 42,
        width: 200,
        marginLeft : '5px'
    } 


    text : StyleProp<any> = {
        color: '#565656', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '14px', 
        lineHeight: '20px',
        width: '25%'
    }
    input : StyleProp<ViewStyle> = {
        marginTop: 30, 
        justifyContent:'flex-start',
        flexDirection: 'row'
    }
    error : StyleProp<any> = {
        justifyContent: 'center',
        marginTop: 30, 
        color: 'red', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '20px', 
        lineHeight: '20px',
        width: '100%'
    }    
}
