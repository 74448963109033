import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import AddReservationPopupViewState from './AddReservationPopupViewState';
import AddReservationPopupViewProps from './AddReservationPopupViewProps';
import InvestmentReservation from '../../../../../../../DTO/InvestmentReservation';
import ProjectsOperation from '../../../../../../../Transfer/ProjectsOperations';

export default class AddReservationPopupViewModel extends ContentViewModel<AddReservationPopupViewState, AddReservationPopupViewProps>{
    projectsOperation = new ProjectsOperation();
    
    constructor(props: ContentView) {
        super(props);


        /* Initial State */
        this.initialState({
            reservation: {} as InvestmentReservation,
            projects: ''
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handleProjectChanged = this.handleProjectChanged.bind(this);
        this.handleSuccessProjectCallback = this.handleSuccessProjectCallback.bind(this);
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handlePhoneChanged = this.handlePhoneChanged.bind(this);
    }

    componentDidMount() {
        this.setField({ isLoading: true });
        this.projectsOperation.getList(this.handleSuccessProjectCallback);
    }

    handleSuccessProjectCallback(projectsList: any[]) {
        let projects = new Array();
        projects.push({ title: "", value: "" });
        projectsList.forEach(project => {
            projects.push({ title: project.consoleTitle, value: project.id });
        });
        this.setField({ projects });
    }

    handleAmountChanged(amount: any){
        let reservation = this.state().reservation;
        reservation.amount = amount;
        this.setField({ reservation });
    }

    handleFirstNameChanged(firstName: any){
        let reservation = this.state().reservation;
        reservation.firstName = firstName;
        this.setField({ reservation });
    }

    handleLastNameChanged(lastName: any){
        let reservation = this.state().reservation;
        reservation.lastName = lastName;
        this.setField({ reservation });
    }

    handleEmailChanged(email: any){
        let reservation = this.state().reservation;
        reservation.email = email;
        this.setField({ reservation });
    }

    handlePhoneChanged(phone: any){
        let reservation = this.state().reservation;
        reservation.phone = phone;
        this.setField({ reservation });
    }

    handleProjectChanged(project: any) {
        let reservation = this.state().reservation;
        reservation.project = project.title
        reservation.projectId = project.value
        this.setField({ reservation });
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleSavePressed() {
        if (!this.state().reservation.project)
            alert("Attention, vous n'avez pas sélectionner de projet");
        else if (!this.state().reservation.firstName)
            alert("Attention, vous n'avez pas mis de prénom");
        else if (!this.state().reservation.lastName)
            alert("Attention, vous n'avez pas mis de nom");
        else if (isNaN(Number(this.state().reservation.amount)))
            alert("Attention, vous n'avez pas mis de montant");
        else if (!this.state().reservation.email)
            alert("Attention, vous n'avez pas mis d'email");
        else {
            this.closePopUp();
            this.props().callBack(this.state().reservation);
        }
    }
}