import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import EditUserViewState from './EditUserViewState';
import EditUserViewProps from './EditUserViewProps';
import EditUserViewStyle from './EditUserViewStyle';
import EditUserViewModel from './EditUserViewModel';
import { View, Text } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';
import { Switch, Route } from 'react-router';
import UserProfileView from './modules/profile/modules/userprofile/UserProfileView';
import UserInvestmentsView from './modules/profile/modules/userinvestments/UserInvestmentsView';
import UserDocumentsView from './modules/profile/modules/userdocuments/userdocuments/UserDocumentsView';
import UserLegalEntitiesView from './modules/profile/modules/userlegalentities/UserLegalEntitiesView';

export default class EditUserView extends ContentView<EditUserViewProps, EditUserViewState, EditUserViewModel, EditUserViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: EditUserViewProps) {
        super(props);

        // Binding
        this.bind(new EditUserViewModel(this), new EditUserViewStyle(this));
    }
    /* RENDERING */

    render() {
        return (

            <View>
                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", marginLeft: "26px", marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ cursor: "pointer" }}>
                            <img onClick={() => this.binding.NavigationManager.navigate('/dashboard/users')} height="31" width="31" src="https://beebondsstorage.blob.core.windows.net/files/consoleImages/close.png"></img>
                        </div>
                        <div>
                            <Text style={this.style.PageTitle}>Investisseur :  {this.state.profile.firstName}  {this.state.profile.lastName}</Text>
                        </div>
                    </div>

                </View>
                <View style={this.style.PurpleMenu}>
                    <div style={{ display: "flex" }}>
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/users/edit/profile', true, this.state.profile)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                            <TranslationView style={this.style.PurpleMenuText}>Profil</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/profile") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/users/edit/investments', true, this.state.profile)} style={{ cursor: "pointer" }}>
                            <TranslationView style={this.style.PurpleMenuText}>Investments</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/investments") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>
                        {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/users/edit/documents', true, this.state.profile)} style={{ cursor: "pointer", marginLeft: "115px" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Documents</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/documents") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>
                        }
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/users/edit/userlegalentities', true, this.state.profile)} style={{ cursor: "pointer", marginLeft: "115px" }}>
                            <TranslationView style={this.style.PurpleMenuText}>LegalEntities</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/userlegalentities") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>
                    </div>
                </View>
                <Switch>
                    <Route path="/:language/dashboard/users/edit/profile" render={() => <UserProfileView editUserCallBack={this.binding.editUserName} />} />
                    <Route path="/:language/dashboard/users/edit/investments" render={() => <UserInvestmentsView />} />
                    {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
                        <Route path="/:language/dashboard/users/edit/documents" render={() => <UserDocumentsView />} />
                    }
                    <Route path="/:language/dashboard/users/edit/userlegalentities" render={() => <UserLegalEntitiesView />} />
                </Switch>
            </View>
        );
    }
}