import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import TransferReservationPopUpViewState from './TransferReservationPopUpViewState';
import TransferReservationPopUpViewProps from './TransferReservationPopUpViewProps';
import TransferReservationPopUpViewStyle from './TransferReservationPopUpViewStyle';
import TransferReservationPopUpViewModel from './TransferReservationPopUpViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View, Text, TouchableWithoutFeedback } from 'react-native';
import InputView from '../../../../../../../Components/Input/input/InputView';


export default class TransferReservationPopUpView extends ContentView<TransferReservationPopUpViewProps, TransferReservationPopUpViewState, TransferReservationPopUpViewModel, TransferReservationPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TransferReservationPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new TransferReservationPopUpViewModel(this), new TransferReservationPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>

        <TranslationView style={this.style.title}>
          TransferReservation
       </TranslationView>

        <View>
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Investor")} labelClass="col-lg-7" onChange={this.binding.handleUserSearchChanged} required={true} value={this.state.userSearch} type="text" />

          <View style={{ height: "200px", marginTop: "20px" }}>
            {this.state.usersSearch.map((user: any) => {
              return (
                <TouchableWithoutFeedback onPress={() => this.binding.selectUserHandler(user)}>
                  <View>
                    <Text style={this.state.destinationUserId == user.id ? this.style.selectedText : null}>{user.firstName} {user.lastName} {user.email}</Text>
                  </View>
                </TouchableWithoutFeedback>
              )
            })}
          </View>
        </View>

        <View style={this.style.footer}>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              Cancel
           </TranslationView>
          </ButtonView>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Confirm
           </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}