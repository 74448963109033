import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import AddPrimeUsersViewState from './AddPrimeUsersViewState';
import AddPrimeUsersViewProps from './AddPrimeUsersViewProps';
import AddPrimeUsersViewStyle from './AddPrimeUsersViewStyle';
import AddPrimeUsersViewModel from './AddPrimeUsersViewModel';
import {ButtonView, TranslationView} from '@levelapp/softfabric-ui';
import ButtonImportFileView from '@levelapp/softfabric-ui/components/Web/buttonimportfile/ButtonImportFileView';
import { View,Text } from 'react-native';
import InputView from '../../../../../../../../../../../../../../Components/Input/input/InputView';

export default class AddPrimeUsersView extends ContentView<AddPrimeUsersViewProps, AddPrimeUsersViewState, AddPrimeUsersViewModel, AddPrimeUsersViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:AddPrimeUsersViewProps)
  {
    super(props);

    // Binding
    this.bind(new AddPrimeUsersViewModel(this), new AddPrimeUsersViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container,text,YesButton ,emailsContainer,headerContainer,headerText,rowContainer,rowText,lineContainer,buttonContainer,errorText} = this.style;
    return (
      <View style={container}>  

          <View style={lineContainer}> 
            <View style={{flex : 1, justifyContent : 'center'}}>
              {this.state.file?
              <div style={text}>
                {this.state.file.name}
              </div>
              :
              <TranslationView style={text}>
                  {'import.file'}
              </TranslationView>}
            </View>
            <View style={{flex : 2}}>
              <ButtonImportFileView style={{flex : 2}} buttonColor="#FFB243" textColor="white" accepted=".json , .xlsx" action={this.binding.handleFileChanged} />        

            </View>
            <View style={{flex : 1}}>
              <ButtonView animation={'Bounce'} borders={'Little'} isLoading={this.state.isLoadingFile} handleClick={this.binding.addPrimeViaFile} backgroundColor="#FFB243" style={YesButton}>
                <TranslationView >
                  {'prime.add.file'}
                </TranslationView>
              </ButtonView>
            </View>
          </View>


          <View style={[lineContainer,{marginTop : 100}]}> 
            <View style={{flex : 3}}>
              <InputView 
              subscribe={() => { }} 
              shouldCheck={false} 
              inputClass="col-lg-7" 
              label={"Adresse mail"} 
              labelClass="col-lg-7"  
              onChange={this.binding.handleEmailChanged} 
              required={true} 
              type="text" /> 
              {this.state.showInputError && <Text style={{color : 'red'}}>Adresse mail non valide</Text> }     
            </View>
            <View style={buttonContainer}>
              <ButtonView  animation={'Bounce'} borders={'Little'} isLoading={this.state.isLoadingInput} handleClick={this.binding.addPrimeViaInput} backgroundColor="#FFB243" style={YesButton}>
                <TranslationView >
                  {'prime.add.input'}
                </TranslationView>
              </ButtonView>
            </View>
          </View>

          {this.props.showSucces &&
          <View style={emailsContainer}>
            <View style = {headerContainer}>
              <TranslationView style = {headerText}>{"prime.email.succes"}</TranslationView>
            </View>
          </View>}

          {this.state.showErrorFile &&
          <View style={emailsContainer}>
            <View style = {headerContainer}>
              <TranslationView style = {errorText}>{"prime.file.error"}</TranslationView>
            </View>
          </View>}


          {this.props.emailList.unknown.length > 0 && this.props.showError &&
          <View style={emailsContainer}>
            <View style = {headerContainer}>
              <TranslationView style = {errorText}>{"prime.email.error.unknown"}</TranslationView>
            </View>
            {this.props.emailList.unknown.map((email : string, index : number)=>{return(
              <View  key ={index} style = {rowContainer}>
                <Text style = {rowText}>{email}</Text>
              </View>
            )})} 
          </View>}

          {this.props.emailList.duplicate.length > 0 && this.props.showError &&
          <View style={emailsContainer}>
            <View style = {headerContainer}>
              <TranslationView style = {errorText}>{"prime.email.error.duplicate"}</TranslationView>
            </View>
            {this.props.emailList.duplicate.map((email : string, index : number)=>{return(
              <View  key ={index} style = {rowContainer}>
                <Text style = {rowText}>{email}</Text>
              </View>
            )})}
          </View>}
        </View>
    );
  }
}