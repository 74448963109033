import MenuViewState from './MenuViewState';
import MenuViewProps from './MenuViewProps';
import { ContentViewModel, ContentView, Application, NavigationManager, Managers } from '@levelapp/softfabric';
import RoleManager from '../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../Common/Managers/ExtendedManagers';

export default class MenuViewModel extends ContentViewModel<MenuViewState, MenuViewProps>{

    NavigationManager : NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)

    constructor(props: ContentView)
    {
        super(props);

        var user = localStorage.getItem("user");  
             

        this.initialState({
            isAdmin: this.RoleManager.isAdmin,
            isNewsletter : this.RoleManager.isNewsletter,
            isExport: this.RoleManager.isExport,
            isEditor : this.RoleManager.isEditor,
            user: user as string
        });
        

        this.logout = this.logout.bind(this);
    }

    logout()
    {
        this.NavigationManager.navigate('/login');
    }
}