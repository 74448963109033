import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ResetAvertiStatusViewState from './ResetAvertiStatusViewState';
import ResetAvertiStatusViewProps from './ResetAvertiStatusViewProps';
import ResetAvertiStatusViewStyle from './ResetAvertiStatusViewStyle';
import ResetAvertiStatusViewModel from './ResetAvertiStatusViewModel';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';

export default class ResetAvertiStatusView extends ContentView<ResetAvertiStatusViewProps, ResetAvertiStatusViewState, ResetAvertiStatusViewModel, ResetAvertiStatusViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ResetAvertiStatusViewProps) {
    super(props);

    // Binding
    this.bind(new ResetAvertiStatusViewModel(this), new ResetAvertiStatusViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>

        <TranslationView style={this.style.title}>
          ConfirmReset
        </TranslationView>

        <View style={this.style.footer}>
          <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.closePopUp} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              No
            </TranslationView>
          </ButtonView>
          <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.props.callBackValid} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Yes
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}