import * as React from 'react';
import CustomSelect from 'react-select';
import UrlConstants from '../../Constants/UrlConstants';

interface Props
{
    label: string;
    required: boolean;
    labelClass: string;
    selectClass: string;
    placeholderMinuts?: string;
    placeholderHours?: string;
    value?: string;
    shouldCheck: boolean;
    onChange: Function;
    Style?: React.CSSProperties;
    dateDesc?: boolean;
    subscribe: Function;
    disabled?: boolean;
}

interface State
{
    error: boolean;
    minuts: number[];
    hours: number[];
    selectedMinuts: number;
    selectedHours: number;
}

export default class HoursSelect extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            error: false,
            selectedMinuts: -1,
            selectedHours: -1,
            minuts: [],
            hours: [],
        }

        // Bindings
        this.handleMinutsChanged = this.handleMinutsChanged.bind(this);
        this.handleHoursChanged = this.handleHoursChanged.bind(this);
        this.isError = this.isError.bind(this);
    }

    componentDidMount()
    {
        // Feeds all select boxes
        let minuts = [];
        let hours = [];

        for (let i = 0; i < 61; i++)
        {
            minuts.push(i);
        }
        for (let i = 1; i < 25; i++)
        {
            hours.push(i);
        }

    

        if (this.props.value != undefined && this.props.value != '')
        {
            if(this.props.value.indexOf(':')> 0) {
                let selectedMinuts = parseInt(this.props.value.split(':')[1]);
                let selectedHours = parseInt(this.props.value.split(':')[0]);
                this.setState({ selectedMinuts, selectedHours,  minuts, hours  });                
            }  
        }
        else
        {
            this.setState({ minuts, hours });
            this.props.subscribe({ id: this.props.label, error: this.props.required && (this.state.selectedMinuts == -1  ||  this.state.selectedHours == -1 ) });
        }
    }

    componentWillUnmount()
    {
        this.props.subscribe({ id: this.props.label, error: false});
    }

    handleMinutsChanged(day: any)
    {
        this.setState({ selectedMinuts: day.value }, () =>
        {
            if (this.state.selectedMinuts != -1 && this.state.selectedHours != -1 )
            {
                this.props.onChange([ this.state.selectedHours,this.state.selectedMinuts,].join(':'));
                this.props.subscribe({id: this.props.label, error: false });
            }
        });
    }

    handleHoursChanged(hours: any)
    {
        let temp = [];
        let lastMinute = 60;
        let currentMinute = this.state.selectedMinuts;

        for (let i = 0; i <= lastMinute; i++)
        {
            temp.push(i);
        }

        if (currentMinute > lastMinute)
        {
            currentMinute = -1;
        }
        this.setState({ selectedHours: hours.value, minuts: temp, selectedMinuts: currentMinute }, () =>
        {
            if (this.state.selectedMinuts != -1 && this.state.selectedHours != -1 )
            {
                this.props.onChange([ this.state.selectedHours,this.state.selectedMinuts].join(':'));
                this.props.subscribe({id: this.props.label, error: false });
            }

        });
    }

    isError()
    {
        if ((this.state.selectedMinuts == -1 || this.state.selectedHours == -1) && this.props.required)
        {
            this.setState({ error: true });
        }
        else if (this.state.error)
        {
            this.setState({ error: false });
        }
    }

    render()
    {
        if (this.props.shouldCheck && !this.state.error)
        {
            this.isError();
        }
        return (
            <div style={this.props.Style}>
                <div className="row">
                    <div className={this.props.labelClass} style={{ display: 'flex' }}>
                        {this.state.error && (<img src={UrlConstants.BB_STORAGE + "/error.svg"} alt="budgeting" style={{ marginRight: 5 }} />)}
                        <label style={{ color: '#565656', fontWeight: 400, fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px', width: '100%', textAlign: 'start' }}>{this.props.label}{this.props.required ? '*' : ''}</label>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 8  }}>
                    <div className={this.props.selectClass} style={{ display: 'flex'}}>
                        {/* hours */}
                        <div style={{marginRight : 5, height: '40px', width: '100%', border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px' }}>
                                <CustomSelect placeholder={this.props.placeholderHours} value={this.state.selectedHours != -1 ? { value: this.state.selectedHours, label: this.state.selectedHours } : null} styles={{
                                    control: (styles: any) => ({ ...styles, border: 'none' }),
                                    placeholder: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#d5d5d5', fontWeight: 300, fontSize: '16px' }),
                                    option: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    input: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    singleValue: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 })
                                }}
                                    options={this.state.hours.map(co => { return ({ label: co < 10 ? '0' + co : co, value: co < 10 ? '0' + co : co }) })}
                                    onChange={(event) => this.setState({ error: false }, () => { this.handleHoursChanged(event) })}
                                    isDisabled={(this.props as unknown as Props).disabled}
                                />
                            </div>
                            <div style ={{fontFamily: 'Montserrat', color: '#565656', fontSize: '30px', fontWeight: 300, alignItems : 'flex-end' }}> : </div>
                         {/* minuts */}
                            <div style={{marginLeft : 5,marginRight : 5, height: '40px', width: '100%', border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px' }}>
                                
                                <CustomSelect placeholder={this.props.placeholderMinuts} value={this.state.selectedMinuts != -1 ? { value: this.state.selectedMinuts, label: this.state.selectedMinuts } : null} styles={{
                                    control: (styles: any) => ({ ...styles, border: 'none' }),
                                    placeholder: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#d5d5d5', fontWeight: 300, fontSize: '16px' }),
                                    option: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    input: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    singleValue: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 })
                                }}
                                    options={this.state.minuts.map(co => { return ({ label: co < 10 ? '0' + co : co, value: co < 10 ? '0' + co : co }) })}
                                    onChange={(event) => this.setState({ error: false }, () => { this.handleMinutsChanged(event) })
                                }
                                    isDisabled={(this.props as unknown as Props).disabled}
                                />
                            </div>

                        
                        </div>                   
                </div>
            </div >
        );
    }
}