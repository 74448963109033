import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import FileBankInformationPopUpViewState from './FileBankInformationPopUpViewState';
import FileBankInformationPopUpViewProps from './FileBankInformationPopUpViewProps';
import FileBankInformationPopUpViewStyle from './FileBankInformationPopUpViewStyle';
import FileBankInformationPopUpViewModel from './FileBankInformationPopUpViewModel';
import {ButtonView, TranslationView} from '@levelapp/softfabric-ui';
import {  View } from 'react-native';
import PopupHeaderView from '../../../../../../../../../../components/popopheader/PopupHeaderView';
import InputView from '../../../../../../../../../../../../../Components/Input/input/InputView';
import ButtonImportFileView from '@levelapp/softfabric-ui/components/Web/buttonimportfile/ButtonImportFileView';
//import DatePicker from "react-datepicker";
import DateSelect from '../../../../../../../../../../../../../Components/Select/DateSelect';
import moment from 'moment';
import Select from '../../../../../../../../../../../../../Components/Select/Select';

export default class FileBankInformationPopUpView extends ContentView<FileBankInformationPopUpViewProps, FileBankInformationPopUpViewState, FileBankInformationPopUpViewModel, FileBankInformationPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:FileBankInformationPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new FileBankInformationPopUpViewModel(this), new FileBankInformationPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container,text,YesButton,error } = this.style;
    return (
      <View style={{ minWidth: 300, minHeight: 700, width: 1200, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={this.binding.handleBackPressed}
          title={"ImportBankInformation"}
          width={1200}/>   

        <View style={container}>  
          <View style={{justifyContent : 'space-between', flexDirection : 'row'}}> 
            <View style={{justifyContent : 'space-around', flexDirection : 'row', width : "500px"}}> 
            {this.state.file?
              <div style={text}>
                {this.state.file.name}
              </div>
              :
              <TranslationView style={text}>
                  {'import.file'}
              </TranslationView>
            }
            <ButtonImportFileView buttonColor="#FFB243" textColor="white" accepted=".json , .xlsx" action={this.binding.handleFileChanged} />        
            </View>
            <ButtonView  animation={'None'} borders={'Little'} isLoading={this.state.isLoading} handleClick={this.binding.handleConfirmPressed} backgroundColor="#FFB243" style={YesButton}>
              <TranslationView >
                {'sendMail'}
              </TranslationView>
            </ButtonView>
          </View>

          <View>
            <InputView 
            subscribe={() => { }} 
            shouldCheck={false} 
            Style={{ marginTop: 30 }} 
            inputClass="col-lg-7" 
            label={"Titre du mail (FR)"} 
            labelClass="col-lg-7" 
            onChange={this.binding.handleTitleFRChanged} 
            required={true} 
            type="text" />
          </View>

          <View>
            <InputView 
            subscribe={() => { }} 
            shouldCheck={false} 
            Style={{ marginTop: 30 }} 
            inputClass="col-lg-7" 
            label={"Titre du mail (NL)"} 
            labelClass="col-lg-7"  
            onChange={this.binding.handleTitleNLChanged} 
            required={true} 
            type="text" />
          </View>

          <View style = {{marginTop: 30, flexDirection : 'row', justifyContent : 'space-between'}} >
            <Select 
              subscribe={() => { }} 
              shouldCheck={false} 
              Style={{ width :"200px" }} 
              selectClass="col-lg-7" 
              label={this.binding.translate("payementNumber")} 
              labelClass="col-lg-7" 
              onChange={this.binding.handlePayementNumberChanged} 
              required={false} 
              value={this.state.emailBankingInformations.payementNumber} 
              options = {["1","2","3"].map((number, key) => { return { label: number, value: number, key } })}/>
            <DateSelect 
              subscribe={() => { }} 
              shouldCheck={false} 
              Style={{width :"300px" }} 
              selectClass="col-lg-7" 
              label={this.binding.translate("confirmDate")} 
              labelClass="col-lg-7" 
              onChange={this.binding.handleConfirmDateChanged} 
              required={false} 
              value={this.state.emailBankingInformations.confirmDate} 
              startYear={moment().year()} 
              endYear={moment().year()+10} />
            <DateSelect 
              subscribe={() => { }} 
              shouldCheck={false} 
              Style={{ width :"300px" }} 
              selectClass="col-lg-7" 
              label={this.binding.translate("payementDate")} 
              labelClass="col-lg-7" 
              onChange={this.binding.handlePayementDateChanged} 
              required={false} 
              value={this.state.emailBankingInformations.payementdate} 
              startYear={moment().year()} 
              endYear={moment().year()+10} />
          </View>

            {this.state.showErrors && 
            <TranslationView style ={error}>
              {"error.mail"}
            </TranslationView>}
        </View>
      </View>
    );
  }
}