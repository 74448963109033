import { Operation } from "@levelapp/softfabric";
import UserNotificationServices from "../Proxy/UserNotificationServices";

export default class UserNotificationOperation extends Operation {
  _service: UserNotificationServices;

  constructor() {
    super();
    this._service = new UserNotificationServices();
  }
  async sendNotification(notification: any, callback: Function = () => {}) {
    return await (this._service as UserNotificationServices).sendNotification(
        notification,
        callback,
    );
  }
}
