import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { UiManagers } from '@levelapp/softfabric-ui';
import StyleManager from '@levelapp/softfabric-ui/ui-managers/StyleManager';
import {Dimensions, StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class EmailsViewStyle extends ContentViewStyle
{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: ViewStyle = {
        height: Dimensions.get('window').height,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding : 10
    }

    content : ViewStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height : '33%',
        width : '50%',
        padding : '2%'
    }
    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 40.65,
        elevation: 60,
        height: '90%',
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        backgroundColor: this.MyStyleManager.primaryColor,
        marginTop: -20,
        margin: 'auto',
        borderRadius: 3
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        padding: 30
    }

    subTitle: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        paddingTop: 2,
        marginLeft: 30,
        marginBottom: 10,
        fontWeight: '100'
    }
    textInput: StyleProp<TextStyle> = {
        fontSize: 18,
        fontFamily: this.MyStyleManager.fontFamily,
        color: "#565656"
    }
}