import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import InvestementDeletedPopUpViewState from './InvestementDeletedPopUpViewState';
import InvestementDeletedPopUpViewProps from './InvestementDeletedPopUpViewProps';

export default class InvestementDeletedPopUpViewModel extends ContentViewModel<InvestementDeletedPopUpViewState, InvestementDeletedPopUpViewProps>
{
    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({}); // Initialize your state here

        this.handleBackPressed = this.handleBackPressed.bind(this);
    }

    handleBackPressed() {
        this.closePopUp();
    }
}