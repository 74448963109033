import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import RolePopUpViewState from './RolePopUpViewState';
import RolePopUpViewProps from './RolePopUpViewProps';


export default class RolePopUpViewModel extends ContentViewModel<RolePopUpViewState, RolePopUpViewProps>
{    
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            userRole : this.props().userRole,
            isNewRole : this.props().userRole.id=="",
            isLoading : false

        }); // Initialize your state here

        // Bindings
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)

    }

    componentDidMount(){
        this.setField((previousState) => previousState.showError = this.props().showError);
    }


    handleInputChange(text: string) {
            this.setField((previousState) => previousState.userRole.name = text);
        
      }
 
    handleCheckboxChange(index: number, roleIndex: number) {
        if(roleIndex<0){
            this.setField((previousState) => previousState.userRole.roles.push(this.props().roles[index]) );
        }else{
            this.setField((previousState) => previousState.userRole.roles.splice(roleIndex,1));
        }
      }

      handleSavePressed()
    {
        if(this.props().userRole.name=== ''){
            this.setField((previousState) => previousState.showError = true);
        }else{
            this.setField((previousState) => previousState.isLoading = true);
            this.props().save(this.props().userRole);
        }
        
    }
    
    handleBackPressed() {
        this.closePopUp();
    }
}