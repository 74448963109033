import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TransferAmountPopUpViewState from './TransferAmountPopUpViewState';
import TransferAmountPopUpViewProps from './TransferAmountPopUpViewProps';
import InvestmentsOperation from '../../../../../../../../../../../../../Transfer/InvestmentsOperations';

export default class TransferAmountPopUpViewModel extends ContentViewModel<TransferAmountPopUpViewState, TransferAmountPopUpViewProps>
{
    usersOperation = new InvestmentsOperation();

    constructor(props: ContentView) {
        super(props);

        this.initialState({
            amount: 0,
            userSearch: "",
            usersSearch: [],
            destinationUserId : 0,
            PMId : 0,
            isPm : false,
            sendEmail : false,
            changeIsWaiting : false,
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handleUserSearchChanged = this.handleUserSearchChanged.bind(this);
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.selectUserHandler = this.selectUserHandler.bind(this);
        this.handleChangeSendEmail = this.handleChangeSendEmail.bind(this);
        this.handleChangeIsWaitingStatus = this.handleChangeIsWaitingStatus.bind(this)
    }

    /* HANDLERS */
    handleAmountChanged(amount: any) {
        this.setField({ amount });
    }

    handleUserSearchChanged(userSearch: any) {
        this.setField({ userSearch });
        this.usersOperation.getList(userSearch, this.handleSuccessUserCallback); 
    }

    handleSuccessUserCallback(ret: any) {
        this.setField({ usersSearch : ret });
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleChangeSendEmail() {
        var sendEmail = !this.state().sendEmail;
        this.setField({sendEmail});
    }

    handleChangeIsWaitingStatus(){
        var changeIsWaiting = !this.state().changeIsWaiting;
        this.setField({changeIsWaiting});
    }

    selectUserHandler(user: any) {
        this.setField({ destinationUserId : user.id });
        this.setField({ isPm : user.isPM });
        this.setField({ PMId : user.pmId });
    }

    handleSavePressed() {
        if(this.state().amount <= 0) {
            alert("Veuillez entrer un montant valide");
            return;
        }

        if(this.state().amount > this.props().maxAmount) {
            alert("Attention, le montant entré est supérieur au montant de l'investissement");
            return;
        }

        if(this.state().destinationUserId == 0) {
            alert("Veuillez sélectionner un utilisateur pour le transfert");
            return;
        }
        
        this.closePopUp();
        this.props().callBack(this.state().amount, this.props().investmentId, this.state().destinationUserId, this.state().isPm, this.state().PMId, this.state().sendEmail,this.state().changeIsWaiting);
    }
}