import {ContentViewModel, NavigationManager, Managers, Application, ContentView} from '@levelapp/softfabric';
import LegalEntityInvestmentsViewState from './LegalEntityInvestmentsViewState';
import LegalEntityInvestmentsViewProps from './LegalEntityInvestmentsViewProps';
import StateManager from '../../../../../../../../../Common/Managers/StateManager';
import LegalEntity from '../../../../../../../../../DTO/LegalEntity';
import StateManagerConst from '../../../../../../../../../Common/Managers/StateManagerConst';
import { View, Text, TouchableWithoutFeedback, Image} from 'react-native';
import DropDownView from '../../../../../../../../../Components/Dropdown/DropDownView';
import { ButtonView } from '@levelapp/softfabric-ui';
import LegalEntitiesViewStyle from '../../../../LegalEntitiesViewStyle';
import InvestmentsOperation from '../../../../../../../../../Transfer/InvestmentsOperations';
import ProjectInvestementStatusUpdatedView from '../../../../../../../../../Components/projectinvestementstatusupdated/ProjectInvestementStatusUpdatedView';
import SendEmailCouponCourantPopUpView from './modules/sendemailcouponcourantpopup/SendEmailCouponCourantPopUpView';
import MailSendedPopUpView from './modules/mailsendedpopup/MailSendedPopUpView';
import AddInvestmentPopUpView from './modules/addinvestmentpopup/AddInvestmentPopUpView';
import InvestementAddedPopUpView from './modules/investementaddedpopup/InvestementAddedPopUpView';
import React from 'react';
import moment from 'moment';
import RoleManager from '../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../Common/Managers/ExtendedManagers';
import TransferAmountPopUpView from '../../../../../users/modules/edituser/modules/profile/modules/userinvestments/modules/transferamountpopup/TransferAmountPopUpView';


export default class LegalEntityInvestmentsViewModel extends ContentViewModel<LegalEntityInvestmentsViewState, LegalEntityInvestmentsViewProps, LegalEntitiesViewStyle>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    dropdownValues = [
        {
            title: "Pending",
            value: false
        },
        {
            title: "Validated",
            value: true
        },
    ];

    constructor(props: ContentView) {
        super(props);

        let entity = this.parameters<LegalEntity>();
        let previousState = this.StateManager.retrieveState(StateManagerConst.INVESTMENT_ENTITY);

        this.initialState({
            legalEntity: entity,
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            datas: [],
            text: previousState != undefined ? previousState.text : ''
        });

        this.handleSuccessInvestmentCallback = this.handleSuccessInvestmentCallback.bind(this);
        this.updateInvestmentStatus = this.updateInvestmentStatus.bind(this);
        this.updateSelectStatuses = this.updateSelectStatuses.bind(this);
        this.getSelectedValue = this.getSelectedValue.bind(this);
        this.handleSuccessInvestmentCallback = this.handleSuccessInvestmentCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.showInvestPopUp = this.showInvestPopUp.bind(this);
        this.addInvestment = this.addInvestment.bind(this);
        this.sendEmailCouponCourantPopUp = this.sendEmailCouponCourantPopUp.bind(this);
        this.transferAmount = this.transferAmount.bind(this);
    }

    componentDidMount() {
        let investments = this.state().legalEntity.legalEntityInvestments;
        this.handleSuccessInvestmentCallback(investments);
    }

    handleSuccessInvestmentCallback(investments: any[]) {
        let datas: any[] = [];
        let rows: any[] = [];
        investments.forEach((investment) => {
            let investmentDTO = {
                "id": investment.id,
                "userName": investment.user.firstName + investment.user.lastName,
                "amount": investment.amount,
                "interests": investment.interests,
                "project": investment.project.consoleTitle,
                "creationDate": investment.creationDate,
                "isValidatedString": this.getSelectedValue(investment).title,
                "isCouponCourus": investment.isCouponCourus,
                "isWaiting" : investment.isWaiting
            }



            var momentDate = moment.utc(investmentDTO.creationDate);
            var dateAsString = momentDate.local().format('DD-MM-YYYY  H:mm:ss ')

            datas.push(investmentDTO);


            if (this.getSelectedValue(investment).title == "Validated") {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text} >{investmentDTO.userName}</Text>
                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <Text style={this.style().text}>{investmentDTO.project}</Text>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI': 'NON'}</Text>
                        <Text style={this.style().text}>{this.getSelectedValue(investment).title}</Text>
                        <Text style={this.style().text}>X</Text>
                        <Text style={this.style().text}></Text>
                    </View>
                )
            }
            else {
                rows.push(
                    <View>
                        <Text style={this.style().text} >{investmentDTO.id}</Text>
                        <Text style={this.style().text} >{investmentDTO.userName}</Text>
                        <Text style={this.style().text}>{investmentDTO.amount}</Text>
                        <Text style={this.style().text}>{investmentDTO.project}</Text>
                        <Text style={this.style().text}>{dateAsString}</Text>
                        <Text style={this.style().text} >{investmentDTO.isWaiting ? 'OUI': 'NON'}</Text>
                        <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, investment) }} selectedValue={this.getSelectedValue(investment)} values={this.dropdownValues}></DropDownView>
                        <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(investment) }} >
                            <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                        </TouchableWithoutFeedback>
                        {investment.project.projectTypeId != 4 ?
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showTransferAmountPopUp(investmentDTO) }}>
                                <Text style={this.style().text} >Transférer</Text>
                            </ButtonView>
                            : <></>
                        }
                        {investmentDTO.isCouponCourus && investmentDTO.interests == 0 && !investment.isValidated && 
                            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showSendEmailCouponCourantPopUp(investment) }}>
                                <Text style={this.style().text} >Envoyer email</Text>
                            </ButtonView>
                        }
                    </View>
                )
            }
        });
        this.setField({ datas: datas, rows: rows, initialData: datas.slice(0), initialRow: rows });
        this.setField({ tableHeaders: ['ID', 'UTILISATEUR', 'AMOUNT', 'PROJET', 'DATE','IS WAITING', 'STATUT', 'CHANGER STATUT', 'TRANSFERER', 'COUPON COURU'] });
        if (this.state().text != '') {
            this.handlerResearching(this.state().text)
        }
    }

    getSelectedValue(invest: any) {
        return this.dropdownValues[invest.isValidated ? 1 : 0];
    }

    updateSelectStatuses(value: any, invest: any) {
        let legal = this.state().legalEntity;
        legal.legalEntityInvestments.filter(inv => inv.id == invest.id)[0].isValidated = value.value;
        this.setField({ legalEntity: legal });
    }

    updateInvestmentStatus(investment: any) {
        this.parameters<any>().legalEntity = this.state().legalEntity;
        new InvestmentsOperation().updateInvestmentStatus(investment)
            .then(() => {
                this.showPopUp(
                    <ProjectInvestementStatusUpdatedView />
                );
            }).then(() => {
                setTimeout(() => this.closePopUp(), 3000);
                this.NavigationManager.navigate('/dashboard/legalentities/', true);
            });
    }

    showSendEmailCouponCourantPopUp(investment: any) {
        this.showPopUp(<SendEmailCouponCourantPopUpView project={investment.project} callBack={this.sendEmailCouponCourantPopUp} investment={investment}></SendEmailCouponCourantPopUpView>);
    }

    sendEmailCouponCourantPopUp(investment: any) {
        new InvestmentsOperation().sendEmailCouponCourant(investment)
            .then(() => {
                this.showPopUp(
                    <MailSendedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/legalentities/', true);
            });
    }

    handlerResearching(txt: string) {
        let rows: any[] = [];

        let datas = this.state().initialData.filter(investment => {
            let contains = false;

            for (let key in investment) {
                contains = JSON.stringify(investment[key]).toLocaleLowerCase().includes(txt.toLocaleLowerCase());
                if (contains) {
                    this.pushRow(investment, rows);
                    return contains;
                }
            }
            return contains;

        });
        this.setField({ text: txt, datas: datas, rows: rows, resetStepView: true });
    }

    pushRow(investment: any, rowsToPush: any[]) {

        let myInvestment = this.state().legalEntity.legalEntityInvestments.find((investmentFull) => (investmentFull.projectId === investment.projectId));

        var momentDate = moment.utc(investment.creationDate);
        var dateAsString = momentDate.local().format('DD-MM-YYYY  H:mm:ss ')

        rowsToPush.push(
            <View>
                <Text style={this.style().text} >{investment.projectId}</Text>
                <Text style={this.style().text} >{investment.userName}</Text>
                <Text style={this.style().text}>{investment.amount}</Text>
                <Text style={this.style().text}>{dateAsString}</Text>
                <Text style={this.style().text} >{investment.isWaiting ? 'OUI': 'NON'}</Text>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, myInvestment) }} selectedValue={this.getSelectedValue(myInvestment)} values={this.dropdownValues}></DropDownView>
                </View>
                <TouchableWithoutFeedback onPress={() => { this.updateInvestmentStatus(myInvestment) }} >
                    <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                </TouchableWithoutFeedback>
            </View>
        )
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    resetTable() {
        if (this.state().text == "")
            this.handlingClose();
        else (this.handlerResearching(this.state().text))
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(investment => {
            this.pushRow(investment, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    handlingClose() {
        this.setField({ text: "", datas: this.state().initialData, rows: this.state().initialRow });
    }

    showInvestPopUp() {
        this.showPopUp(<AddInvestmentPopUpView users= {this.state().legalEntity.users} callBack={this.addInvestment}></AddInvestmentPopUpView>);
    }

    addInvestment(amount: any, project: any, user: any) {
        var investment = { userId:user.id, amount: amount, legalEntityId: this.state().legalEntity.id, Project: { ConsoleTitle: project } };
        new InvestmentsOperation().addInvestment(investment)
            .then(() => {
                this.showPopUp(
                    <InvestementAddedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/legalentities/', true);
            });
    }

    showTransferAmountPopUp(invest: any) {
        this.showPopUp(<TransferAmountPopUpView investmentId={invest.id} maxAmount={invest.amount} callBack={this.transferAmount}></TransferAmountPopUpView>);
    }

    transferAmount(amount: any, investmentId: any, destinationUserId: any, isPm: boolean, PMId: any, sendEmail: boolean, changeIsWaiting: boolean) {
        var investment = { amount: amount, InvestorDestinationId: destinationUserId, InvestmentSourceId: investmentId, IsPm: isPm, PMId: PMId, SendEmail: sendEmail, changeIsWaiting: changeIsWaiting };

        new InvestmentsOperation().transferAmount(investment)
            .then(() => {
                this.showPopUp(
                    <InvestementAddedPopUpView />
                );
            }).then(() => {
                this.NavigationManager.navigate('/dashboard/legalentities/', true);
            });
    }

}