import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import CouponsViewState from './CouponsViewState';
import CouponsViewProps from './CouponsViewProps';
import CouponsOperation from '../../../../../../../../../../Transfer/CouponsOperations';
import { Text, View } from 'react-native';
import React from 'react';
import moment from 'moment';
import { ButtonView } from '@levelapp/softfabric-ui';
import EditCouponPopUpView from './modules/editcouponpopup/EditCouponPopUpView';

export default class CouponsViewModel extends ContentViewModel<CouponsViewState, CouponsViewProps>
{

    couponsOperation = new CouponsOperation();

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            coupons: [],
            tableHeaders: ['#', 'Taux', 'Date', 'Statut', 'Date de paiement', 'Nature du paiement', 'Action'],
            rows: [],
            datas: [],
        }); // Initialize your state here

        // Bindings
        this.refresh = this.refresh.bind(this);
        this.handleSuccessCallback = this.handleSuccessCallback.bind(this);
        this.showEditCouponPopUp = this.showEditCouponPopUp.bind(this);
        this.showAddCouponPopUp = this.showAddCouponPopUp.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.setField({ isLoading: true });
        this.couponsOperation.get(this.props().projectId, this.handleSuccessCallback);
    }

    handleSuccessCallback(ret: any) {
        let rows: any[] = [];
        ret.forEach((coupon: any) => {

            var momentDate = moment.utc(coupon.couponDate);
            var dateAsString = momentDate.local().format('DD-MM-YYYY')

            var momentDate2 = moment.utc(coupon.paymentDate);
            var dateAsString2 = momentDate2.local().format('DD-MM-YYYY')
            if (dateAsString2 == "Invalid date")
                dateAsString2 = "-";

            rows.push(
                <View>
                    <Text>{coupon.number}</Text>
                    <Text>{coupon.rate}%</Text>
                    <Text>{dateAsString}</Text>
                    <Text>{coupon.status}</Text>
                    <Text>{dateAsString2}</Text>
                    <Text>{coupon.type}</Text>
                    <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.showEditCouponPopUp(coupon) }}>
                        <Text>Modifier le coupon</Text>
                    </ButtonView>
                </View>
            )
        });
        this.setField({ rows: rows, coupons : ret });
    }

    showEditCouponPopUp(coupon: any) {
        this.showPopUp(<EditCouponPopUpView coupon={coupon} refresh={this.refresh} projectId={this.props().projectId}></EditCouponPopUpView>);
    }

    showAddCouponPopUp() {
        let lastCoupon = this.state().coupons[this.state().coupons.length-1];    
        if(lastCoupon)   {
            let coupon = {rate: lastCoupon.rate, number : lastCoupon.number+1};
            this.showPopUp(<EditCouponPopUpView coupon={coupon} refresh={this.refresh} projectId={this.props().projectId}></EditCouponPopUpView>);
        }
        else {
            let coupon = {};
            this.showPopUp(<EditCouponPopUpView coupon={coupon} refresh={this.refresh} projectId={this.props().projectId}></EditCouponPopUpView>);
        }       
    }
}