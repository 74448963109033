import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import DeleteInvestmentPopUpViewState from './DeleteInvestmentPopUpViewState';
import DeleteInvestmentPopUpViewProps from './DeleteInvestmentPopUpViewProps';
import DeleteInvestmentPopUpViewStyle from './DeleteInvestmentPopUpViewStyle';
import DeleteInvestmentPopUpViewModel from './DeleteInvestmentPopUpViewModel';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';

export default class DeleteInvestmentPopUpView extends ContentView<DeleteInvestmentPopUpViewProps, DeleteInvestmentPopUpViewState, DeleteInvestmentPopUpViewModel, DeleteInvestmentPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: DeleteInvestmentPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new DeleteInvestmentPopUpViewModel(this), new DeleteInvestmentPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>
        <View>
          <TranslationView style={this.style.title}>
            DeleteInvestment
          </TranslationView>
          <br /><br />
          <Text>Id : {this.props.investment.id}</Text>
          <Text>Project : {this.props.investment.project.consoleTitle}</Text>
          <Text>Montant : {this.props.investment.amount}€</Text>
        </View>

        <View style={this.style.footer}>
          <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              Cancel
            </TranslationView>
          </ButtonView>
          <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Confirm
            </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}