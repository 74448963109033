import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import AddInvestmentPopUpViewState from './AddInvestmentPopUpViewState';
import AddInvestmentPopUpViewProps from './AddInvestmentPopUpViewProps';
import AddInvestmentPopUpViewStyle from './AddInvestmentPopUpViewStyle';
import AddInvestmentPopUpViewModel from './AddInvestmentPopUpViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import InputView from '../../../../../../../../../../../Components/Input/input/InputView';
import DropDownView from '../../../../../../../../../../../Components/Dropdown/DropDownView';

export default class AddInvestmentPopUpView extends ContentView<AddInvestmentPopUpViewProps, AddInvestmentPopUpViewState, AddInvestmentPopUpViewModel, AddInvestmentPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: AddInvestmentPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new AddInvestmentPopUpViewModel(this), new AddInvestmentPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>

        <TranslationView style={this.style.title}>
          AddInvest
         </TranslationView>

        <View>
          <TranslationView style={this.style.subTitle}>
            Project
         </TranslationView>     
         <DropDownView selectedValue={this.state.project} values={this.state.projects} onValueChange={(value: any) => this.binding.handleProjectChanged(value)}></DropDownView>
         <TranslationView style={this.style.subTitle}>
            Users
         </TranslationView>  
         <DropDownView style={{ marginTop: 30 }} selectedValue={this.state.user} values={this.state.users} onValueChange={(value: any) => this.binding.handleUserChanged(value)}></DropDownView>
                        
         <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Amount")} labelClass="col-lg-7" onChange={this.binding.handleAmountChanged} required={true} value={this.state.amount} type="text" />

        </View>

        <View style={this.style.footer}>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              Cancel
             </TranslationView>
          </ButtonView>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Confirm
             </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}