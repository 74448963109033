import { ContentViewModel, NavigationManager, Application, Managers, ContentView } from '@levelapp/softfabric';
import UserProfileViewState from './UserProfileViewState';
import UserProfileViewProps from './UserProfileViewProps';
import React from 'react';
import Profile from '../../../../../../../../../../../DTO/Profile';
import UsersPopUpViewView from './userspopupview/UsersPopUpViewView';
import UsersOperation from '../../../../../../../../../../../Transfer/UsersOperation';
import StatusOperation from '../../../../../../../../../../../Transfer/StatusOperations';
import ProjectInvestementStatusUpdatedView from '../../../../../../../../../../../Components/projectinvestementstatusupdated/ProjectInvestementStatusUpdatedView';
import Address from '../../../../../../../../../../../DTO/Address';
import RegExp from '../../../../../../../../../../../Constants/RegExp'
import RoleConstants from '../../../../../../../../../../../Constants/Constant';
import RoleManager from '../../../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../../../Common/Managers/ExtendedManagers';
import ResetAvertiStatusView from './resetavertistatus/ResetAvertiStatusView';

export default class UserProfileViewModel extends ContentViewModel<UserProfileViewState, UserProfileViewProps>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    usersOperation = new UsersOperation();
    user = "";

    constructor(props: ContentView) {
        super(props);
        var usr = this.parameters<Profile>();

        usr.idRectoUrlImage = this.CheckImage(usr.idRectoUrl);
        usr.idVersoUrlImage = this.CheckImage(usr.idVersoUrl);
        usr.domiciliationUrlImage = this.CheckImage(usr.domiciliationUrl);
        usr.proofOfBankImage = this.CheckImage(usr.proofOfBankImage);
        usr.identificationCertificateImage = this.CheckImage(usr.identificationCertificateUrl);

        this.user = String(localStorage.getItem("user"));

        /* Initial State */
        var isAdmin = localStorage.getItem(RoleConstants.ADMIN);
        if (isAdmin !== "true") {
            //this.NavigationManager.navigate('/login');
        }


        this.initialState({
            profile: {
                address: { box: "", country: "", locality: "", number: "", postCode: "", street: "" }, activitySector: "", userStatus: "", status: "", ppe: false,
                aspNetUserId: "", bceOrTva: "", beeBondsStatus: "", street: "", locality: "", country: "", box: "", bic: "", birthCountry: "", birthDate: "", birthLocation: "",
                civilStatus: "", civility: "", clientNumber: "", conjointName: "", creationDate: "", domiciliationUrl: "", domiciliationUrlImage: "", email: "", fatca: false,
                firstName: "", hasFiscalResidence: false, howDoIHeardAboutBeeBonds: "", iban: "", id: 0, idRectoUrl: "", idRectoUrlImage: "", idVersoUrl: "", idVersoUrlImage: "", proofOfBank: "", proofOfBankImage: "", identificationCertificateImage: "",
                identityPieceAuthority: "", identityPieceNumber: "", identityPieceType: "", identityPieceValidity: "", investments: [], forms: [], language: "", lastName: "", bankIdentificationUrl: "",
                matrimonialRegime: "", mifid: "", nationalNumber: "", nationality: "", otherMatrimonialRegime: "", phoneNumber: "", postalCode: "", professionalStatus: "", consoleTitle: "",
                ppeReason: "", adminComment: "", lastConnectionDate: "", legalEntities: [], identificationCertificateUrl: "", consequencesDeclarationUrl: "", responsibleDeclarationUrl: "", scores: ""
            },
            civilStatusArray: [],
            matrimonialRegimes: [],
            civilities: [this.translate("Mister"), this.translate("Miss"), this.translate("Misses"), this.translate("MisterMisses"), this.translate("Misters"), this.translate("Missess")],
            isLoading: true,
            isAdmin: (isAdmin == 'true'),
            statuses: [],
            selectedStatus: '',
            lastNameDisabled: true,
            firstNameDisabled: true,
            streetDisabled: true,
            numberDisabled: true,
            boxDisabled: true,
            postCodeDisabled: true,
            localityDisabled: true,
            userId: usr.id,
            nationalNumberDisabled: true,
            identityPieceTypeDisabled: true,
            identityPieceAuthorityDisabled: true,
            identityPieceNumberDisabled: true,
            identityPieceValidityDisabled: true,
            ibanDisabled: true,
            bicDisabled: true,
            civilStatusDisabled: true,
            howDidIHearDisabled: true,
            yesNo: [{ value: true, label: this.translate("Yes") }, { value: false, label: this.translate("No") }],
            offShoreResidenceDisabled: true,
            countries: [],
            eecCountries: [],
            ppeDisable: true,
            fatcaDisable: true,
            civilityDisabled: true,
            birthDateDisabled: true,
            birthCountryDisabled: true,
            birthLocationDisabled: true,
            phoneNumberDisabled: true,
            countryDisabled: true,
            activities: [],
            professionalStatuses: [],
            professionelStatusDisabled: true,
            nationalityDisabled: true,
            identityPieceTypes: [this.translate("IdentityCard"), this.translate("Passport"), this.translate("ECard"), this.translate("EPlusCard"), this.translate("FCard"), this.translate("ResidentPermit")],
            changeStatusText: "",
        })

        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.refresh = this.refresh.bind(this);
        this.getBoolean = this.getBoolean.bind(this);
        this.ValidUser = this.ValidUser.bind(this);
        this.ValidUserService = this.ValidUserService.bind(this);
        this.ChangeStatus = this.ChangeStatus.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);

        this.handleLastNameFieldClicked = this.handleLastNameFieldClicked.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.handleLastNameCancelClicked = this.handleLastNameCancelClicked.bind(this);
        this.handleLastNameSaved = this.handleLastNameSaved.bind(this);

        this.handleFirstNameFieldClicked = this.handleFirstNameFieldClicked.bind(this);
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleFirstNameCancelClicked = this.handleFirstNameCancelClicked.bind(this);
        this.handleFirstNameSaved = this.handleFirstNameSaved.bind(this);

        this.handleStreetFieldClicked = this.handleStreetFieldClicked.bind(this);
        this.handleStreetChanged = this.handleStreetChanged.bind(this);
        this.handleStreetCancelClicked = this.handleStreetCancelClicked.bind(this);
        this.handleStreetSaved = this.handleStreetSaved.bind(this);

        this.handleNumberFieldClicked = this.handleNumberFieldClicked.bind(this);
        this.handleNumberChanged = this.handleNumberChanged.bind(this);
        this.handleNumberCancelClicked = this.handleNumberCancelClicked.bind(this);
        this.handleNumberSaved = this.handleNumberSaved.bind(this);

        this.handleBoxFieldClicked = this.handleBoxFieldClicked.bind(this);
        this.handleBoxChanged = this.handleBoxChanged.bind(this);
        this.handleBoxCancelClicked = this.handleBoxCancelClicked.bind(this);
        this.handleBoxSaved = this.handleBoxSaved.bind(this);

        this.handlePostalCodeFieldClicked = this.handlePostalCodeFieldClicked.bind(this);
        this.handlePostalCodeChanged = this.handlePostalCodeChanged.bind(this);
        this.handlePostalCodeCancelClicked = this.handlePostalCodeCancelClicked.bind(this);
        this.handlePostalCodeSaved = this.handlePostalCodeSaved.bind(this);

        this.handleLocalityFieldClicked = this.handleLocalityFieldClicked.bind(this);
        this.handleLocalityChanged = this.handleLocalityChanged.bind(this);
        this.handleLocalityCancelClicked = this.handleLocalityCancelClicked.bind(this);
        this.handleLocalitySaved = this.handleLocalitySaved.bind(this);

        this.disableAllEditableFields = this.disableAllEditableFields.bind(this);

        this.handleNationalNumberChange = this.handleNationalNumberChange.bind(this);
        this.handleNationalNumberCancelClicked = this.handleNationalNumberCancelClicked.bind(this);
        this.handleNationalNumberSaved = this.handleNationalNumberSaved.bind(this);
        this.handleNationalNumberClicked = this.handleNationalNumberClicked.bind(this);

        this.handleIbanClicked = this.handleIbanClicked.bind(this);
        this.handleIbanChanged = this.handleIbanChanged.bind(this);
        this.handleIbanCancelClicked = this.handleIbanCancelClicked.bind(this);
        this.handleIbanSaved = this.handleIbanSaved.bind(this);

        this.handleBicClicked = this.handleBicClicked.bind(this);
        this.handleBicChanged = this.handleBicChanged.bind(this);
        this.handleBicCancelClicked = this.handleBicCancelClicked.bind(this);
        this.handleBicSaved = this.handleBicSaved.bind(this);

        this.handleCivilStatusChange = this.handleCivilStatusChange.bind(this);
        this.handleMatrimonialRegimeChange = this.handleMatrimonialRegimeChange.bind(this);
        this.handleOtherMatrimonialRegimeChange = this.handleOtherMatrimonialRegimeChange.bind(this);
        this.handleConjointNameChange = this.handleConjointNameChange.bind(this);

        this.handleHowDidIHeardClicked = this.handleHowDidIHeardClicked.bind(this);
        this.handleHowDidIHeardChange = this.handleHowDidIHeardChange.bind(this);
        this.handleHowDidIHeardCancelClicked = this.handleHowDidIHeardCancelClicked.bind(this);
        this.handleHowDidIHeardSaved = this.handleHowDidIHeardSaved.bind(this);

        this.verifyCivilStatusChanged = this.verifyCivilStatusChanged.bind(this);

        this.handleCivilStatusCancelClicked = this.handleCivilStatusCancelClicked.bind(this);
        this.handleCivilStatusSaved = this.handleCivilStatusSaved.bind(this);

        this.handlePpeReasonChange = this.handlePpeReasonChange.bind(this);
        this.handlePpeChange = this.handlePpeChange.bind(this);
        this.handlePpeCancelClicked = this.handlePpeCancelClicked.bind(this);
        this.handlePpeSaved = this.handlePpeSaved.bind(this);

        this.handleFiscalResidenceCancelClicked = this.handleFiscalResidenceCancelClicked.bind(this);
        this.handleOffshoreResidenceChange = this.handleOffshoreResidenceChange.bind(this);
        this.handleFiscalResidenceSaved = this.handleFiscalResidenceSaved.bind(this);

        this.handleFiscalResidenceChange = this.handleFiscalResidenceChange.bind(this);

        this.handleFatcaChange = this.handleFatcaChange.bind(this);
        this.handleFatcaCancelClicked = this.handleFatcaCancelClicked.bind(this);
        this.handleFatcaSaved = this.handleFatcaSaved.bind(this);

        this.handleIdentityPieceNumberCancel = this.handleIdentityPieceNumberCancel.bind(this);
        this.handleIdentityPieceNumberSaved = this.handleIdentityPieceNumberSaved.bind(this);
        this.handleIdentityPieceNumberChange = this.handleIdentityPieceNumberChange.bind(this);
        this.handleIdentityPieceNumberClicked = this.handleIdentityPieceNumberClicked.bind(this);


        this.handleIdentityPieceValidityCancel = this.handleIdentityPieceValidityCancel.bind(this);
        this.handleIdentityPieceValiditySaved = this.handleIdentityPieceValiditySaved.bind(this);
        this.handleIdentityPieceValidityChange = this.handleIdentityPieceValidityChange.bind(this);
        this.handleIdentityPieceValidityClicked = this.handleIdentityPieceValidityClicked.bind(this);

        this.handleIdentityPieceAuthorityCancel = this.handleIdentityPieceAuthorityCancel.bind(this);
        this.handleIdentityPieceAuthoritySaved = this.handleIdentityPieceAuthoritySaved.bind(this);
        this.handleIdentityPieceAuthorityChange = this.handleIdentityPieceAuthorityChange.bind(this);
        this.handleIdentityPieceAuthorityClicked = this.handleIdentityPieceAuthorityClicked.bind(this);

        this.handleIdentityPieceTypeCancel = this.handleIdentityPieceTypeCancel.bind(this);
        this.handleIdentityPieceTypeSaved = this.handleIdentityPieceTypeSaved.bind(this);
        this.handleIdentityPieceTypeChange = this.handleIdentityPieceTypeChange.bind(this);
        this.handleIdentityPieceTypeClicked = this.handleIdentityPieceTypeClicked.bind(this);

        this.handleCivilityChange = this.handleCivilityChange.bind(this)
        this.handleCivilityCancelClicked = this.handleCivilityCancelClicked.bind(this);
        this.handleCivilitySaved = this.handleCivilitySaved.bind(this);

        this.handleBirthDateCancel = this.handleBirthDateCancel.bind(this);
        this.handleBirthDateSaved = this.handleBirthDateSaved.bind(this);
        this.handleBirthDateChange = this.handleBirthDateChange.bind(this);
        this.handleBirthDateClicked = this.handleBirthDateClicked.bind(this);

        this.handleBirthLocationCancel = this.handleBirthLocationCancel.bind(this);
        this.handleBirthLocationSaved = this.handleBirthLocationSaved.bind(this);
        this.handleBirtLocationChange = this.handleBirtLocationChange.bind(this);
        this.handleBirthLocationClicked = this.handleBirthLocationClicked.bind(this);

        this.handleBirthCountryCancel = this.handleBirthCountryCancel.bind(this);
        this.handleBirthCountrySaved = this.handleBirthCountrySaved.bind(this);
        this.handleBirthCountryChange = this.handleBirthCountryChange.bind(this);
        this.handleBirthCountryClicked = this.handleBirthCountryClicked.bind(this);

        this.handlePhoneNumberCancel = this.handlePhoneNumberCancel.bind(this);
        this.handlePhoneNumberSaved = this.handlePhoneNumberSaved.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handlePhoneNumberClicked = this.handlePhoneNumberClicked.bind(this);

        this.handleCountryCancel = this.handleCountryCancel.bind(this);
        this.handleCountrySaved = this.handleCountrySaved.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCountryClicked = this.handleCountryClicked.bind(this);


        this.verifyProfessionalStatusChanged = this.verifyProfessionalStatusChanged.bind(this);
        this.handleProfessionalStatusChange = this.handleProfessionalStatusChange.bind(this);
        this.handleActivitySectorChange = this.handleActivitySectorChange.bind(this);
        this.handleBceOrTvaChange = this.handleBceOrTvaChange.bind(this);
        this.handleOtherStatusChange = this.handleOtherStatusChange.bind(this);
        this.handleProfessionalStatusCancelClicked = this.handleProfessionalStatusCancelClicked.bind(this);
        this.handleProfessionalStatusSaved = this.handleProfessionalStatusSaved.bind(this);

        this.handleNationalityChange = this.handleNationalityChange.bind(this)
        this.handleNationalityCancelClicked = this.handleNationalityCancelClicked.bind(this);
        this.handleNationalitySaved = this.handleNationalitySaved.bind(this);

        this.handleAdminCommentChanged = this.handleAdminCommentChanged.bind(this);
        this.changeComment = this.changeComment.bind(this);
        this.handleDocumenTextChanged = this.handleDocumenTextChanged.bind(this);

    }

    componentDidMount() {
        // Fill activitySecotrs
        let activitySectors = [];
        for (let i = 1; i <= 85; i++) {
            activitySectors.push(super.translate("ActivitySector" + i));
        }
        activitySectors.sort((a, b) => {
            return a.localeCompare(b);
        });

        // Fill professional status
        let professionalStatus = [];
        for (let i = 0; i <= 6; i++) {
            professionalStatus.push(super.translate("ProfessionalStatus" + i));
        }

        // Fill EEC countries
        let eecCountries = [];
        for (let i = 1; i <= 247; i++) {
            eecCountries.push(super.translate("CountryEEC" + i));
        }
        eecCountries.sort((a, b) => {
            return a.localeCompare(b);
        });
        // Fill countries
        let countries = [];
        for (let i = 1; i <= 32; i++) {
            countries.push(super.translate("Country" + i));
        }
        countries.push(super.translate("CountryEEC111"));
        countries.sort((a, b) => {
            return a.localeCompare(b);
        });
        // Fill civil status
        let civilStatus = [];
        for (let i = 1; i <= 6; i++) {
            civilStatus.push(super.translate("CivilStatus" + i));
        }

        // Fill matrimonial regime
        let matrimonialRegime = [];
        for (let i = 1; i <= 4; i++) {
            matrimonialRegime.push(super.translate("MatrimonialRegime" + i));
        }


        this.setField({ civilStatusArray: civilStatus, matrimonialRegimes: matrimonialRegime, countries: countries, eecCountries: eecCountries, activities: activitySectors, professionalStatuses: professionalStatus })
        this.refresh();



    }

    refresh() {
        this.usersOperation.getUserById(this.state().userId, this.handleSuccessUserCallback);
    }

    verifyCivilStatusChanged() {
        let state = this.state();
        let oldProfile = state.oldProfile;
        if (state.profile.matrimonialRegime !== oldProfile.matrimonialRegime || state.profile.civilStatus !== oldProfile.civilStatus ||
            state.profile.otherMatrimonialRegime !== oldProfile.otherMatrimonialRegime || state.profile.conjointName !== oldProfile.conjointName) {
            state.civilStatusDisabled = false;
        } else {
            state.civilStatusDisabled = true;
        }
        this.setField(state);
    }

    verifyProfessionalStatusChanged() {
        let state = this.state();
        let oldProfile = state.oldProfile;
        if (state.profile.professionalStatus !== oldProfile.professionalStatus || state.profile.activitySector !== oldProfile.activitySector ||
            state.profile.bceOrTva !== oldProfile.bceOrTva || state.profile.status !== oldProfile.status) {
            state.professionelStatusDisabled = false;
        } else {
            state.professionelStatusDisabled = true;
        }
        this.setField(state);
    }

    handleFatcaChange(fatca: any) {
        let oldProfile = this.state().oldProfile;
        let profile = this.state().profile;
        profile.fatca = fatca.value;
        if (oldProfile.fatca !== profile.fatca) {
            this.setField({ profile, fatcaDisable: false });
        } else {
            this.setField({ profile, fatcaDisable: true });
        }
    }

    handleBirthDateChange(bornDate: string) {
        let profile = this.state().profile;
        profile.birthDate = bornDate;
        this.setField({ profile });
    }

    handleBirtLocationChange(value: string) {
        let profile = this.state().profile;
        profile.birthLocation = value;
        this.setField({ profile });
    }

    handleBirthCountryChange(value: string) {
        let profile = this.state().profile;
        profile.birthCountry = value;
        this.setField({ profile });
    }

    handlePhoneNumberChange(value: string) {
        let profile = this.state().profile;
        profile.phoneNumber = value;
        this.setField({ profile });
    }

    handleCountryChange(value: string) {
        let profile = this.state().profile;
        profile.address.country = value;
        this.setField({ profile });
    }

    handleCivilityChange(civility: any) {
        let profile = this.state().profile;
        profile.civility = civility.value;
        this.setField({ profile: profile }, () => {
            if (this.state().profile.civility !== this.state().oldProfile.civility) {
                this.setField({ civilityDisabled: false })
            } else {
                this.setField({ civilityDisabled: true })
            }
        });
    }

    handleNationalityChange(nationality: any) {
        let profile = this.state().profile;
        let identityPieceTypes;
        profile.nationality = nationality.value;
        if (nationality.value == this.translate("Country3")) {
            identityPieceTypes = [this.translate("IdentityCard"), this.translate("ECard"), this.translate("EPlusCard"), this.translate("FCard"), this.translate("ResidentPermit")];
        }
        else {
            identityPieceTypes = [this.translate("IdentityCard"), this.translate("Passport")];
        }
        this.setField({ identityPieceTypes, profile: profile }, () => {
            if (this.state().profile.nationality !== this.state().oldProfile.nationality) {
                this.setField({ nationalityDisabled: false })
            } else {
                this.setField({ nationalityDisabled: true })
            }
        });
    }

    handleCivilStatusChange(civilStatus: any, callback: Function) {
        if (civilStatus.value != this.translate("CivilStatus1") && civilStatus.value != this.translate("CivilStatus2")) {
            let profile = this.state().profile;
            profile.civilStatus = civilStatus.value;
            profile.matrimonialRegime = '';
            profile.conjointName = '';
            this.setField({ profile: profile }, callback);
        }
        else {
            let profile = this.state().profile;
            profile.civilStatus = civilStatus.value;
            this.setField({ profile: profile });
        }
        this.verifyCivilStatusChanged();
    }

    handlePpeChange(ppe: any) {
        let oldProfile = this.state().oldProfile;
        let profile = this.state().profile;
        profile.ppe = ppe.value;
        if (oldProfile.ppe !== profile.ppe) {
            this.setField({ profile, ppeDisable: false });
        } else {
            this.setField({ profile, ppeDisable: true });
        }
    }

    handleOffshoreResidenceChange(fiscalResidence: any) {
        let oldProfile = this.state().oldProfile;
        let profile = this.state().profile;
        profile.hasFiscalResidence = fiscalResidence.value;
        if (oldProfile.hasFiscalResidence !== profile.hasFiscalResidence) {
            this.setField({ profile, offShoreResidenceDisabled: false });
        } else {
            this.setField({ profile, offShoreResidenceDisabled: true });
        }
    }

    handleFiscalResidenceChange(fiscalResidence: Address) {
        let profile = this.state().profile;
        profile.fiscalResidence = fiscalResidence;
        this.setField({ profile: profile }, this.verifyFiscalResidenceChange());
    }

    verifyFiscalResidenceChange() {
        let currentFisc = this.state().profile.fiscalResidence;
        let oldFisc = this.state().oldProfile.fiscalResidence;
        let res = currentFisc?.street === oldFisc.street &&
            currentFisc?.number === oldFisc.number &&
            currentFisc?.box === oldFisc.box &&
            currentFisc?.postCode === oldFisc.postCode &&
            currentFisc?.locality === oldFisc.locality &&
            currentFisc?.country === oldFisc.country;
        res ? this.setField({ offShoreResidenceDisabled: true }) : this.setField({ offShoreResidenceDisabled: false });


    }

    handlePpeReasonChange(ppeReason: any) {
        let oldProfile = this.state().oldProfile;
        let profile = this.state().profile;
        profile.ppeReason = ppeReason;
        if (oldProfile.ppeReason !== profile.ppeReason) {
            this.setField({ profile, ppeDisable: false });
        } else {
            this.setField({ profile, ppeDisable: true });
        }
    }

    handleNationalNumberChange(nationalNumber: any) {
        let profile = this.state().profile;
        if (RegExp.BE_NATIONA_NUMBER2.test(nationalNumber)) {
            let formated = nationalNumber.slice(0, 2) + '.' + nationalNumber.slice(2, 4) + '.' + nationalNumber.slice(4, 6) + '-' + nationalNumber.slice(6, 9) + '.' + nationalNumber.slice(9, 11);
            profile.nationalNumber = formated;
        } else {
            profile.nationalNumber = nationalNumber;
        }

        this.setField({ profile: profile });
    }

    handleIdentityPieceNumberChange(identityPieceNumber: any) {
        let profile = this.state().profile;
        if (this.state().profile.nationality == this.translate("Country3") && RegExp.BE_ID_CARD2.test(identityPieceNumber)) {
            let formatedIdentityNumber = identityPieceNumber.slice(0, 3) + '-' + identityPieceNumber.slice(3, 10) + '-' + identityPieceNumber.slice(10, 12);
            profile.identityPieceNumber = formatedIdentityNumber;
            this.setField({ profile: profile });
        } else if (this.state().profile.nationality == this.translate("Country3") && (this.state().profile.identityPieceType == this.translate("ResidentPermit") || this.state().profile.identityPieceType == this.translate("ECard") || this.state().profile.identityPieceType == this.translate("EPlusCard") || this.state().profile.identityPieceType == this.translate("FCard")) && RegExp.BE_ID_CARD3.test(identityPieceNumber)) {
            if (this.state().profile.identityPieceType == this.translate("ResidentPermit")) {
                let formatedIdentityNumber = identityPieceNumber.slice(0, 7) + " " + identityPieceNumber.slice(7, 9);
                profile.identityPieceNumber = formatedIdentityNumber;
                this.setField({ profile: profile });
            } else if (this.state().profile.identityPieceType == this.translate("ECard") || this.state().profile.identityPieceType == this.translate("EPlusCard") || this.state().profile.identityPieceType == this.translate("FCard")) {
                let formatedIdentityNumber = 'B' + identityPieceNumber.slice(0, 7) + ' ' + identityPieceNumber.slice(7, 9);
                profile.identityPieceNumber = formatedIdentityNumber;
                this.setField({ profile: profile });
            }
        } else if (this.state().profile.nationality == this.translate("Country3") && (this.state().profile.identityPieceType == this.translate("ResidentPermit") || this.state().profile.identityPieceType == this.translate("ECard") || this.state().profile.identityPieceType == this.translate("EPlusCard") || this.state().profile.identityPieceType == this.translate("FCard")) && RegExp.BE_ID_CARD4.test(identityPieceNumber)) {
            if (this.state().profile.identityPieceType == this.translate("ResidentPermit")) {
                let formatedIdentityNumber = identityPieceNumber.slice(1, 8) + " " + identityPieceNumber.slice(8, 10);
                profile.identityPieceNumber = formatedIdentityNumber;
                this.setField({ profile: profile });
            } else if (this.state().profile.identityPieceType == this.translate("ECard") || this.state().profile.identityPieceType == this.translate("EPlusCard") || this.state().profile.identityPieceType == this.translate("FCard")) {
                let formatedIdentityNumber = identityPieceNumber.slice(0, 8) + ' ' + identityPieceNumber.slice(8, 10);
                profile.identityPieceNumber = formatedIdentityNumber;
                this.setField({ profile: profile });
            }
        }
        else {
            profile.identityPieceNumber = identityPieceNumber;
            this.setField({ profile: profile });
        }
    }

    handleIdentityPieceValidityChange(value: any) {
        let state = this.state();
        state.profile.identityPieceValidity = value;
        this.setField(state);
    }

    handleIdentityPieceAuthorityChange(value: any) {
        let state = this.state();
        state.profile.identityPieceAuthority = value;
        this.setField(state);
    }

    handleAdminCommentChanged(value: any) {
        let state = this.state();
        state.profile.adminComment = value;
        this.setField(state);
    }

    handleIdentityPieceTypeChange(identityPieceType: any) {
        let profile = this.state().profile;
        profile.identityPieceType = identityPieceType.value;

        this.setField({ profile: profile }, () => {
            if (this.state().profile.identityPieceType !== this.state().oldProfile.identityPieceType) {
                this.setField({ identityPieceTypeDisabled: false })
            } else {
                this.setField({ identityPieceTypeDisabled: true })
            }
        });
    }

    handleProfessionalStatusChange(professionalStatus: any) {
        let profile = this.state().profile;
        profile.professionalStatus = professionalStatus.value;
        this.setField({ profile: profile }, this.verifyProfessionalStatusChanged());

    }

    handleActivitySectorChange(activitySector: any) {
        let profile = this.state().profile;
        profile.activitySector = activitySector.value;
        this.setField({ profile: profile }, this.verifyProfessionalStatusChanged());

    }

    handleBceOrTvaChange(bce: any) {
        let profile = this.state().profile;
        profile.bceOrTva = bce;
        this.setField({ profile: profile }, this.verifyProfessionalStatusChanged());

    }

    handleOtherStatusChange(status: any) {
        let profile = this.state().profile;
        profile.status = status;
        this.setField({ profile: profile }, this.verifyProfessionalStatusChanged());

    }

    handleMatrimonialRegimeChange(matrimonialRegime: any, callback: Function) {
        let profile = this.state().profile;
        profile.matrimonialRegime = matrimonialRegime.value;
        this.setField({ profile: profile }, callback);
        this.verifyCivilStatusChanged();
    }

    handleConjointNameChange(conjointName: string) {
        let profile = this.state().profile;
        profile.conjointName = conjointName;
        this.setField({ profile: profile });
        this.verifyCivilStatusChanged();
    }


    handleOtherMatrimonialRegimeChange(otherMatrimonialRegime: string) {
        let profile = this.state().profile;
        profile.otherMatrimonialRegime = otherMatrimonialRegime;
        this.setField({ profile: profile });
        this.verifyCivilStatusChanged();
    }

    getBoolean(value: boolean) {
        return this.state().yesNo.filter(yn => yn.value == value)[0].label as string;
    }

    ValidUser() {

        this.showPopUp(
            <UsersPopUpViewView callBack={this.ValidUserService} />
        );
    }

    ValidUserService() {
        this.setField({ isLoading: true });
        let user = { AspNetUserId: this.state().profile.aspNetUserId };

        this.usersOperation.validUser(user, () => {
            var usr = this.state().profile;
            usr.beeBondsStatus = "Validated";
            this.setField({ profile: usr });
            this.setField({ isLoading: false });
        });
    }

    ChangeStatus() {
        let statusId = this.state().selectedStatus.id;
        let text = this.state().changeStatusText;      
        
        if(statusId == 35 && text == "") {
            alert("Attention veuillez remplire le texte");
            return;
        }

        this.parameters<any>().userStatus = this.state().selectedStatus;
        this.usersOperation.updateUserStatus({
            "userId": this.state().profile.id,
            "statusId": statusId,
            "text" : text
        }).then(() => {
            // tappfiliate
            if (this.state().selectedStatus.id == 2) {
                let id = this.state().profile.clientNumber;
                (window as any).tap('create', '41178-800dee', { integration: "javascript" });
                (window as any).tap('conversion', 'AccountValidated-' + id, 1, { customer_id: id });
            }

            this.showPopUp(
                <ProjectInvestementStatusUpdatedView />
            );
        }).then(() => {
            setTimeout(() => { this.NavigationManager.navigate('/dashboard/users/', true) }, 1000);
            setTimeout(() => { this.closePopUp() }, 1500);
        });
    }

    ResetAvertiStatus() {
        this.showPopUp(
            <ResetAvertiStatusView callBackValid={() => {
                this.parameters<any>().userStatus = this.state().selectedStatus;
                this.usersOperation.resetUserAvertiStatus(this.state().profile.id).then(() => {  
                    this.showPopUp(
                        <ProjectInvestementStatusUpdatedView />
                    );
                }).then(() => {
                    setTimeout(() => { this.NavigationManager.navigate('/dashboard/users/', true) }, 1000);
                    setTimeout(() => { this.closePopUp() }, 1500);
                });
            }}  />
        );
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value });
    }

    CheckImage(url: any) {
        if (url == undefined || url == null || url == "")
            return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/no-picture.jpg";

        var urlLower = url.toLowerCase();
        if (urlLower.includes("png") || urlLower.includes("jpg") || urlLower.includes("jpeg"))
            return url;

        else if (urlLower.includes("pdf") || urlLower.includes("doc"))
            return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/pdf.png";

        return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/no-picture.jpg";
    }

    handleSuccessUserCallback(user: any) {
        new StatusOperation().getStatuses("P", (statusList: any) => {
            var statuses = [{ id: 0, title: "", users: null }, ...statusList.filter((x: any) =>
                x.title != "PENDING"
                && x.title != "NEW"
                && x.title != "FIELDS TO VERIFY"
                && x.title != "ID UPLOADED"
                && x.title != "POD UPLOADED"
                && x.title != "POB UPLOADED"
                && x.title != "VALIDATED TO VERIFY"
                && x.title != user.userStatus.title)];
            var ordering = {} as any, // map for efficient lookup of sortIndex
                sortOrder = ['VALIDATED', 'DOC MISSING - ALL', 'DOC MISSING - ID', 'DOC MISSING - POD', 'DOC MISSING - POB', 'BLOCKED', 'REFUSED'];
            for (var i = 0; i < sortOrder.length; i++) {
                ordering[sortOrder[i]] = i;
            }
            statuses.sort(function (a, b) {
                return (ordering[a.title] - ordering[b.title]) || a.title.localeCompare(b.title);
            });
            this.setField({ statuses: statuses });
        });


        user.idRectoUrlImage = this.CheckImage(user.idRectoUrl);
        user.idVersoUrlImage = this.CheckImage(user.idVersoUrl);
        user.domiciliationUrlImage = this.CheckImage(user.domiciliationUrl);
        user.proofOfBankImage = this.CheckImage(user.proofOfBank);
        user.identificationCertificateImage = this.CheckImage(user.identificationCertificateUrl);
        let state = this.state();

        state.profile = user;
        state.isLoading = false;

        state.oldLastName = user.lastName;
        state.oldFirstName = user.firstName;
        if(!user.address) {
            user.address = {
                street : "",
                number: "",
                box: "",
                postCode: "",
                locality: "",
            }
        }
        state.oldStreet = user.address.street;
        state.oldNumber = user.address.number;
        state.oldBox = user.address.box;
        state.oldPostCode = user.address.postCode;
        state.oldLocality = user.address.locality;
        state.oldNationalNumber = user.nationalNumber;
        state.oldIban = user.iban;
        state.oldBic = user.bic;

        state.oldProfile = JSON.parse(JSON.stringify(user));
        let fiscResidence = state.oldProfile.fiscalResidence;
        if (fiscResidence) {
            if (fiscResidence.street === null) fiscResidence.street = "";
            if (fiscResidence.number === null) fiscResidence.number = "";
            if (fiscResidence.box === null) fiscResidence.box = "";
            if (fiscResidence.postCode === null) fiscResidence.postCode = "";
            if (fiscResidence.locality === null) fiscResidence.locality = "";
            if (fiscResidence.country === null) fiscResidence.country = "";
        }
        if (user.nationality != this.translate("Country3")) {
            this.setField({ identityPieceTypes: [this.translate("IdentityCard"), this.translate("Passport")] });
        } else {
            this.setField({ identityPieceTypes: [this.translate("IdentityCard"), this.translate("ECard"), this.translate("EPlusCard"), this.translate("FCard"), this.translate("ResidentPermit")] });
        }
        this.setField(state);
    }


    handleNationalNumberClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldNationalNumber = this.state().profile.nationalNumber;
            this.disableAllEditableFields();
            this.setField({ nationalNumberDisabled: false });
        }
    }

    handleIdentityPieceNumberClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ identityPieceNumberDisabled: false });
        }
    }

    handleIdentityPieceValidityClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ identityPieceValidityDisabled: false });
        }
    }

    handleIdentityPieceAuthorityClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ identityPieceAuthorityDisabled: false });
        }
    }

    handleIdentityPieceTypeClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ identityPieceTypeDisabled: false });
        }
    }

    handleBirthDateClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ birthDateDisabled: false });
        }
    }

    handleBirthLocationClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ birthLocationDisabled: false });
        }
    }

    handleBirthCountryClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ birthCountryDisabled: false });
        }
    }

    handlePhoneNumberClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ phoneNumberDisabled: false });
        }
    }

    handleCountryClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.disableAllEditableFields();
            this.setField({ countryDisabled: false });
        }
    }

    /* LastName Edit */
    handleLastNameFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldLastName = this.state().profile.lastName;
            this.disableAllEditableFields();
            this.setField({ lastNameDisabled: false });
        }
    }


    handleNationalNumberCancelClicked() {
        this.setField({ nationalNumberDisabled: true });
        this.state().profile.nationalNumber = this.state().oldNationalNumber;
    }

    handleIdentityPieceNumberCancel() {
        this.setField({ identityPieceNumberDisabled: true });
        this.state().profile.identityPieceNumber = this.state().oldProfile.identityPieceNumber;
    }

    handleIdentityPieceAuthorityCancel() {
        this.setField({ identityPieceAuthorityDisabled: true });
        this.state().profile.identityPieceAuthority = this.state().oldProfile.identityPieceAuthority;
    }

    handleIdentityPieceTypeCancel() {
        let state = this.state();
        state.identityPieceTypeDisabled = true;
        state.profile.identityPieceType = state.oldProfile.identityPieceType;
        this.setField(state);
    }

    handleIdentityPieceValidityCancel() {
        this.setField({ identityPieceValidityDisabled: true });
        this.state().profile.identityPieceValidity = this.state().oldProfile.identityPieceValidity;
    }

    handleBirthDateCancel() {
        this.setField({ birthDateDisabled: true });
        this.state().profile.birthDate = this.state().oldProfile.birthDate;
    }

    handleBirthLocationCancel() {
        this.setField({ birthLocationDisabled: true });
        this.state().profile.birthLocation = this.state().oldProfile.birthLocation;
    }

    handleBirthCountryCancel() {
        this.setField({ birthCountryDisabled: true });
        this.state().profile.birthCountry = this.state().oldProfile.birthCountry;
    }

    handlePhoneNumberCancel() {
        this.setField({ phoneNumberDisabled: true });
        this.state().profile.phoneNumber = this.state().oldProfile.phoneNumber;
    }

    handleCountryCancel() {
        this.setField({ countryDisabled: true });
        this.state().profile.address.country = this.state().oldProfile.address.country;
    }

    handleFatcaCancelClicked() {
        let state = this.state();
        state.fatcaDisable = true;
        state.profile.fatca = state.oldProfile.fatca;
        this.setField(state);
    }

    handleCivilStatusCancelClicked() {
        let state = this.state();
        state.civilStatusDisabled = true;
        state.profile.civilStatus = state.oldProfile.civilStatus;
        state.profile.matrimonialRegime = state.oldProfile.matrimonialRegime;
        state.profile.otherMatrimonialRegime = state.oldProfile.otherMatrimonialRegime;
        state.profile.conjointName = state.oldProfile.conjointName;
        this.setField(state);

    }

    handleProfessionalStatusCancelClicked() {
        let state = this.state();
        state.professionelStatusDisabled = true;
        state.profile.professionalStatus = state.oldProfile.professionalStatus;
        state.profile.activitySector = state.oldProfile.activitySector;
        state.profile.status = state.oldProfile.status;
        state.profile.bceOrTva = state.oldProfile.bceOrTva;
        this.setField(state);

    }

    handleCivilityCancelClicked() {
        let state = this.state();
        state.civilityDisabled = true;
        state.profile.civility = state.oldProfile.civility;
        this.setField(state);
    }

    handleNationalityCancelClicked() {
        let state = this.state();
        state.nationalityDisabled = true;
        state.profile.nationality = state.oldProfile.nationality;
        this.setField(state, () => {
            let identityPieceTypes;
            if (state.profile.nationality == this.translate("Country3")) {
                identityPieceTypes = [this.translate("IdentityCard"), this.translate("ECard"), this.translate("EPlusCard"), this.translate("FCard"), this.translate("ResidentPermit")];
            }
            else {
                identityPieceTypes = [this.translate("IdentityCard"), this.translate("Passport")];
            }
            this.setField({ identityPieceTypes })
        });
    }

    handlePpeCancelClicked() {
        let state = this.state();
        state.ppeDisable = true;
        state.profile.ppe = state.oldProfile.ppe;
        state.profile.ppeReason = state.oldProfile.ppeReason;
        this.setField(state);
    }

    handleFiscalResidenceCancelClicked() {
        let state = this.state();
        state.offShoreResidenceDisabled = true;
        state.profile.hasFiscalResidence = state.oldProfile.hasFiscalResidence;
        this.adressBackToOld()
        this.setField(state);

    }

    handleLastNameCancelClicked() {
        this.setField({ lastNameDisabled: true });
        this.state().profile.lastName = this.state().oldLastName;
    }


    handleLastNameChanged(lastName: string) {
        let state = this.state();
        state.profile.lastName = lastName;
        this.setField(state);
    }

    handleNationalNumberSaved() {
        let profile = this.state().profile;
        let nationalNumber = profile.nationalNumber;
        if (nationalNumber == "" || nationalNumber == undefined) {
            nationalNumber = "EMPTY"
        }
        this.setField({ isLoading: true });
        let user = { nationalNumber: nationalNumber, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });

    }

    handleIdentityPieceNumberSaved() {
        this.setField({ isLoading: true });
        let user = { identityPieceNumber: this.state().profile.identityPieceNumber, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleIdentityPieceValiditySaved() {
        this.setField({ isLoading: true });
        let user = { identityPieceValidity: this.state().profile.identityPieceValidity, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleIdentityPieceAuthoritySaved() {
        this.setField({ isLoading: true });
        let user = { identityPieceAuthority: this.state().profile.identityPieceAuthority, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleIdentityPieceTypeSaved() {
        this.setField({ isLoading: true });
        let user = { identityPieceType: this.state().profile.identityPieceType, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleBirthDateSaved() {
        this.setField({ isLoading: true });
        let user = { birthDate: this.state().profile.birthDate, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleBirthLocationSaved() {
        this.setField({ isLoading: true });
        let user = { birthLocation: this.state().profile.birthLocation, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleBirthCountrySaved() {
        this.setField({ isLoading: true });
        let user = { birthCountry: this.state().profile.birthCountry, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handlePhoneNumberSaved() {
        this.setField({ isLoading: true });
        let user = { phoneNumber: this.state().profile.phoneNumber, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleCountrySaved() {
        this.setField({ isLoading: true });
        let user = { Address: { Country: this.state().profile.address.country }, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleFatcaSaved() {
        this.setField({ isLoading: true });
        let user = { fatca: this.state().profile.fatca, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleCivilStatusSaved() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let user = { civilStatus: profile.civilStatus, matrimonialRegime: profile.matrimonialRegime, otherMatrimonialRegime: profile.otherMatrimonialRegime, conjointName: profile.conjointName, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    changeComment() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let user = { adminComment: profile.adminComment, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }


    handleProfessionalStatusSaved() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let user = { professionalStatus: profile.professionalStatus, activitySector: profile.activitySector, status: profile.status, bceOrTva: profile.bceOrTva, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleCivilitySaved() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let user = { civility: profile.civility, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleNationalitySaved() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let user = { nationality: profile.nationality, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handlePpeSaved() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let ppeReason = profile.ppeReason;
        if (ppeReason == "" || ppeReason == undefined) {
            ppeReason = "EMPTY"
        }
        let user = { ppe: profile.ppe, ppeReason: ppeReason, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleFiscalResidenceSaved() {
        this.setField({ isLoading: true });
        let profile = this.state().profile;
        let user = { fiscalResidence: profile.fiscalResidence, hasFiscalResidence: profile.hasFiscalResidence, AspNetUserId: profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleLastNameSaved() {
        this.setField({ isLoading: true });

        let user = { lastName: this.state().profile.lastName, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }



    handleIbanClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldIban = this.state().profile.iban;
            this.disableAllEditableFields();
            this.setField({ ibanDisabled: false });
        }
    }

    handleBicClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldBic = this.state().profile.bic;
            this.disableAllEditableFields();
            this.setField({ bicDisabled: false });
        }
    }

    handleHowDidIHeardClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldDidIHear = this.state().profile.howDoIHeardAboutBeeBonds;
            this.disableAllEditableFields();
            this.setField({ howDidIHearDisabled: false });
        }
    }

    handleHowDidIHeardChange(howDoIHeardAboutBeeBonds: any) {
        let state = this.state();
        state.profile.howDoIHeardAboutBeeBonds = howDoIHeardAboutBeeBonds;
        this.setField(state);
    }

    handleHowDidIHeardCancelClicked() {
        this.setField({ howDidIHearDisabled: true });
        this.state().profile.howDoIHeardAboutBeeBonds = this.state().oldDidIHear;
    }

    handleHowDidIHeardSaved() {
        this.setField({ isLoading: true });
        let how = this.state().profile.howDoIHeardAboutBeeBonds;
        if (how == "" || how == undefined) {
            how = "EMPTY";
        }

        let user = { HowDoIHeardAboutBeeBonds: how, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    /* FirstName Edit */
    handleFirstNameFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldFirstName = this.state().profile.firstName;
            this.disableAllEditableFields();
            this.setField({ firstNameDisabled: false });
        }
    }

    handleIbanCancelClicked() {
        this.setField({ ibanDisabled: true });
        this.state().profile.iban = this.state().oldIban;
    }

    handleBicCancelClicked() {
        this.setField({ bicDisabled: true });
        this.state().profile.bic = this.state().oldBic;
    }

    handleFirstNameCancelClicked() {
        this.setField({ firstNameDisabled: true });
        this.state().profile.firstName = this.state().oldFirstName;
    }

    handleFirstNameChanged(firstName: string) {
        let state = this.state();
        state.profile.firstName = firstName;
        this.setField(state);
    }

    handleIbanChanged(iban: string) {
        let ibanFormatted = this.formatIban(iban);
        let state = this.state();
        state.profile.iban = ibanFormatted;
        this.setField(state);
    }

    handleDocumenTextChanged(text: string) {
        let state = this.state();
        state.changeStatusText = text;
        this.setField(state);
    }

    formatIban(iban: string) {
        iban = iban.replace(/\s/g, '');
        if (iban.length > 4) {
            let ibanCopy = "";
            for (let i = 0; i < iban.length; i++) {
                if (i !== 0 && i % 4 === 0) {
                    ibanCopy += " "
                }
                ibanCopy += iban[i]

            }
            return ibanCopy.toLocaleUpperCase();
        }
        return iban;
    }

    handleBicChanged(bic: string) {
        let state = this.state();
        state.profile.bic = this.formatBic(bic);;
        this.setField(state);
    }

    formatBic(bicToCheck: string) {
        bicToCheck = bicToCheck.replace(/\s/g, '');
        if (bicToCheck.length === 8) {
            let bicCopy = "";
            bicCopy += bicToCheck.substring(0, 4) + " " + bicToCheck.substring(4, 6) + " " + bicToCheck.substring(6, 8);
            return bicCopy.toLocaleUpperCase();
        } else if (bicToCheck.length === 11) {
            let bicCopy = "";
            bicCopy += bicToCheck.substring(0, 4) + " " + bicToCheck.substring(4, 6) + " " + bicToCheck.substring(6, 8) + " " + bicToCheck.substring(8, 11);
            return bicCopy.toLocaleUpperCase();
        }
        return bicToCheck;
    }

    handleIbanSaved() {
        this.setField({ isLoading: true });
        let user = { iban: this.state().profile.iban, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleBicSaved() {
        this.setField({ isLoading: true });
        let user = { bic: this.state().profile.bic, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    handleFirstNameSaved() {
        this.setField({ isLoading: true });

        let user = { firstName: this.state().profile.firstName, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    /* Street Edit */
    handleStreetFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldStreet = this.state().profile.address.street;
            this.disableAllEditableFields();
            this.setField({ streetDisabled: false });
        }
    }

    handleStreetCancelClicked() {
        this.setField({ streetDisabled: true });
        this.state().profile.address.street = this.state().oldStreet;
    }

    handleStreetChanged(street: string) {
        let state = this.state();
        state.profile.address.street = street;
        this.setField(state);
    }

    handleStreetSaved() {
        this.setField({ isLoading: true });

        let user = { Address: { Street: this.state().profile.address.street }, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    /* Number Edit */
    handleNumberFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldNumber = this.state().profile.address.number;
            this.disableAllEditableFields();
            this.setField({ numberDisabled: false });
        }
    }

    handleNumberCancelClicked() {
        this.setField({ numberDisabled: true });
        this.state().profile.address.number = this.state().oldNumber;
    }

    handleNumberChanged(number: string) {
        let state = this.state();
        state.profile.address.number = number;
        this.setField(state);
    }

    handleNumberSaved() {
        this.setField({ isLoading: true });

        let user = { Address: { Number: this.state().profile.address.number }, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    /* Box Edit */
    handleBoxFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldBox = this.state().profile.address.box;
            this.disableAllEditableFields();
            this.setField({ boxDisabled: false });
        }
    }

    handleBoxCancelClicked() {
        this.setField({ boxDisabled: true });
        this.state().profile.address.box = this.state().oldBox;
    }

    handleBoxChanged(box: string) {
        let state = this.state();
        state.profile.address.box = box;
        this.setField(state);
    }

    handleBoxSaved() {
        this.setField({ isLoading: true });
        let box = this.state().profile.address.box;
        if (box == "" || box == undefined) {
            box = "EMPTY";
        }
        let user = { Address: { Box: box }, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    /* PostCode Edit */
    handlePostalCodeFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldPostCode = this.state().profile.address.postCode;
            this.disableAllEditableFields();
            this.setField({ postCodeDisabled: false });
        }
    }

    handlePostalCodeCancelClicked() {
        this.setField({ postCodeDisabled: true });
        this.state().profile.address.postCode = this.state().oldPostCode;
    }

    handlePostalCodeChanged(postCode: string) {
        let state = this.state();
        state.profile.address.postCode = postCode;
        this.setField(state);
    }

    handlePostalCodeSaved() {
        this.setField({ isLoading: true });

        let user = { Address: { PostCode: this.state().profile.address.postCode }, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    /* Locality Edit */
    handleLocalityFieldClicked() {
        if (this.RoleManager.isAdmin || this.RoleManager.isEditor) {
            this.state().oldLocality = this.state().profile.address.locality;
            this.disableAllEditableFields();
            this.setField({ localityDisabled: false });
        }
    }

    handleLocalityCancelClicked() {
        this.setField({ localityDisabled: true });
        this.state().profile.address.locality = this.state().oldLocality;
    }

    handleLocalityChanged(locality: string) {
        let state = this.state();
        state.profile.address.locality = locality;
        this.setField(state);
    }

    handleLocalitySaved() {
        this.setField({ isLoading: true });

        let user = { Address: { Locality: this.state().profile.address.locality }, AspNetUserId: this.state().profile.aspNetUserId, ClientNumber: this.user };
        this.usersOperation.updateUser(user, () => {
            //window.location.reload();
            this.setField({ isLoading: false });
            this.disableAllEditableFields();
            this.refresh();
        });
    }

    adressBackToOld() {
        let oldFisc = this.state().oldProfile.fiscalResidence;
        let profile = this.state().profile;
        if (oldFisc && profile.fiscalResidence) {
            profile.fiscalResidence!.street = oldFisc.street;
            profile.fiscalResidence!.number = oldFisc.number;
            profile.fiscalResidence!.box = oldFisc.box;
            profile.fiscalResidence!.postCode = oldFisc.postCode;
            profile.fiscalResidence!.locality = oldFisc.locality;
            profile.fiscalResidence!.country = oldFisc.country;
        }
        this.setField({ profile: profile });
    }

    disableAllEditableFields() {

        let state = this.state();

        state.lastNameDisabled = true;
        state.firstNameDisabled = true;
        state.streetDisabled = true;
        state.numberDisabled = true;
        state.boxDisabled = true;
        state.postCodeDisabled = true;
        state.localityDisabled = true;
        state.nationalNumberDisabled = true;
        state.ibanDisabled = true;
        state.bicDisabled = true;
        state.civilStatusDisabled = true;
        state.howDidIHearDisabled = true;
        state.offShoreResidenceDisabled = true;
        state.ppeDisable = true;
        state.fatcaDisable = true;
        state.identityPieceAuthorityDisabled = true;
        state.identityPieceNumberDisabled = true;
        state.identityPieceValidityDisabled = true;
        state.identityPieceTypeDisabled = true;
        state.civilityDisabled = true;
        state.birthDateDisabled = true;
        state.birthLocationDisabled = true;
        state.birthCountryDisabled = true;
        state.phoneNumberDisabled = true;
        state.countryDisabled = true;
        state.professionelStatusDisabled = true;
        state.nationalityDisabled = true;

        state.profile.lastName = state.oldLastName;
        state.profile.firstName = state.oldFirstName;
        state.profile.address.street = state.oldStreet;
        state.profile.address.number = state.oldNumber;
        state.profile.address.box = state.oldBox;
        state.profile.address.postCode = state.oldPostCode;
        state.profile.address.locality = state.oldLocality;
        state.profile.nationalNumber = state.oldNationalNumber;
        state.profile.iban = state.oldIban;
        state.profile.bic = state.oldBic;
        state.profile.civilStatus = state.oldProfile.civilStatus
        state.profile.matrimonialRegime = state.oldProfile.matrimonialRegime
        state.profile.conjointName = state.oldProfile.conjointName
        state.profile.otherMatrimonialRegime = state.oldProfile.otherMatrimonialRegime
        state.profile.howDoIHeardAboutBeeBonds = state.oldDidIHear;
        state.profile.hasFiscalResidence = state.oldProfile.hasFiscalResidence;
        state.profile.ppe = state.oldProfile.ppe;
        state.profile.ppeReason = state.oldProfile.ppeReason;
        state.profile.fatca = state.oldProfile.fatca;
        state.profile.identityPieceAuthority = state.oldProfile.identityPieceAuthority;
        state.profile.identityPieceNumber = state.oldProfile.identityPieceNumber;
        state.profile.identityPieceValidity = state.oldProfile.identityPieceValidity;
        state.profile.identityPieceType = state.oldProfile.identityPieceType;
        state.profile.civility = state.oldProfile.civility;
        state.profile.birthDate = state.oldProfile.birthDate;
        state.profile.birthLocation = state.oldProfile.birthLocation;
        state.profile.birthCountry = state.oldProfile.birthCountry;
        state.profile.phoneNumber = state.oldProfile.phoneNumber;
        state.profile.address.country = state.oldProfile.address.country;
        state.profile.professionalStatus = state.oldProfile.professionalStatus
        state.profile.status = state.oldProfile.status
        state.profile.activitySector = state.oldProfile.activitySector
        state.profile.bceOrTva = state.oldProfile.bceOrTva
        state.profile.nationality = state.oldProfile.nationality
        this.adressBackToOld();


        this.props().editUserCallBack(state.profile.lastName, state.profile.firstName);

        this.setField(state);
    }
}