let StateManagerConst = {
    USER : "USER",
    ROLE : "ROLE",
    PROJECT : "PROJECT",
    INVESTMENT_USER : "INVESTMENT_USER",
    INVESTMENT_PROJECT : "INVESTMENT_PROJECT",
    INVESTMENT_ENTITY : "INVESTMENT_ENTITY",
    PROJECT_TAB : "PROJECT_TAB",
    LEGAL_ENTITY : "LEGAL_ENTITY",
    USERS_ENTITY : "USERS_ENTITY",
    INVESTMENT_RESERVATION : "INVESTMENT_RESERVATION",
    LEGAL_ENTITIES_USER: "LEGAL_ENTITIES_USER"
    
};
export default StateManagerConst;