import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import CouponsViewState from './CouponsViewState';
import CouponsViewProps from './CouponsViewProps';
import CouponsViewStyle from './CouponsViewStyle';
import CouponsViewModel from './CouponsViewModel';
import TableView from '../../../../../../../../../../Components/table/TableView';
import { View } from 'react-native';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';

export default class CouponsView extends ContentView<CouponsViewProps, CouponsViewState, CouponsViewModel, CouponsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CouponsViewProps) {
    super(props);

    // Binding
    this.bind(new CouponsViewModel(this), new CouponsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        <View style={{ height: 20 }}></View>
        <View style={{ flexDirection: 'row' }}>
          <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} style={this.style.exportButton} handleClick={() => {this.binding.showAddCouponPopUp() }}>
            <TranslationView style={this.style.exportButtonText}>
              Coupons.add
            </TranslationView>
          </ButtonView>
        </View>
        <View style={{ height: 20 }}></View>
        <TableView
          headerData={this.state.tableHeaders}
          rows={this.state.rows}
          numberOfRows={200}
          isSortable={true}
          isLoading={false}
          lottie={require('../../../../assets/loading.json')}
          datas={this.state.datas}
          keyDatas={["aspNetUserId"]}
          headerIndex={[0]}
          nbPage={1}
          currentPage={1}
          onPageChange={() => { }}
          resetStepView={false}
          handleResetStepView={() => { }}
          resetTable={() => { }}
          fillTables={() => { }}
          headerTextStyle={this.style.headerText}
        ></TableView>
      </View>

    );
  }
}