import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import BankPaymentImportPopUpViewState from './BankPaymentImportPopUpViewState';
import BankPaymentImportPopUpViewProps from './BankPaymentImportPopUpViewProps';
import * as XLSX from 'xlsx';
import TransactionInformations from '../../../../../../../../../../../../../DTO/BankTransactionInformations';
import InvestmentsOperation from '../../../../../../../../../../../../../Transfer/InvestmentsOperations';
import ResponseBankTransactionsImportModel from '../../../../../../../../../../../../../DTO/ResponseBankTransactionsImportModel';
import React from 'react';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import BankTransactionInformations from '../../../../../../../../../../../../../DTO/BankTransactionInformations';



export default class BankPaymentImportPopUpViewModel extends ContentViewModel<BankPaymentImportPopUpViewState, BankPaymentImportPopUpViewProps>
{
    investementsOperation = new InvestmentsOperation();
    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            errorLineAmount: 0,
            currentTab: "importSuccess",
            isInvestLoading: false,
            response: {
                unprocessableBankTransactions: [],
                investmentsToUpdate: [],
                notEnoughAmountBankTransactions: [],
            },
            showInvestments: false,
            isLoading: false,
            showError: false,
            showErrorFile: false,
            showErrorEmptyInvest: false,
            showSucces: false,
            showFailedBankTransaction: false,
            bankTransactionsModel: {
                bankTransactionInformationsList: [],
                projectId: this.props().projectId
            },

            resetStepView: false,
            rows: [],
            initialData: [],
            initialRow: [],
            datas: [],
            nbPage: 0,
            currentPage: 1,
            investToUpdate: [],
            nbRows: 10,

            isErrorLoading: false,
            errorDatas: [],
            errorRows: [],
            initialErrorData: [],
            initialErrorRow: [],
            resetStepViewError: false,
            nbErrorPage: 0,
            currentErrorPage: 1,
            nbErrorRows: 5
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleFileChanged = this.handleFileChanged.bind(this);
        this.handleImport = this.handleImport.bind(this)

        this.handleSuccessCallback = this.handleSuccessCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleUpdateButton = this.handleUpdateButton.bind(this);
        this.handleShowErrorButton = this.handleShowErrorButton.bind(this);


    }




    handleBackPressed() {
        this.closePopUp()
        this.props().handleBackPressed();
    }

    handleUpdateButton() {
        this.setField((previousState) => previousState.isLoading = true);
        let invest = this.state().investToUpdate
        if (invest.length > 0) {
            this.investementsOperation.updateMultipleInvestmentStatus(invest, () => {
                this.setField((previousState) => previousState.isLoading = false)
                this.setField((previousState) => previousState.showInvestments = false)
                this.setField((previousState) => previousState.showSucces = true);
            })
        } else {
            this.setField((previousState) => previousState.isLoading = false)
            this.setField((previousState) => previousState.showErrorEmptyInvest = true);
            this.setField((previousState) => previousState.showInvestments = false);
        }
    }


    handleImport() {
        this.setField((previousState) => previousState.investToUpdate = []);
        this.setField((previousState) => previousState.showErrorEmptyInvest = false);
        this.setField((previousState) => previousState.showSucces = false);
        this.setField((previousState) => previousState.showInvestments = true);
        if (this.state().file != null) {
            this.setField((previousState) => previousState.isInvestLoading = true);
            this.investementsOperation.updateInvestmentStatusFromExcel(this.state().bankTransactionsModel, async (response: ResponseBankTransactionsImportModel) => {
                this.setField((previousState) => previousState.response = response);
                this.setField((previousState) => previousState.errorLineAmount = response.notEnoughAmountBankTransactions.length + response.unprocessableBankTransactions.length);
                this.setField((previousState) => previousState.isInvestLoading = false);
                this.setField((previousState) => previousState.investToUpdate = response.investmentsToUpdate);
                this.setField((previousState) => previousState.nbPage = Math.ceil(response.investmentsToUpdate.length / this.state().nbRows));
                this.setField((previousState) => previousState.nbErrorPage = previousState.errorLineAmount / this.state().nbErrorRows);
                this.handleSuccessCallback();

            })
        }
    }

    handleShowErrorButton() {
        this.setField((previousState) => previousState.showFailedBankTransaction = !previousState.showFailedBankTransaction);
    }

    async handleFileChanged(file: File) {
        this.setField((previousState) => previousState.file = file);
        this.handleReadingInformation(file);
    }

    handleReadingInformation(file: File) {
        if (file != undefined) {

            //Read Excel File
            var fileReader = new FileReader();
            fileReader.onloadend = () => {
                // Leave that
                var binary = "";
                var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                this.TransformExcelInformationToData(binary);
            }
            fileReader.readAsArrayBuffer(file)
        }
    }

    async TransformExcelInformationToData(data: any) {
        var rowData = data.split('\n');
        var TransactionInformationsList: TransactionInformations[] = [];
        if (rowData[0].split(';')[0] == "Id") {
            for (var row = 1; row < rowData.length; row++) {
                var rowColData = rowData[row].split(';');
                if (rowColData[0] && rowColData[8]) {
                    TransactionInformationsList.push({
                        id: rowColData[0],
                        iban: rowColData[8].replace(/\B(?=(\d{4})+(?!\d))/g, " "),
                        name: rowColData[4],
                        amount: rowColData[5],
                        communication: rowColData[16],
                    });
                }
            }
            this.setField((previousState) => previousState.showErrorFile = false);
            this.setField((previousState) => previousState.bankTransactionsModel.bankTransactionInformationsList = TransactionInformationsList);
            await new Promise(resolve => setTimeout(resolve, 10));
            this.handleImport();
        }
        else {
            this.setField((previousState) => previousState.showErrorFile = true);
            this.setField((previousState) => previousState.file = undefined);
            this.setField((previousState) => previousState.showInvestments = false);
            this.setField((previousState) => previousState.investToUpdate = []);
        }
        return;

        var workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
        var json: any[] = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 3 });
        var TransactionInformationsList: TransactionInformations[] = [];
        if (json[0] && json[0]["Id"] && json[0]["Counterparty IBAN"] && json[0]["Description"] && json[0]["Amount"] && json[0]["Communication"]) {
            json.forEach((v: any) => {
                TransactionInformationsList.push({
                    id: v["Id"],
                    iban: v["Counterparty IBAN"].replace(/\B(?=(\d{4})+(?!\d))/g, " "),
                    name: v["Description"],
                    amount: v["Amount"],
                    communication: v["Communication"],
                });

            });
            this.setField((previousState) => previousState.showErrorFile = false);
            this.setField((previousState) => previousState.bankTransactionsModel.bankTransactionInformationsList = TransactionInformationsList);
            await new Promise(resolve => setTimeout(resolve, 10));
            this.handleImport();
        } else {
            this.setField((previousState) => previousState.showErrorFile = true);
            this.setField((previousState) => previousState.file = undefined);
            this.setField((previousState) => previousState.showInvestments = false);
            this.setField((previousState) => previousState.investToUpdate = []);
        }


    }

    handleSuccessCallback() {
        this.fillTableInvest();
        this.fillTableError();

    }

    async fillTableInvest() {
        let datas: any[] = [];
        let investments = this.state().investToUpdate;
        let rows: any[] = [];

        //Investment table
        investments.sort((a, b) => a.id - b.id).slice((this.state().currentPage - 1) * this.state().nbRows, (this.state().currentPage) * this.state().nbRows).forEach((investment) => {
            datas.push(investment);
            rows.push(
                <View>
                    <Text >{investment.id}</Text>
                    <Text >{investment.userId}</Text>
                    <Text >{investment.legalEntity ? investment.legalEntity.corporateName : 'Aucune'}</Text>
                    <Text >{investment.user.clientNumber}</Text>
                    <Text >{investment.user.lastName + " " + investment.user.firstName}</Text>
                    <Text >{investment.user.email}</Text>
                    <Text >{investment.amount}</Text>
                    <TouchableWithoutFeedback onPress={() => { this.deleteField(investment.id) }} >
                        <View style={{ alignItems: 'center' }} >
                            <Image source={require('../../../../../../../assets/trash.svg')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ isLoading: false });

    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    async onPageChange(page: number) {
        this.setField((previousState) => previousState.currentPage = page + 1);
        await new Promise(resolve => setTimeout(resolve, 10));
        this.fillTableInvest()
    }

    async deleteField(investID: number) {
        this.setField((previousState) => previousState.investToUpdate = this.state().investToUpdate.filter(x => x.id != investID));
        await new Promise(resolve => setTimeout(resolve, 10));
        this.setField((previousState) => previousState.nbPage = Math.ceil(this.state().investToUpdate.length / this.state().nbRows));
        this.setField((previousState) => previousState.currentPage = 1);
        this.fillTableInvest()
    }

    async fillTableError() {
        let errorDatas: any[] = [];
        let errorDatasTemp: any[] = [];
        let dataErrorAmount = this.state().response.notEnoughAmountBankTransactions;
        dataErrorAmount.forEach((transaction: BankTransactionInformations) => { transaction.errorType = 'amount' })
        let dataErrorNotFound = this.state().response.unprocessableBankTransactions;
        dataErrorNotFound.forEach((transaction: BankTransactionInformations) => { transaction.errorType = 'unknown' })
        errorDatasTemp.push(...dataErrorAmount)
        errorDatasTemp.push(...dataErrorNotFound)
        let errorRows: any[] = [];

        //Investment table
        errorDatasTemp.sort((a, b) => a.id - b.id).slice((this.state().currentErrorPage - 1) * this.state().nbErrorRows, (this.state().currentErrorPage) * this.state().nbErrorRows).forEach((transaction) => {
            errorDatas.push(transaction);
            errorRows.push(
                <View>
                    <Text >{transaction.id}</Text>
                    <Text >{transaction.name}</Text>
                    <Text >{transaction.iban}</Text>
                    <Text >{transaction.amount}</Text>
                    <Text >{transaction.communication}</Text>
                    <Text >{transaction.errorType === 'amount' ? "Montant incorrect" : "Investissement non trouvé"}</Text>
                </View>
            )
        });
        this.setField({ errorDatas: errorDatas });
        this.setField({ errorRows: errorRows });
        this.setField({ initialErrorData: errorDatas.slice(0) });
        this.setField({ initialErrorRow: errorRows });
        this.setField({ isErrorLoading: false });

    }

    handleResetStepViewError() {
        this.setField({ resetStepViewError: false });
    }

    async onErrorPageChange(page: number) {
        this.setField((previousState) => previousState.currentErrorPage = page + 1);
        await new Promise(resolve => setTimeout(resolve, 10));
        this.fillTableError()
    }



}