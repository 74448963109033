import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ImagesViewState from './ImagesViewState';
import ImagesViewProps from './ImagesViewProps';
import ImagesViewStyle from './ImagesViewStyle';
import ImagesViewModel from './ImagesViewModel';
import { View ,Image} from 'react-native';
import DropppableBoxView from './droppableBox/DroppableBoxView';
import { LottieView } from '@levelapp/softfabric-ui';

export default class ImagesView extends ContentView<ImagesViewProps, ImagesViewState, ImagesViewModel, ImagesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ImagesViewProps)
  {
    super(props);

    // Binding
    this.bind(new ImagesViewModel(this), new ImagesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.box}>
        <View style={this.style.elementContainer}>
        {}
          <DropppableBoxView insertImage ={(image : any) => this.binding.insertImage(image)} projectId={this.state.projectId}></DropppableBoxView>
          
          {this.state.isLoading ? <LottieView height = {50} width={50} source={require('./assets/loading.json')}/> :
            <View style={this.style.Images}>
            {this.state.projectImages.map((image: any,index : number) => {
                return (
                  <View key ={index} style={this.style.Image}>
                    <Image source={{ uri: image.url }} style={{ height: 150, width: 150, resizeMode :'contain'}} />  
                  </View>
                ) 
              })
            }
          </View>}
        </View>
      </View>
    );
  }
}