import {ContentViewModel, ContentView, NavigationManager, Application, Managers} from '@levelapp/softfabric';
import LegalEntityDocumentsViewState from './LegalEntityDocumentsViewState';
import LegalEntityDocumentsViewProps from './LegalEntityDocumentsViewProps';
import LegalEntity from '../../../../../../../../../DTO/LegalEntity'
import DocumentsOperation from '../../../../../../../../../Transfer/DocumentsOperations';

export default class LegalEntityDocumentsViewModel extends ContentViewModel<LegalEntityDocumentsViewState, LegalEntityDocumentsViewProps>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    fileUploaded = 0;

    constructor(props: ContentView) {
        super(props);
        var entity = this.parameters<LegalEntity>();

        entity.bceRegistrationImage = this.CheckImage(entity.bceRegistrationUrl);
        entity.leadersNominationImage = this.CheckImage(entity.leadersNominationUrl);
        entity.bankIdUrl = this.CheckImage(entity.bankIdUrl);


        this.initialState({
            legalEntity: entity,
            isLoading: false,
            bceRegistration: undefined,
            leadersNomination: undefined,
            bankId: undefined,
            isIdentificationLoading: false,
            ubo: undefined,
            error: false
        })

        this.handleBceRegistrationChange = this.handleBceRegistrationChange.bind(this);
        this.handleLeadersNominationChange = this.handleLeadersNominationChange.bind(this);
        this.handleBankIdChange = this.handleBankIdChange.bind(this);
        this.handleUboChange = this.handleUboChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.CheckImage = this.CheckImage.bind(this);
    }

    handleBceRegistrationChange(file: File){
        if (this.state().error) {
            this.setField({ bceRegistration: file, error: false });
        }
        else {
            this.setField({ bceRegistration: file, });
        }
    }

    handleLeadersNominationChange(file: File){
        if (this.state().error) {
            this.setField({ leadersNomination: file, error: false });
        }
        else {
            this.setField({ leadersNomination: file, });
        }
    }

    handleBankIdChange(file: File){
        if (this.state().error) {
            this.setField({ bankId: file, error: false });
        }
        else {
            this.setField({ bankId: file, });
        }
    }

    handleUboChange(file: File){
        if (this.state().error) {
            this.setField({ ubo: file, error: false });
        }
        else {
            this.setField({ ubo: file, });
        }
    }

    handleSave() {
        this.setField({ isLoading: true });
        this.saveBceRegistration();
        this.saveLeadersNomination();
        this.saveBankId();
        this.saveUbo();
    }

    saveBceRegistration() {
        if (this.state().bceRegistration != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().bceRegistration as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: -1,
                    LegalEntityId: this.state().legalEntity.id,
                    Type: "BceRegistration"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                    
                });
            };
            reader.readAsDataURL((this.state().bceRegistration as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveLeadersNomination() {
        if (this.state().leadersNomination != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().leadersNomination as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: -1,
                    LegalEntityId: this.state().legalEntity.id,
                    Type: "LeadersNomination"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                    
                });
            };
            reader.readAsDataURL((this.state().leadersNomination as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveBankId() {
        if (this.state().bankId != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().bankId as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: -1,
                    LegalEntityId: this.state().legalEntity.id,
                    Type: "BankId"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                    
                });
            };
            reader.readAsDataURL((this.state().bankId as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    saveUbo() {
        if (this.state().ubo != undefined) {
            let reader = new FileReader();
            reader.onload = (data: any) => {
                new DocumentsOperation().uploadDocuments({
                    Name: (this.state().ubo as File).name,
                    ImageData: data.target.result.split('base64,')[1],
                    UserId: -1,
                    LegalEntityId: this.state().legalEntity.id,
                    Type: "Ubo"
                }, () => {
                    this.checkIfAllFilesHaveBeenUploaded();
                    
                });
            };
            reader.readAsDataURL((this.state().ubo as File));
        }
        else {
            this.checkIfAllFilesHaveBeenUploaded();
        }
    }

    CheckImage(url: any) {
        if (url.includes("png") || url.includes("jpg") || url.includes("jpeg"))
            return url;

        else if (url.includes("pdf"))
            return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/pdf.png";

        return "https://beebondsstorage.blob.core.windows.net/files/consoleImages/no-picture.jpg";
    }

    checkIfAllFilesHaveBeenUploaded() {
        this.fileUploaded++;
        if (this.fileUploaded == 4)
        {
            this.setField({ isLoading: false });
            alert("Vos documents ont été téléchargé");
            this.NavigationManager.navigate('/dashboard/legalentities/edit/information',true, this.state().legalEntity);    
        }
    }
}