import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import UserLegalEntitiesViewState from './UserLegalEntitiesViewState';
import UserLegalEntitiesViewProps from './UserLegalEntitiesViewProps';
import UserLegalEntitiesViewStyle from './UserLegalEntitiesViewStyle';
import UserLegalEntitiesViewModel from './UserLegalEntitiesViewModel';
import TableView from '../../../../../../../../../../../Components/table/TableView';
import { View } from 'react-native';

export default class UserLegalEntitiesView extends ContentView<UserLegalEntitiesViewProps, UserLegalEntitiesViewState, UserLegalEntitiesViewModel, UserLegalEntitiesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:UserLegalEntitiesViewProps)
  {
    super(props);

    // Binding
    this.bind(new UserLegalEntitiesViewModel(this), new UserLegalEntitiesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.Box}>
      <View style={this.style.boxContainer}>
        <TableView
          handleResetStepView={this.binding.handleResetStepView}
          resetStepView={this.state.resetStepView}
          headerData={this.state.tableHeaders}
          rows={this.state.rows}
          numberOfRows={50}
          isSortable={true}
          resetTable={()=>{}}
          datas={this.state.datas}
          fillTables={(value: any[]) => { this.binding.pushRows(value) }}
          keyDatas={["legalEntityId", "Corporatename", "creationDate"]}
          headerIndex={[0, 1, 2, 3]}
          headerTextStyle={this.style.headerText}
          nbPage={1}
          currentPage={1}
          onPageChange={() => {}}
        ></TableView>
      </View>
    </View>
    );
  }
}