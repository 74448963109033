import { Operation } from '@levelapp/softfabric';
import TabService from '../Proxy/TabService';


export default class TabOperation extends Operation
{
    _service : TabService;

    constructor()
    {
        super();
        this._service = new TabService();
    }   

    async getProjectTabOrders(code: string,callback: Function = () => { })
    {
        await (this._service as TabService).getProjectTabOrders(code,callback);
    }

    async getTabById(id : number,callback: Function = () => { })
    {
        await (this._service as TabService).getTabById(id,callback);
    }

    async insertTab(tab : any,callback: Function = () => { })
    {
        await (this._service as TabService).insertTab(tab,callback);
    }

    async updateTab(tab : any,callback: Function = () => { })
    {
        await (this._service as TabService).updateTab(tab,callback);
    }

    async softDelete(tab : any,callback: Function = () => { })
    {
        await (this._service as TabService).softDelete(tab,callback);
    }

    async depublish(tab : any,callback: Function = () => { })
    {
        await (this._service as TabService).depublish(tab,callback);
    }

    async publishStaging(tab : any,callback: Function = () => { })
    {
        await (this._service as TabService).publishStaging(tab,callback);
    }

    async publishProd(tab : any,callback: Function = () => { })
    {
        await (this._service as TabService).publishProd(tab,callback);
    }
}