import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import EditProjectLeaderViewState from './EditProjectLeaderViewState';
import EditProjectLeaderViewProps from './EditProjectLeaderViewProps';
import ProjectsLeaderOperation from '../../../../../../../Transfer/ProjectLeadersOperations';

export default class EditProjectLeaderViewModel extends ContentViewModel<EditProjectLeaderViewState, EditProjectLeaderViewProps>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        var leader = this.parameters<any>();

        this.initialState({
            leader
        }); // Initialize your state here

        // Bindings
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.handlePhoneChanged = this.handlePhoneChanged.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleStreetChanged = this.handleStreetChanged.bind(this);
        this.handleNumberChanged = this.handleNumberChanged.bind(this);
        this.handleBoxChanged = this.handleBoxChanged.bind(this);
        this.handlePostalCodeChanged = this.handlePostalCodeChanged.bind(this);
        this.handleLocalityChanged = this.handleLocalityChanged.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleCivilityChanged = this.handleCivilityChanged.bind(this);
        this.save = this.save.bind(this);
    }

    handleFirstNameChanged(text: string) {
        let state = this.state();
        state.leader.firstName = text;
        this.setField(state);
    }

    handleLastNameChanged(text: string) {
        let state = this.state();
        state.leader.lastName = text;
        this.setField(state);
    }

    handlePhoneChanged(text: string) {
        let state = this.state();
        state.leader.phoneNumber = text;
        this.setField(state);
    }

    handleEmailChanged(text: string) {
        let state = this.state();
        state.leader.email = text;
        this.setField(state);
    }

    handleStreetChanged(street: string) {
        let state = this.state();
        state.leader.address.street = street;
        this.setField(state);
    }

    handleNumberChanged(number: string) {
        let state = this.state();
        state.leader.address.number = number;
        this.setField(state);
    }

    handleBoxChanged(box: string) {
        let state = this.state();
        state.leader.address.box = box;
        this.setField(state);
    }

    handlePostalCodeChanged(postCode: string) {
        let state = this.state();
        state.leader.address.postCode = postCode;
        this.setField(state);
    }

    handleLocalityChanged(locality: string) {
        let state = this.state();
        state.leader.address.locality = locality;
        this.setField(state);
    }

    handleCountryChange(value: string) {
        let leader = this.state().leader;
        leader.address.country = value;
        this.setField({ leader });
    }

    handleCivilityChanged(value: string) {
        let leader = this.state().leader;
        leader.civility = value;
        this.setField({ leader });
    }


    save() {
        let leader = this.state().leader;
        if(!leader.lastName || !leader.lastName) {
            alert("Veuillez compléter le prénom et nom");            
        }
        var ope = new ProjectsLeaderOperation()
        ope.put(leader, () => {
            alert("Le porteur de projet a été modifié");
            this.NavigationManager.navigate('/dashboard/projectLeaders');
        });
    }
}