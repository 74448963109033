import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import PopupHeaderViewState from './PopupHeaderViewState';
import PopupHeaderViewProps from './PopupHeaderViewProps';
import PopupHeaderViewStyle from './PopupHeaderViewStyle';
import PopupHeaderViewModel from './PopupHeaderViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback , Image, LayoutChangeEvent } from 'react-native';


export default class PopupHeaderView extends ContentView<PopupHeaderViewProps, PopupHeaderViewState, PopupHeaderViewModel, PopupHeaderViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:PopupHeaderViewProps)
  {
    super(props);

    // Binding
    this.bind(new PopupHeaderViewModel(this), new PopupHeaderViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container, title , line , image,MyStyleManager} = this.style;

      
    return (
      <View style={{width : '100%' , zIndex : 1000 }}>
      <View onLayout={(event : LayoutChangeEvent)=>this.binding.handleOnLayout(event)} style={container}>
        <View style={{backgroundColor: MyStyleManager.primaryColor, width : this.props.width  }} >
          <View style={line}>
            <TranslationView style={title}>
              {this.props.title}
            </TranslationView>
            <View style={{flexDirection : 'row' , alignItems : 'center' }}>
              {this.props.children}
              <View style={{width : 60 , alignItems : 'center'}}>
                <TouchableWithoutFeedback onPress={() => this.props.handleBackPressed()} >
                  <Image style={image}
                    source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
                </TouchableWithoutFeedback>
              </View>
            </View>
          </View>
        </View>
      </View>
       <View style={{width : this.state.width , height : this.state.height }}/>
      </View>
    );
  }
}