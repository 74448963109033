import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ProjectsInvestorsPopUpViewState from './ProjectsInvestorsPopUpViewState';
import ProjectsInvestorsPopUpViewProps from './ProjectsInvestorsPopUpViewProps';
import ProjectsInvestorsPopUpViewStyle from './ProjectsInvestorsPopUpViewStyle';
import ProjectsInvestorsPopUpViewModel from './ProjectsInvestorsPopUpViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';

export default class ProjectsInvestorsPopUpView extends ContentView<ProjectsInvestorsPopUpViewProps, ProjectsInvestorsPopUpViewState, ProjectsInvestorsPopUpViewModel, ProjectsInvestorsPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ProjectsInvestorsPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new ProjectsInvestorsPopUpViewModel(this), new ProjectsInvestorsPopUpViewStyle(this));
  }

   /* RENDERING */
   render()
   {
     return (
       <View style={{ marginHorizontal: 50, minWidth: 600 }}>
 
         <TranslationView style={this.style.title}>
           ConfirmValidInvestment
         </TranslationView>
 
         <View style={this.style.footer}>
           <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
             <TranslationView upperCase style={this.style.NoButtonText}>
               No
             </TranslationView>
           </ButtonView>
           <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
             <TranslationView upperCase>
               Yes
             </TranslationView>
           </ButtonView>
         </View>
       </View>
     );
   }
 }