import { ContentViewStyle } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, Dimensions, TextStyle } from 'react-native';

export default class DashboardshellViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        height: Dimensions.get('window').height,
        backgroundColor: '#eee',
        flexDirection: 'row'
    }

    viewContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1
    }

    header: StyleProp<ViewStyle> = {
        height: 70,
        display: 'flex',
        justifyContent: 'center'
    }

    headerTitle: StyleProp<TextStyle> = {
        marginLeft: 20,
        fontSize: 18,
        fontWeight: '100'
    }
}