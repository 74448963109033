import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import UserInvestmentsViewState from './UserInvestmentsViewState';
import UserInvestmentsViewProps from './UserInvestmentsViewProps';
import UserInvestmentsViewStyle from './UserInvestmentsViewStyle';
import UserInvestmentsViewModel from './UserInvestmentsViewModel';
import { View } from 'react-native';
import TableView from '../../../../../../../../../../../Components/table/TableView';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';

export default class UserInvestmentsView extends ContentView<UserInvestmentsViewProps, UserInvestmentsViewState, UserInvestmentsViewModel, UserInvestmentsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UserInvestmentsViewProps) {
    super(props);

    // Binding
    this.bind(new UserInvestmentsViewModel(this), new UserInvestmentsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.Box}>
        <View style={this.style.boxContainer}>
          {/* Research Input */}
          {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
            <View>
              <ButtonView animation={'None'} borders={'Little'} style={this.style.exportButtonText} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.binding.showInvestPopUp() }}>
                <TranslationView upperCase>AddInvest</TranslationView>
              </ButtonView>
            </View>
          }
          <View style={this.style.containerResearch}>
            {/*<ResearchInputView
              textInFront="Recherche : "
              placeholder="search"
              handlerResearching={(text: string) => this.binding.handlerResearching(text)}
              handlingClose={() => { this.binding.handlingClose() }}
              text={this.state.text}
            />*/}
          </View >

          <TableView
            handleResetStepView={this.binding.handleResetStepView}
            resetStepView={this.state.resetStepView}
            headerData={this.state.tableHeaders}
            rows={this.state.rows}
            numberOfRows={150}
            isSortable={true}
            resetTable={this.binding.resetTable}
            datas={this.state.datas}
            fillTables={(value: any[]) => { this.binding.pushRows(value) }}
            keyDatas={["projectId", "amount", "creationDate", "isValidatedString"]}
            headerIndex={[0, 1, 2, 3]}
            headerTextStyle={this.style.headerText}
            nbPage={1}
            currentPage={1}
            onPageChange={() => { }}
          ></TableView>
        </View>
      </View>
    );
  }
}