import { ContentViewModel, NavigationManager, Managers, Application, ContentView } from '@levelapp/softfabric';
import StateManager from '../../../../../../../../../Common/Managers/StateManager';
import LegalEntity from '../../../../../../../../../DTO/LegalEntity';
import StateManagerConst from '../../../../../../../../../Common/Managers/StateManagerConst';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import DropDownView from '../../../../../../../../../Components/Dropdown/DropDownView';
import React from 'react';
import UsersLinkedViewStyle from './UsersLinkedViewStyle';
import UsersLinkedViewProps from './UsersLinkedViewProps';
import UsersLinkedViewState from './UsersLinkedViewState';
import UserLegalEntityOperations from '../../../../../../../../../Transfer/UserLegalEntityOperations'
import ProjectInvestementStatusUpdatedView from '../../../../../../../../../Components/projectinvestementstatusupdated/ProjectInvestementStatusUpdatedView';
import AddLinkPopUpView from "./modules/addlinkpopup/AddLinkPopUpView";
import UserLegalEntityOperation from "../../../../../../../../../Transfer/UserLegalEntityOperations";
import LegalEntityOperations from '../../../../../../../../../Transfer/LegalEntityOperations';
import RoleManager from '../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../Common/Managers/ExtendedManagers';

export default class UsersLinkedViewModel extends ContentViewModel<UsersLinkedViewState, UsersLinkedViewProps, UsersLinkedViewStyle>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    dropdownValues = [
        {
            title: "Pending",
            value: false
        },
        {
            title: "Validated",
            value: true
        },
    ];
    constructor(props: ContentView) {
        super(props);

        let entity = this.parameters<LegalEntity>();
        let previousState = this.StateManager.retrieveState(StateManagerConst.USERS_ENTITY);

        this.initialState({
            legalEntity: entity,
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            isLoading: false
        });

        this.handleSuccessUsersCallback = this.handleSuccessUsersCallback.bind(this);
        this.getSelectedValue = this.getSelectedValue.bind(this);
        this.updateSelectStatuses = this.updateSelectStatuses.bind(this);
        this.updateUserStatus = this.updateUserStatus.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.pushRow = this.pushRow.bind(this);
        this.handlingClose = this.handlingClose.bind(this);
        this.handlerResearching = this.handlerResearching.bind(this);
        this.editField = this.editField.bind(this);
        this.addLinkExexecute = this.addLinkExexecute.bind(this);
        this.updateView = this.updateView.bind(this);
        this.updateViewExecute = this.updateViewExecute.bind(this);
    }

    componentDidMount() {
        let users = this.state().legalEntity.users;
        this.handleSuccessUsersCallback(users);
    }

    handleSuccessUsersCallback(users: any[]) {
        let datas: any[] = [];
        let rows: any[] = [];
        users.forEach((user) => {
            let userDTO = {
                "id": user.id,
                "userName": user.firstName + " " + user.lastName,
                "email" : user.email,
                "isValidatedString": this.getSelectedValue(user).title,                
            }

            datas.push(userDTO);

            rows.push(
                <View>
                    <Text style={this.style().text} >{userDTO.id}</Text>
                    <Text style={this.style().text} >{userDTO.userName}</Text>
                    <Text style={this.style().text} >{userDTO.email}</Text>
                    <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, user) }} selectedValue={this.getSelectedValue(user)} values={this.dropdownValues}></DropDownView>
                    <TouchableWithoutFeedback onPress={() => { this.updateUserStatus(user) }} >
                        <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => { this.editField(user) }} >
                        <View>
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ datas: datas, rows: rows, initialData: datas.slice(0), initialRow: rows });
        this.setField({ tableHeaders: ['USER ID', 'PRÉNOM ET NOM', 'EMAIL', 'STATUT', 'CHANGER STATUT', 'PROFIL'] });

    }

    getSelectedValue(users: any) {
        return this.dropdownValues[users.isValidatedToLegalEntity ? 1 : 0];
    }


    updateSelectStatuses(value: any, user: any) {
        let legal = this.state().legalEntity;
        legal.users.filter(usr => usr.id == user.id)[0].isValidatedToLegalEntity = value.value;
        this.setField({ legalEntity: legal });
    }

    updateUserStatus(user: any) {
        this.parameters<any>().legalEntity = this.state().legalEntity;
        let userLegalEntityDTO = { userId: user.id, legalEntityId: this.state().legalEntity.id, isValidated: user.isValidatedToLegalEntity };
        new UserLegalEntityOperations().updateStatus(userLegalEntityDTO).then(() => {
            this.showPopUp(
                <ProjectInvestementStatusUpdatedView />
            );
        }).then(() => {
            setTimeout(() => this.closePopUp(), 3000);
            this.NavigationManager.navigate('/dashboard/legalentities', true);
        });
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(user => {
            this.pushRow(user, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(user: any, rowsToPush: any[]) {

        let myUser = this.state().legalEntity.users.find((userFull) => (userFull.projectId === user.projectId));

        rowsToPush.push(
            <View>
                <Text style={this.style().text} >{user.id}</Text>
                <Text style={this.style().text} >{user.userName}</Text>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <DropDownView onValueChange={(value: any) => { this.updateSelectStatuses(value, myUser) }} selectedValue={this.getSelectedValue(myUser)} values={this.dropdownValues}></DropDownView>
                </View>
                <TouchableWithoutFeedback onPress={() => { this.updateUserStatus(myUser) }} >
                    <Image style={{ flex: 1, alignItems: 'flex-start', width: 20, height: 20 }} source={{ uri: "https://images.vexels.com/media/users/3/143373/isolated/preview/4b7f61d880ecd0125b88ac6898721733-blue-flat-check-mark-by-vexels.png" }}></Image>
                </TouchableWithoutFeedback>
                <TouchableWithoutFeedback onPress={() => { this.editField(user) }} >
                        <View>
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
            </View>
        )
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    resetTable() {
        if (this.state().text == "")
            this.handlingClose();
        else (this.handlerResearching(this.state().text))
    }

    handlingClose() {
        this.setField({ text: "", datas: this.state().initialData, rows: this.state().initialRow });
    }

    handlerResearching(txt: string) {
        let rows: any[] = [];

        let datas = this.state().initialData.filter(user => {
            let contains = false;

            for (let key in user) {
                contains = JSON.stringify(user[key]).toLocaleLowerCase().includes(txt.toLocaleLowerCase());
                if (contains) {
                    this.pushRow(user, rows);
                    return contains;
                }
            }
            return contains;

        });
        this.setField({ text: txt, datas: datas, rows: rows, resetStepView: true });
    }

    editField(user: any) {
        this.NavigationManager.navigate('/dashboard/users/edit', true, user);
    }

    showAddLinkPopUp() {
       this.showPopUp(<AddLinkPopUpView callBack={this.addLinkExexecute}></AddLinkPopUpView>);
    }

    addLinkExexecute(destinationUserId: any) {     
        var model = {"InvestorDestinationId" : destinationUserId, "PMId" : this.state().legalEntity.id};

        new UserLegalEntityOperation().addLink(model, this.updateView);
    }

    updateView() {
        new LegalEntityOperations().getLegalEntityById(this.state().legalEntity.id, this.updateViewExecute);
    } 

    updateViewExecute(legalEntity: any) {
        this.handleSuccessUsersCallback(legalEntity.users);
    }
}