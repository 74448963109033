import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import UserLegalEntitiesViewState from './UserLegalEntitiesViewState';
import UserLegalEntitiesViewProps from './UserLegalEntitiesViewProps';
import Profile from '../../../../../../../../../../../DTO/Profile';
import React from 'react';
import { Text, TouchableWithoutFeedback, View } from 'react-native';
import UserLegalEntitiesViewStyle from './UserLegalEntitiesViewStyle';
import StateManager from '../../../../../../../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../../../../../../../Common/Managers/StateManagerConst';
import ExtendedManagers from '../../../../../../../../../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../../../../../../../../../Common/Managers/RolesManager';


export default class UserLegalEntitiesViewModel extends ContentViewModel<UserLegalEntitiesViewState, UserLegalEntitiesViewProps, UserLegalEntitiesViewStyle>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    public _user: any;

    constructor(props: ContentView) {
        super(props);

        let usr = this.parameters<Profile>();
        let previousState = this.StateManager.retrieveState(StateManagerConst.LEGAL_ENTITIES_USER);


        this._user =  localStorage.getItem("user");

        /* Initial State */
        this.initialState({
            profile: usr,
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            isAdmin: this.RoleManager.isAdmin,
        });

    }
    componentDidMount() {
        let legalEntities = this.state().profile.legalEntities;
        this.handleSuccessLegalEntitiesCallback(legalEntities);
       
    }

    handleSuccessLegalEntitiesCallback(legalEntities: any[]) {
        let datas: any[] = [];
        let rows: any[] = [];
        legalEntities.forEach((legalEntity) => {
            let legalEntityDTO = {
                "id": legalEntity.id,
                "corporateName": legalEntity.corporateName,
            }

        datas.push(legalEntityDTO);
        
            rows.push(
                <View>
                <Text style={this.style().text} >{legalEntity.id}</Text>
                <TouchableWithoutFeedback onPress={() => { this.redirect(legalEntity) }} >
                <Text style={this.style().text}>{legalEntity.corporateName}</Text>
                </TouchableWithoutFeedback>
                
            </View>                )
            
        });
        this.setField({ datas: datas, rows: rows, initialData: datas.slice(0), initialRow: rows });
        this.setField({ tableHeaders: ['ID', 'NOM'] });
    }
    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(legalEntity => {
            this.pushRow(legalEntity, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(legalEntity: any, rowsToPush: any[]) {

        let myLegalEntity = this.state().profile.investments.find((legalEntityFull) => (legalEntityFull.id === legalEntity.id));

        rowsToPush.push(
            <View>
                <Text style={this.style().text} >{legalEntity.id}</Text>
                <TouchableWithoutFeedback onPress={() => { this.redirect(myLegalEntity) }} >
                <Text style={this.style().text}>{legalEntity.corporateName}</Text>
                </TouchableWithoutFeedback>
                
            </View>
        )
    }

    redirect(legalEntity: any){
        this.NavigationManager.navigate('/dashboard/legalentities/edit', true, legalEntity);
    }
}