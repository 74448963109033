import {ContentViewModel,ContentView, NavigationManager, Application, Managers} from '@levelapp/softfabric';
import EmailsViewState from './EmailsViewState';
import EmailsViewProps from './EmailsViewProps';
import { Quill } from 'react-quill';
const sgMail = require('@sendgrid/mail');

var Font = Quill.import('formats/font');
Font.whitelist = ['mirza', 'roboto', 'montserrat'];
Quill.register(Font, true);

export default class EmailsViewModel extends ContentViewModel<EmailsViewState, EmailsViewProps>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            message: "",
            subject: "",
            email: "",
        }); // Initialize your state here

        // Bindings
        this.sendMail = this.sendMail.bind(this);

    }

    componentDidMount(){
        this.NavigationManager.navigate('/dashboard/emails');
    }

    sendMail(){
       const msg = {
        to: this.state().email,
        from: 'noreply@beebonds.com',
        subject: this.state().subject,
        text: 'and easy to do anywhere, even with Node.js',
        html: this.state().message,
    };
        sgMail.setApiKey("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");
        sgMail.send(msg);
        
    }

    handleMessageChanged(message: string){
        let state = this.state();
        state.message = message;
        this.setField(state); 
    }

    handleEmailChanged(email:string){
        let state = this.state();
        state.email = email;
        this.setField(state);
    }

    handleSubjectChanged(subject:string){
        let state = this.state();
        state.subject = subject;
        this.setField(state);
    }

    wysiwygModule() {

        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],

                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'direction': 'rtl' }],                         // text direction

                [{ 'size': ['12px', '16px', '18px'] }],  // custom dropdown
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                [{ 'font': Font.whitelist }],
                [{ 'align': [] }],
                ['clean'],                                         // remove formatting button
                ['link', 'image', 'video'],
                ['showHtml']
            ],
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            }
        }
    }
}