import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import TableCellViewState from './TableCellViewState';
import TableCellViewProps from './TableCellViewProps';
import TableCellViewStyle from './TableCellViewStyle';
import TableCellViewModel from './TableCellViewModel';
import { View } from 'react-native';

export default class TableCellView extends ContentView<TableCellViewProps, TableCellViewState, TableCellViewModel, TableCellViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TableCellViewProps)
  {
    super(props);

    // Binding
    this.bind(new TableCellViewModel(this), new TableCellViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.props.style}>
        {this.props.value}
      </View>
    );
  }
}