import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import FinanceInfosViewState from './FinanceInfosViewState';
import FinanceInfosViewProps from './FinanceInfosViewProps';
export default class FinanceInfosViewModel extends ContentViewModel<FinanceInfosViewState, FinanceInfosViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            isLoading : true,
            placementTypes1: [
                {"Title" : "Publique Non-Subordonnée", "id" : 1 },
                {"Title" : "Privée Non-Subordonnée", "id" : 2 },
                {"Title" : "Publique Subordonnée", "id" : 3 },
                {"Title" : "Privée Subordonnée", "id" : 4 },
            ],
            placementTypes2: [
                {"Title" : "Nominative", "id" : 5 },
                {"Title" : "Au Porteur", "id" : 6 },
            ]

        }); // Initialize your state here

        // Bindings
      

    }
    componentDidMount(){        
        this.setField((previousState) => previousState.isLoading = false);
    }   
}