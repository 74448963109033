import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
export default class CouponsService extends Service {
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async get(projectId: any, callback: Function) {
        this.httpClient.get("api/coupons/GetByProject/" + projectId).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async save(coupon: any, callback: Function = () => { }) {
        this.httpClient.post("api/coupons", [], coupon).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async delete(coupon: any, callback: Function = () => { }) {
        this.httpClient.delete("api/coupons/"+coupon.id, []).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }
}