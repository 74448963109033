const EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const PHONE = /^((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))$/;
const BE_ID_CARD = /^[0-9]{3}-[0-9]{7}-[0-9]{2}$/;
const BE_ID_CARD2 = /^[0-9]{12}$/
const BE_ID_CARD3 = /^[0-9]{9}$/
const BE_ID_CARD4 = /^B[0-9]{9}$/
const BE_ID_CARD5 = /^B[0-9]{7}\s[0-9]{2}$/
const BE_ID_CARD6 = /^[0-9]{7}\s[0-9]{2}$/
const BE_NATIONAL_NUMBER = /^[0-9]{2}.[0-9]{2}.[0-9]{2}-[0-9]{3}.[0-9]{2}$/;
const BE_NATIONA_NUMBER2 = /^[0-9]{11}$/;
const ALPHA_NUM = /^[a-zA-Z0-9][a-zA-Z0-9]*$/;
const PASSWORD = /^(?=.*?[A-ZÀÈÌÒÙÁÉÍÓÚÝÂÊÎÔÛÃÑÕÄËÏÖÜŸÇßØÅÆ])(?=.*?[a-zàèìòùáéíóúýâêîôûãñõäëïöüÿçøåæœ]).{8,50}$/;
const BIC = /^([a-zA-Z]{4})\s?([a-zA-Z]{2})\s?(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))\s?((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)$/;

export default { EMAIL, PHONE, BE_ID_CARD, BE_ID_CARD2, BE_NATIONAL_NUMBER, BE_NATIONA_NUMBER2, ALPHA_NUM, PASSWORD, BIC, BE_ID_CARD3, BE_ID_CARD4, BE_ID_CARD5, BE_ID_CARD6};