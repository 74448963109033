import UsersPopUpViewViewState from './UsersPopUpViewViewState';
import UsersPopUpViewViewProps from './UsersPopUpViewViewProps';
import UsersPopUpViewViewStyle from './UsersPopUpViewViewStyle';
import UsersPopUpViewViewModel from './UsersPopUpViewViewModel';
import React from 'react';
import { View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import {ButtonView , TranslationView} from '@levelapp/softfabric-ui';

export default class UsersPopUpViewView extends ContentView<UsersPopUpViewViewProps, UsersPopUpViewViewState, UsersPopUpViewViewModel, UsersPopUpViewViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:UsersPopUpViewViewProps)
  {
    super(props);

    // Binding
    this.bind(new UsersPopUpViewViewModel(this), new UsersPopUpViewViewStyle(this));
  }

   /* RENDERING */
   render()
   {
     return (
       <View style={{ marginHorizontal: 50, minWidth: 600 }}>
 
         <TranslationView style={this.style.title}>
           ConfirmValid
         </TranslationView>
 
         <View style={this.style.footer}>
           <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
             <TranslationView upperCase style={this.style.NoButtonText}>
               No
             </TranslationView>
           </ButtonView>
           <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
             <TranslationView upperCase>
               Yes
             </TranslationView>
           </ButtonView>
         </View>
       </View>
     );
   }
 }