import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class CouponsViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    headerText: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontWeight: 'bold',
        fontSize : 15,
        textAlign : 'center'
    }

    exportButton: StyleProp<ViewStyle> = {       
        marginTop: 40,
        marginLeft: 65,
        marginBottom: 10,     
        backgroundColor: this.MyStyleManager.primaryColor,
        borderRadius: 10,
        paddingVertical : 5,
        
    }

    exportButtonText: StyleProp<TextStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        color: 'white',
        fontSize: 17,
        fontWeight: '100',
        textAlign: 'center',  
    }

}