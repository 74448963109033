import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import TableRowViewState from './TableRowViewState';
import TableRowViewProps from './TableRowViewProps';
import TableCellView from './tablecell/TableCellView';
import * as React from 'react';
import { View } from 'react-native';
import TableRowViewStyle from './TableRowViewStyle';

export default class TableRowViewModel extends ContentViewModel<TableRowViewState, TableRowViewProps, TableRowViewStyle>{
      /* CONSTRUCTOR */
      constructor(view:ContentView)
      {
          super(view);
  
          this.initialState({
              row : []
          });
          this.initialiseRow = this.initialiseRow.bind(this);
      }
      componentDidMount(){
        this.initialiseRow();
      }

      initialiseRow() : JSX.Element[]
      {
          if(this.props().dataCellList != undefined && this.props().dataCellList.length > 0)
          {
            let row : JSX.Element[] = [];
            this.props().dataCellList.forEach((element) => {
              let cell = <TableCellView style={this.style().cell} value={element} ></TableCellView>;
              row.push(cell);
            });
            return row;
          }

          return [<View/>]
      }

      getCellViews() :  JSX.Element[]
      {
        if(this.props().dataCellList != undefined)
        {
          let row : JSX.Element[] = [];
          this.props().dataCellList.forEach((element , index) => {            
            let cell = <TableCellView style={this.style().cell} key={index} value={element} ></TableCellView>;           
            if(element.props && !isNaN(element.props.children)) {
              cell = <TableCellView style={this.style().cellNumber} key={index} value={element} ></TableCellView>;
            }
            else if(element.props && element.props.children && element.props.children.props 
              && element.props.children.props.children  && element.props.children.props.children.props) {
              cell = <TableCellView style={this.style().cellEmail} key={index} value={element} ></TableCellView>;
            }
            row.push(cell);
          });
          return row;
        }

        return [<View/>]
      }
}