import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class TableRowViewStyle extends ContentViewStyle{
    cell: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent : 'center',
        borderBottomWidth: 1,
        borderColor: '#e0e0e0',
    };

    cellNumber: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent : 'center',
        borderBottomWidth: 1,
        borderColor: '#e0e0e0',
    };

    cellEmail: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent : 'center',
        borderBottomWidth: 1,
        borderColor: '#e0e0e0',
        flexWrap: 'wrap-reverse',
    };
}