import { Operation } from '@levelapp/softfabric';
import CertificatesService from '../Proxy/CertificatesService';


export default class CertificatesOperation extends Operation
{
    _service : CertificatesService;

    constructor()
    {
        super();
        this._service = new CertificatesService();
    }       

    async sendCertificates(documents: any, callback: Function = () => { })
    {
        await (this._service as CertificatesService).sendCertificates(documents, callback);
    }
    async getCertificate(documents: any, callback: Function = () => { })
    {
        await (this._service as CertificatesService).getCertificate(documents, callback);
    }
}