import {ContentViewStyle} from '@levelapp/softfabric';
import { ViewStyle, StyleProp } from 'react-native';

export default class UserDocumentsViewStyle extends ContentViewStyle
{
    Box: StyleProp<ViewStyle> =
    {
        backgroundColor : 'white',
        marginLeft: '30px',
        marginTop: '30px',
        marginRight: '30px',
        paddingLeft: '92px',
        paddingTop: '51px',
        paddingRight: '150px',
        paddingBottom: '31px',
        borderRadius:10,
    }    

    PicturesTitle: React.CSSProperties = {
        color: '#32325D',
        fontSize: '24px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        lineHeight: '37px',
        marginTop: '70px',
    };

    ValidButton:  React.CSSProperties =  {
        height: '42px',
        width: '320px',
        marginTop: '57px',        
     };

     ImportEntry:  StyleProp<ViewStyle> =  {
        width: '320px',
     };
}