import React from 'react';
import {ContentView} from '@levelapp/softfabric'; 
import TraductionViewState from './TraductionViewState';
import TraductionViewProps from './TraductionViewProps';
import TraductionViewStyle from './TraductionViewStyle';
import TraductionViewModel from './TraductionViewModel';
import { View } from 'react-native';
import { TranslationTableView , TranslationView } from '@levelapp/softfabric-ui';


export default class TraductionView extends ContentView<TraductionViewProps, TraductionViewState, TraductionViewModel, TraductionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TraductionViewProps)
  {
    super(props);

    // Binding
    this.bind(new TraductionViewModel(this), new TraductionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>

          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>
              Traduction
            </TranslationView>

            <TranslationView  style={this.style.subTitle}>
              Translate
            </TranslationView>
          </View>

          <View style={this.style.boxContainer}>

            <TranslationTableView
              exportExcel= {true}
              exportJson= {true}
              dontWorkOnMobile="Doesn't work on mobile"
              isLoading = {false}
              textInFrontForResearch = "Search : "
              nothingToDisplayText = "No results "
              placeholderInput = "Search..."
              numberItemsPerPage = {50}
              buttonImportText = "Import"
            />
          </View>
        </View>
      </View>
    );
  }
}