import PopUpSendNotificationViewStyle from "./PopUpSendNotificationViewStyle";
import PopUpSendNotificationViewModel from "./PopUpSendNotificationViewModel";
import PopUpSendNotificationViewState from "./PopUpSendNotificationViewState";
import PopUpSendNotificationViewProps from "./PopUpSendNotificationViewProps";
import { ContentView } from "@levelapp/softfabric";
import React from "react";
import { Text, View } from "react-native";
import InputView from "../Input/input/InputView";
import { ButtonView, TranslationView } from "@levelapp/softfabric-ui";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default class PopUpSendNotificationView extends ContentView<
  PopUpSendNotificationViewProps,
  PopUpSendNotificationViewState,
  PopUpSendNotificationViewModel,
  PopUpSendNotificationViewStyle
> {
  constructor(props: PopUpSendNotificationViewProps) {
    super(props);

    this.bind(
      new PopUpSendNotificationViewModel(this),
      new PopUpSendNotificationViewStyle(this),
    );
  }
  render() {
    return (
      <div>
        <View style={this.style.benefitDetailsContainer}>
          <TranslationView
            style={{
              color: "#FFB243",
              fontFamily: "Montserrat",
              fontSize: 30,
              fontWeight: "bold",
              paddingBottom: 20,
            }}
          >
            Send Notification User
          </TranslationView>
          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <InputView
                subscribe={() => { }}
                shouldCheck={false}
                inputClass="col-lg-7"
                label={"Titre FR"}
                labelClass="col-lg-7"
                onChange={(title: string) =>
                  this.binding.onTitleFRChange(title, "titleFR")
                }
                value={this.state.titleFR}
                disabled={false}
                required={false}
                type="text"
              />
            </View>
          </View>

          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <InputView
                subscribe={() => { }}
                shouldCheck={false}
                inputClass="col-lg-7"
                label={"Titre NL"}
                labelClass="col-lg-7"
                onChange={(title: string) =>
                  this.binding.onTitleFRChange(title, "titleNL")
                }
                value={this.state.titleNL}
                disabled={false}
                required={false}
                type="text"
              />
            </View>
          </View>

          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <InputView
                subscribe={() => { }}
                shouldCheck={false}
                inputClass="col-lg-7"
                label={"Titre EN"}
                labelClass="col-lg-7"
                onChange={(title: string) =>
                  this.binding.onTitleFRChange(title, "titleEN")
                }
                value={this.state.titleEN}
                disabled={false}
                required={false}
                type="text"
              />
            </View>
          </View>

          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <Text style={this.style.label}>Paragraphe FR</Text>
              <ReactQuill
                value={this.state.messageFR}
                onChange={(subtitle: string) =>
                  this.binding.onTitleFRChange(subtitle, "messageFR")}
              />
            </View>
          </View>
          <br></br>
          <br></br>
          <br></br>
          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <Text style={this.style.label}>Paragraphe NL</Text>
              <ReactQuill
                value={this.state.messageNL}
                onChange={(subtitle: string) =>
                  this.binding.onTitleFRChange(subtitle, "messageNL")}
              />
            </View>
          </View>
          <br></br>
          <br></br>
          <br></br>

          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <Text style={this.style.label}>Paragraphe EN</Text>
              <ReactQuill
                value={this.state.messageEN}
                onChange={(subtitle: string) =>
                  this.binding.onTitleFRChange(subtitle, "messageEN")}
              />
            </View>
          </View>
          <br></br>
          <br></br>
          <br></br>

          <View style={this.style.containerSameLine}>
            <View style={this.style.input}>
              <InputView
                subscribe={() => { }}
                shouldCheck={false}
                inputClass="col-lg-7"
                label={"Email pour envoie de test"}
                labelClass="col-lg-7"
                onChange={(mail: string) =>
                  this.binding.onEmailTestChanged(mail)
                }
                value={this.state.mailTest}
                disabled={false}
                required={false}
                type="text"
              />
            </View>
          </View>
          <ButtonView
            animation={"None"}
            borders={"Little"}
            style={this.style.saveButton}
            isLoading={this.state.isLoading}
            handleClick={() => this.binding.handleSendNotification(true)}
          >
            <TranslationView>SendNotificationTest</TranslationView>
          </ButtonView>
          <ButtonView
            animation={"None"}
            borders={"Little"}
            style={this.style.saveButton}
            isLoading={this.state.isLoading}
            handleClick={() => this.binding.handleSendNotification(false)}
          >
            <TranslationView>Send Notification</TranslationView>
          </ButtonView>
        </View>
      </div>
    );
  }
}
