import {ContentViewModel,ContentView, Application, Managers, NavigationManager} from '@levelapp/softfabric';
import PrimeUsersListViewState from './PrimeUsersListViewState';
import PrimeUsersListViewProps from './PrimeUsersListViewProps';
import ProjectsOperation from '../../../../../../../../../../../../../../Transfer/ProjectsOperations';
import PrimeUser from '../../../../../../../../../../../../../../DTO/PrimeUser';
import React from 'react';
import { View, TouchableWithoutFeedback,Text,Image} from 'react-native';

export default class PrimeUsersListViewModel extends ContentViewModel<PrimeUsersListViewState, PrimeUsersListViewProps>
{
    projectOperation = new ProjectsOperation();
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {

        super(view);

        this.initialState({
            isLoading : true,
            primeUserList : [],
            resetStepView: false,
            rows: [],
            initialData : [],
            initialRow : [],
            datas: [],
            nbPage: 0,
            currentPage: 1,
            nbRows : 10,
        }); // Initialize your state here

        // Bindings
        this.fillTable = this.fillTable.bind(this)
        this.handleResetStepView = this.handleResetStepView.bind(this);

    }

    componentDidMount(){
        this.getData()
    }

    getData(){
        this.projectOperation.getPrimeUsers(this.props().projectId,(data : PrimeUser[])=>{          
            this.setField((previousState) => previousState.primeUserList = data);
            this.setField((previousState) => previousState.isLoading = false);
            this.setField((previousState) => previousState.nbPage = Math.ceil(data.length/this.state().nbRows));
            this.fillTable()
        })
    }

    fillTable() {
        let datas: any[] = [];
        let primeUserList = this.state().primeUserList;
        let rows: any[] = [];

        //Investment table
        primeUserList.slice((this.state().currentPage-1)*this.state().nbRows,(this.state().currentPage)*this.state().nbRows).forEach((primeUser) => {
            datas.push(primeUser);
            rows.push(
                <View>
                    <Text >{primeUser.user !== null? primeUser.user.email :primeUser.legalEntity.email}</Text>
                    <Text >{primeUser.user !== null? primeUser.user.id :"/"}</Text>
                    <Text >{primeUser.user !== null? primeUser.user.lastName:"/"}</Text>
                    <Text >{primeUser.user !== null? primeUser.user.firstName:"/"}</Text>
                    <Text >{primeUser.legalEntity !== null? primeUser.legalEntity.id : '/'}</Text>
                    <Text >{primeUser.legalEntity !== null? primeUser.legalEntity.corporateName : '/'}</Text>
                    <TouchableWithoutFeedback onPress={() => { this.deletePrimeUser(primeUser.id)}} >
                        <View style={{ alignItems: 'center' }} >
                            <Image source={require('./assets/trash.svg')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ isLoading: false });
        
    }

    deletePrimeUser(primeid : number){
        this.setField((previousState) => previousState.isLoading = true);
        this.projectOperation.deletePrimeUsers(primeid,()=>{           
            this.getData()
        }) 
    }
    handleResetStepView() {
        this.setField((previousState) => previousState.resetStepView =false);
    }

    async onPageChange(page: number) {
        this.setField((previousState) => previousState.currentPage = page + 1);
        await new Promise(resolve => setTimeout(resolve, 10));
        this.fillTable()
    }
}