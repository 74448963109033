import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import ProjectLeadersViewState from './ProjectLeadersViewState';
import ProjectLeadersViewProps from './ProjectLeadersViewProps';
import { View, Text, TouchableWithoutFeedback, Image } from 'react-native';
import ProjectsLeaderOperation from '../../../../../Transfer/ProjectLeadersOperations';
import React from 'react';
import ProjectLeadersViewStyle from './ProjectLeadersViewStyle';

export default class ProjectLeadersViewModel extends ContentViewModel<ProjectLeadersViewState, ProjectLeadersViewProps, ProjectLeadersViewStyle> {
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    textResearch: string = "";
    interval: any;

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            isLoading: true,
            datas: [],
            nbPage: 0,
            currentPage: 1,
            text: ""
        }); // Initialize your state here

        // Bindings
        this.handleSuccessGetCallback = this.handleSuccessGetCallback.bind(this);
        this.add = this.add.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }

    add() {
        let leader = {
            address: {

            }
        };
        this.NavigationManager.navigate('/dashboard/projectLeaders/edit', true, leader);
    }

    refresh() {
        this.setField({ isLoading: true });
        let ope = new ProjectsLeaderOperation();
        ope.get(this.handleSuccessGetCallback)
    }

    handleSuccessGetCallback(ret: any) {
        let rows: any[] = [];

        ret = ret.filter((x: any) => x.firstName.toLowerCase().includes(this.textResearch.toLowerCase()) ||
            x.lastName.toLowerCase().includes(this.textResearch.toLowerCase()) ||
            (x.email && x.email.toLowerCase().includes(this.textResearch.toLowerCase())));

        ret.forEach((leader: any) => {
            leader.projectsListAsString = leader.projectsListAsString.replace(/-/g, '\n');
            rows.push(
                <View>
                    <Text style={this.style().text} >{leader.lastName}</Text>
                    <Text style={this.style().text} >{leader.firstName}</Text>
                    <Text style={this.style().text} >{leader.email}</Text>
                    <Text style={this.style().textLeft} >{leader.phoneNumber}</Text>
                    <Text style={this.style().textLeft} >{leader.projectsListAsString}</Text>
                    <TouchableWithoutFeedback onPress={() => { this.editField(leader) }} >
                        <View style={{ display: 'flex', flex: 1, alignItems: 'center' }} >
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ tableHeaders: ['NOM', 'PRENOM', 'EMAIL', 'PHONE', 'PROJETS', 'EDIT'], });
        this.setField({ rows: rows, isLoading: false });

    }

    editField(leader: any) {
        this.NavigationManager.navigate('/dashboard/projectLeaders/edit', true, leader);
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }


    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }


    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(user => {
            this.pushRow(user, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(userRole: any, rowsToPush: any[]) {
        rowsToPush.push(
            <View>
                <Text style={this.style().text}>{userRole.user}</Text>
                <Text style={this.style().text}>{userRole.role}</Text>
                <TouchableWithoutFeedback onPress={() => { this.editField(userRole) }} >
                    <View>
                        <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        )
    }

    handleResearching(searchText: string) {
        this.setField({ text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    handlingClose() {
        this.textResearch = "";
        this.onPageChange(0);
    }
}