import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import SendEmailToPendingPopUpViewState from './SendEmailToPendingPopUpViewState';
import SendEmailToPendingPopUpViewProps from './SendEmailToPendingPopUpViewProps';
import SendEmailToPendingPopUpViewStyle from './SendEmailToPendingPopUpViewStyle';
import SendEmailToPendingPopUpViewModel from './SendEmailToPendingPopUpViewModel';
import { View,Text } from 'react-native';
import PopupHeaderView from '../../../../../../../../../../components/popopheader/PopupHeaderView';
import TableView from '../../../../../../../../../../../../../Components/table/TableView';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';


export default class SendEmailToPendingPopUpView extends ContentView<SendEmailToPendingPopUpViewProps, SendEmailToPendingPopUpViewState, SendEmailToPendingPopUpViewModel, SendEmailToPendingPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:SendEmailToPendingPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new SendEmailToPendingPopUpViewModel(this), new SendEmailToPendingPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {topLineContainer,bottomLineContainer,tableContainer,headerText,stepColor,YesButton,title} = this.style;
    const headerSuccessInvest = ['Invest ID','User ID',"PM", 'Nom', 'Email', 'Montant','Is Waiting','Statut'];
    return (
      <View style={{ minWidth: 300,height: 850, width: 1200, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={this.binding.handleBackPressed}
          title={"mailPending.header"}
          width={1200}/> 

        <View style={topLineContainer}> 
              <TranslationView style={title}>{'email.pending.number'}</TranslationView> 
              <Text style={title}>{this.state.investPending.length}</Text> 
        </View>
        

        <View style={tableContainer}>
        <TableView
          handleResetStepView={this.binding.handleResetStepView}
          resetStepView={this.state.resetStepView}
          headerData={headerSuccessInvest}
          rows={this.state.rows}
          numberOfRows={this.state.nbRows}
          isLoading={this.state.isLoading}
          lottie={require('../../../../../../../assets/loading.json')}
          isSortable={false}  
          resetTable={()=>{}}
          datas={this.state.datas}
          fillTables={() => { }}
          keyDatas={headerSuccessInvest}
          headerIndex={[0, 1, 2, 3, 4, 5, 6]}
          headerTextStyle={headerText}
          onPageChange={(page: number) => this.binding.onPageChange(page)}
          nbPage={this.state.nbPage}
          currentPage={this.state.currentPage - 1}
          styleOfChoosenButton={stepColor}/>
        </View>

        {!this.state.showSucces && <View style={bottomLineContainer}> 
            <ButtonView animation={'Bounce'} borders={'Little'} isLoading={this.state.isSendLoading} handleClick={this.binding.handleSendEmailPressed} backgroundColor="#FFB243" style={YesButton}>
              <TranslationView >
                {'email.pending'}
              </TranslationView> 
            </ButtonView>
        </View>}

        {this.state.showSucces && <View style={bottomLineContainer}> 
              <TranslationView style={title}>
                {'email.sent'}
              </TranslationView> 
        </View>}
        

      
 
        
      </View>
    );
  }
}