import { ContentViewModel, ContentView, Application, LanguageManager, Managers } from '@levelapp/softfabric';
import TabCreationViewState from './TabCreationViewState';
import TabCreationViewProps from './TabCreationViewProps';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import TabCreationViewStyle from './TabCreationViewStyle';
import ProjectOperation from '../../../../../../Transfer/ProjectsOperations';
import TabOperation from '../../../../../../Transfer/TabOperation';
import BlockOperation from '../../../../../../Transfer/BlockOperation';
import ValidationPopUpView from '../../../../../../Common/PopUp/validationPopUpView/ValidationPopUpView';
import React from 'react';
import ShowTextPopUpView from '../../../../../../Common/PopUp/showTextPopUpView/ShowTextPopUpView';

export default class TabCreationViewModel extends ContentViewModel<TabCreationViewState, TabCreationViewProps, TabCreationViewStyle>{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    MyLanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    projectOperation = new ProjectOperation();
    tabOperation = new TabOperation();
    blockOperation = new BlockOperation();
    projectCode: any;

    constructor(props: ContentView) {

        super(props);
        /* Initial State */
        this.initialState({
            isLoadingTab: true,
            isLoadingBlock: true,
            currentLanguage: this.MyLanguageManager.language,
            tabOrders: [],
            blockOrders: [],
            currentBlockIndex: 0,
            currentTabIndex: 0,
            isSavedTab: true,
            isSavedBlock: true,
            currentBlock: null,
            currentTab: null,
            isNewTab: false,
            isNewBlock: false,
        });

        /* BINDING */
        this.addNewTab = this.addNewTab.bind(this);
        this.addNewBlock = this.addNewBlock.bind(this);
        this.setBlockOrders = this.setBlockOrders.bind(this);
        this.setCurrentTabIndex = this.setCurrentTabIndex.bind(this);
        this.setCurrentBlockIndex = this.setCurrentBlockIndex.bind(this);
        this.setIsSavedTab = this.setIsSavedTab.bind(this);
        this.setIsSavedBlock = this.setIsSavedBlock.bind(this);
        this.setTabOrders = this.setTabOrders.bind(this);
        this.compare = this.compare.bind(this);
        this.setTabTitle = this.setTabTitle.bind(this);
        this.setTabOrder = this.setTabOrder.bind(this);
        this.setBlockOrder = this.setBlockOrder.bind(this);
        this.setBlockContent = this.setBlockContent.bind(this);
        this.setCurrentTabId = this.setCurrentTabId.bind(this);
        this.removeTab = this.removeTab.bind(this);
        this.removeBlock = this.removeBlock.bind(this);
        this.setCurrentBlockId = this.setCurrentBlockId.bind(this);
        this.setCurrentTabState = this.setCurrentTabState.bind(this);
        this.setCurrentBlockState = this.setCurrentBlockState.bind(this);
        this.afficherStatus = this.afficherStatus.bind(this);
    }
    componentDidMount() {
        this.projectCode = this.parameters<string>();
        if (this.state().tabOrders.length == 0) {
            this.setTabOrders();
        }
    }

    setTabOrders() {
        this.tabOperation.getProjectTabOrders(this.parameters<string>(), (orders: any[]) => {
            let sortedOrders = orders.sort(this.compare);
            this.setField({ tabOrders: sortedOrders }, () => {
                if (sortedOrders.length != 0) {
                    this.tabOperation.getTabById(this.state().tabOrders[this.state().currentTabIndex].id, (tab: any) => {
                        this.setField({ currentTab: tab, isSavedTab: true, isLoadingTab: false }, () => {
                            this.setBlockOrders();
                        });
                    });
                }else {
                    this.setField({ currentTab: null, isSavedTab: true, isLoadingTab: false })
                }
            });
        });
    }

    compare(orderA: any, orderB: any) {
        if (orderA.order < orderB.order)
            return -1;
        if (orderA.order > orderB.order)
            return 1;
        // a doit être égal à b
        return 0;
    }

    setIsSavedTab(value: boolean) {
        this.setField({ isSavedTab: value });
    }

    setIsSavedBlock(value: boolean) {
        this.setField({ isSavedBlock: value });
    }


    setBlockOrders() {
        this.blockOperation.getTabBlockOrders(this.state().currentTab.id, (blockOrders: any[]) => {
            let sortedOrders = blockOrders.sort(this.compare);
            this.setField({ blockOrders: sortedOrders }, () => {
                if (sortedOrders.length != 0) {
                    this.blockOperation.getBlockById(this.state().blockOrders[this.state().currentBlockIndex].id, (block: any) => {
                        this.setField({ currentBlock: block, isSavedBlock: true, isLoadingBlock: false });
                    })
                } else {
                    this.setField({ currentBlock: null, isSavedBlock: true, isLoadingBlock: false });
                }
            });
        });
    }

    setCurrentTabIndex(index: number) {
        if (this.state().currentTabIndex == index) return;

        if (this.state().isNewTab && this.state().tabOrders[this.state().tabOrders.length - 1].id == 0) {
            this.setField({ isNewTab: true, isSavedTab: true, isSavedBlock: true, currentTabIndex: index ,currentBlockIndex :0}, () => {
                this.setTabOrders();
            });
        } else {
            if (!this.state().isSavedTab) {
                this.showPopUp(
                    <ValidationPopUpView text={"NonSavedTab"} callBack={() => {
                        this.tabOperation.getTabById(this.state().tabOrders[index].id, (tab: any) => {
                            this.setField({ currentTabIndex: index,currentBlockIndex :0, isSavedTab: true, currentTab: tab, isLoadingTab: false }, () => {
                                this.setBlockOrders();
                            });
                        });

                    }} />
                )
            }
            if (!this.state().isSavedBlock) {
                this.showPopUp(
                    <ValidationPopUpView text={"NonSavedBlock"} callBack={() => {
                        this.tabOperation.getTabById(this.state().tabOrders[index].id, (tab: any) => {
                            this.setField({ currentTabIndex: index,currentBlockIndex :0, isSavedTab: true, currentTab: tab, isLoadingTab: false }, () => {
                                this.setBlockOrders();
                            });
                        });

                    }} />
                )
            }
            if (this.state().isSavedBlock && this.state().isSavedTab) {
                this.tabOperation.getTabById(this.state().tabOrders[index].id, (tab: any) => {
                    this.setField({ currentTabIndex: index,currentBlockIndex :0, currentTab: tab, isLoadingTab: false, isSavedTab: true }, () => {
                        this.setBlockOrders();
                    });
                });;
            }
        }

    }

    setCurrentBlockIndex(index: number) {
        if (this.state().currentBlockIndex == index) return;

        if (this.state().isNewBlock && this.state().blockOrders[this.state().blockOrders.length - 1].id == 0) {
            this.setField({ isNewBlock: true ,currentBlockIndex : index}, () => {
                this.setBlockOrders();
            });
        } else {
            if (!this.state().isSavedBlock) {
                this.showPopUp(
                    <ValidationPopUpView text={"NonSavedBlock"} callBack={() => {
                        this.blockOperation.getBlockById(this.state().blockOrders[index].id, (block: any) => {
                            this.setField({ currentBlock: block, currentBlockIndex: index, isSavedBlock: true, isLoadingBlock: false });
                        })
                    }} />
                )
            }
            if (this.state().isSavedBlock && this.state().isSavedTab) {
                this.blockOperation.getBlockById(this.state().blockOrders[index].id, (block: any) => {
                    this.setField({ currentBlock: block, currentBlockIndex: index, isLoadingBlock: false });
                })
            }
        }
    }

    setTabTitle(title: string, lang: string) {
        let currentTabTemp = this.state().currentTab;
        switch (lang) {
            case "fr":
                currentTabTemp.title.fr = title;
                this.setField({ currentTab: currentTabTemp });
                break;
            case "nl":
                currentTabTemp.title.nl = title;
                this.setField({ currentTab: currentTabTemp });
                break;
            case "en":
                currentTabTemp.title.en = title;
                var tabOrders = this.state().tabOrders;
                tabOrders[this.state().currentTabIndex].title = title;
                this.setField({ currentTab: currentTabTemp, tabOrders: tabOrders });
                break;
        }
    }

    removeTab() {
        if (this.state().currentTab.id != 0) {
            this.tabOperation.softDelete(this.state().currentTab);
        }
        let tabOrdersTemp = this.removeTabToArray(this.state().tabOrders);
        this.setField({ tabOrders: tabOrdersTemp }, () => {
            if (this.state().tabOrders.length != 0)
                this.setCurrentTabIndex(0);
            else this.setField({ currentTab: null, currentTabIndex: -1, currentBlock: null, currentBlockIndex: -1, blockOrders: [], isSavedTab: true })
        });
    }

    removeTabToArray(array: any[]) {
        array.splice(this.state().currentTabIndex, 1);
        return array;
    }

    removeBlock() {
        if (this.state().currentBlock.id != 0) {
            this.blockOperation.softDelete(this.state().currentBlock);
        }
        let tempBlockOrders = this.removeBlockToArray(this.state().blockOrders);
        this.setField({ blockOrders: tempBlockOrders }, () => {
            if (this.state().blockOrders.length != 0)
                this.setCurrentBlockIndex(0);
            else this.setField({ currentBlock: null, currentBlockIndex: -1, isSavedBlock: true })
        });
    }

    removeBlockToArray(array: any[]) {
        array.splice(this.state().currentBlockIndex, 1);
        return array;
    }

    setBlockOrder(order: string) {
        let orderInt = order;
        let currentBlockTemp = this.state().currentBlock;
        currentBlockTemp.order = orderInt;
        if (order != "") {
            let blockOrders = this.state().blockOrders;
            blockOrders[this.state().currentBlockIndex].order = order;
            this.setField({ currentBlock: currentBlockTemp, blockOrders: blockOrders });
        }
    }

    setBlockContent(content: string, lang: string) {
        let currentBlockTemp = this.state().currentBlock;
        switch (lang) {
            case "fr":
                currentBlockTemp.content.fr = content;
                this.setField({ currentBlock: currentBlockTemp });
                break;
            case "nl":
                currentBlockTemp.content.nl = content;
                this.setField({ currentBlock: currentBlockTemp });
                break;
            case "en":
                currentBlockTemp.content.en = content;
                this.setField({ currentBlock: currentBlockTemp });
                break;
        }
    }

    setTabOrder(order: string) {
        let orderInt = order;
        let currentTabTemp = this.state().currentTab;
        currentTabTemp.order = orderInt;
        let tabOrders = this.state().tabOrders;
        tabOrders[this.state().currentTabIndex].order = order;
        this.setField({ currentTab: currentTabTemp, tabOrders: tabOrders });
    }

    addNewTab() {
        if (this.state().isSavedTab) {
            let orderTab = {
                "id": 0,
                "order": "",
                "title": "",
            }

            let tabToInsert = {
                "id": 0,
                "projectCode": this.parameters<string>(),
                "order": "",
                "title": {
                    "fr": "",
                    "nl": "",
                    "en": "",
                },
                "publishingStateId": 1,
                "blocks": []
            }

            let tabOrderTemp = this.state().tabOrders;
            tabOrderTemp.push(orderTab);
            this.setField({ tabOrders: tabOrderTemp, blockOrders: [], currentTabIndex: this.state().tabOrders.length - 1, currentBlockIndex: -1, currentTab: tabToInsert, currentBlock: undefined, isSavedTab: false, isSavedBlock: true, isNewTab: true }, () => { });
        }
    }

    setCurrentTabId(newId: number) {
        let currentTabTemp = this.state().currentTab;
        currentTabTemp.id = newId;
        let tabOrdersTemp = this.state().tabOrders;
        tabOrdersTemp[this.state().currentTabIndex].id = newId;
        this.setField({ currentTab: currentTabTemp, tabOrders: tabOrdersTemp });
    }

    setCurrentBlockId(newId: number) {
        let currentBlockTemp = this.state().currentBlock;
        currentBlockTemp.id = newId;
        let blockOrdersTemp = this.state().blockOrders;
        blockOrdersTemp[this.state().currentBlockIndex].id = newId;
        this.setField({ currentBlock: currentBlockTemp, blockOrders: blockOrdersTemp });
    }

    setCurrentTabState(state: number) {
        let currentTabTemp = this.state().currentTab;

        if (currentTabTemp.publishingStateId == 2 && state == 3
            || currentTabTemp.publishingStateId == 3 && state == 2) {
            currentTabTemp.publishingStateId = 4;
        } else if (state == 1 && currentTabTemp.publishingStateId == 4) {
            currentTabTemp.publishingStateId = 3;
        } else {
            currentTabTemp.publishingStateId = state;
        }
        this.setField({ currentTab: currentTabTemp }, () => {
            this.setBlockOrders();
        });
    }

    setCurrentBlockState(state: number) {
        let currentBlockTemp = this.state().currentBlock;
        if (currentBlockTemp.publishingStateId == 2 && state == 3
            || currentBlockTemp.publishingStateId == 3 && state == 2) {
            currentBlockTemp.publishingStateId = 4;
        } else if (state == 1 && currentBlockTemp.publishingStateId == 4) {
            currentBlockTemp.publishingStateId = 3;
        } else {
            currentBlockTemp.publishingStateId = state;
        }
        this.setField({ currentBlock: currentBlockTemp });
    }

    addNewBlock() {
        if (!this.state().isSavedTab) {
            this.showPopUp(
                <ShowTextPopUpView text={"CreateBlockError"}></ShowTextPopUpView>
            )
        } else {
            let orderBlock = {
                "id": 0,
                "order": "",
            }
            let blockToInsert = {
                "id": 0,
                "tabId": this.state().currentTab.id,
                "order": "",
                "content": {
                    "fr": "",
                    "nl": "",
                    "en": "",
                },
                "publishingStateId": 1,
            }

            let blockOrderTemp = this.state().blockOrders;
            blockOrderTemp.push(orderBlock);
            this.setField({ blockOrders: blockOrderTemp, currentBlock: blockToInsert, currentBlockIndex: blockOrderTemp.length - 1, isSavedBlock: false, isNewBlock: true });
        }
    }

    afficherStatus(status: number) {
        switch (status) {
            case 1:
                return "NotPublished";
            case 2:
                return "PlushStaging";
            case 3:
                return "PublishProd";
            case 4:
                return "PublishProdAndStaging";
            default:
                return "";
                break;
        }
    }
}