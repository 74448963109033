import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import QuestionsViewState from './QuestionsViewState';
import QuestionsViewProps from './QuestionsViewProps';
import QuestionsViewStyle from './QuestionsViewStyle';
import QuestionsViewModel from './QuestionsViewModel';
import { View, TextInput,Text, TouchableWithoutFeedback } from 'react-native';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import ProjectQuestionDTO from '../../../../../../../../../../../../DTO/ProjectQuestionDTO';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';

export default class QuestionsView extends ContentView<QuestionsViewProps, QuestionsViewState, QuestionsViewModel, QuestionsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:QuestionsViewProps)
  {
    super(props);

    // Binding
    this.bind(new QuestionsViewModel(this), new QuestionsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {title,containerSameLine,inputInline,input,inputText,label,inputTextDisable,questionContainer,questionTitleContainer,questionDetailsContainer,button,buttonContainer} = this.style
    return (
      <View>
        <TranslationView style={title}>project.questions</TranslationView>   
        {this.props.project.projectQuestions && this.props.project.projectQuestions.map((projectQuestionDTO : ProjectQuestionDTO, index : number) => {return(
        <View key={index} style={questionContainer}>
          <TouchableWithoutFeedback onPress={()=>this.binding.handleShowHideQuestions(index)}>
            <View style={questionTitleContainer}  >
                <Text style={title}>{projectQuestionDTO.title.fr}</Text>
                <Text >{this.state.indexQuestionsToShow.includes(index)? ' (hide)' :' (show)' }</Text>
            </View>
          </TouchableWithoutFeedback>

          {this.state.indexQuestionsToShow.includes(index) && <View style={questionDetailsContainer}>
          <View style={containerSameLine}>
              <View style={inputInline}>
                <InputView 
                subscribe={() => { }} 
                shouldCheck={false} 
                inputClass="col-lg-7" 
                label={"Ordre"} 
                labelClass="col-lg-7" 
                onChange={(title : string)=>this.props.onOrderChange(title,index)}
                value={projectQuestionDTO.order} 
                disabled = {!this.props.isEditable} 
                required={false} 
                type="text" />
              </View> 
            </View>
            <View style={containerSameLine}>
              <View style={input}>
                <InputView 
                subscribe={() => { }} 
                shouldCheck={false} 
                inputClass="col-lg-7" 
                label={"Question FR"} 
                labelClass="col-lg-7" 
                onChange={(title : string)=>this.props.onTitleFRChange(title,index)}
                value={projectQuestionDTO.title.fr} 
                disabled = {!this.props.isEditable} 
                required={false} 
                type="text" />
              </View> 
            </View>

            <View style={containerSameLine}>
              <View style={input}>
                <InputView 
                subscribe={() => { }} 
                shouldCheck={false} 
                inputClass="col-lg-7" 
                label={"Question NL"}
                labelClass="col-lg-7" 
                onChange={(title : string)=>this.props.onTitleNLChange(title,index)}
                value={projectQuestionDTO.title.nl } 
                disabled = {!this.props.isEditable} 
                required={false} 
                type="text" />
              </View> 
            </View>

            <View style={containerSameLine}>
              <View style={input}>
                <InputView 
                subscribe={() => { }} 
                shouldCheck={false} 
                inputClass="col-lg-7" 
                label={"Question EN"}
                labelClass="col-lg-7" 
                onChange={(title : string)=>this.props.onTitleENChange(title,index)}
                value={projectQuestionDTO.title.en } 
                disabled = {!this.props.isEditable} 
                required={false} 
                type="text" />
              </View> 
            </View>
            
            <View style={containerSameLine}>
              <View style={inputInline}>
                <Text style={label}>Reponse FR</Text>
                <TextInput 
                multiline={true} 
                style={this.props.isEditable ? inputText : inputTextDisable}
                numberOfLines={7} 
                editable={this.props.isEditable} 
                value={projectQuestionDTO.response.fr } 
                onChangeText={(title : string)=>this.props.onResponseFRChange(title,index)} />
              </View>

              <View style={inputInline}>
                <Text style={label}>Reponse NL</Text>
                <TextInput 
                multiline={true} 
                style={this.props.isEditable ? inputText : inputTextDisable}
                numberOfLines={7} 
                editable={this.props.isEditable} 
                value={projectQuestionDTO.response.nl } 
                onChangeText={(title : string)=>this.props.onResponseNLChange(title,index)} />
              </View>

              <View style={inputInline}>
                <Text style={label}>Reponse EN</Text>
                <TextInput 
                multiline={true} 
                style={this.props.isEditable ? inputText : inputTextDisable}
                numberOfLines={7} 
                editable={this.props.isEditable} 
                value={projectQuestionDTO.response.en} 
                onChangeText={(title : string)=>this.props.onResponseENChange(title,index)}  />          
              </View> 
            </View>
                  
          </View>}
        </View>)
      })}

        {this.props.isEditable && <View style={buttonContainer}  >
          <ButtonView style = {button} animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleAddClick}>
            <Text >
              {"+"}
            </Text> 
          </ButtonView>
        </View>}
        
        


       
      </View>
    );
  }
}