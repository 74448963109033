import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import FileBankInformationPopUpViewState from './FileBankInformationPopUpViewState';
import FileBankInformationPopUpViewProps from './FileBankInformationPopUpViewProps';
import * as XLSX from 'xlsx';
import PaymentInformationOperation from '../../../../../../../../../../../../../Transfer/PaymentInformationOperation';

export default class FileBankInformationPopUpViewModel extends ContentViewModel<FileBankInformationPopUpViewState, FileBankInformationPopUpViewProps>
{
    paymentInformationOperation = new PaymentInformationOperation();
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            emailBankingInformations :{
                titleFR : '',
                titleNL : '',
                confirmDate: '',
                payementdate: '',
                payementNumber : 1
            },
            file : undefined,
            values :[1,2,3],
            showErrors : false,
            isLoading : false
            
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleConfirmPressed = this.handleConfirmPressed.bind(this);

        this.handleTitleFRChanged = this.handleTitleFRChanged.bind(this);
        this.handleTitleNLChanged = this.handleTitleNLChanged.bind(this);
        this.handleConfirmDateChanged = this.handleConfirmDateChanged.bind(this);
        this.handlePayementDateChanged = this.handlePayementDateChanged.bind(this);
        this.handlePayementNumberChanged = this.handlePayementNumberChanged.bind(this);
        this.handleFileChanged = this.handleFileChanged.bind(this);
        
        

    }
    handleBackPressed() {
        this.closePopUp();
    }

    handleConfirmPressed() {
        if (
            this.state().file == null ||
            this.state().emailBankingInformations.titleFR == '')
        {
            this.setField((previousState) => previousState.showErrors = true);
        }
        else
        {
            this.setField((previousState) => previousState.isLoading = true);
            this.handleReadingBankInformation()
            //alert('ok')
        }
    }


    handleTitleFRChanged(text: string)
    {
        this.setField((previousState) => previousState.emailBankingInformations.titleFR = text);
    }
    handleTitleNLChanged(text: string)
    {
        this.setField((previousState) => previousState.emailBankingInformations.titleNL = text);
    }
    handlePayementNumberChanged(number: any)
    {        
        this.setField((previousState) => previousState.emailBankingInformations.payementNumber = number.value);
    }

    handleConfirmDateChanged(text: string)
    {
        this.setField((previousState) => previousState.emailBankingInformations.confirmDate = text);
    }
    handlePayementDateChanged(text: string)
    {
        this.setField((previousState) => previousState.emailBankingInformations.payementdate = text);
    }

    handleFileChanged(file: File)
    {
        this.setField((previousState) => previousState.file = file);
    }

    handleReadingBankInformation() {
        let file = this.state().file;
        let emailInfos = this.state().emailBankingInformations;
        if (file != undefined) {

            //Read Excel File
            var fileReader = new FileReader();
            fileReader.onloadend = () => {
                // Leave that
                var binary = "";
                var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                this.TransformExcelBankInformationToData(binary,emailInfos);
            }
            fileReader.readAsArrayBuffer(file)
        }
    }

    TransformExcelBankInformationToData(data: any, emailInfos : any) {
        var workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
        var json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        var bankInfos: any[] = [];
        json.forEach((v: any) => {
            let civility = v["Mr / Mme / Mlle"];
            let name = v["Nom"];
            let firstName = v["Prénom"];
            let iban = v["IBAN"];
            let email = v["Email"];
            let salutation = v["Civilité"];
            let lang = v["Langue"];

            let infos = {
                "civility": civility, "name": name, "firstName": firstName, "iban": iban, "email": email, "salutation": salutation, "lang" : lang
            };

            bankInfos.push(infos);

        });
 
        let model = { 
            "PaymentInformationList": bankInfos, 
            "EmailPayementInformation" : emailInfos,
            "ProjectCode": this.props().projectCode };
        
            this.paymentInformationOperation.sendPaymentConfirmationEmail(model, () => {
                this.closePopUp();
        });
    }

    
}