import { ContentViewStyle } from '@levelapp/softfabric';
import { ViewStyle, StyleProp, TextStyle } from 'react-native';

export default class LegalEntityInfosViewStyle extends ContentViewStyle{
    PurpleMenu: StyleProp<ViewStyle> =
    {
        backgroundColor: '#615D8A',
        height: '60px',
        paddingTop: '20px',
        paddingLeft: '78px',
    }

PurpleMenuText: StyleProp<TextStyle> = {
    color: 'white',
    fontSize: 17,
}

PageTitle: StyleProp<TextStyle> = {
    color: '#32325D',
    fontSize: 20,
    paddingLeft: '83px',
    paddingTop: '24px',
    paddingBottom: '22px',
    fontWeight: 'bold',
}

Box: StyleProp<ViewStyle> =
    {
        backgroundColor: 'white',
        marginLeft: '30px',
        marginTop: '30px',
        marginRight: '30px',
        paddingLeft: '92px',
        paddingTop: '51px',
        paddingRight: '150px',
        paddingBottom: '31px',
        borderRadius: 10,
    }

Title: StyleProp<TextStyle> = {
    color: '#FFB243',
    fontSize: 20,
    fontWeight: 'bold',
    marginRight: '40px',
    textAlign: 'center'
}

Title2: React.CSSProperties= {
    color: '#FFB243',
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 30
}

FieldTitle: StyleProp<TextStyle> = {
    marginTop: '30px'
}

headersYellow: React.CSSProperties = {
    color: '#FFB243',
    fontSize: '24px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    lineHeight: '37px'
};

ValidUserButton: StyleProp<ViewStyle> = {
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42px',
    marginTop: '30px',
};

UpdateButton2: StyleProp<ViewStyle> = {
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '42px',
    marginTop: 20,
};

PicturesTitle: React.CSSProperties = {
    color: '#32325D',
    fontSize: '20px',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    lineHeight: '37px',
    marginTop: '70px',
};

EditButton: StyleProp<ViewStyle> = {
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',  
    backgroundColor: '#FFB243',
    marginTop: '57px',
    marginLeft: '10px',
    marginRight: '10px',
    height: '42px',
    width: '100px'
};
UpdateButton: StyleProp<ViewStyle> = {
    borderRadius: 8,
    paddingVertical: 8,
    paddingHorizontal: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',  
    backgroundColor: '#FFB243',
    marginTop: 30,
    height: '42px',

};

FeedbackArea: React.CSSProperties = {
    backgroundColor: 'red',
};
}