import { ContentViewModel, ContentView, NavigationManager, Application, Managers } from '@levelapp/softfabric';
import EditUserViewState from './EditUserViewState';
import EditUserViewProps from './EditUserViewProps';
import Profile from '../../../../../../../DTO/Profile';
import UsersOperation from '../../../../../../../Transfer/UsersOperation';
import RoleManager from '../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../Common/Managers/ExtendedManagers';

export default class EditUserViewModel extends ContentViewModel<EditUserViewState, EditUserViewProps>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    usersOperation = new UsersOperation();
    
    constructor(props: ContentView) {
        super(props);
        var usr = this.parameters<Profile>();

        this.initialState({
            profile: usr
        })

        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.editUserName = this.editUserName.bind(this);

    }   

    componentDidMount()
    {
        this.setField({isLoading : true});
        var usr = this.parameters<Profile>();
        this.NavigationManager.navigate('/dashboard/users/edit/profile',true,usr);     

        this.usersOperation.getUserById(this.state().profile.id, this.handleSuccessUserCallback);
    }

    handleSuccessUserCallback(user : any)
    {
        this.setField({ profile : user});
    }

    editUserName(lastName : any, firstName:any)
    {
        let profile = this.state().profile;
        profile.lastName = lastName;
        profile.firstName = firstName;
        
        this.setField({ profile : profile});
    }
}