import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import SendEmailCouponCourantPopUpViewState from './SendEmailCouponCourantPopUpViewState';
import SendEmailCouponCourantPopUpViewProps from './SendEmailCouponCourantPopUpViewProps';
import SendEmailCouponCourantPopUpViewStyle from './SendEmailCouponCourantPopUpViewStyle';
import SendEmailCouponCourantPopUpViewModel from './SendEmailCouponCourantPopUpViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';
import InputView from '../../../../../../../../../../../../../Components/Input/input/InputView';

export default class SendEmailCouponCourantPopUpView extends ContentView<SendEmailCouponCourantPopUpViewProps, SendEmailCouponCourantPopUpViewState, SendEmailCouponCourantPopUpViewModel, SendEmailCouponCourantPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: SendEmailCouponCourantPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new SendEmailCouponCourantPopUpViewModel(this), new SendEmailCouponCourantPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>

        <TranslationView style={this.style.title}>
          SendEmailCouponCourant
         </TranslationView>

        <Text style={{ marginTop: "20px" }}>Project : {this.props.project.consoleTitle}</Text>
        <Text>Date Émission :	{this.props.project.issueDate}</Text>
        <Text>Taux :	{this.props.project.annualInterestRate}%</Text>
        <Text>Nominal :	{this.props.investment.amount.toLocaleString(undefined, { maximumFractionDigits: 2 })}€</Text>
        <Text>Montant non imposable : {this.state.notImposableAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}€</Text>    
        <Text>Montant imposable : {this.state.imposableAmount.toLocaleString(undefined, { maximumFractionDigits: 2 })}€</Text>

        <View>
          <InputView subscribe={() => { }} shouldCheck={false} placeholder={"dd/mm/yyyy"} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("RealDate")} labelClass="col-lg-7" onChange={this.binding.handleDateChanged} required={true} value={this.state.date} type="text" />
        </View>

        <Text style={{ marginTop: "20px" }}>Date : {this.state.dateAsString}</Text>
        <Text>Days : {this.state.daysNumber}</Text>      
        <Text>Intérêts Bruts : {this.state.brutInterest}</Text>
        <Text>PM :	{this.state.pm}</Text>
        <Text>Intérêts Nets sur la quotité exemptée :	{this.state.netInterestExempted}</Text>
        <Text>Intérêts Nets sur la quotité précomptée :	{this.state.netInterest}</Text>
        <Text>Montant à Payer :	{this.state.amount}</Text>


        <View style={this.style.footer}>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              Cancel
             </TranslationView>
          </ButtonView>
          <ButtonView  animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Confirm
             </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}