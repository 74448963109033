import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class QuestionsViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  
    title: StyleProp<TextStyle> = {
        display : 'flex',
        justifyContent : 'flex-start',
        color: this.MyStyleManager.primaryColor,
        fontSize: 20,
        fontWeight: 'bold',
    }
    containerSameLine : StyleProp<ViewStyle> = {
        display : 'flex',
        flexDirection : 'row', 
        justifyContent : 'flex-start'
    }

    inputInline : StyleProp<ViewStyle> = {
        display : 'flex',
         margin: "1%" ,
         width : '30%',
         flexDirection : 'column'
    }

 
    buttonInLine : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'flex-end',
        margin: "1%" ,
        width : '15%'
    }
    input : StyleProp<ViewStyle> = {
        display : 'flex',
        margin: "1%" ,
        width : '98%',
    }
    inputText : StyleProp<TextStyle> = {
        display : 'flex',
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize: 16,
        borderWidth : 0.1,
        borderRadius : 5,
        padding : 10
    }

    inputTextDisable : StyleProp<TextStyle> = {
        display : 'flex',
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize: 16,
        borderWidth : 0.1,
        borderRadius : 5,
        padding : 10,
        backgroundColor : '#E5E8E8'
    }

    label : StyleProp<TextStyle> = {
        display : 'flex',
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize: 14,
        marginBottom : 15
 
    }
    questionContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        borderWidth : 1,
        borderTopEndRadius : 10,
        borderTopStartRadius : 10,
        marginVertical : 25
    }

    questionTitleContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        flexDirection : 'row',
        borderTopEndRadius : 10,
        borderTopStartRadius : 10,
        backgroundColor :'#E5E8E8',
        padding: 15,


        
        
    }

    questionDetailsContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        paddingTop: 15,
        marginBottom : 20
    }
    button: StyleProp<ViewStyle> =
    {       
        display: 'flex',
        borderRadius: 200,
        height: 50,
        width: 50,
        justifyContent : 'center',
        alignItems : 'center',
    } 
    buttonContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'flex-end',
        flexDirection : 'row',
        borderTopEndRadius : 10,
        borderTopStartRadius : 10,
        padding: 15,   
    }

    
}