import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import CompanyInfosViewState from './CompanyInfosViewState';
import CompanyInfosViewProps from './CompanyInfosViewProps';
import ProjectsOperation from '../../../../../../../../../../../../Transfer/ProjectsOperations';
import TranslationDTO from '../../../../../../../../../../../../DTO/TranslationDTO';
import IBAN from 'iban';
import RegExp from '../../../../../../../../../../../../Constants/RegExp';
import Bank from '../../../../../../../../../../../../DTO/Bank';
import BankOperation from '../../../../../../../../../../../../Transfer/BankOperation';
import BankPopUpView from '../popup/bankpopup/BankPopUpView';
import React from 'react';
import ProjectsLeaderOperation from '../../../../../../../../../../../../Transfer/ProjectLeadersOperations';
import LegalFormsOperations from '../../../../../../../../../../../../Transfer/LegalFormsOperations';
export default class CompanyInfosViewModel extends ContentViewModel<CompanyInfosViewState, CompanyInfosViewProps>
{
    BankOperation = new BankOperation();
    ProjectsOperation = new ProjectsOperation();
    LeaderOperation = new ProjectsLeaderOperation();
    LegalFormsOperations = new LegalFormsOperations();

    /* CONSTRUCTOR */
    constructor(view: ContentView) {
        super(view);

        this.initialState({
            activities: [],
            banks: [],
            isLoading: true,
            isIBANValid: true,
            isBICValid: true,
            leaders: [],
            forms: []

        }); // Initialize your state here

        // Bindings
        this.loadActivitiesList = this.loadActivitiesList.bind(this);
        this.handleActivityDropdownChange = this.handleActivityDropdownChange.bind(this)
        this.handleIBANChange = this.handleIBANChange.bind(this);
        this.handleBICChange = this.handleBICChange.bind(this);
        this.showPopUpBank = this.showPopUpBank.bind(this)
        this.addBank = this.addBank.bind(this);
        this.loadBankList = this.loadBankList.bind(this);
        this.loadLeaders = this.loadLeaders.bind(this);
        this.loadLegalForms = this.loadLegalForms.bind(this);
    }

    componentDidMount() {
        this.loadActivitiesList();
        this.loadBankList();
        this.loadLeaders();
        this.loadLegalForms();
        this.setField((previousState) => previousState.isLoading = false);
    }

    loadActivitiesList() {
        this.ProjectsOperation.getActivitiesList((activities: TranslationDTO[]) => {
            this.setField((previousState) => previousState.activities = activities);
        })
    }

    loadLeaders() {
        this.LeaderOperation.get((leaders: any[]) => {
            leaders.forEach((l: any) => {
                l.fullName = l.firstName + " " + l.lastName;
            });
            this.setField((previousState) => previousState.leaders = leaders);
        })
    }

    loadLegalForms() {
        this.LegalFormsOperations.get((forms: any[]) => {
            forms.forEach((f: any) => {
                f.fullName = f.code + " - " + f.title.fr;
            });
            this.setField((previousState) => previousState.forms = forms);
        })
    }

    handleActivityDropdownChange(activity: any) {
        this.props().onActivityDropdownChange(this.state().activities.find(a => a.id == activity.value))
    }

    handleIBANChange(text: string) {
        this.setField((previousState) => previousState.isIBANValid = this.checkIBANValidity(text));
        this.props().onIBANChange(text)

    }

    handleBICChange(text: string) {
        this.setField((previousState) => previousState.isBICValid = RegExp.BIC.test(text));
        this.props().onBICChange(text)
    }

    checkIBANValidity(text: string): boolean {
        if (IBAN.isValid(text)) {
            return true
        } else {
            return false
        }
    }

    loadBankList() {
        this.BankOperation.get((banks: Bank[]) => {
            this.setField((previousState) => previousState.banks = banks);
            if (banks.find(x => x.id == this.props().project.projectBankId)?.bankFR) {
                this.props().onBankChange({ label: banks.find(x => x.id == this.props().project.projectBankId)?.bankFR, value: this.props().project.projectBankId })
            }
        })

    }
    showPopUpBank() {
        this.showPopUp(
            <BankPopUpView
                saveBank={this.addBank} />
        );
    }
    addBank(bank: Bank) {
        this.BankOperation.addBank(bank, () => {
            this.loadBankList();
            this.closePopUp();
        })
    }
}