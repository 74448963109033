import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import Bank from '../DTO/Bank';

export default class BankService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    
    async getBanks(callback: Function)
    {
        this.httpClient.get("api/bank/GetBanksList", []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async getBankById(bankId: number, callback: Function)
    {
        this.httpClient.get("api/bank/ById/" + bankId, []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }    

    async post(bank: Bank, onSuccess: Function) {
        this.httpClient
            .post('api/bank', [],bank)
            .then((response: any) => {
                if (response) {
                    if (response.data) {
                        onSuccess(response.data.data);
                    }
                }
            });
    }

}