import {
  Service,
  Application,
  HttpManager,
  Managers,
} from "@levelapp/softfabric";

export default class UsersService extends Service {
  httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

  async get(callback: Function) {
    this.httpClient.get("api/User", []).then((response: any) => {
      if (response != undefined) {
        callback(response.data.data);
      }
    });
  }

  async getUserById(userId: any, callback: Function) {
    this.httpClient
      .get("api/User/GetUserById/" + userId, [])
      .then((response: any) => {
        if (response != undefined) {
          callback(response.data.data);
        }
      });
  }

  async validUser(usr: any, callback: Function) {
    this.httpClient
      .post("api/User/ValidateUser", [], usr)
      .then((response: any) => {
        if (response != undefined) {
          callback(response.data.data);
        }
      });
  }

  async updateUserStatus(updateUserStatus: any, callback: Function) {
    this.httpClient
      .put("api/User/UpdateUserStatus", [], updateUserStatus)
      .then((response: any) => {
        if (response != undefined) {
          callback(response.data.data);
        }
      });
  }

  async resetUserAvertiStatus(userId: any, callback: Function) {
    this.httpClient
      .put("api/User/ResetAvertiStatus/" + userId, [], null)
      .then((response: any) => {
        if (response != undefined) {
          callback(response.data.data);
        }
      });
  }

  async updateUser(user: any, callback: Function) {
    this.httpClient
      .put("api/User/UpdateUser", [], user)
      .then((response: any) => {
        if (response != undefined) {
          callback(response.data.data);
        }
      });
  }

  async getUserInfos(onSuccess: Function) {
    this.httpClient.get("api/IdentityInfos", []).then((response: any) => {
      if (response) {
        if (response.data) {
          onSuccess(response.data.data);
        }
      }
    });
  }

  async getSearchPagination(
    status: any,
    page: number,
    itemPage: number,
    searchText: string,
    getInvestors: boolean,
    callback: Function = () => {},
  ) {
    if (!searchText) searchText = "placeholder";
    //searchText = encodeURI(searchText);
    this.httpClient
      .get(
        "api/User/Pagination/" +
          status +
          "/" +
          page +
          "/" +
          itemPage +
          "/" +
          searchText +
          "/" +
          getInvestors,
      )
      .then((response: any) => {
        if (response) {
          if (response.data) {
            callback(response.data.data);
          }
        }
      });
  }

  async export(
    status: any,
    searchText: string,
    getInvestors: any,
    callback: Function = () => {},
  ) {
    if (!searchText) searchText = "placeholder";
    this.httpClient
      .get("api/User/Export/" + status + "/" + searchText + "/" + getInvestors)
      .then((response: any) => {
        if (response) {
          if (response.data) {
            callback(response.data.data);
          }
        }
      });
  }

  async exportAll(callback: Function = () => {}) {
    this.httpClient.get("api/User/ExportAll").then((response: any) => {
      if (response) {
        if (response.data) {
          callback(response.data.data);
        }
      }
    });
  }

  async exportVBout(
    page: number,
    itemPage: number,
    status: any,
    searchText: string,
    callback: Function = () => {},
  ) {
    if (!searchText) searchText = "placeholder";
    this.httpClient
      .get(
        "api/User/ExportVBout/" +
          status +
          "/" +
          page +
          "/" +
          itemPage +
          "/" +
          searchText,
      )
      .then((response: any) => {
        if (response) {
          if (response.data) {
            callback(response.data.data);
          }
        }
      });
  }

  async exportNotAvertis(callback: Function = () => {}) {
    this.httpClient.get("api/User/GetNonAvertiUsers/").then((response: any) => {
      if (response) {
        if (response.data) {
          callback(response.data.data);
        }
      }
    });
  }

  async exportAvertisNull(callback: Function = () => {}) {
    this.httpClient
      .get("api/User/GetAvertiNullUsers/")
      .then((response: any) => {
        if (response) {
          if (response.data) {
            callback(response.data.data);
          }
        }
      });
  }

  async checkIdentityValidity(sendEmail: boolean, onSuccess: Function) {
    this.httpClient
      .get("api/User/CheckIdentityValidity?sendEmail=" + sendEmail, [])
      .then((response: any) => {
        if (response) {
          if (response.data) {
            onSuccess(response.data.data);
          }
        }
      });
  }
}
