import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import TableCellViewState from './TableCellViewState';
import TableCellViewProps from './TableCellViewProps';

export default class TableCellViewModel extends ContentViewModel<TableCellViewState, TableCellViewProps>{
    constructor(view:ContentView)
    {
        super(view);

        this.initialState({

        });
    }
}