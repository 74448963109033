import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ViewStyle } from 'react-native';

export default class ResearchInputViewStyle extends ContentViewStyle
{
    input : StyleProp<ViewStyle> =
    {
        flexDirection : 'row',
        marginRight : 5,
        marginBottom : 25,
        height : 25,
    }

    textInFront : StyleProp<ViewStyle> =
    {
        marginRight: 5,
        alignSelf : 'center',
    }




    inputView : StyleProp<ViewStyle> =
    {
        flex: 1,
        flexDirection : 'row',
        alignSelf : 'stretch',

    };

    button : StyleProp<ViewStyle> =
    {
        position : 'absolute',
        right : 0,
        alignSelf : 'center',
        marginRight :5,
    };

    containerInput : StyleProp<ViewStyle> =
    {
        flexDirection :'row',
        flex : 1,
        borderColor : 'gray',
        borderWidth : 1,
        borderRadius : 5,
    }

}