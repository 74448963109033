import { ContentViewModel, ContentView, Application, NavigationManager, Managers } from '@levelapp/softfabric';
import UsersOperation from '../../../../../Transfer/UsersOperation';
import * as XLSX from 'xlsx';
import { Text, View } from 'react-native';
import React from 'react';
import StatusOperation from '../../../../../Transfer/StatusOperations';
import StateManager from '../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../Common/Managers/StateManagerConst';
import ExtendedManagers from '../../../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../../../Common/Managers/RolesManager';
import Helpers from '../../../../../Components/Helpers/Helpers';
import NonInvestorsViewState from './NonInvestorsViewState';
import NonInvestorsViewProps from './NonInvestorsViewProps';
import NonInvestorsViewStyle from './NonInvestorsViewStyle';

export default class NonInvestorsViewModel extends ContentViewModel<NonInvestorsViewState, NonInvestorsViewProps, NonInvestorsViewStyle>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    usersOperation = new UsersOperation();
    textResearch: string = "";
    interval: any;


    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.USER);
        this.interval = null;


        /* Initial State */
        this.initialState({
            statuses: [],
            selectedStatus: previousState != undefined ? previousState.selectedStatus : { id: 0, title: "ALL", users: null },
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            isLoading: true,
            users: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            isExporting: false,
            isExport: this.RoleManager.isExport,
            isAdmin: this.RoleManager.isAdmin,
            isCheckingValidty: false,
        });

        if (previousState != undefined) {
            this.textResearch = previousState.text;
        }

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.exportUsers = this.exportUsers.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.setStatuses = this.setStatuses.bind(this);
        this.handleStatusChanged = this.handleStatusChanged.bind(this);
        this.resetResearch = this.resetResearch.bind(this);
        this.pushRows = this.pushRows.bind(this);
        this.resetTable = this.resetTable.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.setStatuses();
        if (Helpers.getQueryVariable('id')) {
            this.usersOperation.getUserById(Helpers.getQueryVariable('id'), this.editField);
        }
    }

    refresh() {
        this.setField({ isLoading: true });
        this.usersOperation.getSearchPagination(this.state().selectedStatus.title, this.state().currentPage, 20, this.textResearch, false, this.handleSuccessUserCallback);
    }

    setStatuses() {
        let statuses: any[] = [];
        statuses.push({ id: 0, title: "ALL", users: null });
        new StatusOperation().getStatuses("P", (statusList: any) => {
            statusList.forEach((status: any) => {
                statuses.push(status);
            });
            var ordering = {} as any, // map for efficient lookup of sortIndex
                sortOrder = ['ALL', 'NEW', 'VALIDATED', 'VALIDATED TO VERIFY', 'FIELDS TO VERIFY', 'PENDING', 'PENDING - ID', 'ID UPLOADED', 'ID EXPIRED', 'POD UPLOADED', 'POB UPLOADED', 'DOC MISSING - ALL', 'DOC MISSING - ID', 'DOC MISSING - POD', 'DOC MISSING - POB', 'BLOCKED', 'REFUSED'];
            for (var i = 0; i < sortOrder.length; i++) {
                ordering[sortOrder[i]] = i;
            }
            statuses.sort(function (a, b) {
                return (ordering[a.title] - ordering[b.title]) || a.title.localeCompare(b.title);
            });
            this.setField({ statuses: statuses });
        });
    }

    handleSuccessUserCallback(ret: any) {
        let users: any[] = ret.objects;
        let datas: any[] = [];
        let rows: any[] = [];

        if (ret.totalPage == 0) {
            this.setField({ datas: [] });
            this.setField({ rows: [] });
            this.setField({ initialData: [] });
            this.setField({ initialRow: [] });
            this.setField({ tableHeaders: ['ID', 'NUMBER', 'DATE CREATION', 'NOMS', 'MAIL', 'STATUS', 'MODIFICATION', 'EDIT'] });
            this.setField({ users: undefined });
            this.setField({ isLoading: false });
            this.setField({ nbPage: ret.totalPage });
            return;
        }

        users.forEach((user) => {
            let userDTO = {
                "id": user.id,
                "creationDate": user.lastModificationDate,
                "name": user.firstName + " " + user.lastName,
                "clientNumber": user.clientNumber,
                "email": user.email,
                "userStatusTitle": user.userStatus.title,
                "heard": user.howDoIHeardAboutBeeBonds
            }

            //var date = new Date(user.formCompletionDate);
            //var dateAsString = date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " ";
            //date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();

            var modifDate = new Date(user.lastModificationDate);
            var modifDateAsString = modifDate.getDate() + "-" + (modifDate.getMonth() + 1) + "-" + modifDate.getFullYear() + " ";
            //modifDate.getHours() + ":" + modifDate.getMinutes() + ":" + modifDate.getSeconds();

            datas.push(userDTO);
            rows.push(
                <View>
                    <Text style={this.style().text}>{userDTO.id}</Text>
                    <Text style={this.style().text}>{userDTO.clientNumber}</Text>
                    <Text style={this.style().text}>{userDTO.name}</Text>
                    <Text style={this.style().text}>{userDTO.email}</Text>
                    <Text style={this.style().text}>{modifDateAsString}</Text>
                    <Text style={this.style().text}>{userDTO.heard}</Text>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ tableHeaders: ['ID', 'NUMBER', 'NOMS', 'MAIL', 'DATE', 'Heard about BB'] });
        this.setField({ users: users });
        this.setField({ isLoading: false });
        this.setField({ nbPage: ret.totalPage });
    }

    editField(user: any) {
        //if(this.RoleManager.isAdmin || this.RoleManager.isEditor)
        //{
        let myUser = this.state().users.find((userFull) => userFull.id === user.id);
        if (myUser == null) {
            this.NavigationManager.navigate('/dashboard/users/edit', true, user);
        } else {
            this.NavigationManager.navigate('/dashboard/users/edit', true, myUser);
        }
        //}else{
        //  alert("No permission")
        //}
    }

    componentWillUnmount() {
        this.StateManager.addState(StateManagerConst.USER, this.state());
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    handleStatusChanged(value: any) {
        this.setField({ selectedStatus: value }, () => {
            this.handleResearching(this.state().text);
        });
    }

    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }

    resetTable() {
        if (this.state().selectedStatus.title == "ALL" && this.state().text == "") {
            this.resetResearch();
        }
    }

    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(user => {
            this.pushRow(user, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(user: any, rowsToPush: any[]) {
        var modifDate = new Date(user.creationDate);
        var modifDateAsString = modifDate.getDate() + "-" + (modifDate.getMonth() + 1) + "-" + modifDate.getFullYear() + " ";

        rowsToPush.push(
            <View>
                <Text style={this.style().text}>{user.id}</Text>
                <Text style={this.style().text}>{user.clientNumber}</Text>
                <Text style={this.style().text}>{user.name}</Text>
                <Text style={this.style().text}>{user.email}</Text>
                <Text style={this.style().text}>{modifDateAsString}</Text>
                <Text style={this.style().text}>{user.heard}</Text>
            </View>
        )
    }

    handlingClose() {
        this.state().isResearching = false;
        this.textResearch = "";
        this.onPageChange(0);
    }

    resetResearch() {
        this.setField({ datas: this.state().initialData.slice(0) });
        this.setField({ rows: this.state().initialRow.slice(0) });
        this.setField({ selectedStatus: "ALL" });
    }

    handleResearching(searchText: string) {
        this.setField({ isLoading: true, isResearching: true, text: searchText });
        this.textResearch = searchText;

        if (this.interval) {
            clearInterval(this.interval);
        }
        this.interval = setTimeout(() => {
            this.onPageChange(0)
        }, 1000)
    }

    exportUsers() {
        this.setField({ isExporting: true });
        this.usersOperation.export(this.state().selectedStatus.title, this.textResearch, false, (userToExport: any) => {

            var headers = ['id', 'Date de création du compte', 'Email', 'Prénom', 'Nom', 'Comment a entendu parler de BeeBonds'];
            var jsonContent: any[] = [];
            userToExport.forEach((value: any) => {
                var element = "{";
                var row = "";

                var modifDate = new Date(value.lastModificationDate);
                var month = (modifDate.getMonth() + 1).toString();
                if (modifDate.getMonth() + 1 < 10)
                    month = "0" + month;
                var day = (modifDate.getDate()).toString();
                if (modifDate.getDate() < 10)
                    day = "0" + day;
                var modifDateAsString = modifDate.getFullYear() + '' + month + '' + day;

                row += JSON.stringify(headers[0]) + ":" + JSON.stringify(value.id);
                row += ",";
                row += JSON.stringify(headers[1]) + ":" + JSON.stringify(modifDateAsString);
                row += ",";
                row += JSON.stringify(headers[2]) + ":" + JSON.stringify(value.email);
                row += ",";
                row += JSON.stringify(headers[3]) + ":" + JSON.stringify(value.firstName);
                row += ",";
                row += JSON.stringify(headers[4]) + ":" + JSON.stringify(value.howDoIHeardAboutBeeBonds);
                element = element + row;
                element += "}";
                jsonContent.push(JSON.parse(element));
            });
            var wb = XLSX.utils.book_new();
            var o = XLSX.utils.json_to_sheet(jsonContent, { header: headers });
            XLSX.utils.book_append_sheet(wb, o, "Users");
            XLSX.writeFile(wb, "BeeBondsUsers.xlsx");
            this.setField({ isExporting: false });
        });
    }
}