import { Operation } from '@levelapp/softfabric';
import BlockService from '../Proxy/BlockService';


export default class BlockOperation extends Operation
{
    _service : BlockService;

    constructor()
    {
        super();
        this._service = new BlockService();
    }   

    async getTabBlockOrders(tabId : number,callback: Function = () => { })
    {
        await (this._service as BlockService).getTabBlockOrders(tabId,callback);
    }

    async getBlockById(id : number,callback: Function = () => { })
    {
        await (this._service as BlockService).getBlockById(id,callback);
    }

    async insertBlock(block : any,callback: Function = () => { })
    {
        await (this._service as BlockService).inserBlock(block,callback);
    }

    async updateBlock(block : any,callback: Function = () => { })
    {
        await (this._service as BlockService).updateBlock(block,callback);
    }

    async softDelete(block : any,callback: Function = () => { })
    {
        await (this._service as BlockService).softDelete(block,callback);
    }

    async depublish(block : any,callback: Function = () => { })
    {
        await (this._service as BlockService).depublish(block,callback);
    }

    async publishStaging(block : any,callback: Function = () => { })
    {
        await (this._service as BlockService).publishStaging(block,callback);
    }

        async publishProd(block : any,callback: Function = () => { })
    {
        await (this._service as BlockService).publishProd(block,callback);
    }
}