import React from 'react';
import { ContentView, Application } from '@levelapp/softfabric';
import TabViewState from './TabViewState';
import TabViewProps from './TabViewProps';
import TabViewStyle from './TabViewStyle';
import TabViewModel from './TabViewModel';
import { View, TextInput, Text } from 'react-native';
import { TranslationView, ButtonView, StyleManager, UiManagers } from '@levelapp/softfabric-ui';

export default class TabView extends ContentView<TabViewProps, TabViewState, TabViewModel, TabViewStyle>
{
  MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
  /* CONSTRUCTOR */
  constructor(props: TabViewProps) {
    super(props);

    // Binding
    this.bind(new TabViewModel(this), new TabViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        < View style={this.style.box}>
          <View style={{ padding: 50 }}>

            <View style={this.style.InputRow}>
              <TranslationView style={this.style.title}>State</TranslationView>
              <TranslationView style={this.style.status} >{this.props.afficherStatus(this.props.currentTab.publishingStateId)}</TranslationView>
            </View>

            <View style={this.style.buttonList}>
              {/* REMOVE TAB */}
              {this.props.currentTab.id != 0 &&
                <View style={this.style.button}>
                  <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.removeTab} textColor="white" isLoading={this.state.isLoading} backgroundColor="#FF392C">
                    <TranslationView>
                      Delete
                    </TranslationView>
                  </ButtonView>
                </View>
              }
              {/* SEND BUTTON */}
              <View style={this.style.button}>
                <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.submit} textColor="white" isLoading={this.state.isLoading} backgroundColor="#4ECF6C">
                  <TranslationView>
                    Save
                    </TranslationView>
                </ButtonView>
              </View>
              {/* DEPUBLISH*/}
              {(this.props.currentTab.publishingStateId == 2 || this.props.currentTab.publishingStateId == 4) && this.props.currentTab.id != 0 &&
                <View style={this.style.button}>
                  <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.dePublish} textColor="white" isLoading={this.state.isLoading} backgroundColor="grey">
                    <TranslationView>
                      Depublish
                  </TranslationView>
                  </ButtonView>
                </View>
              }
              {/* PUBLISH STAGING */}
              {this.props.currentTab.publishingStateId != 2 && this.props.currentTab.publishingStateId != 4 && this.props.currentTab.id != 0 &&
                <View style={this.style.button}>
                  <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.publishStaging} textColor="white" isLoading={this.state.isLoading} backgroundColor="#615D8A">
                    <TranslationView>
                      PublishStaging
                    </TranslationView>
                  </ButtonView>
                </View>
              }
              {/* PUBLISH PROD */}
              {this.props.currentTab.publishingStateId != 3 && this.props.currentTab.publishingStateId != 4 && this.props.currentTab.id != 0 &&
                <View style={this.style.button}>
                  <ButtonView  animation={'None'} borders={'Little'} handleClick={this.binding.publishProd} textColor="white" isLoading={this.state.isLoading} backgroundColor={this.MyStyleManager.primaryColor}>
                    <TranslationView>
                      PublishProd
                    </TranslationView>
                  </ButtonView>
                </View>
              }
            </View>
            <View style={this.style.orderView}>
              <View style={this.style.InputRow}>
                <Text style={this.style.title}>Tab Order:</Text>
                <TextInput style={{ fontSize: 20, width: 50, borderWidth: 1, borderColor: 'grey', }} placeholder="?" onChangeText={(orderS: string) => this.binding.handleOrderTabChange(orderS)} value={this.props.currentTab.order} />
              </View>
            </View>
            <View style={this.style.tableHeader}>
              <TranslationView style={this.style.title}>EnterTabTitle</TranslationView>

              <View style={this.style.InputRow}>
                <TranslationView style={this.style.title} >fr</TranslationView>
                <TextInput style={{ fontSize: 20, borderWidth: 1, borderColor: 'grey' }} placeholder={this.binding.translate('TitleTab')} onChangeText={(title: string) => this.binding.handleTitleChanged(title, 'fr')} value={this.props.currentTab.title.fr} />
              </View>

              <View style={this.style.InputRow}>
                <TranslationView style={this.style.title} >nl</TranslationView>
                <TextInput style={{ fontSize: 20, borderWidth: 1, borderColor: 'grey', }} placeholder={this.binding.translate('TitleTab')} onChangeText={(title: string) => this.binding.handleTitleChanged(title, 'nl')} value={this.props.currentTab.title.nl} />
              </View>

              <View style={this.style.InputRow}>
                <TranslationView style={this.style.title} >en</TranslationView>
                <TextInput style={{ fontSize: 20, borderWidth: 1, borderColor: 'grey', }} placeholder={this.binding.translate('TitleTab')} onChangeText={(title: string) => this.binding.handleTitleChanged(title, 'en')} value={this.props.currentTab.title.en} />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}