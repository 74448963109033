import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import DescriptionViewState from './DescriptionViewState';
import DescriptionViewProps from './DescriptionViewProps';
import DescriptionViewStyle from './DescriptionViewStyle';
import DescriptionViewModel from './DescriptionViewModel';
import { TextInput, View,Text } from 'react-native';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class DescriptionView extends ContentView<DescriptionViewProps, DescriptionViewState, DescriptionViewModel, DescriptionViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:DescriptionViewProps)
  {
    super(props);

    // Binding
    this.bind(new DescriptionViewModel(this), new DescriptionViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {title,containerSameLine,input,inputText,label,inputTextDisable} = this.style
    return (
      <View>   
        <TranslationView style={title}>projet.description.description</TranslationView>
        <View style={[containerSameLine,{flexDirection : 'column'}]}>
          <View style={input}>
          <Text style={label}>FR</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.projectDescription.fr} 
          onChangeText={(projectDescription: string) => this.props.onDescriptionFRChange(projectDescription)} />
          </View>
          <View style={input}>
          <Text style={label}>NL</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.projectDescription.nl} 
          onChangeText={(projectDescription: string) => this.props.onDescriptionNLChange(projectDescription)} />
          </View>
          <View style={input}>
          <Text style={label}>EN</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.projectDescription.en} 
          onChangeText={(projectDescription: string) => this.props.onDescriptionENChange(projectDescription)} />
          </View>          
        </View>

        <TranslationView style={title}>AboutLegalText</TranslationView>
        <View style={[containerSameLine,{flexDirection : 'column'}]}>
          <View style={input}>
          <Text style={label}>FR</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.aboutLegalInfoText.fr} 
          onChangeText={(projectDescription: string) => this.props.onAboutLegalTextFRChange(projectDescription)} />
          </View>
          <View style={input}>
          <Text style={label}>NL</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.aboutLegalInfoText.nl} 
          onChangeText={(projectDescription: string) => this.props.onAboutLegalTextNLChange(projectDescription)} />
          </View>
          <View style={input}>
          <Text style={label}>EN</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.aboutLegalInfoText.en} 
          onChangeText={(projectDescription: string) => this.props.onAboutLegalTextENChange(projectDescription)} />
          </View>          
        </View>

        <TranslationView style={title}>DocumentLegalText</TranslationView>
        <View style={[containerSameLine,{flexDirection : 'column'}]}>
          <View style={input}>
          <Text style={label}>FR</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.documentsLegalInfoText.fr} 
          onChangeText={(projectDescription: string) => this.props.onDocumentLegalTextFRChange(projectDescription)} />
          </View>
          <View style={input}>
          <Text style={label}>NL</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.documentsLegalInfoText.nl} 
          onChangeText={(projectDescription: string) => this.props.onDocumentLegalTextNLChange(projectDescription)} />
          </View>
          <View style={input}>
          <Text style={label}>EN</Text>
          <TextInput 
          multiline={true} 
          style={this.props.isEditable ? inputText : inputTextDisable}
          numberOfLines={7} 
          editable={this.props.isEditable} 
          value={this.props.project.documentsLegalInfoText.en} 
          onChangeText={(projectDescription: string) => this.props.onDocumentLegalTextENChange(projectDescription)} />
          </View>          
        </View>
      
      </View>
    );
  }
}