import { Application, ContentViewStyle } from "@levelapp/softfabric";
import { StyleProp, TextStyle, ViewStyle } from "react-native";
import { StyleManager, UiManagers } from "@levelapp/softfabric-ui";
export default class PopUpSendNotificationViewStyle extends ContentViewStyle {
  MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(
    UiManagers.STYLE_MANAGER,
  );

  benefitDetailsContainer: StyleProp<ViewStyle> = {
    display: "flex",
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 20,
    marginBottom: 20,
  };
  containerSameLine: StyleProp<ViewStyle> = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  };
  input: StyleProp<ViewStyle> = {
    display: "flex",
    margin: "1%",
    width: "98%",
  };
  inputInline: StyleProp<ViewStyle> = {
    display: "flex",
    margin: "1%",
    width: "30%",
    flexDirection: "column",
    backgroundColor: 'red'
  };
  label: StyleProp<TextStyle> = {
    display: "flex",
    fontFamily: this.MyStyleManager.fontFamily,
    fontSize: 14,
    marginBottom: 15,
  };

  inputText: StyleProp<TextStyle> = {
    display: "flex",
    fontFamily: this.MyStyleManager.fontFamily,
    fontSize: 16,
    borderWidth: 0.1,
    borderRadius: 5,
    padding: 10,
  };
  saveButton: StyleProp<ViewStyle> = {
    backgroundColor: this.MyStyleManager.primaryColor,
    borderRadius: 8,
    display: "flex",
    margin: 20,
    marginLeft: 500,
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: 200,
  };
}
