import { ContentViewStyle } from '@levelapp/softfabric';
import { TextStyle, StyleProp, ViewStyle } from 'react-native';

export default class ShowTextPopUpViewStyle extends ContentViewStyle
{
    title: StyleProp<TextStyle> = {
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        marginTop:75,
        marginBottom:100
    };

    NoButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginRight : '5px'
    }  

   YesButton: StyleProp<ViewStyle> =
    {       
        height: 42,
        width: 119,
        marginLeft : '5px'
    } 

    NoButtonText: StyleProp<TextStyle> = {
       color: '#9C9C9C',
    };
}