import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ExportViewState from './ExportViewState';
import ExportViewProps from './ExportViewProps';
import ExportViewStyle from './ExportViewStyle';
import ExportViewModel from './ExportViewModel';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';
import DateSelect from '../../../../../Components/Select/DateSelect';
import moment from 'moment';
import Select from '../../../../../Components/Select/Select';

export default class ExportView extends ContentView<ExportViewProps, ExportViewState, ExportViewModel, ExportViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ExportViewProps) {
    super(props);

    // Binding
    this.bind(new ExportViewModel(this), new ExportViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>
          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>Export</TranslationView>
          </View>
          {this.state.isLoading &&
            <View style={this.style.content}>
              <Text>Chargement des données...</Text>
            </View>
          }
          {!this.state.isLoading &&
            <View style={this.style.content}>
              <Text style={{ marginLeft: 10, fontSize: 24, fontWeight: 'bold' }}>Filtres</Text>
              <View style={{ height: 20 }}></View>
              <View style={[this.style.inputInline, { zIndex: 2 }]}>
                <View style={{ width: 400 }}>
                  <Text>Date de création (Jour/Mois/Année)</Text>
                  <DateSelect
                    subscribe={() => { }}
                    placeholderDay='Jour'
                    placeholderMonth='Mois'
                    placeholderYear='Année'
                    shouldCheck={false}
                    selectClass="col-lg-7"
                    label={""}
                    labelClass="col-lg-7"
                    onChange={this.binding.dateChanged}
                    required={false}
                    disabled={false}
                    value={''}
                    startYear={moment().year()}
                    endYear={moment().year() + 10} />
                </View>
                <View style={{ width: 40 }}>
                </View>
                <View style={{ width: 200 }}>
                  <Text>Type de compte</Text>
                  <Select
                    value={this.state.selectedStatus}
                    options={this.state.statuses.map((statut: any, key) => { return { label: statut.title, value: statut.title, key } })}
                    onChange={(value: any) => this.binding.handleStatusChanged(value)}
                    subscribe={() => { }}
                    shouldCheck={false}
                    selectClass="col-lg-7"
                    label={""}
                    labelClass="col-lg-7"
                    required={false}>
                  </Select>

                </View>
              </View>

              <View style={{ height: 50 }}></View>

              <Text style={{ marginLeft: 10 }}>Nombre de comptes : {this.state.data.length}</Text>

              <View style={{ height: 40 }}></View>
              <View style={{ flexDirection: 'row' }} >
              <ButtonView
                  animation={'None'}
                  borders={'Little'}
                  backgroundColor='#FFB243'
                  isLoading={this.state.isExporting}
                  style={this.style.exportButton}
                  handleClick={() => { this.binding.exportUsers() }}>
                <Text style={this.style.exportButtonText}>
                  Exporter
                </Text>
              </ButtonView>
              <ButtonView
                  animation={'None'}
                  borders={'Little'}
                  backgroundColor='#FFB243'
                  isLoading={this.state.isExporting}
                  style={this.style.EmailToSend}
                  handleClick={() => { this.binding.exportNotif() }}>
                <Text style={this.style.exportButtonText}>
                  <TranslationView>sendEmailToInvestors</TranslationView>
                </Text>
              </ButtonView>
              </View>
            </View>
          }
        </View>
      </View>
    );
  }
}
