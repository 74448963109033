import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';


export default class CertificatesService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);  
    auth: any = Application.current.resolve(Managers.AUTH_MANAGER);

    async sendCertificates(certificates: any, callback: Function)
    {
        this.httpClient.post("api/Certificates/SendCertificates", [], certificates).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }

    async getCertificate(certificates: any, callback: Function)
    {        
       
        this.httpClient.post("api/Certificates/GetCertificateModel", [], certificates).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}