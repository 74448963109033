import React from 'react';
import { Application, ContentView } from '@levelapp/softfabric';
import EmailsViewState from './EmailsViewState';
import EmailsViewProps from './EmailsViewProps';
import EmailsViewStyle from './EmailsViewStyle';
import EmailsViewModel from './EmailsViewModel';
import { View } from 'react-native';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import RoleManager from '../../../../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../../../../Common/Managers/ExtendedManagers';

export default class EmailsView extends ContentView<EmailsViewProps, EmailsViewState, EmailsViewModel, EmailsViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: EmailsViewProps) {
    super(props);

    // Binding
    this.bind(new EmailsViewModel(this), new EmailsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        <View style={{ height: 20 }}></View>
        {(Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER).isAdmin || (Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)).isEditor) &&
          <View style={{ flex: 1, flexDirection: 'row', marginLeft: '65px', alignItems: 'center' }}>
            <TranslationView style={{ justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>ImportBankInformation</TranslationView>
            <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={() => this.binding.handleFileBankInformationEndEmail(true)} animation="None" borders='Rectangle' isLoading={false}><TranslationView>{'SendTest'}</TranslationView></ButtonView>
            <View style={{ width: 20 }} />
            <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={() => this.binding.handleFileBankInformationEndEmail(false)} animation="None" borders='Rectangle' isLoading={false}><TranslationView>{'Send'}</TranslationView></ButtonView>
          </View>
        }

        {(Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER).isAdmin || (Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)).isEditor) &&
          <>
            <View style={{ height: 20 }}></View>
            <View style={{ flex: 1, flexDirection: 'row', marginLeft: '65px', alignItems: 'center' }}>
              <TranslationView style={{ justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>ImportObligations</TranslationView>
              <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={this.binding.showCerificatesPopUp} animation="None" borders='Rectangle' isLoading={false}><TranslationView>{'import'}</TranslationView></ButtonView>
            </View>
          </>
        }

        <View style={{ height: 20 }}></View>
        <View style={{ flex: 1, flexDirection: 'row', marginLeft: '65px', alignItems: 'center' }}>
          <TranslationView style={{ justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>SendEmailToInvestors</TranslationView>
          <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={this.binding.senEmailToPorjectInvestors} animation="None" borders='Rectangle' isLoading={false}><TranslationView>{'Send'}</TranslationView></ButtonView>
        </View>

      </View>
    );
  }
}