import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import ShowTextPopUpViewState from './ShowTextPopUpViewState';
import ShowTextPopUpViewProps from './ShowTextPopUpViewProps';

export default class ShowTextPopUpViewModel extends ContentViewModel<ShowTextPopUpViewState, ShowTextPopUpViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
    }

    /* COMPONENT EVENTS */
    componentWillMount(){}

    /* HANDLERS */
    handleBackPressed()
    {
        this.closePopUp();
    }
}