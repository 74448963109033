import * as React from 'react';
import CustomSelect from 'react-select';
import UrlConstants from '../../Constants/UrlConstants';

interface Props
{
    label: string;
    required: boolean;
    labelClass: string;
    selectClass: string;
    placeholderDay?: string;
    placeholderMonth?: string;
    placeholderYear?: string;
    value?: string;
    shouldCheck: boolean;
    onChange: Function;
    Style?: React.CSSProperties;
    startYear: number;
    endYear: number;
    dateDesc?: boolean;
    subscribe: Function;
    disabled?: boolean;
}

interface State
{
    error: boolean;
    days: number[];
    months: number[];
    years: number[];
    selectedDay: number;
    selectedMonth: number;
    selectedYear: number;
}

export default class DateSelect extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = {
            error: false,
            selectedDay: -1,
            selectedMonth: -1,
            selectedYear: -1,
            days: [],
            months: [],
            years: []
        }

        // Bindings
        this.handleDayChanged = this.handleDayChanged.bind(this);
        this.handleMonthChanged = this.handleMonthChanged.bind(this);
        this.handleYearChanged = this.handleYearChanged.bind(this);
        this.isError = this.isError.bind(this);
    }

    componentDidMount()
    {
        // Feeds all select boxes
        let days = [];
        let months = [];
        let years = [];

        for (let i = 1; i < 32; i++)
        {
            if (i <= 12)
            {
                months.push(i);
            }
            days.push(i);
        }

        for (let i = this.props.startYear; i <= this.props.endYear; i++)
        {
            years.push(i);
        }
        if (this.props.dateDesc)
        {
            years = years.sort((a, b) => { return b - a; })
        }

        if (this.props.value != undefined && this.props.value != '')
        {
            if(this.props.value.indexOf('/')> 0) {
                let selectedDay = parseInt(this.props.value.split('/')[0]);
                let selectedMonth = parseInt(this.props.value.split('/')[1]);
                let selectedYear = parseInt(this.props.value.split('/')[2]);
                this.setState({ selectedDay, selectedMonth, selectedYear, days, months, years });                
            }
            else {
                let selectedDay = parseInt(this.props.value.split('-')[0]);
                let selectedMonth = parseInt(this.props.value.split('-')[1]);
                let selectedYear = parseInt(this.props.value.split('-')[2]);
                this.setState({ selectedDay, selectedMonth, selectedYear, days, months, years }); 
            }
            
        }
        else
        {
            this.setState({ days, months, years });
            this.props.subscribe({ id: this.props.label, error: this.props.required && (this.state.selectedDay == -1  ||  this.state.selectedMonth == -1  || this.state.selectedYear == -1) });
        }
    }

    componentWillUnmount()
    {
        this.props.subscribe({ id: this.props.label, error: false});
    }

    handleDayChanged(day: any)
    {
        this.setState({ selectedDay: day.value }, () =>
        {
            if (this.state.selectedDay != -1 && this.state.selectedMonth != -1 && this.state.selectedYear != -1)
            {
                this.props.onChange([this.state.selectedDay, this.state.selectedMonth, this.state.selectedYear].join('-'));
                this.props.subscribe({id: this.props.label, error: false });
            }
        });
    }

    handleMonthChanged(month: any)
    {
        let temp = [];
        let lastDay = 31;
        let currentDay = this.state.selectedDay;

        if (month.value == 2)
        {
            if (this.state.selectedYear == -1)
            {
                lastDay = 29
            }
            else
            {
                // Case of bissextile
                if ((this.state.selectedYear % 4 == 0) && ((this.state.selectedYear % 100 != 0) || (this.state.selectedYear % 400 == 0)))
                {
                    lastDay = 29;
                }
                else
                {
                    lastDay = 28;
                }
            }

        }

        else if (month.value == 1 || month.value == 3 || month.value == 5 || month.value == 7 || month.value == 8 || month.value == 10 || month.value == 12)
        {
            lastDay = 31;
        }
        else
        {
            lastDay = 30;
        }

        for (let i = 1; i <= lastDay; i++)
        {
            temp.push(i);
        }

        if (currentDay > lastDay)
        {
            currentDay = -1;
        }
        this.setState({ selectedMonth: month.value, days: temp, selectedDay: currentDay }, () =>
        {
            if (this.state.selectedDay != -1 && this.state.selectedMonth != -1 && this.state.selectedYear != -1)
            {
                this.props.onChange([this.state.selectedDay, this.state.selectedMonth, this.state.selectedYear].join('-'));
                this.props.subscribe({id: this.props.label, error: false });
            }

        });
    }

    handleYearChanged(year: any)
    {
        let selectedDay = this.state.selectedDay;
        let days = [];
        let lastDay;

        if (this.state.selectedMonth == 2)
        {
            if ((year.value % 4 == 0) && ((year.value % 100 != 0) || (year.value % 400 == 0)))
            {
                lastDay = 29;
            }
            else
            {
                lastDay = 28;
            }

            for (let i = 1; i <= lastDay; i++)
            {
                days.push(i);
            }

            if (selectedDay > lastDay)
            {
                selectedDay = -1;
            }

            this.setState({ selectedYear: year.value, days, selectedDay }, () =>
            {
                if (this.state.selectedDay != -1 && this.state.selectedMonth != -1 && this.state.selectedYear != -1)
                {
                    this.props.onChange([this.state.selectedDay, this.state.selectedMonth, this.state.selectedYear].join('-'));
                    this.props.subscribe({id: this.props.label, error: false });
                }
            });
        }
        else
        {
            this.setState({ selectedYear: year.value }, () =>
            {
                if (this.state.selectedDay != -1 && this.state.selectedMonth != -1 && this.state.selectedYear != -1)
                {
                    this.props.onChange([this.state.selectedDay, this.state.selectedMonth, this.state.selectedYear].join('-'));
                    this.props.subscribe({id: this.props.label, error: false });
                }
            });
        }
    }

    isError()
    {
        if ((this.state.selectedDay == -1 || this.state.selectedMonth == -1 || this.state.selectedYear == -1) && this.props.required)
        {
            this.setState({ error: true });
        }
        else if (this.state.error)
        {
            this.setState({ error: false });
        }
    }

    render()
    {
        if (this.props.shouldCheck && !this.state.error)
        {
            this.isError();
        }
        return (
            <div style={this.props.Style}>
                <div className="row">
                    <div className={this.props.labelClass} style={{ display: 'flex' }}>
                        {this.state.error && (<img src={UrlConstants.BB_STORAGE + "/error.svg"} alt="budgeting" style={{ marginRight: 5 }} />)}
                        <label style={{ color: '#565656', fontWeight: 400, fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px', width: '100%', textAlign: 'start' }}>{this.props.label}{this.props.required ? '*' : ''}</label>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 8  }}>
                    <div className={this.props.selectClass} style={{ display: 'flex'}}>
                         {/* DAYS */}
                            <div style={{marginRight : 5, height: '40px', width: '100%', border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px' }}>
                                <CustomSelect placeholder={this.props.placeholderDay} value={this.state.selectedDay != -1 ? { value: this.state.selectedDay, label: this.state.selectedDay } : null} styles={{
                                    control: (styles: any) => ({ ...styles, border: 'none' }),
                                    placeholder: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#d5d5d5', fontWeight: 300, fontSize: '16px' }),
                                    option: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    input: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    singleValue: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 })
                                }}
                                    options={this.state.days.map(co => { return ({ label: co < 10 ? '0' + co : co, value: co < 10 ? '0' + co : co }) })}
                                    onChange={(event) => this.setState({ error: false }, () => { this.handleDayChanged(event) })
                                }
                                    isDisabled={(this.props as unknown as Props).disabled}
                                />
                            </div>

                        {/* MONTHS */}
                            <div style={{marginRight : 5, marginLeft : 5, height: '40px', width: '100%', border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px' }}>
                                <CustomSelect placeholder={this.props.placeholderMonth} value={this.state.selectedMonth != -1 ? { value: this.state.selectedMonth, label: this.state.selectedMonth } : null} styles={{
                                    control: (styles: any) => ({ ...styles, border: 'none' }),
                                    placeholder: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#d5d5d5', fontWeight: 300, fontSize: '16px' }),
                                    option: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    input: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    singleValue: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 })
                                }}
                                    options={this.state.months.map(co => { return ({ label: co < 10 ? '0' + co : co, value: co < 10 ? '0' + co : co }) })}
                                    onChange={(event) => this.setState({ error: false }, () => { this.handleMonthChanged(event) })}
                                    isDisabled={(this.props as unknown as Props).disabled}
                                />
                            </div>
                        {/* YEARS */}
                            <div style={{marginLeft : 5, height: '40px', width: '100%', border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px' }}>
                                <CustomSelect placeholder={this.props.placeholderYear} value={this.state.selectedYear != -1 ? { value: this.state.selectedYear, label: this.state.selectedYear } : null} styles={{
                                    control: (styles: any) => ({ ...styles, border: 'none' }),
                                    placeholder: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#d5d5d5', fontWeight: 300, fontSize: '16px' }),
                                    option: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    input: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                    singleValue: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 })
                                }}
                                    options={this.state.years.map(co => { return ({ label: co, value: co }) })}
                                    onChange={(event) => this.setState({ error: false }, () => { this.handleYearChanged(event) })}
                                    isDisabled={(this.props as unknown as Props).disabled}
                                />
                            </div>
                        </div>                   
                </div>
            </div >
        );
    }
}