import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ResetAvertiStatusViewState from './ResetAvertiStatusViewState';
import ResetAvertiStatusViewProps from './ResetAvertiStatusViewProps';

export default class ResetAvertiStatusViewModel extends ContentViewModel<ResetAvertiStatusViewState, ResetAvertiStatusViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}