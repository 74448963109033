import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TransferReservationPopUpViewState from './TransferReservationPopUpViewState';
import TransferReservationPopUpViewProps from './TransferReservationPopUpViewProps';
import UsersOperation from '../../../../../../../Transfer/UsersOperation';


export default class TransferReservationPopUpViewModel extends ContentViewModel<TransferReservationPopUpViewState, TransferReservationPopUpViewProps>
{
    usersOperation = new UsersOperation();

    constructor(props: ContentView) {
        super(props);

        this.initialState({
            amount: 0,
            userSearch: "",
            usersSearch: [],
            destinationUserId : 0
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handleUserSearchChanged = this.handleUserSearchChanged.bind(this);
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.selectUserHandler = this.selectUserHandler.bind(this);
    }

    /* HANDLERS */
    handleAmountChanged(amount: any) {
        this.setField({ amount });
    }

    handleUserSearchChanged(userSearch: any) {
        this.setField({ userSearch });
        this.usersOperation.getSearchPagination("ALL", 1, 10, userSearch, true, this.handleSuccessUserCallback); 
    }

    handleSuccessUserCallback(ret: any) {
        this.setField({ usersSearch : ret.objects });
    }

    handleBackPressed() {
        this.closePopUp();
    }

    selectUserHandler(user: any) {
        this.setField({ destinationUserId : user.id });
    }

    handleSavePressed() {
        if(this.state().destinationUserId == 0) {
            alert("Veuillez sélectionner un utilisateur pour le transfert");
            return;
        }
        let reservation = this.props().reservation;
        let transferDto ={
            reservationSourceId: reservation.id,
            amount: reservation.amount,
            investorDestinationId: this.state().destinationUserId,
            projectDestinationId: reservation.projectId
        }
        
        this.closePopUp();
        this.props().callBack(transferDto);
    }
}