import { ContentViewStyle, Application } from '@levelapp/softfabric';
import { StyleProp, ViewStyle, ImageStyle, TextStyle } from 'react-native';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';


export default class MenuViewStyle extends ContentViewStyle
{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    menu: StyleProp<ViewStyle> = {
        backgroundColor: 'white',
        width: 260,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 2,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 40.65,
        elevation: 80,
    };

    logoContainer: StyleProp<ViewStyle> = {
        padding: 15
    };

    logo: StyleProp<ImageStyle> = {
        width: 200,
        height: 80
    }

    linksContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        flexDirection: 'column'
    };

    linkActive: StyleProp<ViewStyle> = {
        width: 230,
        height: 50,
        backgroundColor: this.MyStyleManager.primaryColor,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 25,
        flexDirection: 'row'
    };

    link: StyleProp<ViewStyle> = {
        width: 230,
        height: 50,
        borderRadius: 3,
        display: 'flex',
        paddingLeft: 25,
        alignItems: 'center',
        flexDirection: 'row'
    };

    linkTextActive: StyleProp<TextStyle> = {
        color: this.MyStyleManager.activeTextColor,
        fontSize: 14
    };

    linkText: StyleProp<TextStyle> = {
        color: '#a9afbb',
        fontSize: this.MyStyleManager.fontSize
    };

    linkIcon: StyleProp<ImageStyle> = {
        height: 24,
        width: 24,
        marginRight: 30
    };
}