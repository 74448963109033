import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class CerificatesPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    container : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "column",  
        justifyContent : "space-between",
        paddingHorizontal: 50, 
        paddingVertical: 60,

    }

   button: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        height: 42,
        marginLeft : 30,
        flexWrap : 'wrap',
    } 


    text : StyleProp<any> = {
        color: '#565656', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '14px', 
    }
    textLink : StyleProp<TextStyle> = {
        color: '#565656', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 25, 
    }

    
    lineContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'space-evenly', 
        flexDirection : 'row'
    }
    loadingContainer: StyleProp<ViewStyle> = {
        display : 'flex',
        flex : 1,      
        margin : 30,
        justifyContent : 'center',
        alignItems : 'center',
    }
    linkContainer: StyleProp<ViewStyle> = {
        display : 'flex',
        flex : 1,
        margin : 30,
        justifyContent : 'center',
        alignItems : 'center',
    }


    
}