import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import InputViewState from './InputViewState';
import InputViewProps from './InputViewProps';
import InputViewStyle from './InputViewStyle';
import InputViewModel from './InputViewModel';
import UrlConstants from '../../../Constants/UrlConstants';
import { TextInput } from 'react-native';

export default class InputView<P = InputViewProps> extends ContentView<P, InputViewState, InputViewModel, InputViewStyle>
{
    constructor(props: P) {
        super(props);

        this.bind(new InputViewModel(this), new InputViewStyle(this));
    }

    render() {
        if ((this.props as unknown as InputViewProps).shouldCheck) {
            this.binding.isFieldInError();
        }

        return (
            <div style={(this.props as unknown as InputViewProps).Style}>
                <div className="row" >
                    <div className={(this.props as unknown as InputViewProps).labelClass} style={{ display: 'flex', margin: (this.props as unknown as InputViewProps).centered ? "auto" : "" }}>
                        {this.state.error && (<img src={UrlConstants.BB_STORAGE + '/error.svg'} alt="error" style={{ marginRight: 5 }} />)}
                        <label style={{ color: '#565656', fontWeight: 400, fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px', width: '100%', textAlign: 'start' }}>
                            {(this.props as unknown as InputViewProps).label}{(this.props as unknown as InputViewProps).required ? '' : ''}
                        </label>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 8 }}>
                    <div className={(this.props as unknown as InputViewProps).inputClass} style={{ margin: (this.props as unknown as InputViewProps).centered ? "auto" : "" }}>
                        <div style={{ height: '40px', width: '100%', border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px', background: (this.props as unknown as InputViewProps).disabled ? 'url(' + UrlConstants.BB_STORAGE + '/lock.svg) no-repeat' : 'none', backgroundPosition: (this.props as unknown as InputViewProps).disabled ? 'right 1em bottom 10px' : 'none'}}>
                            {(this.props as unknown as InputViewProps).disabled &&
                                <label style={{ verticalAlign:"middle", width: '100%', height: '40px', fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300, lineHeight: '40px', border: 'none', paddingLeft: 10,  }} >{(this.props as unknown as InputViewProps).value}</label>
                            }
                            {!(this.props as unknown as InputViewProps).disabled &&
                                <TextInput style={{fontFamily: 'Montserrat', color: '#565656', width: '100%', height: '100%', paddingLeft: 10, fontSize: 16 }} placeholder={(this.props as unknown as InputViewProps).placeholder} value={(this.props as unknown as InputViewProps).value} onChange={this.binding.valueChanged}  onBlur={() => this.binding.isFieldInError()}  />
                            }
                            {(this.props as unknown as InputViewProps).type == 'password' && this.state.value.length > 0 && (
                                <div style={{ height: 30, width: 30, position: 'absolute', right: 20, top: 5, cursor: 'pointer' }} onClick={this.binding.handleEyeClicked}>
                                    <img src={this.state.passwordVisible ? UrlConstants.BB_STORAGE + '/noeye.svg' : UrlConstants.BB_STORAGE + '/eye.svg'} alt="eye" height={30} width={30} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}