import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import LegalEntityDocumentsViewState from './LegalEntityDocumentsViewState';
import LegalEntityDocumentsViewProps from './LegalEntityDocumentsViewProps';
import LegalEntityDocumentsViewStyle from './LegalEntityDocumentsViewStyle';
import LegalEntityDocumentsViewModel from './LegalEntityDocumentsViewModel';
import {ButtonView, TranslationView} from '@levelapp/softfabric-ui';
import FileInputView from '../../../../../../../../../Components/FileInput/FileInputView';
import { View } from 'react-native';

export default class LegalEntityDocumentsView extends ContentView<LegalEntityDocumentsViewProps, LegalEntityDocumentsViewState, LegalEntityDocumentsViewModel, LegalEntityDocumentsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:LegalEntityDocumentsViewProps)
  {
    super(props);

    // Binding
    this.bind(new LegalEntityDocumentsViewModel(this), new LegalEntityDocumentsViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
<View style={this.style.Box}>
        <div className="row" style={{ marginTop: 47 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("BceFileLabel")} onChange={this.binding.handleBceRegistrationChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.bceRegistration} accept="image/*, .pdf" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("LeadersFileLabel")} onChange={this.binding.handleLeadersNominationChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.leadersNomination} accept="image/*, .pdf" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("BankFileLabel")} onChange={this.binding.handleBankIdChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.bankId} accept="image/*, .pdf" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("UboLabel")} onChange={this.binding.handleUboChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.ubo} accept="image/*, .pdf" />
          </div>
        </div>

      <div style={this.style.ValidButton}>
        <ButtonView  animation={'None'} borders={'Little'}  backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={() => { this.binding.handleSave() }}>
          <TranslationView upperCase>Save</TranslationView>
        </ButtonView>
        </div>
      </View>
    );
  }
}