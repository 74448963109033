import { Operation } from '@levelapp/softfabric';
import InvestmentReservationService from '../Proxy/InvestmentReservationService';


export default class InvestmentReservationOperation extends Operation
{
    _service : InvestmentReservationService;

    constructor()
    {
        super();
        this._service = new InvestmentReservationService();
    }   

    async addReservation(reservation: any, callback: Function = () => { })
    {
        await (this._service as InvestmentReservationService).addReservation(reservation, callback);
    }

    async getSearchPagination(status: any, page : number, itemPage : number, searchText : string, callback : Function) {
        await (this._service as InvestmentReservationService).getSearchPagination(status, page, itemPage, searchText, callback);
    }
}