import {ContentViewModel, NavigationManager, Application, Managers, ContentView} from '@levelapp/softfabric';
import NewsletterViewState from './NewsletterViewState';
import NewsletterViewProps from './NewsletterViewProps';
import NewsletterOperation from '../../../../../Transfer/NewsLetterOperations';
import SubscribePopUpView from '../../../../../Components/subscribePopUp/SubscribePopUpView';
import * as React from 'react';

export default class NewsletterViewModel extends ContentViewModel<NewsletterViewState, NewsletterViewProps>{
    NavigationManager : NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);

    constructor(props: ContentView)
    {
        super(props);
        /* Initial State */
        this.initialState({ 
            email: '',
            firstName : '',
            lastName : '',
            isLoading: false,
            acceptNewsLetter: true,
            campaignNewsLetter: true
     }); 
        /* BINDING */
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handleFirstNameChanged = this.handleFirstNameChanged.bind(this);
        this.handleLastNameChanged = this.handleLastNameChanged.bind(this);
        this.handleAcceptNewsLetterhanged = this.handleAcceptNewsLetterhanged.bind(this);
        this.handleCampaignNewsLetterhanged = this.handleCampaignNewsLetterhanged.bind(this);
        this.submit = this.submit.bind(this);
    }

    /* HANDLERS */
    handleEmailChanged(email: string)
    {
        this.setField({ email: email });
    }

    handleFirstNameChanged(firstName: string)
    {
        this.setField({ firstName: firstName });
    }

    handleLastNameChanged(lastName: string)
    {
        this.setField({ lastName: lastName });
    }

    handleAcceptNewsLetterhanged(value: boolean)
    {
        this.setField({ acceptNewsLetter: value });
    }

    handleCampaignNewsLetterhanged(value: boolean)
    {
        this.setField({ campaignNewsLetter: value });
    }

    /* FUNCTIONS */
    submit()
    {
        if(this.state().email != ''){
            this.setField({isLoading: true})
            new NewsletterOperation().subscribe({
                "email" : this.state().email,
                "firstName" : this.state().firstName,
                "lastName" : this.state().lastName,
                "acceptNewsLetter": this.state().acceptNewsLetter,
                "campaignNewsLetter": this.state().campaignNewsLetter
    
            }, () => {
                this.setField({isLoading: false, email: '', firstName: '', lastName: ''});
                this.showPopUp(<SubscribePopUpView/>);
                setTimeout(() => {this.closePopUp()},2000)
            })
        }

    }
}