import { Operation } from "@levelapp/softfabric";
import UserRole from "../DTO/UserRole";
//import MockRoleService from "../Proxy/MockRolesServices";
import RoleService from "../Proxy/RoleServices";

export default class RoleOperations extends Operation
{
    _service : RoleService;

    constructor()
    {
        super();
        this._service = new RoleService();
    }

    async getUsersWithRoles(callback : Function = () => { })
    {
        await (this._service as RoleService).getUsersWithRole(callback);
    }

    async getAllRoles(callback : Function = () => { })
    {
        await (this._service as RoleService).getAllRoles(callback);
    }

    async updateUserRoles(userRole : UserRole ,callback : Function = () => { })
    {
        await (this._service as RoleService).updateUserRoles(userRole,callback);
    }

    async createUserRoles(userRole : UserRole ,onSuccess : Function = () => { },onError: Function = () => { })
    {
        await (this._service as RoleService).createUserRoles(userRole,onSuccess,onError);
    }
  
}