import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import { Image, View } from 'react-native';
import ProjectInfosViewState from './ProjectInfosViewState';
import ProjectInfosViewProps from './ProjectInfosViewProps';
import ProjectsOperation from '../../../../../../../../../../../../Transfer/ProjectsOperations';
import ProjectTypeDTO from '../../../../../../../../../../../../DTO/ProjectTypeDTO';
import React from 'react';

export default class ProjectInfosViewModel extends ContentViewModel<ProjectInfosViewState, ProjectInfosViewProps>
{
    ProjectsOperation = new ProjectsOperation();
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            types : [],
            safetyTypes: [{'title' : 'Sans sûreté ', 'id' : 1}, {'title' : 'Avec sûreté ', 'id' : 2}, {'title' : 'Hypothèque 1er rang ', 'id' : 3}, {'title' : 'Prêt Assuré  ', 'id' : 4}]

        }); // Initialize your state here

        // Bindings
        this.loadTypesList = this.loadTypesList.bind(this);
        this.handleFileChanged = this.handleFileChanged.bind(this);
        this.showCoverPic = this.showCoverPic.bind(this);

    }
    
    componentDidMount(){
        this.loadTypesList();
    }

   
  


    loadTypesList() {
        this.ProjectsOperation.getTypesList((types : ProjectTypeDTO[])=>{
            this.setField((previousState) => previousState.types = types);
        })
        
    }

    handleFileChanged(file : File){
        this.setField((previousState) => previousState.coverFile = file);
        this.props().onCoverPictureChange(file);
    }
    showCoverPic(){
        this.showPopUp(
        <View style={{ height: 700, width: 900}}>
            <Image source={{ uri: this.props().project.coverPictureUrl }} style={{ height: "100%", width: "100%",resizeMode: 'contain'}}/>
        </View>)
    }
}