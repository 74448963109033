import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import UserDocumentsViewState from './UserDocumentsViewState';
import UserDocumentsViewProps from './UserDocumentsViewProps';
import UserDocumentsViewStyle from './UserDocumentsViewStyle';
import UserDocumentsViewModel from './UserDocumentsViewModel';
import { View } from 'react-native';
import FileInputView from '../../../../../../../../../../../../Components/FileInput/FileInputView';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';

export default class UserDocumentsView extends ContentView<UserDocumentsViewProps, UserDocumentsViewState, UserDocumentsViewModel, UserDocumentsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UserDocumentsViewProps) {
    super(props);

    // Binding
    this.bind(new UserDocumentsViewModel(this), new UserDocumentsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.Box}>
        <div className="row" style={{ marginTop: 47 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("IdentificationFile1Label")} onChange={this.binding.handleIdentityPieceRectoChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.identityPieceRecto} accept="image/*, .pdf" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("IdentificationFile2Label")} onChange={this.binding.handleIdentityPieceVersoChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.identityPieceVerso} accept="image/*, .pdf" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("IdentificationFile3Label")} onChange={this.binding.handleDomiciliationChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.domiciliation} accept="image/*, .pdf" />
          </div>
        </div>

        <div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("IdentificationFile4Label")} onChange={this.binding.handleProofOfBankChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.proofOfBank} accept="image/*, .pdf" />
          </div>
        </div>

        {(this.state.profile.isAverti == null || this.state.profile.isAverti == true) &&
          <div className="row" style={{ marginTop: 19 }}>
            <div className="col-lg-7">
              <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("identificationCertificateUrl")} onChange={this.binding.handleIdentificationCertificateUrlChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.identificationCertificateUrl} accept="image/*, .pdf" />
            </div>
          </div>
        }

        {/*<div className="row" style={{ marginTop: 19 }}>
          <div className="col-lg-7">
            <FileInputView btnLabel={this.binding.translate("BrowseLabel")} label={this.binding.translate("BankIdentification")} onChange={this.binding.handleBankChange} placeholder={this.binding.translate("FileUploadPlaceholder")} file={this.state.bank} accept="image/*, .pdf" />
          </div>
    </div>*/}


        <div style={this.style.ValidButton}>
          <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={() => { this.binding.handleSave() }}>
            <TranslationView upperCase>Save</TranslationView>
          </ButtonView>
        </div>
      </View>
    );
  }
}