import { Service, Application , Managers, AuthManager } from '@levelapp/softfabric';
import UrlConstants from '../Constants/UrlConstants';

export default class LoginService extends Service {
    //httpClient: HttpManager;
    storage: StorageManager;
    auth: AuthManager;

    constructor() {
        super();

        //this.httpClient = Application.current.resolve<HttpManager>(Managers.AUTH_MANAGER);
        //this.httpClient.handleSomethingWentWrong = (error: any) => { console.warn('Erreur ' + JSON.stringify(error)) }

        this.storage = Application.current.resolve(Managers.STORAGE_MANAGER);
        this.auth = Application.current.resolve(Managers.AUTH_MANAGER);
    }

    async post(credentials: any, onSuccess: Function, onFailure: Function) {
        fetch(`${UrlConstants.BB_BACKEND}/Connect/Token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: `grant_type=password&username=${credentials.email}&` +
                `password=${credentials.password}&` +
                `client_id=admin&` +
                `client_secret=31BB45F68BEE96AC4D94DFDBC93E8&` +
                `scope=admin_api identity_api offline_access openid`
        })
            .then(response => response.json())
            .then((json: any) => {
                if (json.access_token !== undefined) {
                    // Storing the response (token and refresh) on disk
                    this.auth
                        .setToken(json, (success: boolean) => {          
                            onSuccess(success);
                        });
                }
                else {
                    onFailure();
                }
            })
            .catch(() => {
                onFailure();
            });
    }   
}