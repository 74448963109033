import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class UserLegalEntityService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async updateStatus(userLegalEntity: any, callback: Function)
    {      
        this.httpClient.put("api/userlegalentity/updateStatus", [], userLegalEntity).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }

    async addLink(userLegalEntity: any, callback: Function)
    {      
        this.httpClient.post("api/userlegalentity/addLink", [], userLegalEntity).then((response: any) => {
            if (response != undefined) {
                callback(response.data.data);
            }
        });
    }
}