import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ExtendedCheckBoxViewState from './ExtendedCheckBoxViewState';
import ExtendedCheckBoxViewProps from './ExtendedCheckBoxViewProps';
import ExtendedCheckBoxViewStyle from './ExtendedCheckBoxViewStyle';
import ExtendedCheckBoxViewModel from './ExtendedCheckBoxViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, CheckBox } from 'react-native';

export default class ExtendedCheckBoxView extends ContentView<ExtendedCheckBoxViewProps, ExtendedCheckBoxViewState, ExtendedCheckBoxViewModel, ExtendedCheckBoxViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ExtendedCheckBoxViewProps)
  {
    super(props);

    // Binding
    this.bind(new ExtendedCheckBoxViewModel(this), new ExtendedCheckBoxViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { checkbox , container } = this.style;

    return (
      <View style={container}>
        <CheckBox style={checkbox} value={this.props.value} onValueChange={this.props.onValueChange} />
        <TranslationView style={{ color: '#565656', fontWeight: '400', fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px'}}>{this.props.title}</TranslationView>
      </View>
    );
  }
}