import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ProjectInvestorsViewState from './ProjectInvestorsViewState';
import ProjectInvestorsViewProps from './ProjectInvestorsViewProps';
import ProjectInvestorsViewStyle from './ProjectInvestorsViewStyle';
import ProjectInvestorsViewModel from './ProjectInvestorsViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import ResearchInputView from '../../../../../../../../../../../Components/researchinput/ResearchInputView';
import TableView from '../../../../../../../../../../../Components/table/TableView';
import DropDownView from '../../../../../../../../../../../Components/Dropdown/DropDownView';

export default class ProjectInvestorsView extends ContentView<ProjectInvestorsViewProps, ProjectInvestorsViewState, ProjectInvestorsViewModel, ProjectInvestorsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ProjectInvestorsViewProps) {
    super(props);

    // Binding
    this.bind(new ProjectInvestorsViewModel(this), new ProjectInvestorsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View>
        {(this.state.isAdmin || this.state.isExport) &&
          <div>
            <View style={{ flexDirection: 'row' }}>
              <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.exportInvestors() }}>
                <TranslationView style={this.style.exportButtonText}>
                  ExportTheInvestors
                </TranslationView>
              </ButtonView>
              {this.state.isAdmin && <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.showPrimePopUp() }}>
                <TranslationView style={this.style.exportButtonText}>
                  {'prime.infos'}
                </TranslationView>
              </ButtonView>}
              {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) && <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.showBankDataPayementImportPopUp() }}>
                <TranslationView style={this.style.exportButtonText}>
                  {'bank.PayementImport'}
                </TranslationView>
              </ButtonView>}
            </View>

            <br></br>

            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.sendMailButton} handleClick={() => { this.binding.SendEmailToPending() }}>
              <TranslationView style={this.style.exportButtonText}>
                SendAnEmailToPending
              </TranslationView>
            </ButtonView>
            <br></br>
            {(this.state.isAdmin || this.state.isExport) &&
              <View style={{ flex: 1, flexDirection: 'row', marginLeft: '65px', alignItems: 'center' }}>
                <TranslationView style={{ justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>ExportObligations</TranslationView>
                <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={this.binding.exportRegister} animation="None" borders='Rectangle' isLoading={this.state.isExportLoading}><TranslationView>Export</TranslationView></ButtonView>
              </View>
            }
            <br></br>
            {(this.state.isAdmin || this.state.isExport) &&
              <View style={{ flex: 1, flexDirection: 'row', marginLeft: '65px', alignItems: 'center' }}>
                <TranslationView style={{ justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>ExportBankInformation</TranslationView>
                <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={this.binding.exportRegisterInterests} animation="None" borders='Rectangle' isLoading={this.state.isExportLoading}><TranslationView>Export</TranslationView></ButtonView>
              </View>
            }
            <br></br>
            {(this.state.isAdmin || this.state.isExport) &&
              <View style={{ flex: 1, flexDirection: 'row', marginLeft: '65px', alignItems: 'center' }}>
                <TranslationView style={{ justifyContent: 'center', alignItems: 'center', marginRight: '10px' }}>Moneytrans</TranslationView>
                <ButtonView backgroundColor="#FFB243" textColor="white" handleClick={this.binding.exportMoneyTrans} animation="None" borders='Rectangle' isLoading={this.state.isExportLoading}><TranslationView>Export</TranslationView></ButtonView>
              </View>
            }
            <br></br>          
          </div>
        }

        <View style={this.style.boxContainer}>
          {/* Research Input */}
          < View style={this.style.containerResearch}>
            <TranslationView style={{ marginRight: '20px' }}>Search</TranslationView>
            <DropDownView style={{ marginRight: '20px' }} selectedValue={this.state.selectedStatus} values={this.state.statuses} onValueChange={(value: any) => this.binding.handleStatusChanged(value)}></DropDownView>
            <ResearchInputView
              textInFront=""
              placeholder="search"
              handlerResearching={(text: string) => this.binding.handlerResearching(text)}
              handlingClose={() => { this.binding.handlingClose() }}
              text={this.state.text}
            />
          </View >

          <TableView
            handleResetStepView={this.binding.handleResetStepView}
            resetStepView={this.state.resetStepView}
            headerData={this.state.tableHeaders}
            rows={this.state.rows}
            numberOfRows={200}
            isSortable={true}
            isLoading={this.state.isLoading}
            lottie={require('../../../../../assets/loading.json')}
            resetTable={this.binding.resetTable}
            datas={this.state.datas}
            fillTables={(value: any[]) => { this.binding.pushRows(value) }}
            keyDatas={["aspNetUserId", "iban", "lastName", "email", "amount", "isValidatedString", "notUsed", "creationDate"]}
            headerIndex={[0, 1, 2, 3, 4, 5, 6, 7]}
            headerTextStyle={this.style.headerText}
            nbPage={this.state.nbPage}
            currentPage={this.state.currentPage - 1}
            onPageChange={(page: number) => this.binding.onPageChange(page)}
            styleOfChoosenButton={this.style.stepColor}

          ></TableView>
        </View>
      </View>
    );
  }
}