import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class PrimePopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    
    tabsContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "row",  
        borderBottomWidth : 0.2
    }

    tabContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flex : 1,    
        alignItems : "center",
        padding : 10,
        
        borderTopEndRadius : 10,
        borderTopStartRadius : 10, 
        

    }
    currentTabContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flex : 1,
        alignItems : "center",
        backgroundColor : "#D5DBDB",
        padding : 10,
        

    }
    tabTitle : StyleProp<TextStyle> =
    {
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 18, 
        fontWeight : 'bold'
    }



    
}