import LoginService from '../Proxy/LoginService';
import { Operation } from '@levelapp/softfabric';

export default class LoginOperation extends Operation {
    _service: LoginService;

    constructor() {
        super();
        this._service = new LoginService();
    }

    async post(credentials: any, onSuccess: Function = () => { }, onFailure: Function = () => { }) {
        await (this._service as LoginService).post(credentials, onSuccess, onFailure);
    }   
}