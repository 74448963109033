import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';

export default class EditProjectViewStyle extends ContentViewStyle{

    PurpleMenu: StyleProp<ViewStyle> =
    {
        backgroundColor : '#615D8A',
        height: '60px',
        paddingTop: '20px',
        paddingLeft : '78px',
    }       

    PurpleMenuText: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
    }

    PageTitle: StyleProp<TextStyle> = {
        color: '#32325D',
        fontSize: 20,
        paddingLeft : '26px',
        paddingTop: '24px',
        paddingBottom: '22px',
        fontWeight: 'bold',
    }

    Box: StyleProp<ViewStyle> =
    {
        backgroundColor : 'white',
        marginLeft: '30px',
        marginTop: '30px',
        marginRight: '30px',
        paddingLeft: '92px',
        paddingTop: '51px',
        paddingRight: '150px',
        paddingBottom: '31px',
        borderRadius:10,
    }    

    Title: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontSize: 24,
        fontWeight: 'bold'
    }

    FieldTitle: StyleProp<TextStyle> = {
        marginTop: '30px'
    }

    headersYellow: React.CSSProperties = {
        color: '#FFB243',
        fontSize: '24px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        lineHeight: '37px'
    };

    ValidUserButton:  StyleProp<ViewStyle> =  {
       height: '42px',
       marginTop: '57px',
    };

    PicturesTitle: React.CSSProperties = {
        color: '#32325D',
        fontSize: '24px',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        lineHeight: '37px',
        marginTop: '70px',
    };
}

