import { Operation } from '@levelapp/softfabric';
import StatusService from '../Proxy/StatusService';


export default class StatusOperation extends Operation
{
    _service : StatusService;

    constructor()
    {
        super();
        this._service = new StatusService();
    }   

    async getStatuses(type: string, callback: Function = () => { })
    {
        if(type == undefined) type = 'P';
        await (this._service as StatusService).get(type, callback);
    }
}