import { Operation } from '@levelapp/softfabric';
import ProjectLeadersService from '../Proxy/ProjectLeadersServices';


export default class ProjectsLeaderOperation extends Operation
{
    _service : ProjectLeadersService;

    constructor()
    {
        super();
        this._service = new ProjectLeadersService();
    }  

    async get(callback: Function = () => { })
    {
        await (this._service as ProjectLeadersService).get(callback);
    }

    async put(model: any, callback: Function = () => { })
    {
        await (this._service as ProjectLeadersService).put(model, callback);
    }

}