import * as React from 'react';
import Address from '../../DTO/Address';
import Select from '../Select/Select';
import InputView from '../Input/input/InputView';

interface Props {
    subscribe: Function;
    placeholderStreet?: string;
    placeholderStreetNumber?: string;
    placeholderBox?: string;
    placeholderPostCode?: string;
    placeholderLocality?: string;
    placeholderCountry?: string;
    labelStreet: string;
    labelStreetNumber: string;
    labelBox: string;
    labelPostCode: string;
    labelLocality: string;
    labelCountry: string;
    shouldCheck: boolean;
    onChange: Function;
    countries: string[];
    Style?: React.CSSProperties;
    value?: Address;
}

interface State {
    error: boolean;
}

export default class AddressForm extends React.Component<Props, State>
{
    constructor(props: Props) {
        super(props);
        this.state = { error: false }

        // Bindings
        this.handleStreetChange = this.handleStreetChange.bind(this);
        this.handleStreetNumberChange = this.handleStreetNumberChange.bind(this);
        this.handleBoxChange = this.handleBoxChange.bind(this);
        this.handleLocalityChange = this.handleLocalityChange.bind(this);
        this.handlePostCodeChange = this.handlePostCodeChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
    }

    handleStreetChange(street: string) {
        let address = this.props.value != null ? this.props.value : { box: '', number: '', country: '', locality: '', postCode: '', street: '' };
        address.street = street;
        this.props.onChange(address);
    }
    handleStreetNumberChange(streetNumber: string) {
        let address = this.props.value != null ? this.props.value : { box: '', number: '', country: '', locality: '', postCode: '', street: '' };
        address.number = streetNumber;
        this.props.onChange(address);
    }
    handleBoxChange(box: string) {
        let address = this.props.value != null ? this.props.value : { box: '', number: '', country: '', locality: '', postCode: '', street: '' };
        address.box = box;
        this.props.onChange(address);
    }

    handlePostCodeChange(postCode: string) {
        let address = this.props.value != null ? this.props.value : { box: '', number: '', country: '', locality: '', postCode: '', street: '' };
        address.postCode = postCode;
        this.props.onChange(address);
    }

    handleLocalityChange(locality: string) {
        let address = this.props.value != null ? this.props.value : { box: '', number: '', country: '', locality: '', postCode: '', street: '' };
        address.locality = locality;
        this.props.onChange(address);
    }

    handleCountryChange(country: any) {
        let address = this.props.value != null ? this.props.value : { box: '', number: '', country: '', locality: '', postCode: '', street: '' };
        address.country = country.value;
        this.props.onChange(address);
    }

    render() {
        return (
            <div>
                <div>
                    <InputView
                        subscribe={(obj: any) => this.props.subscribe(obj)}
                        placeholder={this.props.placeholderStreet}
                        inputClass="col-lg-7"
                        value={this.props.value != undefined ? this.props.value.street : ''}
                        labelClass="col-lg-7" required={false} onChange={(street: string) => { this.handleStreetChange(street) }}
                        label={this.props.labelStreet} type="text"
                        shouldCheck={this.props.shouldCheck}
                        Style={{ marginTop: 30 }}
                        name="address"
                        autocomplete="street-address"
                    />                   
                </div>
                <div style={{ display: 'flex'}}>
                <InputView
                        subscribe={(obj: any) => this.props.subscribe(obj)}
                        placeholder={this.props.placeholderStreetNumber}
                        inputClass="col-lg-12"
                        value={this.props.value != undefined ? this.props.value.number : ''}
                        labelClass="col-lg-12" required={false} onChange={(streetNumber: string) => { this.handleStreetNumberChange(streetNumber) }}
                        label={this.props.labelStreetNumber} type="text"
                        shouldCheck={this.props.shouldCheck}
                        Style={{ marginTop: 30 }}
                        name="address"
                        autocomplete="street-number"
                    />
                    <InputView
                        subscribe={(obj: any) => this.props.subscribe(obj)}
                        placeholder={this.props.placeholderBox}
                        inputClass="col-lg-12"
                        value={this.props.value != undefined ? this.props.value.box : ''}
                        labelClass="col-lg-12" required={false} onChange={(box: string) => { this.handleBoxChange(box) }}
                        label={this.props.labelBox} type="text"
                        shouldCheck={this.props.shouldCheck}
                        Style={{ marginTop: 30, marginLeft: 60 }}
                        name="address"
                        autocomplete="address-box"
                    />
                </div>
                <div style={{ display: 'flex'}}>
                    <InputView
                        subscribe={(obj: any) => this.props.subscribe(obj)}
                        placeholder={this.props.placeholderPostCode}
                        inputClass="col-lg-12"
                        value={this.props.value != undefined ? this.props.value.postCode : ''}
                        labelClass="col-lg-12" required={false} onChange={(postCode: string) => { this.handlePostCodeChange(postCode) }}
                        label={this.props.labelPostCode} type="text"
                        shouldCheck={this.props.shouldCheck}
                        Style={{ marginTop: 30 }}
                        name="address"
                        autocomplete="postal-code"
                    />

                    <InputView
                        subscribe={(obj: any) => this.props.subscribe(obj)}
                        placeholder={this.props.placeholderLocality}
                        inputClass="col-lg-12"
                        value={this.props.value != undefined ? this.props.value.locality : ''}
                        labelClass="col-lg-12" required={false} onChange={(box: string) => { this.handleLocalityChange(box) }}
                        label={this.props.labelLocality} type="text"
                        shouldCheck={this.props.shouldCheck}
                        Style={{ marginTop: 30 , marginLeft: 60 }}
                        name="address"
                        autocomplete="address-level2"
                    />
                </div>
                <Select shouldCheck={this.props.shouldCheck}
                    subscribe={(obj: any) => this.props.subscribe(obj)}
                    Style={{ marginTop: 30 }}
                    label={this.props.labelCountry} labelClass="col-lg-7"
                    options={this.props.countries.map(co => { return ({ label: co, value: co }) })}
                    required={false}
                    selectClass="col-lg-7"
                    placeholder={this.props.placeholderCountry}
                    onChange={this.handleCountryChange}
                    value={this.props.value != undefined ? this.props.value.country : ''} />
            </div>
        );
    }
}