import {ContentViewStyle} from '@levelapp/softfabric';
import { TextStyle, StyleProp } from 'react-native';

export default class MailSendedPopUpViewStyle extends ContentViewStyle
{
    title: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 50,
        marginBottom: 50,
    };
}