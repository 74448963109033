import { Operation } from '@levelapp/softfabric';
import LegalEntityService from '../Proxy/LegalEntityService';


export default class LegalEntityOperations extends Operation
{
    _service : LegalEntityService;

    constructor()
    {
        super();
        this._service = new LegalEntityService();
    }   

    async getSearchPagination(status: any, page : number, itemPage : number, searchText : string, callback : Function) {
        await (this._service as LegalEntityService).getSearchPagination(status, page, itemPage, searchText, callback);
    }

    async getLegalEntityById(legalEntityId: any, callback: Function = () => { })
    {
        await (this._service as LegalEntityService).getLegalEntityById(legalEntityId, callback);
    }    

    async export(status:any, searchText : string, callback : Function) {
        await (this._service as LegalEntityService).export(status, searchText, callback);
    }

    async updateLegalEntityStatus(legalEntityStatus: any, callback: Function = () => { })
    {
        await (this._service as LegalEntityService).updateLegalEntityStatus(legalEntityStatus, callback);
    }

    async updateLegalEntity(legalEntity: any, callback: Function = () => { })
    {
        await (this._service as LegalEntityService).updateLegalEntity(legalEntity, callback);
    }    
}