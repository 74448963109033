import * as  React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ResearchInputViewState from './ResearchInputViewState';
import ResearchInputViewProps from './ResearchInputViewProps';
import ResearchInputViewStyle from './ResearchInputViewStyle';
import ResearchInputViewModel from './ResearchInputViewModel';
import { View, Text, TextInput, TouchableWithoutFeedback } from 'react-native';

export default class ResearchInputView extends ContentView<ResearchInputViewProps, ResearchInputViewState, ResearchInputViewModel, ResearchInputViewStyle>
{

  /* CONSTRUCTOR */
  constructor(props:ResearchInputViewProps)
  {
    super(props);

    // Binding
    this.bind(new ResearchInputViewModel(this), new ResearchInputViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.input}>
          <View style={this.style.textInFront}>
            <Text>
              {this.props.textInFront}
            </Text>
          </View>
          <View style={this.style.containerInput}>
            <View style={[this.style.inputView,this.props.inputStyle]}>
              <TextInput value={this.state.mySearchValue} onChangeText={this.binding.handleSearchValueChanged} placeholder = {this.props.placeholder}/>
            </View>
            <View style={this.style.button}>
              <TouchableWithoutFeedback onPress={() => this.binding.handleResetPressed()}>
                <View>
                  <Text style={this.props.crossStyle}>
                    X
                  </Text>                
                </View>             
              </TouchableWithoutFeedback>
            </View>
          </View>
      </View>
    );
  }
}