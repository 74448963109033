import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DocumentViewState from './DocumentViewState';
import DocumentViewProps from './DocumentViewProps';
import ValidationPopUpView from '../../../../../../../../../../../../Common/PopUp/validationPopUpView/ValidationPopUpView';
import DocumentsOperation from '../../../../../../../../../../../../Transfer/DocumentsOperations';
import React from 'react';

export default class DocumentViewModel extends ContentViewModel<DocumentViewState, DocumentViewProps>{
    Documents = new DocumentsOperation();
    
    constructor(props: ContentView) {
        super(props);

        /* Initial State */
        this.initialState({
        });


        /* BINDINGS */
        this.deleteDocument = this.deleteDocument.bind(this);

    }

    deleteDocument() {
        this.showPopUp(
            <ValidationPopUpView text={"AskDeleteDoc"} callBack={() => {
              this.Documents.deleteDocument(this.props().document.id,() =>{
                  this.props().removeFromList(this.props().index);
              });
            }} />
          )
    }

    openDocument(url: any) {
        window.open(url, '_blank', 'noreferrer')
    }
}