import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import LegalEntityInvestmentsViewState from './LegalEntityInvestmentsViewState';
import LegalEntityInvestmentsViewProps from './LegalEntityInvestmentsViewProps';
import LegalEntityInvestmentsViewStyle from './LegalEntityInvestmentsViewStyle';
import LegalEntityInvestmentsViewModel from './LegalEntityInvestmentsViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import TableView from '../../../../../../../../../Components/table/TableView';

export default class LegalEntityInvestmentsView extends ContentView<LegalEntityInvestmentsViewProps, LegalEntityInvestmentsViewState, LegalEntityInvestmentsViewModel, LegalEntityInvestmentsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: LegalEntityInvestmentsViewProps) {
    super(props);

    // Binding
    this.bind(new LegalEntityInvestmentsViewModel(this), new LegalEntityInvestmentsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.Box}>
        <View style={this.style.boxContainer}>
          {/* Research Input */}
          <View>
            {(this.binding.RoleManager.isAdmin || this.binding.RoleManager.isEditor) &&
              <ButtonView animation={'None'} borders={'Little'} style={this.style.exportButtonText} backgroundColor='#FFB243' isLoading={false} handleClick={() => { this.binding.showInvestPopUp() }}>
                <TranslationView upperCase>AddInvest</TranslationView>
              </ButtonView>
            }
          </View>
          <View style={this.style.containerResearch}>
            {/*<ResearchInputView
              textInFront="Recherche : "
              placeholder="search"
              handlerResearching={(text: string) => this.binding.handlerResearching(text)}
              handlingClose={() => { this.binding.handlingClose() }}
              text={this.state.text}
            />*/}
          </View >

          <TableView
            handleResetStepView={this.binding.handleResetStepView}
            resetStepView={this.state.resetStepView}
            headerData={this.state.tableHeaders}
            rows={this.state.rows}
            numberOfRows={50}
            isSortable={true}
            resetTable={this.binding.resetTable}
            datas={this.state.datas}
            fillTables={(value: any[]) => { this.binding.pushRows(value) }}
            keyDatas={["projectId", "userName", "amount", "creationDate", "isValidatedString"]}
            headerIndex={[0, 1, 2, 3]}
            headerTextStyle={this.style.headerText}
            nbPage={1}
            currentPage={1}
            onPageChange={() => { }}
          ></TableView>
        </View>
      </View>
    );
  }
}