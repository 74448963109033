import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import AddPrimeUsersViewState from './AddPrimeUsersViewState';
import AddPrimeUsersViewProps from './AddPrimeUsersViewProps';
import ProjectsOperation from '../../../../../../../../../../../../../../Transfer/ProjectsOperations';
import * as XLSX from 'xlsx';
import PrimeUsersModel from '../../../../../../../../../../../../../../DTO/PrimeUsersModel';

export default class AddPrimeUsersViewModel extends ContentViewModel<AddPrimeUsersViewState, AddPrimeUsersViewProps>
{
    projectOperation = new ProjectsOperation();
    
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            isLoadingFile : false,
            isLoadingInput : false,
            showInputError : false,
            showErrorFile : false,
            email : "",
            model :  { 
                "emailList": [], 
                "projectId": this.props().projectId }
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this)
        this.handleFileChanged = this.handleFileChanged.bind(this)
        this.handleEmailChanged = this.handleEmailChanged.bind(this)
        this.addPrimeViaFile = this.addPrimeViaFile.bind(this)
        this.addPrimeViaInput = this.addPrimeViaInput.bind(this)

    }

    handleBackPressed(){
        this.closePopUp()
    }
 

    async handleFileChanged(file: File)
    {
        this.setField((previousState) => previousState.file = file);
        await new Promise(resolve => setTimeout(resolve, 1));
        this.handleReadingInformation();
        
    }

    addPrimeViaFile() {
        if (this.state().file != null)
        {
            this.setField((previousState) => previousState.isLoadingFile = true);
            this.projectOperation.addPrimeUsers(this.state().model,(data : any)=>{this.handleCallBack(data)})
        }
    }

    handleEmailChanged(email : string)
    {
        this.setField((previousState) => previousState.email = email);
 
    }

    addPrimeViaInput() {
        if(this.state().email !==''){
            let model : PrimeUsersModel = { 
                emailList: [this.state().email], 
                projectId: this.props().projectId 
            }
            this.setField((previousState) => previousState.isLoadingInput = true);
            this.projectOperation.addPrimeUsers(model,(data : any)=>{this.handleCallBack(data)})
        }
    }

    handleCallBack( data : any){
        this.setField((previousState) => previousState.isLoadingInput = false);
        this.setField((previousState) => previousState.isLoadingFile = false);
        this.props().onResponseDataReceived(data)
    }

    handleReadingInformation() {
        let file = this.state().file;
        if (file != undefined) {

            //Read Excel File
            var fileReader = new FileReader();
            fileReader.onloadend = () => {
                // Leave that
                var binary = "";
                var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
                var length = bytes.byteLength;
                for (var i = 0; i < length; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }
                this.TransformExcelInformationToData(binary);
            }
            fileReader.readAsArrayBuffer(file)
        }
    }

    TransformExcelInformationToData(data: any) {
        var workbook: XLSX.WorkBook = XLSX.read(data, { type: "binary" });
        var json : any[]= XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
        var emails: string[] = [];
        if(json[0]["Email"]) 
        {
            json.forEach((v: any) => {
            let email = v["Email"];
            emails.push(email);});
            this.setField((previousState) => previousState.showErrorFile = false);
        }else{
            this.setField((previousState) => previousState.showErrorFile = true);
            this.setField((previousState) => previousState.file = undefined);
        }
        this.setField((previousState) => previousState.model.emailList = emails);
    }
}