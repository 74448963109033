import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle, ViewStyle} from 'react-native';

export default class DescriptionViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  
    title: StyleProp<TextStyle> = {
        display : 'flex',
        justifyContent : 'flex-start',
        color: this.MyStyleManager.primaryColor,
        fontSize: 25,
        fontWeight: 'bold',
        margin: 15,
    }
    containerSameLine : StyleProp<ViewStyle> = {
        display : 'flex',
        flexDirection : 'row', 
        justifyContent : 'flex-start'
    }

    inputInline : StyleProp<ViewStyle> = {
        display : 'flex',
         margin: "1%" ,
         width : '30%',
         flexDirection : 'column'
    }

 
    buttonInLine : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'flex-end',
        margin: "1%" ,
        width : '15%'
    }
    input : StyleProp<ViewStyle> = {
        display : 'flex',
        margin: "1%" ,
        width : '98%',
    }
    inputText : StyleProp<TextStyle> = {
        display : 'flex',
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize: 16,
        borderWidth : 0.1,
        borderRadius : 5,
        padding : 10
    }

    inputTextDisable : StyleProp<TextStyle> = {
        display : 'flex',
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize: 16,
        borderWidth : 0.1,
        borderRadius : 5,
        padding : 10,
        backgroundColor : '#E5E8E8'
    }

    label : StyleProp<TextStyle> = {
        display : 'flex',
        fontFamily : this.MyStyleManager.fontFamily,
        fontSize: 14,
        marginBottom : 15
 
    }

    
}