import {ContentViewModel, ContentView, NavigationManager, Application, Managers} from '@levelapp/softfabric';
import EditLegalEntityViewState from './EditLegalEntityViewState';
import EditLegalEntityViewProps from './EditLegalEntityViewProps';
import LegalEntity from '../../../../../../../DTO/LegalEntity';
import LegalEntityOperations from '../../../../../../../Transfer/LegalEntityOperations';
import RoleManager from '../../../../../../../Common/Managers/RolesManager';
import ExtendedManagers from '../../../../../../../Common/Managers/ExtendedManagers';

export default class EditLegalEntityViewModel extends ContentViewModel<EditLegalEntityViewState, EditLegalEntityViewProps>{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    legalEntityOperation = new LegalEntityOperations();
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)

    constructor(props: ContentView) {
        super(props);

        var entity = this.parameters<LegalEntity>();

        this.initialState({
            legalEntity: entity
        })

        this.NavigationManager.navigate('/dashboard/legalentities/edit/information',true,entity); 
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.editName = this.editName.bind(this);
    }

    componentDidMount()
    {
        this.setField({isLoading : true});
        this.legalEntityOperation.getLegalEntityById(this.state().legalEntity.id, this.handleSuccessUserCallback);
    }

    handleSuccessUserCallback(legalEntity : any)
    {
        this.setField({ legalEntity : legalEntity});
    }

    editName(corporateName : any)
    {
        let entity = this.state().legalEntity;
        entity.corporateName = corporateName;
        
        this.setField({ legalEntity : entity});
    }
}
