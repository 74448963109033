import { Operation } from '@levelapp/softfabric';
import UserLegalEntityService from '../Proxy/UserLegalEntityService';

export default class UserLegalEntityOperation extends Operation
{
    
    _service : UserLegalEntityService;

    constructor()
    {
        super();
        this._service = new UserLegalEntityService();
    }   

    async updateStatus(UserLegalEntity: any, callback: Function = () => { })
    {
        await (this._service as UserLegalEntityService).updateStatus(UserLegalEntity, callback);
    }

    async addLink(UserLegalEntity: any, callback: Function = () => { })
    {
        await (this._service as UserLegalEntityService).addLink(UserLegalEntity, callback);
    }

}