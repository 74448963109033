import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import BankPopUpViewState from './BankPopUpViewState';
import BankPopUpViewProps from './BankPopUpViewProps';

export default class BankPopUpViewModel extends ContentViewModel<BankPopUpViewState, BankPopUpViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            bank : {
                bankFR : "",
                bankEN : "",
                bankNL : ""   
            },
            isLoading : false,
            showErrors : false
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleConfirmPressed = this.handleConfirmPressed.bind(this);

        this.handleBankFRChanged = this.handleBankFRChanged.bind(this);
        this.handleBankNLChanged = this.handleBankNLChanged.bind(this);
        this.handleBankENChanged = this.handleBankENChanged.bind(this);

    }
 
    handleBackPressed(){
        this.closePopUp()
    }

    handleConfirmPressed(){
        if(this.state().bank.bankFR == ""
        || this.state().bank.bankNL == ""
        || this.state().bank.bankEN == ""){
            this.setField((previousState) => previousState.showErrors = true);
        }else{
            this.setField((previousState) => previousState.isLoading = true);
            this.props().saveBank(this.state().bank)  
        }
        
    }

    handleBankFRChanged(text : string){
        this.setField((previousState) => previousState.bank.bankFR = text);
    }
    handleBankNLChanged(text : string){
        this.setField((previousState) => previousState.bank.bankNL = text);
    }
    handleBankENChanged(text : string){
        this.setField((previousState) => previousState.bank.bankEN = text);
    }

}