import React from 'react';
import { View, } from 'react-native';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';
import UsersViewProps from './NonInvestorsViewProps';
import UsersViewModel from './NonInvestorsViewModel';
import UsersViewStyle from './NonInvestorsViewStyle';
import TableView from '../../../../../Components/table/TableView';
import ResearchInputView from '../../../../../Components/researchinput/ResearchInputView';
import NonInvestorsViewProps from './NonInvestorsViewProps';
import NonInvestorsViewState from './NonInvestorsViewState';
import NonInvestorsViewModel from './NonInvestorsViewModel';
import NonInvestorsViewStyle from './NonInvestorsViewStyle';


export default class NonInvestorsView extends ContentView<NonInvestorsViewProps, NonInvestorsViewState, NonInvestorsViewModel, NonInvestorsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: UsersViewProps) {
    super(props);

    // Binding
    this.bind(new UsersViewModel(this), new UsersViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>


          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>
            users
            </TranslationView>

          </View>
          <View style={this.style.bigBoxContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-start' }}>
              {(this.state.isExport || this.state.isAdmin) && 
                <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.exportUsers() }}>
                  <TranslationView style={this.style.exportButtonText}>
                    ExportYourUsersNonInvest
                  </TranslationView>
                </ButtonView>}              
            </View>


            <View style={this.style.boxContainer}>
              {/* Research Input */}
              < View style={this.style.containerFilter}>
                <TranslationView style={{ marginRight: '20px' }}>Search</TranslationView>
                 <ResearchInputView
                  textInFront=""
                  placeholder="search"
                  handlerResearching={(text: any) => this.binding.handleResearching(text)}
                  handlingClose={() => { this.binding.handlingClose() }}
                  text={this.state.text}
                />

              </View >
            </View>

            <TableView
              handleResetStepView={this.binding.handleResetStepView}
              resetStepView={this.state.resetStepView}
              headerData={this.state.tableHeaders}
              rows={this.state.rows}
              numberOfRows={20}
              isLoading={this.state.isLoading}
              lottie={require('./assets/loading.json')}
              isSortable={true}
              resetTable={this.binding.resetTable}
              datas={this.state.datas}
              fillTables={(value: any[]) => { this.binding.pushRows(value) }}
              keyDatas={["id", "clientNumber", "name", "email", "creationDate",]}
              headerIndex={[0, 1, 2, 3, 4]}
              headerTextStyle={this.style.headerText}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              styleOfChoosenButton={this.style.stepColor}
            ></TableView>
          </View>
        </View>
      </View>
    );
  }
}