import React from 'react';
import { View } from 'react-native';
import { ContentView } from '@levelapp/softfabric';
import {TranslationView} from '@levelapp/softfabric-ui';
import ShowTextPopUpViewProps from './ShowTextPopUpViewProps';
import ShowTextPopUpViewState from './ShowTextPopUpViewState';
import ShowTextPopUpViewModel from './ShowTextPopUpViewModel';
import ShowTextPopUpViewStyle from './ShowTextPopUpViewStyle';

export default class ShowTextPopUpView extends ContentView<ShowTextPopUpViewProps, ShowTextPopUpViewState, ShowTextPopUpViewModel, ShowTextPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ShowTextPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShowTextPopUpViewModel(this), new ShowTextPopUpViewStyle(this));
  }

   /* RENDERING */
   render()
   {
     return (
       <View style={{ marginHorizontal: 50, minWidth: 600 , flex : 1}}>
        <View style = {{display :'flex' , flex :1,alignItems : 'center',justifyContent : 'center'}}>
          <TranslationView style={this.style.title}>
          {this.props.text}
          </TranslationView>
        </View>
       </View>
     );
   }
 }