import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';

export default class ExtendedCheckBoxViewStyle extends ContentViewStyle
{
    page: StyleProp<ViewStyle> = {
        flex: 1
    }

    checkbox: StyleProp<any> = {
        width : 20,
        height : 20,
        marginHorizontal: 5,
    }

    titleActive: StyleProp<TextStyle> = {
        fontSize: 14,
        color: '#3F3F3F',
        fontFamily : 'Montserrat SemiBold',
    }

    titleError: StyleProp<TextStyle> = {
        fontSize: 14,
        color: 'red',
        fontFamily : 'Montserrat SemiBold',
    }

    container : StyleProp<ViewStyle> = {
         flexDirection: 'row', 
         alignItems: 'center',
         paddingVertical : 5,
         paddingRight : 10,
    }
}