import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ProjectLeadersViewState from './ProjectLeadersViewState';
import ProjectLeadersViewProps from './ProjectLeadersViewProps';
import ProjectLeadersViewStyle from './ProjectLeadersViewStyle';
import ProjectLeadersViewModel from './ProjectLeadersViewModel';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import TableView from '../../../../../Components/table/TableView';
import ResearchInputView from '../../../../../Components/researchinput/ResearchInputView';

export default class ProjectLeadersView extends ContentView<ProjectLeadersViewProps, ProjectLeadersViewState, ProjectLeadersViewModel, ProjectLeadersViewStyle> {
  /* CONSTRUCTOR */
  constructor(props: ProjectLeadersViewProps) {
    super(props);

    // Binding
    this.bind(new ProjectLeadersViewModel(this), new ProjectLeadersViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { container, box, boxTitle, title, headerText, stepColor, addButton, addButtonText, bigBoxContainer } = this.style
    return (
      <View style={container}>
        <View style={box}>

          {/* Color BOX */}
          <View style={boxTitle}>
            <TranslationView style={title}>
              ProjectLeaders
            </TranslationView>
          </View>
          <View style={bigBoxContainer}>
            {true &&
              <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} style={addButton} handleClick={this.binding.add}>
                <TranslationView style={addButtonText}>
                  {'projectLeaders.add'}
                </TranslationView>
              </ButtonView>
            }

            {/* Research Input */}
            < View style={this.style.containerFilter}>
              <TranslationView style={{ marginRight: '20px' }}>Search</TranslationView>
              <ResearchInputView
                textInFront=""
                placeholder="search"
                handlerResearching={(text: any) => this.binding.handleResearching(text)}
                handlingClose={() => { this.binding.handlingClose() }}
                text={this.state.text}
              />

            </View >


            <TableView
              handleResetStepView={this.binding.handleResetStepView}
              resetStepView={this.state.resetStepView}
              headerData={this.state.tableHeaders}
              rows={this.state.rows}
              numberOfRows={200}
              isLoading={this.state.isLoading}
              lottie={require('./assets/loading.json')}
              isSortable={false}
              resetTable={() => { }}
              datas={this.state.datas}
              fillTables={(value: any[]) => { this.binding.pushRows(value) }}
              keyDatas={['NOM', 'ROLES']}
              headerIndex={[0, 1, 2, 3, 4, 5, 6]}
              headerTextStyle={headerText}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              styleOfChoosenButton={stepColor}
            ></TableView>
          </View>
        </View>
      </View>
    );
  }
}