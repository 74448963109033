import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import CompanyInfosViewState from './CompanyInfosViewState';
import CompanyInfosViewProps from './CompanyInfosViewProps';
import CompanyInfosViewStyle from './CompanyInfosViewStyle';
import CompanyInfosViewModel from './CompanyInfosViewModel';
import { View } from 'react-native';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import Select from '../../../../../../../../../../../../Components/Select/Select';
import TranslationDTO from '../../../../../../../../../../../../DTO/TranslationDTO';
import Bank from '../../../../../../../../../../../../DTO/Bank';

export default class CompanyInfosView extends ContentView<CompanyInfosViewProps, CompanyInfosViewState, CompanyInfosViewModel, CompanyInfosViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: CompanyInfosViewProps) {
    super(props);

    // Binding
    this.bind(new CompanyInfosViewModel(this), new CompanyInfosViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { title, containerSameLine, input, inputInline, buttonBank, buttonInLine } = this.style
    return (
      <View style={{ zIndex: -1 }}>
        <TranslationView style={title}>project.company.general</TranslationView>
        <View style={containerSameLine}>

          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Nom de l'entreprise en FR"}
              labelClass="col-lg-7"
              value={this.props.project.name && this.props.project.name.fr}
              onChange={this.props.onNameFRChange}
              disabled={!this.props.isEditable}
              required={true}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Nom de l'entreprise en NL"}
              labelClass="col-lg-7"
              value={this.props.project.name && this.props.project.name.nl}
              onChange={this.props.onNameNLChange}
              disabled={!this.props.isEditable}
              required={true}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Nom de l'entreprise en EN"}
              labelClass="col-lg-7"
              value={this.props.project.name && this.props.project.name.en}
              onChange={this.props.onNameENChange}
              disabled={!this.props.isEditable}
              required={true}
              type="text" />
          </View>
        </View>
        <View style={inputInline}>
          <InputView
            subscribe={() => { }}
            shouldCheck={false}
            inputClass="col-lg-7"
            label={"N° d'Entreprise (BCE)"}
            labelClass="col-lg-7"
            value={this.props.project.bce}
            onChange={this.props.onBCEChange}
            disabled={!this.props.isEditable}
            required={false}
            type="text" />
        </View>
        <View style={[containerSameLine, { zIndex: 9999 }]}>
          <View style={inputInline}>
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              selectClass="col-lg-7"
              label={this.binding.translate("legalForm")}
              labelClass="col-lg-7"
              onChange={this.props.onLegalFormChange}
              disabled={!this.props.isEditable}
              required={false}
              value={this.state.forms.find(x => x.id === this.props.project.legalFormId)?.fullName}
              options={this.state.forms.length > 0 && this.state.forms.map((form: any, key) => { return { label: form.fullName, value: form.id, key } })} />
          </View>
        </View>
        <TranslationView style={title}>project.company.activity</TranslationView>
        <View style={[containerSameLine, { zIndex: 999 }]}>

          <View style={inputInline}>
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              selectClass="col-lg-7"
              label={'Activité'}
              labelClass="col-lg-7"
              onChange={this.binding.handleActivityDropdownChange}
              disabled={!this.props.isEditable}
              required={false}
              value={this.props.project.activity && this.props.project.activity.fr}
              options={this.state.activities.length > 0 && this.state.activities.map((activity: TranslationDTO, key) => { return { label: activity.fr, value: activity.id, key } })} />
          </View>

          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Activité en FR"}
              labelClass="col-lg-7"
              value={this.props.project.activity && this.props.project.activity.fr}
              onChange={this.props.onActivityFRChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Activité en NL"}
              labelClass="col-lg-7"
              value={this.props.project.activity && this.props.project.activity.nl}
              onChange={this.props.onActivityNLChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Activité en EN"}
              labelClass="col-lg-7"
              value={this.props.project.activity && this.props.project.activity.en}
              onChange={this.props.onActivityENChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>


        <View style={containerSameLine}>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              isValueValid={this.props.project.startOfActivity > 0}
              label={"Début de l'activité"}
              labelClass="col-lg-7"
              value={this.props.project.startOfActivity.toString()}
              onChange={this.props.onstartOfActivityChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <TranslationView style={title}>project.company.bankInformations</TranslationView>
        <View style={[containerSameLine, { zIndex: 900 }]}>
          <View style={inputInline}>
            <Select
              subscribe={() => { }}
              shouldCheck={false}
              selectClass="col-lg-7"
              label={'Banque'}
              labelClass="col-lg-7"
              onChange={this.props.onBankChange}
              disabled={!this.props.isEditable}
              required={false}
              value={this.props.selectedBank}
              options={this.state.banks.length > 0 && this.state.banks.map((bank: Bank, key) => { return { label: bank.bankFR, value: bank.id, key } })} />
          </View>
          {this.props.isEditable && <View style={buttonInLine}>
            <ButtonView style={buttonBank} animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.showPopUpBank}>
              <TranslationView >
                {"bank.add"}
              </TranslationView>
            </ButtonView>
          </View>}
        </View>
        <View style={containerSameLine}>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              isValueValid={this.state.isIBANValid}
              inputClass="col-lg-7"
              label={"IBAN"}
              labelClass="col-lg-7"
              value={this.props.project.iban}
              onChange={this.binding.handleIBANChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              isValueValid={this.state.isBICValid}
              inputClass="col-lg-7"
              label={"BIC"}
              labelClass="col-lg-7"
              value={this.props.project.bic}
              onChange={this.binding.handleBICChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <TranslationView style={title}>project.company.generalinfo</TranslationView>
        <View style={containerSameLine}>
          <View style={input}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Rue"}
              labelClass="col-lg-7"
              value={this.props.project.address && this.props.project.address.street}
              onChange={this.props.onStreetChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Numéro"}
              labelClass="col-lg-7"
              value={this.props.project.address && this.props.project.address.number}
              onChange={this.props.onNumberChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={'Boite'}
              labelClass="col-lg-7"
              value={this.props.project.address && this.props.project.address.box}
              onChange={this.props.onBoxChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <View style={containerSameLine}>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Code Postal"}
              labelClass="col-lg-7"
              value={this.props.project.address && this.props.project.address.postCode}
              onChange={this.props.onPostCodeChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Ville"}
              labelClass="col-lg-7"
              value={this.props.project.address && this.props.project.address.locality}
              onChange={this.props.onLocalityChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
          <View style={inputInline}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Pays"}
              labelClass="col-lg-7"
              value={this.props.project.address && this.props.project.address.country}
              onChange={this.props.onCountryChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <View style={containerSameLine}>
          <View style={input}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"Adresse FR complète pour les mails (adressFR en DB)"}
              labelClass="col-lg-7"
              placeholder={'Ex : Mont des Arts, 7 - 1000 - Bruxelles'}
              value={this.props.project.addressFR}
              onChange={this.props.onFullAddressFRChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <View style={containerSameLine}>
          <View style={input}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              placeholder={'Ex : Kunstberg, 7 - B - 1000 - Brussel'}
              label={"Adresse NL complète pour les mails (adressNL en DB)"}
              labelClass="col-lg-7"
              value={this.props.project.addressNL}
              onChange={this.props.onFullAddressNLChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <View style={containerSameLine}>
          <View style={input}>
            <InputView
              subscribe={() => { }}
              shouldCheck={false}
              inputClass="col-lg-7"
              label={"URL du site de l'entreprise"}
              labelClass="col-lg-7"
              value={this.props.project.enterpriseWebSite}
              onChange={this.props.onWebsiteURLChange}
              disabled={!this.props.isEditable}
              required={false}
              type="text" />
          </View>
        </View>

        <TranslationView style={title}>project.company.leader</TranslationView>
        <View style={[inputInline, { zIndex: 9999 }]}>
          <Select
            subscribe={() => { }}
            shouldCheck={false}
            selectClass="col-lg-7"
            label={''}
            labelClass="col-lg-7"
            onChange={this.props.onLeaderChange}
            disabled={!this.props.isEditable}
            required={false}
            value={this.state.leaders.find(x => x.id === this.props.project.leaderId)?.fullName}
            options={this.state.leaders.length > 0 && this.state.leaders.map((leader: any, key) => { return { label: leader.fullName, value: leader.id, key } })} />
        </View>
      </View>
    );
  }
}