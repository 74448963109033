import PermissionsViewState from './RolesViewState';
import PermissionsViewProps from './RolesViewProps';
import { ContentViewModel, ContentView, Application, NavigationManager, Managers } from '@levelapp/softfabric';
import { Text, View, TouchableWithoutFeedback, Image } from 'react-native';
import React from 'react'; 
import PermissionsViewStyle from './RolesViewStyle';
import StateManager from '../../../../../Common/Managers/StateManager';
import StateManagerConst from '../../../../../Common/Managers/StateManagerConst';
import RolesOperations from '../../../../../Transfer/RolesOperations';
import RolePopUpView from './rolepopup/RolePopUpView';
import Role from '../../../../../DTO/Role';
import UserRole from '../../../../../DTO/UserRole';
import ExtendedManagers from '../../../../../Common/Managers/ExtendedManagers';
import RoleManager from '../../../../../Common/Managers/RolesManager';
import { TranslationView } from '@levelapp/softfabric-ui';

export default class RolesViewModel extends ContentViewModel<PermissionsViewState, PermissionsViewProps, PermissionsViewStyle>
{
    NavigationManager: NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
    StateManager: StateManager = Application.current.resolve<StateManager>("STATE_MANAGER");
    RoleManager: RoleManager = Application.current.resolve<RoleManager>(ExtendedManagers.ROLE_MANAGER)
    roleOperation = new RolesOperations();
    textResearch: string = "";
    interval: any;
    _roles : Role[] = [];


    constructor(props: ContentView) {
        super(props);
        let previousState = this.StateManager.retrieveState(StateManagerConst.ROLE);
        this.interval = null;
        

        /* Initial State */
        this.initialState({
            statuses: [],
            selectedStatus: previousState != undefined ? previousState.selectedStatus : { id: 0, title: "ALL", usersRoles: null },
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            tableHeaders: [],
            isLoading: true,
            usersRoles: [],
            datas: [],
            text: previousState != undefined ? previousState.text : '',
            nbPage: 0,
            currentPage: 1,
            isResearching: false,
            isExporting: false,
        });

        if (previousState != undefined) {
            this.textResearch = previousState.text;
        }

        this.roleOperation.getAllRoles((roles : any)=>{
            this._roles = roles;
        })

        /* BINDINGS */
        this.editField = this.editField.bind(this);
        this.handleSuccessUserCallback = this.handleSuccessUserCallback.bind(this);
        this.handleResetStepView = this.handleResetStepView.bind(this);
        this.saveUserRole = this.saveUserRole.bind(this);
        this.addUserRole = this.addUserRole.bind(this);
        this.showPopUpAddUserRoles = this.showPopUpAddUserRoles.bind(this);

        this.refresh = this.refresh.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    }

    componentDidMount() {
        this.refresh();
    }
    refresh() {
        this.setField({ isLoading: true });
        this.roleOperation.getUsersWithRoles(this.handleSuccessUserCallback)
    }



    handleSuccessUserCallback(ret: any) {
        let usersRoles: any[] = ret;
        let datas: any[] = [];
        let rows: any[] = [];

        usersRoles.forEach((userRole) => {
            var userRoleDTO = {
                'id' : userRole.id,
                'name' : userRole.name,
                'roles' : userRole.roles,
            }
            var rolesList : string = '';

            userRoleDTO.roles.forEach((role : Role)=> rolesList+= "  "+role.name)

            datas.push(userRoleDTO);
            rows.push(
                <View>
                    <Text style={this.style().text}>{userRoleDTO.name}</Text>
                    <Text style={this.style().text}>{rolesList}</Text>
                    <TouchableWithoutFeedback onPress={() => { this.editField(userRole) }} >
                        <View style={{ alignItems: 'center' }} >
                            <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ tableHeaders: [ 'NOM', 'ROLES', 'EDIT'] });
        this.setField({ usersRoles: usersRoles });
        this.setField({ isLoading: false });
        this.setField({ nbPage: ret.totalPage });
    }

    editField(userRole: any) { 
        let user : UserRole = this.state().usersRoles.find((user) => user.id === userRole.id);    
        this.showPopUp(
            <RolePopUpView 
            userRole ={user}
            roles = {this._roles}
            save={this.saveUserRole} />
        );
    }


    handleResetStepView() {
        this.setField({ resetStepView: false });
    }


    onPageChange(page: number) {
        this.state().currentPage = page + 1;
        this.refresh()
    }


    pushRows(datasToPush: any[]) {
        let rows: any[] = [];
        datasToPush.forEach(user => {
            this.pushRow(user, rows);
        });

        this.setField({ rows: rows, datas: datasToPush, resetStepView: true });
    }

    pushRow(userRole: any, rowsToPush: any[]) {
        rowsToPush.push(
            <View>
                <Text style={this.style().text}>{userRole.user}</Text>
                <Text style={this.style().text}>{userRole.role}</Text>
                <TouchableWithoutFeedback onPress={() => { this.editField(userRole) }} >
                    <View>
                        <Image source={require('./assets/edit.png')} style={{ height: 24, width: 24 }} resizeMode="contain"></Image>
                    </View>
                </TouchableWithoutFeedback>
            </View>
        )
    }

    saveUserRole(userRole: any) { 
        this.roleOperation.updateUserRoles(userRole, () => {
            this.refresh();
            this.showPopUp(
                <View style={{ marginHorizontal: 50, minWidth: 600 }}>
                    <TranslationView style={{
                            textAlign: 'center',
                            color: '#4A495C',
                            fontSize: 21,
                            fontWeight: '600',
                            marginTop: 50
                        }}>
                     role.update.succes
                    </TranslationView>
                </View>)
        }); 
    }

    addUserRole(userRole: any) { 
        this.roleOperation.createUserRoles(
            userRole,
            () => {
                this.closePopUp();
                this.refresh();
            },
            () => {
                this.closePopUp();
                this.refresh();
                this.showPopUpAddUserRoles(true)
            }
            ); 
    }

    showPopUpAddUserRoles(isErrorDisplay : boolean){
        this.showPopUp(
            <RolePopUpView 
            userRole ={{
                id : "",
                name : "",
                roles : [], 
            }}
            roles = {this._roles}
            save={this.addUserRole}
            showError = {isErrorDisplay}/> 
        );
    }
}
