import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import BankPaymentImportPopUpViewState from './BankPaymentImportPopUpViewState';
import BankPaymentImportPopUpViewProps from './BankPaymentImportPopUpViewProps';
import BankPaymentImportPopUpViewStyle from './BankPaymentImportPopUpViewStyle';
import BankPaymentImportPopUpViewModel from './BankPaymentImportPopUpViewModel';
import PopupHeaderView from '../../../../../../../../../../components/popopheader/PopupHeaderView';
import {  View,Text } from 'react-native';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import ButtonImportFileView from '@levelapp/softfabric-ui/components/Web/buttonimportfile/ButtonImportFileView';
import TableView from '../../../../../../../../../../../../../Components/table/TableView';

export default class BankPaymentImportPopUpView extends ContentView<BankPaymentImportPopUpViewProps, BankPaymentImportPopUpViewState, BankPaymentImportPopUpViewModel, BankPaymentImportPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:BankPaymentImportPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new BankPaymentImportPopUpViewModel(this), new BankPaymentImportPopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {text,YesButton,lineContainer,tabContainer,tabTitle,tableContainer,errorFileContainer,errorText,headerText,stepColor,showErrorButton,errorTableContainer} = this.style;
    const headerInvest = ['Invest ID','User ID',"PM", 'Numéro Client', 'Nom', 'Email', 'Montant','Supprimer'];
    const headerBankTransactionError = ['Transaction ID','Description',"IBAN", 'Montant', 'Communication',"Type d'erreur"];

    return (
      <View style={{ minWidth: 300,height: 900, width: 1500, maxWidth: "100%" }}>

        <PopupHeaderView
          handleBackPressed={this.binding.handleBackPressed}
          title={"bank.transaction.import.header"}
          width={1500}/> 

        <View style={lineContainer}> 
          <View style={{flex : 1, justifyContent : 'center'}}>
            {this.state.file?
            <div style={text}>
              {this.state.file.name}
            </div>
            :
            <TranslationView style={text}>
                {'import.file'}
            </TranslationView>} 
          </View>
          <View style={{flex : 2}}>
            <ButtonImportFileView style={{flex : 2}} buttonColor="#FFB243" textColor="white" accepted=".csv" action={this.binding.handleFileChanged} />        

          </View>
          <View style={{flex : 1}}>
            {this.state.showInvestments && this.state.investToUpdate.length > 0 && <ButtonView animation={'Bounce'} borders={'Little'} isLoading={this.state.isLoading} handleClick={this.binding.handleUpdateButton} backgroundColor="#FFB243" style={YesButton}>
              <TranslationView >
                {'bank.import.add.file'}
              </TranslationView> 
            </ButtonView>}
          </View>
        </View>

        {this.state.showErrorFile &&
          <View style={errorFileContainer}>
              <TranslationView style = {errorText}>{"bank.transaction.error.file"}</TranslationView>
          </View>}

        {this.state.showSucces  &&
          <View style={tabContainer}>
            <TranslationView style={tabTitle}>{'importSuccess'}</TranslationView>
          </View>}

        {this.state.showError &&
          <View style={tabContainer}>
          <TranslationView style={[tabTitle,{color: 'red'}]}>{'importError'}</TranslationView>
          </View>
            }

        {this.state.showErrorEmptyInvest &&
          <View style={tabContainer}>
          <TranslationView style={[tabTitle,{color: 'red'}]}>{'importErrorEmpty'}</TranslationView>
          </View>
            }


        {this.state.showInvestments && 
        <View style={tableContainer}>
          

          <View style ={{flexDirection : 'row'}}>
            <View style ={{flexDirection : 'row'}}>
              <TranslationView style={tabTitle}>{'investNumber'}</TranslationView>
              <Text style={tabTitle}>{this.state.investToUpdate.length}</Text>
            </View>
            <View style ={{marginLeft : 20,flexDirection : 'row', justifyContent : 'space-evenly'}}>
              <TranslationView style={[tabTitle,{color: 'red'}]}>{'importError'}</TranslationView>
              <Text style={[tabTitle,{color: 'red'}]}>{this.state.errorLineAmount }</Text>
                <View style ={{marginLeft : 20}}>
                  <ButtonView animation={'Bounce'} borders={'Little'} isLoading={false} handleClick={this.binding.handleShowErrorButton} backgroundColor="#D5DBDB" style={showErrorButton}>
                    <TranslationView >
                      {'show.error'}
                    </TranslationView> 
                  </ButtonView>
                </View>
              
            </View>
          </View>

          {this.state.showFailedBankTransaction && 
          <View style={errorTableContainer}>
           <TableView
            style={{width :1000}}
            handleResetStepView={this.binding.handleResetStepViewError}
            resetStepView={this.state.resetStepViewError}
            headerData={headerBankTransactionError}
            rows={this.state.errorRows}
            numberOfRows={this.state.nbErrorRows}
            isLoading={this.state.isErrorLoading}
            lottie={require('../../../../../../../assets/loading.json')}
            isSortable={false}  
            resetTable={()=>{}}
            datas={this.state.errorDatas}
            fillTables={() => {}}
            keyDatas={headerBankTransactionError}
            headerIndex={[0, 1, 2, 3, 4, 5, 6]}
            headerTextStyle={headerText}
            onPageChange={(page: number) => this.binding.onErrorPageChange(page)}
            nbPage={this.state.nbErrorPage}
            currentPage={this.state.currentErrorPage - 1}
            styleOfChoosenButton={stepColor}/>
          </View>
         }
  
          <TableView
          handleResetStepView={this.binding.handleResetStepView}
          resetStepView={this.state.resetStepView}
          headerData={headerInvest}
          rows={this.state.rows}
          numberOfRows={this.state.nbRows}
          isLoading={this.state.isInvestLoading}
          lottie={require('../../../../../../../assets/loading.json')}
          isSortable={false}  
          resetTable={()=>{}}
          datas={this.state.datas}
          fillTables={() => {}}
          keyDatas={headerInvest}
          headerIndex={[0, 1, 2, 3, 4, 5, 6]}
          headerTextStyle={headerText}
          onPageChange={(page: number) => this.binding.onPageChange(page)}
          nbPage={this.state.nbPage}
          currentPage={this.state.currentPage - 1}
          styleOfChoosenButton={stepColor}/>
        </View>}

    
      </View>
    );
  }
}