import {ContentViewModel , ContentView} from '@levelapp/softfabric';
import DropDownViewState from './DropDownViewState';
import DropDownViewProps from './DropDownViewProps';

export default class DropDownViewModel extends ContentViewModel<DropDownViewState, DropDownViewProps>{
    constructor(props: ContentView) {
        super(props);

        this.initialState({selectedValue : undefined, values : []});

        this.handleValueChanged = this.handleValueChanged.bind(this);
        this.handleDefaultValue = this.handleDefaultValue.bind(this);
    } 

    handleValueChanged (value : any){
        this.setField({selectedValue : value});
        this.props().onValueChange(JSON.parse(value));
    }

    componentDidMount()
    {
        this.handleDefaultValue();
    }

    handleDefaultValue()
    {
        if(this.props().selectedValue != undefined && this.state().selectedValue == undefined)
        {
            this.setField({selectedValue : JSON.stringify(this.props().selectedValue)});
        }
    }
}