import React from 'react';
import { ContentView, LanguageManager, Application, Managers } from '@levelapp/softfabric';
import DocumentViewState from './DocumentViewState';
import DocumentViewProps from './DocumentViewProps';
import DocumentViewStyle from './DocumentViewStyle';
import DocumentViewModel from './DocumentViewModel';
import { View, Image, Text, TouchableHighlight, TouchableOpacity } from 'react-native';

export default class DocumentView extends ContentView<DocumentViewProps, DocumentViewState, DocumentViewModel, DocumentViewStyle>
{
  languageManager: LanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
  /* CONSTRUCTOR */
  constructor(props: DocumentViewProps) {
    super(props);

    // Binding
    this.bind(new DocumentViewModel(this), new DocumentViewStyle(this));
  }

  /* RENDERING */
  render() {
    var language = this.languageManager.language;
    return (
      <View style={this.style.box}>
        <Image style={{ width: '23px', height: '28px', margin: '25px' }} source={require('./assets/docicon.svg')} />
        <TouchableOpacity style={{justifyContent: 'center'}} onPress={() => this.binding.openDocument(this.props.document.url)}>
          <View style={this.style.documentTitleView}>
            {language == "fr-BE" ? <Text style={this.style.documentTitle}>{this.props.document.title.fr}</Text> :
              language == "nl-BE" ? <Text style={this.style.documentTitle}>{this.props.document.title.nl}</Text> :
                <Text style={this.style.documentTitle}>{this.props.document.title.en}</Text>}
          </View>
        </TouchableOpacity>
        <View style={this.style.download}>
          <TouchableHighlight
            onPress={() => { this.binding.deleteDocument() }}>
            <View>
              <Image
                source={require('./assets/trash.svg')}
                style={{ height: 30, width: 30 }} />
            </View>

          </TouchableHighlight>
        </View>
      </View>
    );
  }
}