import { Operation } from "@levelapp/softfabric";
import LegalFormsService from "../Proxy/LegalFormsService";

export default class LegalFormsOperations extends Operation
{
    _service : LegalFormsService;

    constructor()
    {
        super();
        this._service = new LegalFormsService();
    }   

    async get(callback: Function = () => { })
    {
        await (this._service as LegalFormsService).get(callback);
    }
}