import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';
import UserRole from '../DTO/UserRole';

export default class RoleService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);     

    async getUsersWithRole(onSuccess: Function)
    {     
        this.httpClient.handleSomethingWentWrong = () => onSuccess([]);

        this.httpClient
        .get(`api/role/userWithRoles`)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
        
    }

    async getAllRoles(onSuccess: Function)
    {     
        this.httpClient.handleSomethingWentWrong = () => onSuccess([]);
        this.httpClient
        .get(`api/role/all`)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
        
    }
    async updateUserRoles(userRole : UserRole , onSuccess: Function)
    {       
        this.httpClient
        .put('api/role/UpdateUserRoles',[],userRole)
        .then((response : any) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }
        });
    }
    async createUserRoles(userRole : UserRole , onSuccess: Function, onError : Function)
    {       
        this.httpClient
        .post('api/role/CreateUserRoles',[],userRole)
        .then((response : any ) => {
            if (response)
            {
                if (response.data) 
                {                  
                    onSuccess(response.data.data);
                }
            }else{
                onError()
            }
        });
    }
    
}