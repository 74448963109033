import React from 'react';
import { View } from 'react-native';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { ContentView } from '@levelapp/softfabric';
import ProjectsViewProps from './ProjectsViewProps';
import ProjectsViewState from './ProjectsViewState';
import ProjectsViewModel from './ProjectsViewModel';
import ProjectsViewStyle from './ProjectsViewStyle';
import ResearchInputView from '../../../../../../Components/researchinput/ResearchInputView';
import TableView from '../../../../../../Components/table/TableView';


export default class ProjectsView extends ContentView<ProjectsViewProps, ProjectsViewState, ProjectsViewModel, ProjectsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ProjectsViewProps) {
    super(props);

    // Binding
    this.bind(new ProjectsViewModel(this), new ProjectsViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.container}>
        <View style={this.style.box}>


          {/* Color BOX */}
          <View style={this.style.boxTitle}>
            <TranslationView style={this.style.title}>
              Projects
           </TranslationView>
            <TranslationView style={this.style.subTitle}>
              ManageYourProjects
           </TranslationView>
          </View>
         
          <View style={this.style.boxContainer}>
           {(this.state.isExport || this.state.isAdmin) &&
              <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={this.style.exportButton} handleClick={() => { this.binding.ExportNumberInvestment() }}>
              <TranslationView style={this.style.exportButton}>
              ExportNumberInvestment
              </TranslationView>
              </ButtonView>
            
            }


            {/* Research Input */}
            <View style={this.style.containerResearch}>
            {this.state.isAdmin &&
                <ButtonView  animation={'Bounce'} borders={'None'} isLoading={false} handleClick={() => { this.binding.createNewProject() }} backgroundColor={"#FFB243"} style={this.style.addProjectButton}>
                  <TranslationView style={this.style.buttonText}>
                    AddProject
                  </TranslationView>
                </ButtonView>
              }
            </View >
            <View style={this.style.containerResearch}>
              <ResearchInputView
                textInFront="Recherche : "
                placeholder="search"
                handlerResearching={(text: string) => this.binding.handleResearching(text)}
                handlingClose={() => { this.binding.handlingClose() }}
                text={this.state.text}
              />
            </View >

            <TableView
              isLoading={this.state.isLoading}
              lottie={require('./assets/loading.json')}
              handleResetStepView={this.binding.handleResetStepView}
              resetStepView={this.state.resetStepView}
              headerData={this.state.tableHeaders}
              rows={this.state.rows}
              numberOfRows={20}
              isSortable={true}
              resetTable={this.binding.resetTable}
              datas={this.state.datas}
              fillTables={(value: any[]) => { this.binding.pushRows(value) }}
              keyDatas={["name", "nbInvestiseurValide", "montantTotValid", "nbInvestiseurAttente", "montantTotAttente"]}
              headerIndex={[0, 1, 2, 3, 4, 5, 6]}
              headerTextStyle={this.style.headerText}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              styleOfChoosenButton={this.style.stepColor}
            ></TableView>
          </View>
        </View>

      </View>
    );
  }
}