import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import MailSendedPopUpViewState from './MailSendedPopUpViewState';
import MailSendedPopUpViewProps from './MailSendedPopUpViewProps';

export default class MailSendedPopUpViewModel extends ContentViewModel<MailSendedPopUpViewState, MailSendedPopUpViewProps>
{
    constructor(props: ContentView) {
        super(props);

        /* Initial State */
        this.initialState({
        });

        this.handleBackPressed = this.handleBackPressed.bind(this);
    }

    handleBackPressed() {
        this.closePopUp();
    }

}