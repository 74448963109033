import { ContentViewModel, Application, LanguageManager, Managers, ContentView } from '@levelapp/softfabric';
import TabViewState from './TabViewState';
import TabViewProps from './TabViewProps';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import TabOperation from '../../../../../../../Transfer/TabOperation';
import ProjectOperation from '../../../../../../../Transfer/ProjectsOperations';
import BlockOperation from '../../../../../../../Transfer/BlockOperation';
import ShowTextPopUpView from '../../../../../../../Common/PopUp/showTextPopUpView/ShowTextPopUpView';
import React from 'react';
import ValidationPopUpView from '../../../../../../../Common/PopUp/validationPopUpView/ValidationPopUpView';

export default class TabViewModel extends ContentViewModel<TabViewState, TabViewProps>{
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
    MyLanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);
    projectOperation = new ProjectOperation();
    tabOperation = new TabOperation();
    blockOperation = new BlockOperation();

    constructor(props: ContentView) {

        super(props);
        /* Initial State */
        this.initialState({
            currentLanguage: this.MyLanguageManager.language,
            isLoading: false,
        });

        /* BINDING */
        this.handleTitleChanged = this.handleTitleChanged.bind(this);
        this.handleOrderTabChange = this.handleOrderTabChange.bind(this);
        this.removeTab = this.removeTab.bind(this);
        this.submit = this.submit.bind(this);
        this.publishStaging = this.publishStaging.bind(this);
        this.publishProd = this.publishProd.bind(this);
        this.dePublish = this.dePublish.bind(this);
    }

    componentDidMount() {
        if (this.props().tabOrders.length != 0) {
            this.tabOperation.getTabById(this.props().tabOrders[0].id, (tab: any) => {
                this.setField({ currentTab: tab }, () => {
                });
            });
        }
    }

    submit() {
        if (!this.props().isSavedBlock) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedBlock"}></ShowTextPopUpView>
            )
            return;
        }

        if (isNaN(parseInt(this.props().currentTab.order))) {
            this.showPopUp(
                <ShowTextPopUpView text={"isTabNumber"}></ShowTextPopUpView>
            )
            return;
        } else if (this.props().currentTab.title.fr == "" || this.props().currentTab.title.nl == "" || this.props().currentTab.title.en == "") {
            this.showPopUp(
                <ShowTextPopUpView text={"NoTitleTab"}></ShowTextPopUpView>
            )
            return;
        }
        //Si il n'est pas return avant on envoie la requete
        if (this.props().currentTab.id == 0) {
            //insert
            this.showPopUp(
                <ValidationPopUpView text={"SaveTab"} callBack={() => this.tabOperation.insertTab(this.props().currentTab, (response: any) => {
                    // Demander au parent de rechopper la list des tabOrders, surtout ne pas changer les blocks !!!
                    this.props().setCurrentTabId(response.id);
                    this.props().setTabOrders();
                    this.props().setIsSavedTab(true);
                })} />
            );
        } else {
            //update
            this.showPopUp(
                <ValidationPopUpView text={"UpdateTab"} callBack={() => this.tabOperation.updateTab(this.props().currentTab, () => {
                    // Demander au parent de rechopper la list des tabOrders, surtout ne pas changer les blocks !!!
                    this.props().setTabOrders();
                    this.props().setIsSavedTab(true);
                })} />
            );
        }
    }

    handleTitleChanged(title: string, lang: string) {
        switch (lang) {
            case "fr":
                if (this.props().currentTab.title.fr.replace(/ /g,' ') != title.replace(/ /g,' ')) {
                    this.props().setTabTitle(title, "fr");
                    this.props().setIsSavedTab(false);
                }
                break;
            case "nl":
                if (this.props().currentTab.title.nl.replace(/ /g,' ') != title.replace(/ /g,' ')) {
                    this.props().setTabTitle(title, "nl");
                    this.props().setIsSavedTab(false);
                }
                break;
            case "en":
                if (this.props().currentTab.title.en.replace(/ /g,' ') != title.replace(/ /g,' ')) {
                    this.props().setTabTitle(title, "en");
                    this.props().setIsSavedTab(false);
                }
                break;
        }
    }

    removeTab() {
        this.showPopUp(
            <ValidationPopUpView text={"DeleteTab"} callBack={() => {
                this.props().removeTab();
            }} />
        );
    }

    handleOrderTabChange(order: string) {
        if (this.props().currentTab.order != order) {
            this.props().setTabOrder(order);
            this.props().setIsSavedTab(false);
        }
    }

    publishStaging() {
        if (!this.props().isSavedBlock) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedBlockError"}></ShowTextPopUpView>
            )
            return;
        }
        if (!this.props().isSavedTab) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedTab"}></ShowTextPopUpView>
            )
            return;
        }
        this.showPopUp(

            <ValidationPopUpView text={"ValidatePublishStaging"} callBack={() => this.tabOperation.publishStaging(this.props().currentTab, () => {
                this.props().setCurrentTabState(2);
            })} />
        );
    }

    publishProd() {
        if (!this.props().isSavedBlock) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedBlockError"}></ShowTextPopUpView>
            )
            return;
        }
        if (!this.props().isSavedTab) {
            this.showPopUp(
                <ShowTextPopUpView text={"NonSavedTab"}></ShowTextPopUpView>
            )
            return;
        }
        this.showPopUp(

            <ValidationPopUpView text={"ValidatePublishProd"} callBack={() => this.tabOperation.publishProd(this.props().currentTab, () => {
                this.props().setCurrentTabState(3);
            })} />
        );
    }

    dePublish() {
        this.showPopUp(

            <ValidationPopUpView text={"ValidateDepublish"} callBack={() => this.tabOperation.depublish(this.props().currentTab, () => {
                this.props().setCurrentTabState(1);
            })} />
        );
    }

}