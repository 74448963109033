import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';

export default class BankPaymentImportPopUpViewStyle extends ContentViewStyle
{
    MyStyleManager : StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);
   
    tabsContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flexDirection: "row",  
        borderBottomWidth : 0.2
    }

    tabContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        alignItems : "flex-start",
        marginLeft : 50,  
    }

    errorText : StyleProp<TextStyle> = {
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 18, 
        fontWeight : 'bold',
        color : 'red'
    }
    errorContainer : StyleProp<ViewStyle> = {
        margin : 50,
    }
    currentTabContainer : StyleProp<ViewStyle> =
    {
        display: "flex", 
        flex : 1,
        alignItems : "center",
        padding : 10,
        borderTopEndRadius : 10,
        borderTopStartRadius : 10,
        backgroundColor : '#D5DBDB', 
    }

    errorTableContainer: StyleProp<ViewStyle> =
    {
        alignItems : "center",
        margin: 20,
        padding : 10,
        borderWidth : 0.5,
        borderRadius : 20,
    }
    tabTitle : StyleProp<TextStyle> =
    {
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 18, 
        lineHeight : 20,
        fontWeight : 'bold',
        
    }
    
   
   YesButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        display: 'flex',
        height: 42,
        justifyContent : 'center',
        alignItems : 'center'
    } 

    showErrorButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        display: 'flex',
        height: 20,
        width : 100,
        justifyContent : 'center',
        alignItems : 'center'
    } 


    text : StyleProp<any> = {
        color: '#565656', 
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: '14px', 
    }

 
    lineContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'space-evenly', 
        flexDirection : 'row',
        margin: 50,
    }

    tableContainer : StyleProp<ViewStyle> = {
        display : 'flex',
        margin: 50,
        marginTop :0,
        paddingBottom : 50
    }

    errorFileContainer : StyleProp<ViewStyle> = {
        display: 'flex',
        margin : 50,
    }

    errorFileText : StyleProp<TextStyle> = {
        fontFamily: this.MyStyleManager.fontFamily, 
        fontSize: 18, 
        fontWeight : 'bold',
        color : 'red'
    }
    headerText: StyleProp<TextStyle> = {
        color: '#FFB243',
        fontWeight: 'bold',
        fontSize : 12,
        textAlign : 'center'
    }

 

    stepColor : StyleProp<ViewStyle> =
    {
        backgroundColor : this.MyStyleManager.primaryColor,
    }



    
}



    
