import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import BenefitsViewState from './BenefitsViewState';
import BenefitsViewProps from './BenefitsViewProps';
import BenefitsViewStyle from './BenefitsViewStyle';
import BenefitsViewModel from './BenefitsViewModel';
import { View, Text, TextInput, TouchableWithoutFeedback } from 'react-native';
import InputView from '../../../../../../../../../../../../Components/Input/input/InputView';
import ProjectBenefitDTO from '../../../../../../../../../../../../DTO/ProjectBenefitDTO';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';

export default class BenefitsView extends ContentView<BenefitsViewProps, BenefitsViewState, BenefitsViewModel, BenefitsViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: BenefitsViewProps) {
    super(props);

    // Binding
    this.bind(new BenefitsViewModel(this), new BenefitsViewStyle(this));
  }

  /* RENDERING */
  render() {
    const { title, containerSameLine, inputInline, input, inputText, label, inputTextDisable, benefitContainer, benefitTitleContainer, benefitDetailsContainer, button, buttonContainer } = this.style
    return (
      <View>
        <TranslationView style={title}>project.benefits</TranslationView>
        {this.props.project.projectBenefits.map((projectBenefitDTO: ProjectBenefitDTO, index: number) => {
          return (
            <View key={index} style={benefitContainer}>
              <TouchableWithoutFeedback onPress={() => this.binding.handleShowHideBenefits(index)}>
                <View style={benefitTitleContainer}  >
                  <Text style={title}>{projectBenefitDTO.title.fr}</Text>
                  <Text >{this.state.indexBenefitsToShow.includes(index) ? ' (hide)' : ' (show)'}</Text>
                </View>
              </TouchableWithoutFeedback>

              {this.state.indexBenefitsToShow.includes(index) && <View style={benefitDetailsContainer}>
                <View style={containerSameLine}>
                  <View style={inputInline}>
                    <InputView
                      subscribe={() => { }}
                      shouldCheck={false}
                      inputClass="col-lg-7"
                      label={"Ordre"}
                      labelClass="col-lg-7"
                      onChange={(title: string) => this.props.onOrderChange(title, index)}
                      value={projectBenefitDTO.order.toString()}
                      disabled={!this.props.isEditable}
                      required={false}
                      type="text" />
                  </View>
                </View>
                <View style={containerSameLine}>
                  <View style={input}>
                    <InputView
                      subscribe={() => { }}
                      shouldCheck={false}
                      inputClass="col-lg-7"
                      label={"Titre FR"}
                      labelClass="col-lg-7"
                      onChange={(title: string) => this.props.onTitleFRChange(title, index)}
                      value={projectBenefitDTO.title.fr}
                      disabled={!this.props.isEditable}
                      required={false}
                      type="text" />
                  </View>
                </View>

                <View style={containerSameLine}>
                  <View style={input}>
                    <InputView
                      subscribe={() => { }}
                      shouldCheck={false}
                      inputClass="col-lg-7"
                      label={"Titre NL"}
                      labelClass="col-lg-7"
                      onChange={(title: string) => this.props.onTitleNLChange(title, index)}
                      value={projectBenefitDTO.title.nl}
                      disabled={!this.props.isEditable}
                      required={false}
                      type="text" />
                  </View>
                </View>

                <View style={containerSameLine}>
                  <View style={input}>
                    <InputView
                      subscribe={() => { }}
                      shouldCheck={false}
                      inputClass="col-lg-7"
                      label={"Titre EN"}
                      labelClass="col-lg-7"
                      onChange={(title: string) => this.props.onTitleENChange(title, index)}
                      value={projectBenefitDTO.title.en}
                      disabled={!this.props.isEditable}
                      required={false}
                      type="text" />
                  </View>
                </View>

                <View style={containerSameLine}>
                  <View style={inputInline}>
                    <Text style={label}>Paragraphe FR</Text>
                    <TextInput
                      multiline={true}
                      style={this.props.isEditable ? inputText : inputTextDisable}
                      numberOfLines={7}
                      editable={this.props.isEditable}
                      value={projectBenefitDTO.subtitle.fr}
                      onChangeText={(subtitle: string) => this.props.onSubtitleFRChange(subtitle, index)} />
                  </View>

                  <View style={inputInline}>
                    <Text style={label}>Paragraphe NL</Text>
                    <TextInput
                      multiline={true}
                      style={this.props.isEditable ? inputText : inputTextDisable}
                      numberOfLines={7}
                      editable={this.props.isEditable}
                      value={projectBenefitDTO.subtitle.nl}
                      onChangeText={(subtitle: string) => this.props.onSubtitleNLChange(subtitle, index)} />
                  </View>

                  <View style={inputInline}>
                    <Text style={label}>Paragraphe EN</Text>
                    <TextInput
                      multiline={true}
                      style={this.props.isEditable ? inputText : inputTextDisable}
                      numberOfLines={7}
                      editable={this.props.isEditable}
                      value={projectBenefitDTO.subtitle.en}
                      onChangeText={(subtitle: string) => this.props.onSubtitleENChange(subtitle, index)} />
                  </View>
                </View>

              </View>}
              {this.props.isEditable && false &&
                <View style={buttonContainer}>
                  <ButtonView style={button} animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={() => this.binding.handleDeleteClick(projectBenefitDTO.id)}>
                    <Text >
                      {"-"}
                    </Text>
                  </ButtonView>
                </View>}
            </View>)
        })}

        {this.props.isEditable && <View style={buttonContainer}  >
          <ButtonView style={button} animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={false} handleClick={this.binding.handleAddClick}>
            <Text >
              {"+"}
            </Text>
          </ButtonView>
        </View>}


      </View>
    );
  }
}