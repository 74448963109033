import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import RolesViewState from './RolesViewState';
import RolesViewProps from './RolesViewProps';
import RolesViewStyle from './RolesViewStyle';
import RolesViewModel from './RolesViewModel';
import {ButtonView, TranslationView} from '@levelapp/softfabric-ui';
import { View } from 'react-native';
import TableView from '../../../../../Components/table/TableView';


export default class RolesView extends ContentView<RolesViewProps, RolesViewState, RolesViewModel, RolesViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:RolesViewProps)
  {
    super(props);

    // Binding
    this.bind(new RolesViewModel(this), new RolesViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {container,box,boxTitle,title,headerText,stepColor,addButton,addButtonText,bigBoxContainer} = this.style
    return (
    <View style={container}>
      <View style={box}>

          {/* Color BOX */}
        <View style={boxTitle}>
          <TranslationView style={title}>
            roles
          </TranslationView>
        </View>
        <View style={bigBoxContainer}>
            <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isExporting} style={addButton} handleClick={this.binding.showPopUpAddUserRoles}>
            <TranslationView style={addButtonText}>
              {'roles.add'}
            </TranslationView>
          </ButtonView>

          <TableView
              handleResetStepView={this.binding.handleResetStepView}
              resetStepView={this.state.resetStepView}
              headerData={this.state.tableHeaders}
              rows={this.state.rows}
              numberOfRows={20}
              isLoading={this.state.isLoading}
              lottie={require('./assets/loading.json')}
              isSortable={false}  
              resetTable={()=>{}}
              datas={this.state.datas}
              fillTables={(value: any[]) => { this.binding.pushRows(value) }}
              keyDatas={['NOM', 'ROLES']}
              headerIndex={[0, 1, 2, 3, 4, 5, 6]}
              headerTextStyle={headerText}
              onPageChange={(page: number) => this.binding.onPageChange(page)}
              nbPage={this.state.nbPage}
              currentPage={this.state.currentPage - 1}
              styleOfChoosenButton={stepColor}
            ></TableView>
        </View>
      </View>
    </View>
    );
  }
}