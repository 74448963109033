import { Operation } from '@levelapp/softfabric';
import Bank from '../DTO/Bank';
import BankService from '../Proxy/BankServices';


export default class BankOperation extends Operation
{
    _service : BankService;

    constructor()
    {
        super();
        this._service = new BankService();
    }   

    async get(callback: Function = () => { })
    {
        await (this._service as BankService).getBanks(callback)
    }

    async getBankById(bankId: number, callback: Function = () => { })
    {
        await (this._service as BankService).getBankById(bankId, callback);
    }    

    async addBank(bank: Bank, callback: Function = () => { })
    {
        await (this._service as BankService).post(bank,callback);
    }

    
}