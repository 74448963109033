import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import RolePopUpViewState from './RolePopUpViewState';
import RolePopUpViewProps from './RolePopUpViewProps';
import RolePopUpViewStyle from './RolePopUpViewStyle';
import RolePopUpViewModel from './RolePopUpViewModel';
import {  View } from 'react-native';
import PopupHeaderView from '../../../components/popopheader/PopupHeaderView';
import ExtendedCheckBoxView from '../../../components/extendedcheckbox/ExtendedCheckBoxView';
import { ButtonView, TranslationView } from '@levelapp/softfabric-ui';
import Role from '../../../../../../DTO/Role';
import InputView from '../../../../../../Components/Input/input/InputView';

export default class RolePopUpView extends ContentView<RolePopUpViewProps, RolePopUpViewState, RolePopUpViewModel, RolePopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:RolePopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new RolePopUpViewModel(this), new RolePopUpViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container,centerPos ,text,textError} = this.style;
    return (
      <View style={{ minWidth: 300, minHeight: 300, width: 700, maxWidth: "100%" }}>

      <PopupHeaderView
        handleBackPressed={this.binding.handleBackPressed}
        title={"roles.modification"}
        width={700}
      />  

      <View style={container}> 
        {this.state.isNewRole?
        <View> 
            <InputView 
              subscribe={() => { }} 
              shouldCheck={false} 
              Style={{ marginTop: 30 }} 
              inputClass="col-lg-7" 
              label={"Email utilisateur"} 
              labelClass="col-lg-7" 
              onChange={this.binding.handleInputChange} 
              required={true} 
              type="text"/>
            {this.state.showError && <TranslationView  style={textError}>
              {"roles.error"}
            </TranslationView>}
          </View>
            :
          <View style={{justifyContent: 'space-between', alignItems : 'center'}}>
          <TranslationView  style={text}>
            {"user.name"}
          </TranslationView>
          <View >
            <div style={text}>
              {this.props.userRole.name}
            </div>
          </View>
          <View/>
        </View>}
        

        <View> 
          <TranslationView style={text}>
            {"roles.list"} 
          </TranslationView>
            {this.props.roles.map((role: Role, index : number) =>{
                return ( 
                  role.name !='SendingMail' && 
                  <ExtendedCheckBoxView
                  key = {index}
                  title={role.name}
                  value={this.props.userRole.roles.filter(u => u.id === role.id).length > 0}
                  onValueChange={() => this.binding.handleCheckboxChange(index,this.props.userRole.roles.indexOf(this.props.userRole.roles.filter(u => u.id === role.id)[0]))}
                />);
              })}
        </View>

        <View style={centerPos}>
          <ButtonView animation={'None'} borders={'Little'} backgroundColor='#FFB243' isLoading={this.state.isLoading} handleClick={this.binding.handleSavePressed}>
            <TranslationView >
              {"roles.save"}
            </TranslationView>
          </ButtonView> 
        </View>
                
      </View>

      
          
      </View>
      
    );
  }
}