import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import ProjectInvestementStatusUpdatedViewState from './ProjectInvestementStatusUpdatedViewState';
import ProjectInvestementStatusUpdatedViewProps from './ProjectInvestementStatusUpdatedViewProps';
import ProjectInvestementStatusUpdatedViewStyle from './ProjectInvestementStatusUpdatedViewStyle';
import ProjectInvestementStatusUpdatedViewModel from './ProjectInvestementStatusUpdatedViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Image } from 'react-native';

export default class ProjectInvestementStatusUpdatedView extends ContentView<ProjectInvestementStatusUpdatedViewProps, ProjectInvestementStatusUpdatedViewState, ProjectInvestementStatusUpdatedViewModel, ProjectInvestementStatusUpdatedViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: ProjectInvestementStatusUpdatedViewProps) {
    super(props);

    // Binding
    this.bind(new ProjectInvestementStatusUpdatedViewModel(this), new ProjectInvestementStatusUpdatedViewStyle(this));
  }


  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
            <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20, justifyContent: 'flex-end' }}
              source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
          </TouchableWithoutFeedback>
        </View>

        <TranslationView style={this.style.title}>
          StatusUpdated
         </TranslationView>
      </View>
    );
  }
}