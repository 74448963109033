import { Operation } from '@levelapp/softfabric';
import PrimeUsersModel from '../DTO/PrimeUsersModel';
import ProjectsService from '../Proxy/ProjectsService';


export default class ProjectsOperation extends Operation
{
    _service : ProjectsService;

    constructor()
    {
        super();
        this._service = new ProjectsService();
    }   

    async exportRegister(projectId: string, callback: Function = () => { })
    {
        await (this._service as ProjectsService).exportRegister(projectId, callback);
    }
    async exportMoneyTrans(projectId: string, callback: Function = () => { })
    {
        await (this._service as ProjectsService).exportMoneyTrans(projectId, callback);
    }

    async exportRegisterInterests(projectId: string, callback: Function = () => { })
    {
        await (this._service as ProjectsService).exportRegisterInterests(projectId, callback);
    }

    async get(callback: Function = () => { })
    {
        await (this._service as ProjectsService).get(callback);
    }

    async getByCode(code : any,callback: Function = () => { })
    {
        await (this._service as ProjectsService).getByCode(code,callback);
    }

    async insertabs(project : any,callback: Function = () => { })
    {
        await (this._service as ProjectsService).insertProjectTabs(project,callback);
    }

    async insertProject(project : any,callback: Function = () => { })
    {
        await (this._service as ProjectsService).insertProject(project,callback);
    }

    async updateProject(project : any,callback: Function = () => { })
    {
        await (this._service as ProjectsService).updateProject(project,callback);

    }

    async deleteProject(project : any, callback: Function = () => { })
    {
        await (this._service as ProjectsService).deleteProject(project,callback);
    }
    
    async getList(callback: Function = () => { })
    {
        await (this._service as ProjectsService).getList(callback);
    } 
    async getTypesList(callback: Function = () => { })
    {
        await (this._service as ProjectsService).getTypesList(callback);
    } 
    async getBenefitsQuestions(id: number, callback: Function = () => { })
    {
        await (this._service as ProjectsService).getBenefitsQuestions(id,callback);
    } 
    async getActivitiesList(callback: Function = () => { })
    {
        await (this._service as ProjectsService).getActivitiesList(callback);
    } 

    async getSearchPagination(page : number, itemPage : number, searchText : string, callback : Function) {
        await (this._service as ProjectsService).getSearchPagination(page, itemPage, searchText, callback);
    }

    async sendEmailToPending(project:any, callback: Function = () => {}){
        await (this._service as ProjectsService).sendEmailToPending(project, callback);
    }//TODO

    async addPrimeUsers(primeUsersModel : PrimeUsersModel, callback: Function = () => {}){
        await (this._service as ProjectsService).addPrimeUsers(primeUsersModel, callback);
    }

    async getPrimeUsers(projectId : number, callback: Function = () => {}){
        await (this._service as ProjectsService).getPrimeUsers(projectId, callback);
    }

    async deletePrimeUsers(primeId : number, callback: Function = () => {}){
        await (this._service as ProjectsService).deletePrimeUser(primeId, callback);
    }

    async getInvestmentsPending(projectId : number, callback: Function = () => {}){
        await (this._service as ProjectsService).getInvestmentsPending(projectId, callback);
    }
   async sendExportInvestmentNumber(callback: Function = () => { }) {
       await (this._service as ProjectsService).sendExportInvestmentNumber(callback);

   }

}