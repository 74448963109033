import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import EditProjectViewState from './EditProjectViewState';
import EditProjectViewProps from './EditProjectViewProps';
import EditProjectViewStyle from './EditProjectViewStyle';
import EditProjectViewModel from './EditProjectViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, Text } from 'react-native';
import { Switch, Route } from 'react-router';
import ProjectInvestorsView from './modules/investors/projectinvestors/ProjectInvestorsView';
import InformationsView from './modules/informations/InformationsView';
import DocumentsView from './modules/documents/DocumentsView';
import ImagesView from './modules/images/ImagesView';
import CouponsView from './modules/coupons/CouponsView';
import EmailsView from './modules/emails/EmailsView';

export default class EditProjectView extends ContentView<EditProjectViewProps, EditProjectViewState, EditProjectViewModel, EditProjectViewStyle>
{
    /* CONSTRUCTOR */
    constructor(props: EditProjectViewProps) {
        super(props);

        // Binding
        this.bind(new EditProjectViewModel(this), new EditProjectViewStyle(this));
    }

    /* RENDERING */
    render() {
        return (
            <View>
                <View>
                    <div style={{ display: "flex", marginLeft: "26px", marginTop: "20px", marginBottom: "20px" }}>
                        <div style={{ cursor: "pointer" }}>
                            <img onClick={() => this.binding.navigate('/dashboard/projects')} height="31" width="31" src="https://beebondsstorage.blob.core.windows.net/files/consoleImages/close.png"></img>
                        </div>
                        <div>
                            <Text style={this.style.PageTitle}>Project :  {this.state.project.code.toUpperCase()} </Text>
                        </div>

                        <div>
                            <Text style={this.style.PageTitle}>Project :  {this.state.project.code.toUpperCase()}</Text>
                            <Text style={this.style.PageTitle}>Nombres d'investissements :  {this.state.project.investments.filter((x:any)=>x.isValidated).length}</Text>
                            <Text style={this.style.PageTitle}>Total d'investissements :  {this.state.total}</Text>
                        </div>

                    </div>
                </View>
                <View style={this.style.PurpleMenu}>
                    <div style={{ display: "flex" }}>
                        <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/projects/edit/investors', true, this.state.project)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                            <TranslationView style={this.style.PurpleMenuText}>Investors</TranslationView>
                            <div style={{ borderBottom: document.location.pathname.indexOf("/investors") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                        </div>

                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/projects/edit/informations', true, this.state.project)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Informations</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/informations") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>


                        {(this.state.isAdmin || this.state.isEditor || this.state.isMarketingEmail) &&
                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/projects/edit/documents', true, this.state.project)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Documents</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/documents") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>
                        }
                        {(this.state.isAdmin || this.state.isEditor) &&
                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/projects/edit/images', true, this.state.project)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Images</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/images") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>
                        }
                         {(this.state.isAdmin || this.state.isEditor) &&
                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/projects/edit/coupons', true, this.state.project)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Coupons</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/coupons") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>
                        }
                        {(this.state.isAdmin || this.state.isEditor || this.state.isMarketingEmail) &&
                            <div onClick={() => this.binding.NavigationManager.navigate('/dashboard/projects/edit/emails', true, this.state.project)} style={{ paddingRight: "115px", cursor: "pointer" }}>
                                <TranslationView style={this.style.PurpleMenuText}>Emails</TranslationView>
                                <div style={{ borderBottom: document.location.pathname.indexOf("/emails") != -1 ? '3px solid #FFB243' : '', marginTop: 15 }} />
                            </div>
                        }
                    </div>
                </View>
                {/* /:language/dashboard/projects/edit */}

                <Switch>
                    <Route path="/:language/dashboard/projects/edit/investors" render={() => <ProjectInvestorsView project={this.state.project} />} />
                    <Route path="/:language/dashboard/projects/edit/informations" render={() => <InformationsView />} />
                    <Route path="/:language/dashboard/projects/edit/documents" render={() => <DocumentsView />} />
                    <Route path="/:language/dashboard/projects/edit/images" render={() => <ImagesView />} />
                    <Route path="/:language/dashboard/projects/edit/coupons" render={() => <CouponsView  projectId={this.state.project.id} />} />
                    <Route path="/:language/dashboard/projects/edit/emails" render={() => <EmailsView  projectCode={this.state.project.code} investments={this.state.project.investments} />} />
                </Switch>
            </View>
        );
    }
}
