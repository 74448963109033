import { ContentViewModel, ContentView } from "@levelapp/softfabric";
import DroppableBoxViewState from "./DroppableBoxViewState";
import DroppableBoxViewProps from "./DroppableBoxViewProps";
import ValidationPopUpView from "../../../../../../../../../../../../Common/PopUp/validationPopUpView/ValidationPopUpView";
import ShowTextPopUpView from "../../../../../../../../../../../../Common/PopUp/showTextPopUpView/ShowTextPopUpView";
import DocumentsOperation from "../../../../../../../../../../../../Transfer/DocumentsOperations";
import React from "react";

export default class DroppableBoxViewModel extends ContentViewModel<
  DroppableBoxViewState,
  DroppableBoxViewProps
> {
  Documents = new DocumentsOperation();

  constructor(props: ContentView) {
    super(props);

    /* Initial State */
    this.initialState({
      currentFile: null,
      documentToSend: {
        projectId: this.props().projectId,
        url: "",
        title: {
          fr: "",
          nl: "",
          en: "",
        },
        file: null,
      },
      warnUser: false,
    });

    /* BINDINGS */
    this.handleClick = this.handleClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.save = this.save.bind(this);
    this.handleTitleFr = this.handleTitleFr.bind(this);
    this.handleTitleNl = this.handleTitleNl.bind(this);
    this.handleTitleEn = this.handleTitleEn.bind(this);
    this.removeCurrentFile = this.removeCurrentFile.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.checkboxClick = this.checkboxClick.bind(this);
  }

  handleClick() {
    (this._view.refs.fileUploader as any).click();
  }

  handleDrop(file: any) {
    if (this.state().currentFile != null) return;
    this.setField({ currentFile: file[0] });
  }

  save() {
    if (
      this.state().documentToSend.title.fr == "" ||
      this.state().documentToSend.title.nl == "" ||
      this.state().documentToSend.title.en == ""
    ) {
      this.showPopUp(<ShowTextPopUpView text={"FillTimeinEachLanguage"} />);
    } else {
      this.showPopUp(
        <ValidationPopUpView
          text={"InsertDoc"}
          callBack={() => {
            var file = this.state().currentFile;
            var send = this.state().documentToSend;
            send.warnUsers = this.state().warnUser;
            this.getBase64(file, (result: any) => {
              send.file = result.split("base64,")[1];
              this.Documents.insertDocument(send, (response: any) => {
                var emptyDoc = {
                  projectId: this.props().projectId,
                  url: "",
                  title: {
                    fr: "",
                    nl: "",
                    en: "",
                  },
                  file: null,
                };
                this.setField(
                  { currentFile: null, documentToSend: emptyDoc },
                  () => {
                    this.props().insertDocument(response);
                  },
                );
              });
            });
          }}
        />,
      );
    }
  }

  getBase64(file: any, cb: Function) {
    let reader = new FileReader();
    reader.onload = function () {
      cb(reader.result);
    };
    reader.readAsDataURL(file);
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  removeCurrentFile() {
    var emptyDoc = {
      projectId: this.props().projectId,
      url: "",
      title: {
        fr: "",
        nl: "",
        en: "",
      },
      file: null,
    };
    this.setField({ currentFile: null, documentToSend: emptyDoc });
  }

  handleTitleFr(documentFR: string) {
    var document = this.state().documentToSend;
    document.title.fr = documentFR;
    this.setField({ documentToSend: document });
  }
  handleTitleNl(documentNL: string) {
    var document = this.state().documentToSend;
    document.title.nl = documentNL;
    this.setField({ documentToSend: document });
  }
  handleTitleEn(documentEN: string) {
    var document = this.state().documentToSend;
    document.title.en = documentEN;
    this.setField({ documentToSend: document });
  }
  checkboxClick() {
    this.setField({ warnUser: !this.state().warnUser });
  }
}
