import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import SendEmailCouponCourantPopUpViewState from './SendEmailCouponCourantPopUpViewState';
import SendEmailCouponCourantPopUpViewProps from './SendEmailCouponCourantPopUpViewProps';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Investment from '../../../../../../../../../../../../../DTO/Investment';

const moment = extendMoment(Moment);


export default class SendEmailCouponCourantPopUpViewModel extends ContentViewModel<SendEmailCouponCourantPopUpViewState, SendEmailCouponCourantPopUpViewProps>
{
    netInterest: any;

    constructor(props: ContentView) {
        super(props);

        /* Initial State */
        this.initialState({
            amount: 0,
            daysNumber: 0,
            brutInterest: 0,
            pm: 0,
            netInterest: 0,
            date: "",
            dateAsString: "",
            imposableAmount: this.props().investment.amount,
            notImposableAmount: 0,
            netInterestExempted: 0,
            interestsAsDouble: 0
        });

        this.netInterest = 0;

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
        this.handleAmountChanged = this.handleAmountChanged.bind(this);
        this.handleDateChanged = this.handleDateChanged.bind(this);

        /* todo check */
        if (this.props().project.annualInterestRate == 7.4)
            this.props().project.annualInterestRate = 7;
    }

    /* HANDLERS */
    handleAmountChanged(amount: any) {
        this.setField({ amount });
    }

    handleDateChanged(date: any) {
        this.setField({ date });
        const EndDate = moment(date, 'DD/MM/YYYY', 'fr', true);
        const StartDate = moment(this.props().project.issueDate, 'DD/MM/YYYY', 'fr', true);
        if (!EndDate.isValid())
            return;

        this.setField({ dateAsString: EndDate.format('DD MMMM YYYY', 'fr') });

        const range = EndDate.diff(StartDate, 'days') + 1;

        this.setField({ daysNumber: range });

        var rateInPercent = this.props().project.annualInterestRate / 100;
        let brutInterest = this.props().investment.amount * rateInPercent / 365 * range;

        let  pm = brutInterest * 0;
        if (this.props().project.code != "pitaya" && this.props().project.code != "orientalis") {
            pm = brutInterest * 0.3;
        }

        if(this.props().investment.legalEntityId > 0) {
            pm = 0;
        } 
       
        this.netInterest = brutInterest - pm;

        

        let amount = this.props().investment.amount + this.netInterest;

        this.setField({ pm: pm.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "€" });
        this.props().investment.Interests = this.netInterest.toLocaleString(undefined, { maximumFractionDigits: 2 });

        if (this.props().investment.amount > 15000 && this.props().project.code == "pitaya") {
            let brutInterest = (this.state().notImposableAmount) * rateInPercent / 365 * range;
            let pm = brutInterest * 0;
            this.netInterest = brutInterest - pm;            

            let brutInterestShelt = this.state().imposableAmount * rateInPercent / 365 * range;
            let pmShelt = brutInterestShelt * 0.3;           

            this.setField({ pm: pmShelt.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "€" });
            let netInterestShelt = brutInterestShelt - pmShelt;          

            this.setField({ netInterestExempted: netInterestShelt.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "€" });
            amount = this.props().investment.amount + this.netInterest + netInterestShelt;
            let interests = this.netInterest + netInterestShelt;
            this.props().investment.Interests = interests.toLocaleString(undefined, { maximumFractionDigits: 2 });
        }        

        this.setField({ brutInterest: brutInterest.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "€" });
        this.setField({ netInterest: this.netInterest.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "€" });
        this.setField({ amount: amount.toLocaleString(undefined, { maximumFractionDigits: 2 }) + "€" });
        this.setField({ interestsAsDouble: this.netInterest });
    }

    handleBackPressed() {
        this.closePopUp();
    }

    handleSavePressed() {
        let invest = this.props().investment;
        try {
            let i = Math.round(this.state().interestsAsDouble * 100) / 100;
            console.log(i);
        } catch (e) {
            alert("Cannot convert " + this.state().interestsAsDouble + " interest to number")
            return;
        }
        let inv = {
            id: invest.id,
            project: { "consoleTitle": this.props().project.consoleTitle },
            interests: Math.round(this.state().interestsAsDouble * 100) / 100,
            userId: invest.userId,
            legalEntityId: invest.LegalEntityId,
            amount: invest.amount
        } as Investment;
        this.closePopUp();
        this.props().callBack(inv);
    }
}