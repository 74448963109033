import {ContentViewStyle} from '@levelapp/softfabric';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class AddReservationPopupViewStyle extends ContentViewStyle{
    title: StyleProp<TextStyle> = {
        textAlign: 'center',
        color: '#4A495C',
        fontSize: 21,
        fontWeight: '600',
        marginTop: 10
    };

    subTitle: StyleProp<TextStyle> = {
        textAlign: 'left',
        color: '#565656',
        fontSize: 14,
        fontWeight: '400',
    };

    footer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 50,
        justifyContent: 'space-evenly'
    };

    NoButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        height: 42,
        width: 119,
        marginRight : '5px'
    }  

   YesButton: StyleProp<ViewStyle> =
    {       
        borderRadius: 8,
        paddingVertical: 8,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',  
        height: 42,
        width: 119,
        marginLeft : '5px'
    } 

    NoButtonText: StyleProp<TextStyle> = {
       color: '#9C9C9C',
    };
}