import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';

export default class CompanyInfosViewStyle extends ContentViewStyle
{
    MyStyleManager: StyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

  
    title: StyleProp<TextStyle> = {
        display : 'flex',
        justifyContent : 'flex-start',
        color: this.MyStyleManager.primaryColor,
        fontSize: 25,
        fontWeight: 'bold',
        margin: 15,
    }
    containerSameLine : StyleProp<ViewStyle> = {
        display : 'flex',
        flexDirection : 'row', 
        justifyContent : 'flex-start'
    }

    inputInline : StyleProp<ViewStyle> = {
        display : 'flex',
         margin: "1%" ,
         width : '23%'
    }
    input : StyleProp<ViewStyle> = {
        display : 'flex',
        margin: "1%" ,
        width : '48%'
    }
    buttonBank: StyleProp<ViewStyle> =
    {       
        display: 'flex',
        borderRadius: 8,
        height: '60%',
        width: '100%',
        justifyContent : 'center',
        alignItems : 'center',
    }   
    
    buttonInLine : StyleProp<ViewStyle> = {
        display : 'flex',
        justifyContent : 'flex-end',
        margin: "1%" ,
        width : '15%'
    }    
}