import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import CheckIdentityValidityPopupViewState from './CheckIdentityValidityPopupViewState';
import CheckIdentityValidityPopupViewProps from './CheckIdentityValidityPopupViewProps';
import UsersOperation from '../../../../../../../Transfer/UsersOperation';
import React from 'react';
import { View,Text } from 'react-native';
import Profile from '../../../../../../../DTO/Profile';

export default class CheckIdentityValidityPopupViewModel extends ContentViewModel<CheckIdentityValidityPopupViewState, CheckIdentityValidityPopupViewProps>
{
    _usersOperation = new UsersOperation();
      /* CONSTRUCTOR */
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({
            showSucces : false,
            isSendLoading : false,
            isLoading : false,
            resetStepView: false,
            rows: [],
            initialRow: [],
            initialData: [],
            datas: [],
            nbPage: 0,
            currentPage: 1,
            invalidIdentityDateUsers : [],
            nbRows : 5
        }); // Initialize your state here

        // Bindings
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSuccessCallback = this.handleSuccessCallback.bind(this);

        this.handleSendEmailPressed = this.handleSendEmailPressed.bind(this);

    }

    async componentDidMount(){
        this.setField((previousState) => previousState.showSucces = false)
        this.setField((previousState) => previousState.nbPage = Math.ceil(this.props().response.length/this.state().nbRows));
        this.setField((previousState) => previousState.invalidIdentityDateUsers = this.props().response)
        await new Promise(resolve => setTimeout(resolve, 10));
        this.handleSuccessCallback()
        
    }

    handleSendEmailPressed(){
      this.setField((previousState) => previousState.isSendLoading = true)    
      this._usersOperation.checkIdentityValidity(true,()=>{
        this.setField((previousState) => previousState.isSendLoading = false)  
        this.setField((previousState) => previousState.showSucces = true)  
    })
    }

    handleBackPressed(){
        this.closePopUp()
    }

    handleResetStepView() {
        this.setField({ resetStepView: false });
    }

    async onPageChange(page: number) {
        this.setField((previousState) => previousState.currentPage = page + 1);
        await new Promise(resolve => setTimeout(resolve, 10));
        this.handleSuccessCallback()
    }

    async handleSuccessCallback() {
        let datas: any[] = [];
        let users = this.state().invalidIdentityDateUsers;
        let rows: any[] = [];

        users.slice((this.state().currentPage-1)*this.state().nbRows,(this.state().currentPage)*this.state().nbRows).forEach((user : Profile) => {
            datas.push(user);
            rows.push(
                <View>          
                    <Text >{user.id}</Text>
                    <Text >{user.clientNumber}</Text>
                    <Text >{user.firstName + " " + user.lastName}</Text>
                    <Text >{user.email}</Text>
                    <Text >{user.identityPieceValidity}</Text>
                </View>
            )
        });
        this.setField({ datas: datas });
        this.setField({ rows: rows });
        this.setField({ initialData: datas.slice(0) });
        this.setField({ initialRow: rows });
        this.setField({ isLoading: false });
    }
  }