import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import TransferAmountPopUpViewState from './TransferAmountPopUpViewState';
import TransferAmountPopUpViewProps from './TransferAmountPopUpViewProps';
import TransferAmountPopUpViewStyle from './TransferAmountPopUpViewStyle';
import TransferAmountPopUpViewModel from './TransferAmountPopUpViewModel';
import { TranslationView, ButtonView } from '@levelapp/softfabric-ui';
import { View, Text, TouchableWithoutFeedback, CheckBox } from 'react-native';
import InputView from '../../../../../../../../../../../../../Components/Input/input/InputView';

export default class TransferAmountPopUpView extends ContentView<TransferAmountPopUpViewProps, TransferAmountPopUpViewState, TransferAmountPopUpViewModel, TransferAmountPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: TransferAmountPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new TransferAmountPopUpViewModel(this), new TransferAmountPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>

        <TranslationView style={this.style.title}>
          TransferAmount
       </TranslationView>
      
        <View>
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Amount")} labelClass="col-lg-7" onChange={this.binding.handleAmountChanged} required={true} value={this.state.amount} type="text" />
          <InputView subscribe={() => { }} shouldCheck={false} Style={{ marginTop: 30 }} inputClass="col-lg-7" label={this.binding.translate("Investor")} labelClass="col-lg-7" onChange={this.binding.handleUserSearchChanged} required={true} value={this.state.userSearch} type="text" />

          <View style={{ height: "200px", marginTop: "20px" }}>
            {this.state.usersSearch.slice(0,10).map((user: any) => {
              return (
                <TouchableWithoutFeedback onPress={() => this.binding.selectUserHandler(user)}>
                  <View>
                    <Text style={this.state.destinationUserId == user.id ? this.style.selectedText : null}>{user.name} {user.email}</Text>
                  </View>
                </TouchableWithoutFeedback>
              )
            })}
          </View>
        </View>

        <View style={{ display: "flex", flexDirection: 'row', marginTop: 15 }}>
            <CheckBox onValueChange={this.binding.handleChangeSendEmail} value={this.state.sendEmail} />
            <Text style={{ marginLeft: 5 }}>Envoyer l'email avec les infos de paiement</Text>
          </View>
           
          <View style={{ display: "flex", flexDirection: 'row', marginTop: 15 }}>
            <CheckBox onValueChange={this.binding.handleChangeIsWaitingStatus} value={this.state.changeIsWaiting} />
            <Text style={{ marginLeft: 5 }}>Changer le status "Is Waiting" de l'utilisateur</Text>
          </View> 

        <View style={this.style.footer}>    
          <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleBackPressed} backgroundColor="#CCCCCC" style={this.style.NoButton}>
            <TranslationView upperCase style={this.style.NoButtonText}>
              Cancel
           </TranslationView>
          </ButtonView>
          <ButtonView animation={'None'} borders={'Little'} isLoading={false} handleClick={this.binding.handleSavePressed} backgroundColor="#FFB243" style={this.style.YesButton}>
            <TranslationView upperCase>
              Confirm
           </TranslationView>
          </ButtonView>
        </View>
      </View>
    );
  }
}