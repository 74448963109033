import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import DocumentsViewState from './DocumentsViewState';
import DocumentsViewProps from './DocumentsViewProps';
import DocumentsOperation from '../../../../../../../../../../Transfer/DocumentsOperations';

export default class DocumentsViewModel extends ContentViewModel<DocumentsViewState, DocumentsViewProps>{
    Documents = new DocumentsOperation();

    constructor(props: ContentView) {
        super(props);
        var project = this.parameters<any>();
        this.initialState({
            projectId: project.id,
            projectDocuments : [],
        })
        this.insertDocument = this.insertDocument.bind(this);
        this.removeFromList = this.removeFromList.bind(this);

    }

    componentDidMount(){
        this.Documents.getByProject(this.state().projectId,(documents : any[]) => {
            this.setField({projectDocuments : documents});
        });
    }

    removeFromList(index: number){
        var list = this.state().projectDocuments;
        list.splice(index,1);
        this.setField({projectDocuments : list })
    }

    insertDocument(document : any){
        var list = this.state().projectDocuments;
        list.push(document);
        this.setField({projectDocuments : list});
    }
}