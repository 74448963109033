import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import ResearchInputViewState from './ResearchInputViewState';
import ResearchInputViewProps from './ResearchInputViewProps';

export default class ResearchInputViewModel extends ContentViewModel<ResearchInputViewState, ResearchInputViewProps>
{

    constructor(props:ContentView)
    {
        super(props);

        this.initialState({mySearchValue: this.props().text!});

        this.handleResetPressed = this.handleResetPressed.bind(this);
        this.handleSearchValueChanged = this.handleSearchValueChanged.bind(this);

    }

    handleSearchValueChanged(searchValue: string)
    {
        if(searchValue == "")
        {
            this.handleResetPressed();
        }else
        {
        this.setField({mySearchValue:searchValue});
        this.props().handlerResearching(searchValue);
        this.props().setCurrentPage != undefined && (this.props().setCurrentPage as Function) (0);
        }

    }

    handleResetPressed()
    {
        this.setField({mySearchValue:''});
        this.props().handlingClose();
    }
}