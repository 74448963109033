import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import SubscribePopUpViewState from './SubscribePopUpViewState';
import SubscribePopUpViewProps from './SubscribePopUpViewProps';
export default class SubscribePopUpViewModel extends ContentViewModel<SubscribePopUpViewState, SubscribePopUpViewProps>{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
        });
    }

}