import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import ProjectsInvestorsPopUpViewState from './ProjectsInvestorsPopUpViewState';
import ProjectsInvestorsPopUpViewProps from './ProjectsInvestorsPopUpViewProps';

export default class ProjectsInvestorsPopUpViewModel extends ContentViewModel<ProjectsInvestorsPopUpViewState, ProjectsInvestorsPopUpViewProps>
{
    constructor(props: ContentView)
    {
        super(props);

        /* Initial State */
        this.initialState({
        });

        /* BINDINGS */
        this.handleBackPressed = this.handleBackPressed.bind(this);
        this.handleSavePressed = this.handleSavePressed.bind(this);
    }

    /* COMPONENT EVENTS */
    componentWillMount(){}

    /* HANDLERS */
    handleBackPressed()
    {
        this.closePopUp();
    }

    handleSavePressed(){        
        this.closePopUp();
        this.props().callBack();
    }
}