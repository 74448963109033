import React from "react";
import { ContentView } from "@levelapp/softfabric";
import {
  View,
  TouchableWithoutFeedback,
  Text,
  TextInput,
  TouchableHighlight,
  Image,
} from "react-native";
import { TranslationView } from "@levelapp/softfabric-ui";
import DroppableBoxViewProps from "./DroppableBoxViewProps";
import DroppableBoxViewState from "./DroppableBoxViewState";
import DroppableBoxViewModel from "./DroppableBoxViewModel";
import DroppableBoxViewStyle from "./DroppableBoxViewStyle";
import DroppableView from "../droppable/DroppableView";

export default class DropppableBoxView extends ContentView<
  DroppableBoxViewProps,
  DroppableBoxViewState,
  DroppableBoxViewModel,
  DroppableBoxViewStyle
> {
  /* CONSTRUCTOR */
  constructor(props: DroppableBoxViewProps) {
    super(props);

    // Binding
    this.bind(new DroppableBoxViewModel(this), new DroppableBoxViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={this.style.dropContainer}>
        {this.state.currentFile == null ? (
          <DroppableView handleDrop={this.binding.handleDrop}>
            <TranslationView style={this.style.title}>
              Add a new file
            </TranslationView>
            <TranslationView style={this.style.clearTitle}>
              You can drag and drop it here
            </TranslationView>
            <TranslationView style={this.style.clearTitle}>OR</TranslationView>
            <View>
              <View style={this.style.button}>
                <TouchableWithoutFeedback
                  onPress={() => this.binding.handleClick()}
                >
                  <View>
                    <TranslationView>Choose a file</TranslationView>
                    <input
                      type="file"
                      id="file"
                      ref="fileUploader"
                      accept="image/*, .pdf"
                      onChange={(file: React.ChangeEvent<HTMLInputElement>) => {
                        if (file.target.files != null) {
                          this.binding.handleDrop(file.target.files);
                        }
                      }}
                      style={{ display: "none" }}
                    />
                  </View>
                </TouchableWithoutFeedback>
              </View>
            </View>
          </DroppableView>
        ) : (
          <View style={{ alignItems: "center" }}>
            <View style={this.style.name}>
              <Text style={this.style.fileName}>
                {this.state.currentFile.name}
              </Text>
              <View style={this.style.littleIcon}>
                <Image
                  source={require("./assets/Success.svg")}
                  style={{ height: 20, width: 20 }}
                />
              </View>
              <View style={this.style.littleIcon}>
                <TouchableHighlight
                  onPress={() => {
                    this.binding.removeCurrentFile();
                  }}
                >
                  <View>
                    <Image
                      source={require("./assets/trash.svg")}
                      style={{ height: 20, width: 20 }}
                    />
                  </View>
                </TouchableHighlight>
              </View>
            </View>

            <View style={this.style.inputs}>
              <View style={this.style.inputContainer}>
                <TranslationView style={this.style.label}>
                  titleDocFR
                </TranslationView>
                <View>
                  <TextInput
                    editable={true}
                    style={this.style.input}
                    onChangeText={(documentFR: string) => {
                      this.binding.handleTitleFr(documentFR);
                    }}
                    value={this.state.documentToSend.title.fr}
                  />
                </View>
              </View>
              <View style={this.style.inputContainer}>
                <TranslationView style={this.style.label}>
                  titleDocNL
                </TranslationView>
                <View>
                  <TextInput
                    editable={true}
                    style={this.style.input}
                    onChangeText={(documentNL: string) => {
                      this.binding.handleTitleNl(documentNL);
                    }}
                    value={this.state.documentToSend.title.nl}
                  />
                </View>
              </View>
              <View style={this.style.inputContainer}>
                <TranslationView style={this.style.label}>
                  titleDocEN
                </TranslationView>
                <View>
                  <TextInput
                    editable={true}
                    style={this.style.input}
                    onChangeText={(documentEN: string) => {
                      this.binding.handleTitleEn(documentEN);
                    }}
                    value={this.state.documentToSend.title.en}
                  />
                </View>
              </View>
              <View>
                <TranslationView>SendDocToUsers</TranslationView>
              </View>
              <input
                type="checkbox"
                checked={this.state.warnUser}
                onClick={() => {
                  this.binding.checkboxClick();
                }}
                style={{ width: 25, height: 25, }}
              />
            </View>

            <View style={this.style.buttonSave}>
              <TouchableWithoutFeedback onPress={() => this.binding.save()}>
                <View
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TranslationView style={this.style.saveText}>
                    Save
                  </TranslationView>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </View>
        )}
      </View>
    );
  }
}
