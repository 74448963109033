import {ContentViewStyle, Application} from '@levelapp/softfabric';
import { StyleProp, ViewStyle, TextStyle } from 'react-native';
import { UiManagers } from '@levelapp/softfabric-ui';
import MyStyleManager from '../../../../../../../../../../../../Common/Managers/MyStyleManager';

export default class DocumentViewStyle extends ContentViewStyle{
  MyStyleManager = Application.current.resolve<MyStyleManager>(UiManagers.STYLE_MANAGER);
  
  box: StyleProp<ViewStyle> = {
    display : 'flex',
    flexDirection :'row',
    flex : 1,
    width : '100%',
    shadowColor: "#0000006b",
    shadowOffset: {
        width: 1,
        height: 1,
    },
    shadowOpacity: 0.30,
    shadowRadius: 30,
    borderRadius : 10,
    height : '78px',
  }

  documentTitleView: StyleProp<ViewStyle>= {
    display : 'flex',
    justifyContent :'center',
  }

  documentTitle: StyleProp<TextStyle> = {
    fontFamily : this.MyStyleManager.fontFamily,
    color : '#565656',
    fontSize : 16,
  }

  download: StyleProp<ViewStyle> = {
    display : 'flex',
    flex : 1,
    justifyContent :'center',
    alignItems :'flex-end',
    paddingRight :'14px',
  }
}