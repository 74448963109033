import * as React from 'react';
import CustomSelect from 'react-select';
import UrlConstants from '../../Constants/UrlConstants';

interface Props
{
    subscribe:Function;
    label: string;
    required: boolean;
    labelClass: string;
    selectClass: string;
    placeholder?: string;
    shouldCheck: boolean;
    value: any;
    onChange: Function;
    Style?: any;
    options: any;
    info?: boolean;
    infoCallback?: Function;
    disabled?: boolean;
}

interface State
{
    error: boolean;
}

export default class Select extends React.Component<Props, State>
{
    constructor(props: Props)
    {
        super(props);
        this.state = { error: false };

        // Bindings
        this.isError = this.isError.bind(this);
    }

    componentDidMount()
    {
        if(this.props.required && (this.props.value == null || this.props.value == ""))
        {
            this.props.subscribe({ id: this.props.label, error: true});
        }
    }

    componentWillUnmount()
    {
        this.props.subscribe({ id: this.props.label, error: false});
    }

    isError()
    {
        if (this.props.value == '' && this.props.required)
        {
            this.setState({ error: true });
        }
        else if (this.state.error)
        {
            this.setState({ error: false });
        }
    }

    render()
    {
        if (this.props.shouldCheck && !this.state.error)
        {
            this.isError();
        }
        return (
            <div style={this.props.Style}>
                <div className="row" >
                    <div className={this.props.labelClass} style={{ display: 'flex' }}>
                        {this.state.error && (<img src={UrlConstants.BB_STORAGE + "/error.svg"} alt="error" style={{ marginRight: 5 }} />)}
                        <label style={{ color: '#565656', fontWeight: 400, fontFamily: 'Montserrat', fontSize: '14px', lineHeight: '20px', width: '100%', textAlign: 'start' }}>
                            {this.props.label}{this.props.required ? '' : ''}
                            {this.props.info && (<img src={UrlConstants.BB_STORAGE + '/info.svg'} alt="info" style={{ marginLeft: 5, cursor: 'pointer' }} onClick={() => { if (this.props.infoCallback) this.props.infoCallback() }} />)}
                        </label>
                    </div>
                </div>
                <div className="row" style={{ marginTop: 8 }}>
                    <div className={this.props.selectClass}>
                        <div style={{ height: '40px', width: '100%',border: this.state.error ? '1px solid #f25457' : '1px solid #d5d5d5', borderRadius: '3px' }}>
                            <CustomSelect  placeholder={this.props.placeholder} value={
                                this.props.value == "Oui" || this.props.value == true ? { label: "Oui", value: "Oui" }  :
                                this.props.value == "Non" || this.props.value == false ? { label: "Non", value: "Non" }  :
                                    this.props.value != '' ?
                            { label: this.props.value, value: this.props.value }
                                : null} styles={{
                                control: (styles: any) => ({ ...styles, border: 'none' }),
                                placeholder: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#d5d5d5', fontWeight: 300, fontSize: '16px' }),
                                option: (styles: any, state: any) => ({ ...styles, fontFamily: 'Montserrat', color: state.isDisabled ? '#56565680' : '#565656', zIndex : 500, fontSize: '16px', fontWeight: 300, }),
                                input: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),
                                singleValue: (styles: any) => ({ ...styles, fontFamily: 'Montserrat', color: '#565656', fontSize: '16px', fontWeight: 300 }),

                            }}
                                options={this.props.options}
                                onChange={(event:any) => { this.setState({ error: false }, () => {
                                    this.props.onChange(event, this.isError);
                                    this.props.subscribe({ id: this.props.label, error: false });
                                }); }}
                                onBlur={this.isError} isDisabled={(this.props as unknown as Props).disabled}
                            />
                        </div>
                    </div>
                </div>
            </div >)
    }
}
