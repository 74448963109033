import {Application, ContentViewStyle} from '@levelapp/softfabric';
import { UiManagers } from '@levelapp/softfabric-ui';
import {StyleProp,TextStyle,ViewStyle} from 'react-native';
import MyStyleManager from '../../../../../../../../../../Common/Managers/MyStyleManager';

export default class ImagesViewStyle extends ContentViewStyle
{
    MyStyleManager = Application.current.resolve<MyStyleManager>(UiManagers.STYLE_MANAGER);

  box: StyleProp<ViewStyle> = {
      display : 'flex',
      flex : 1,
      backgroundColor : 'white',
      marginTop : '70px',
      marginRight :'47px',
      marginLeft : '47px',
      borderRadius : 20,
      shadowColor: "#0000006b",
      shadowOffset: {
          width: 1,
          height: 1,
      },
      shadowOpacity: 0.30,
      shadowRadius: 40,
  }

  elementContainer: StyleProp<ViewStyle> = {
    display :'flex',
    flex:1,
    marginTop : '29px',
    marginHorizontal : '150px',

  }
  dropContainer: StyleProp<ViewStyle> = {
    display : 'flex',
    height : '179px',
    marginBottom : '17px',
    borderStyle : 'dashed',
    borderWidth : 1,
    borderColor : 'grey',
    justifyContent : 'center',
    alignItems : 'center',
    }

    Image: StyleProp<ViewStyle> = {
      margin : 5,
    }
  
    Images: StyleProp<ViewStyle> = {
      display :'flex',
      flex : 1,
      justifyContent : 'center',
      alignItems : 'center',
      flexDirection : 'row',
      flexWrap : 'wrap',
      marginBottom : 50,
    }
  
    title: StyleProp<TextStyle> = {
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 25,
      color : this.MyStyleManager.boldFontColor,
      fontWeight : '500',
    }

    clearTitle: StyleProp<TextStyle> = {
      fontFamily : this.MyStyleManager.fontFamily,
      fontSize : 19,
      color : "#DDDCDC",
    }
}