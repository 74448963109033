import { Service, Application, HttpManager, Managers } from '@levelapp/softfabric';

export default class ProjectLeadersService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);    
    auth: any = Application.current.resolve(Managers.AUTH_MANAGER);

    async get(callback: Function)
    {
        this.httpClient.get("api/ProjectLeaders", []).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }  

    async put(model: any, callback: Function = () => { })
    {
        this.httpClient.put("api/ProjectLeaders", [], model).then((response: any) => 
        {
            if (response != undefined)
            {
                callback(response.data.data);
            }
        });
    }
}