import * as XLSX from "xlsx";
import TranslationDTO from "../../DTO/TranslationDTO";
import {ContentView, ContentViewModel} from "@levelapp/softfabric";
import ReadExcelViewState from "./ReadExcelViewState";
import ReadExcelViewProps from "./ReadExcelViewProps";
import React from "react";
import ProjectDTO from "../../DTO/ProjectDTO";
import MultilingualContentDTO from "../../DTO/MultilingualContentDTO";
import StructuredDataDTO from "../../DTO/StructuredDataDTO";

export default class ReadExcelViewModel extends ContentViewModel<ReadExcelViewState, ReadExcelViewProps>{
    constructor(props: ContentView){
        super(props);
        this.handleFile = this.handleFile.bind(this);

        this.initialState({
            project: this.props().project
        });
    }

    handleFile(file: React.ChangeEvent<HTMLInputElement>): any {
        if (file){
            this.handleGetStructuredData(
                file
            )
                .then((json) => {
                    let project : ProjectDTO = this.props().project;
                    json.Details.forEach((element: any) => {
                        if (element["campagne"] !== undefined) {
                            project.projectName = element["campagne"].FR;
                        } else if (element["phrase 1"] !== undefined) {
                            project.projectIntroduction.fr = element["phrase 1"].FR;
                            project.projectIntroduction.nl = element["phrase 1"].NL;
                            project.projectIntroduction.en = element["phrase 1"].EN;
                        } else if (element["entreprise"] !== undefined) {
                            project.name.fr = element["entreprise"].FR;
                            project.name.nl = element["entreprise"].NL;
                            project.name.en = element["entreprise"].EN;
                        } else if (element["descriptif"] !== undefined) {
                            project.projectDescription.fr = element["descriptif"].FR;
                            project.projectDescription.nl = element["descriptif"].NL;
                            project.projectDescription.en = element["descriptif"].EN;
                        } else if (element["avertissement"] !== undefined) {
                            project.aboutLegalInfoText.fr = element["avertissement"].FR;
                            project.aboutLegalInfoText.nl = element["avertissement"].NL;
                            project.aboutLegalInfoText.en = element["avertissement"].EN;
                        } else if (element["avertissement documents"] !== undefined) {
                            project.documentsLegalInfoText.fr = element["avertissement documents"].FR;
                            project.documentsLegalInfoText.nl = element["avertissement documents"].NL;
                            project.documentsLegalInfoText.en = element["avertissement documents"].EN;
                        }
                    });

                    json.listeAvantage?.map((
                        avantage :MultilingualContentDTO,
                        index : number
                    ): void=>{
                        project.projectBenefits.push({
                            title : avantage.titre,
                            subtitle : avantage.texte,
                            order : index,
                            projectID : project.id,
                            id : 0,
                            subtitleId : 0,
                            titleId:0,
                        })
                    })

                    json.pointAttention?.map((
                        points: MultilingualContentDTO,
                        index: number
                    ): void=>{
                        project.projectQuestions.push({
                            title : points.titre,
                            response : points.texte,
                            order : index,
                            projectID : project.id,
                            id : 0,
                            responseId : 0,
                            titleId : 0,
                        })
                    })
                    this.props().callBack(project);
                })
                .catch((error) => {
                    console.error(error);
                });
        }else {
            console.error("No file found")
        }
    }
    handleGetStructuredData(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                const fileContent :string = event.target?.result as string;

                const workbook = XLSX.read(fileContent, { type: 'binary' });

                const sheet = workbook.Sheets["Details"];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                const header: string[] = ['FR', 'NL', 'EN'];

                let listAvantage : MultilingualContentDTO[] = []
                let pointAttention: MultilingualContentDTO[] = []
                let currentContent: MultilingualContentDTO  = resetContent();
                let cmp :number = 0;
                let isAvantage2 : boolean = false;
                let isPointAttention2 :boolean = false;

                const pageData: any[] = jsonData.slice(1).map((row: any) => {
                    //Initialisation
                    const obj: { [key: string]: string } = {};
                    let firstElementName = row[0] || 'nom'+ ++cmp;
                    const normalizedFirstElementName = firstElementName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

                    if(normalizedFirstElementName.includes("avantages du projet") || isAvantage2){
                        isAvantage2 = true;
                        if (normalizedFirstElementName.includes("titre")) {
                            currentContent.titre = createMultilingualContent(row);

                        }
                        if (normalizedFirstElementName.includes ("texte")){
                            currentContent.texte = createMultilingualContent(row);

                            listAvantage.push(currentContent);

                            currentContent = resetContent();
                        }
                    }
                    if(normalizedFirstElementName.includes("points d'attention")){
                        isAvantage2 = false;
                        isPointAttention2 = true;
                        currentContent.titre = createMultilingualContent(row);

                    }else if (isPointAttention2){
                        isPointAttention2 = false;
                        currentContent.texte = createMultilingualContent(row);

                        pointAttention.push(currentContent);

                        currentContent = resetContent();

                    }

                    header.forEach((name : string, index :number) => {
                        const value = row[index + 1];
                        if (value !== undefined && value !== '') {
                            const trimmedValue = value.trim();
                                if(!normalizedFirstElementName.includes("titre") && !normalizedFirstElementName.includes("texte")){
                                    obj[name] = trimmedValue;
                                }
                        }
                    });

                    return Object.keys(obj).length > 0 ? { [normalizedFirstElementName.trim()]: obj } : undefined;
                }).filter(Boolean);

                function resetContent() :MultilingualContentDTO {
                    return {
                        titre : { fr: '', nl: '', en: '' },
                        texte : { fr: '', nl: '', en: '' }
                    };
                }

                function createMultilingualContent ( row:string[] ) :TranslationDTO {
                    return {
                        fr : row[1].trim(),
                        nl : row[2].trim(),
                        en : row[3].trim()
                    };
                }

                const structuredData : StructuredDataDTO = {
                    Details :pageData,
                    listeAvantage : listAvantage,
                    pointAttention :pointAttention,
                }

                resolve(structuredData);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.readAsBinaryString(file);
        });
    }
}

