import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import InvestementDeletedPopUpViewState from './InvestementDeletedPopUpViewState';
import InvestementDeletedPopUpViewProps from './InvestementDeletedPopUpViewProps';
import InvestementDeletedPopUpViewStyle from './InvestementDeletedPopUpViewStyle';
import InvestementDeletedPopUpViewModel from './InvestementDeletedPopUpViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Image } from 'react-native';

export default class InvestementDeletedPopUpView extends ContentView<InvestementDeletedPopUpViewProps, InvestementDeletedPopUpViewState, InvestementDeletedPopUpViewModel, InvestementDeletedPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:InvestementDeletedPopUpViewProps)
  {
    super(props);

    // Binding
    this.bind(new InvestementDeletedPopUpViewModel(this), new InvestementDeletedPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
            <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20, justifyContent: 'flex-end' }}
              source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
          </TouchableWithoutFeedback>
        </View>

        <TranslationView style={this.style.title}>
          InvestmentDeleted
         </TranslationView>
      </View>
    );
  }
}