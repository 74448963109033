import { Application, ContentViewStyle } from '@levelapp/softfabric';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import { Dimensions, StyleProp, TextStyle, ViewStyle } from 'react-native';

export default class ExportViewStyle extends ContentViewStyle {
    MyStyleManager = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: ViewStyle = {
        height: Dimensions.get('window').height,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        padding: 10
    }

    content: ViewStyle = {
        flex: 1,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        height: '33%',
        width: '50%',
        padding: '2%'
    }

    newsletterBox: ViewStyle = {
        height: 800,
        width: 1500,
        backgroundColor: 'white',
        borderRadius: 15,
        shadowColor: 'gray',
        shadowOpacity: 0.5,
        shadowOffset: { width: 1, height: 1 },
        shadowRadius: 150,
    }

    NewsletterContainer: ViewStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        height: 150,
        width: '100%',


    }

    box: StyleProp<ViewStyle> = {
        width: '95%',
        backgroundColor: 'white',
        borderRadius: 6,
        marginTop: 40,
        shadowColor: "#0000006b",
        shadowOffset: {
            width: 1,
            height: 0,
        },
        shadowOpacity: 0.70,
        shadowRadius: 40.65,
        elevation: 60,
        height: '90%',
    }

    boxTitle: StyleProp<ViewStyle> = {
        width: '98%',
        backgroundColor: this.MyStyleManager.primaryColor,
        marginTop: -20,
        margin: 'auto',
        borderRadius: 3
    }

    title: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 30,
        padding: 30
    }

    tableHeader: StyleProp<TextStyle> =
        {
            color: this.MyStyleManager.primaryColor,
        }


    subTitle: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        paddingTop: 2,
        marginLeft: 30,
        marginBottom: 10,
        fontWeight: '100'
    }

    bigTitle: StyleProp<TextStyle> = {
        color: '#545454',
        fontSize: 16,
        fontWeight: "bold",
        paddingBottom: 30
    }

    label: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#FFB243',
        marginBottom: 10
    }

    boxContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        alignSelf: 'center',
        minHeight: 250,
        width: '100%',
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
    }

    availableIn: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    subCategories: StyleProp<ViewStyle> = {
        marginTop: 50
    }

    radioContainer: StyleProp<ViewStyle> = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 5
    }

    radioLabel: StyleProp<TextStyle> = {
        fontSize: 16,
        fontWeight: '600',
        color: '#FFB243',
        marginLeft: 10
    }

    buttonBox: StyleProp<ViewStyle> =
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
        }

    stepColor: StyleProp<ViewStyle> =
        {
            backgroundColor: this.MyStyleManager.primaryColor,
        }

    exportButton: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        marginHorizontal: 10,
        paddingHorizontal: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 300
    }
    EmailToSend: StyleProp<ViewStyle> = {
        borderRadius: 8,
        paddingVertical: 8,
        marginHorizontal: 10,
        paddingHorizontal: 25,
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto'
    }

    exportButtonText: StyleProp<TextStyle> = {
        color: 'white',
        fontSize: 17,
        fontWeight: '100',
        textAlign: 'center',
    }

    inputInline: StyleProp<ViewStyle> = {
        display: 'flex',
        margin: "1%",
        flexDirection: 'row',
    }

}
