import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import DroppableViewState from './DroppableViewState';
import DroppableViewProps from './DroppableViewProps';

export default class DroppableViewModel extends ContentViewModel<DroppableViewState, DroppableViewProps>{
    divRef : any;
    dragCounter = 0;
    constructor(props: ContentView) {
        super(props);

        this.initialState({
            dragging: false,
        })
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {
        this.divRef.addEventListener('dragenter', this.handleDragIn);
        this.divRef.addEventListener('dragleave', this.handleDragOut);
        this.divRef.addEventListener('dragover', this.handleDrag);
        this.divRef.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        this.dragCounter = 0;
        this.divRef.removeEventListener('dragenter', this.handleDragIn);
        this.divRef.removeEventListener('dragleave', this.handleDragOut);
        this.divRef.removeEventListener('dragover', this.handleDrag);
        this.divRef.removeEventListener('drop', this.handleDrop);
    }

    handleDrag(event: any) {
        event.preventDefault()
        event.stopPropagation()
    }

    handleDragIn(event: any) {
        event.preventDefault()
        event.stopPropagation()
        this.dragCounter++;
        if (event.dataTransfer.items && event.dataTransfer.items.length == 1) {
            this.setField({ dragging: true })
        }
    }

    handleDragOut(event: any) {
        event.preventDefault()
        event.stopPropagation()
        this.dragCounter--;
        if (this.dragCounter > 0) return
        this.setField({ dragging: false })
    }

    handleDrop(event: any) {
        event.preventDefault()
        event.stopPropagation()
        this.setField({ dragging: false })
        if (event.dataTransfer.files && event.dataTransfer.files.length == 1) {
            this.props().handleDrop(event.dataTransfer.files)
            event.dataTransfer.clearData()
            this.dragCounter = 0
        }
    }
}