import { ContentViewModel, ContentView } from '@levelapp/softfabric';
import TableViewState from './TableViewState';
import TableViewProps from './TableViewProps';
import TableRowView from './tablerow/TableRowView';
import TableViewStyle from './TableViewStyle';
import React from 'react';
import { View, TouchableWithoutFeedback, Text, Image } from 'react-native';


export default class TableViewModel extends ContentViewModel<TableViewState, TableViewProps, TableViewStyle>{
    /* CONSTRUCTOR */

    pages: number;
    imageDesc: any = require('./assets/sort_desc.png');
    imageAsc: any = require('./assets/sort_asc.png');
    image: any = require('./assets/sort.png');

    constructor(view: ContentView) {
        super(view);
        var tmp = "asc";
        if (this.props().defaultSortDesc != undefined && this.props().defaultSortDesc) {

            tmp = "desc";
        }

        this.initialState({
            currentTable: [],
            indexFirstPageElement: 0,
            currentPage: 0,
            numberOfPages: 1,
            currentSort: tmp,
            defaultSortDesc: this.props().defaultSortDesc,
            image: this.image,
            imageSort: undefined,
            sortIndice: - 1,
        });

        this.pages = 0;
        this.getCurrentTable = this.getCurrentTable.bind(this);
        this.getResetStepView = this.getResetStepView.bind(this);
        this.getNumberTotPages = this.getNumberTotPages.bind(this);
        this.OnPress = this.OnPress.bind(this);
        this.sort = this.sort.bind(this);
        this.changePage = this.changePage.bind(this);
        this.getCurrentPage = this.getCurrentPage.bind(this);
    }

    getNumberTotPages() {
        return this.props().nbPage;
    }


    getResetStepView() {
        if (this.props().resetStepView) {
            this.props().handleResetStepView != undefined && (this.props().handleResetStepView as Function)();
            this.setField({ currentPage: 0 });
        }
    }

    getCurrentPage() {
        if (this.props().currentPage != this.state().currentPage) {
            this.setField({ currentPage: this.props().currentPage })
            return 0;
        } else {
            return this.state().currentPage;
        }
    }

    changePage(nextPage: number) {
        this.props().onPageChange(nextPage);
        this.setField({ currentPage: nextPage });
    }

    getCurrentTable(): any[] {
        let tableTemp: any = [];
        let dataCellList: any = [];
        let dataCelleHeader: any = [];
        /* HEADER CREATION */
        this.props().headerData.forEach((header, index) => {
            let jsxHeader;
            if (this.props().headerIndex.includes(index)) {
                jsxHeader = (
                    <TouchableWithoutFeedback key={this.props().keyDatas[index]} onPress={() => this.props().isSortable ? this.OnPress(index) : {}} style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start' }}>
                            <Text style={[this.props().headerTextStyle]}>{header}</Text>
                            <Image source={this.state().sortIndice == index ? this.state().imageSort : this.state().image} style={this.props().isSortable ? this.style().showImage : this.style().notShowingImage} resizeMode="contain" />
                        </View>
                    </TouchableWithoutFeedback>
                )
            } else {
                jsxHeader = <Text style={[this.props().headerTextStyle]}>{header}</Text>
            }

            dataCelleHeader.push(jsxHeader)
        });
        tableTemp.push(<TableRowView key={0} style={this.style().header} dataCellList={dataCelleHeader}></TableRowView>);

        /* ROWS CREATION*/
        for (let i = 0; i < this.props().numberOfRows && i < this.props().rows.length; i++) {
            dataCellList = [];
            this.props().rows[i].props.children.forEach((cell: any) => {
                dataCellList.push(cell);
            });
            tableTemp.push(<TableRowView key={i + 1} style={this.style().row} dataCellList={dataCellList}></TableRowView>);
        }
        return tableTemp;
    }

    /* SORT */

    OnPress(key: number) {
        if (key != this.state().sortIndice) {
            if (this.props().defaultSortDesc != undefined && this.props().defaultSortDesc) {
                this.setField({ currentSort: 'desc', sortIndice: key, imageSort: this.imageDesc }, () => this.sort());
            } else {
                this.setField({ currentSort: 'asc', sortIndice: key, imageSort: this.imageAsc }, () => this.sort());
            }
        }
        else {
            this.setField({ sortIndice: key });
            if (this.props().defaultSortDesc != undefined && this.props().defaultSortDesc) {
                //First 
                //Desc - Asc - Default
                if (this.state().currentSort == 'desc') {
                    this.setField({ currentSort: 'asc', sortIndice: key, imageSort: this.imageAsc }, () => this.sort());
                } else if (this.state().currentSort == 'asc') {
                    this.setField({ currentSort: 'default', sortIndice: key, imageSort: this.image }, () => this.sort());
                } else if (this.state().currentSort == 'default') {
                    this.setField({ currentSort: 'desc', sortIndice: key, imageSort: this.imageDesc }, () => this.sort());
                }
            }
            else {

                //Second
                //Asc - Desc - Default
                if (this.state().currentSort == 'desc') {
                    this.setField({ currentSort: 'default', sortIndice: key, imageSort: this.image }, () => this.sort());
                } else if (this.state().currentSort == 'asc') {
                    this.setField({ currentSort: 'desc', sortIndice: key, imageSort: this.imageDesc }, () => this.sort());
                } else if (this.state().currentSort == 'default') {
                    this.setField({ currentSort: 'asc', sortIndice: key, imageSort: this.imageAsc }, () => this.sort());
                }
            }
        }
    }

    sort() {
        if (this.props().isSortable && this.state().sortIndice != -1) {
            //DESC-ASC-DEFAULT
            if (this.state().defaultSortDesc != undefined && this.state().defaultSortDesc && this.state().currentSort == 'asc') {
                this.props().fillTables(this.props().datas.slice(0).sort((a: any, b: any) => { return a[this.props().keyDatas[this.state().sortIndice]] < b[this.props().keyDatas[this.state().sortIndice]] ? -1 : 1 }));
            }
            else if (this.state().defaultSortDesc != undefined && this.state().defaultSortDesc && this.state().currentSort == 'desc') {

                this.props().fillTables(this.props().datas.slice(0).sort((a: any, b: any) => { return a[this.props().keyDatas[this.state().sortIndice]] > b[this.props().keyDatas[this.state().sortIndice]] ? -1 : 1 }));

            } else if (this.state().defaultSortDesc != undefined && this.state().defaultSortDesc && this.state().currentSort == 'default') {

                //this.setField({currentArray : this.state().initialArrayState.slice(0)});
                this.props().resetTable();
                // Envoyer une méthode d'update de l'array
            }


            //ASC-DESC-DEFAULT

            if ((this.state().defaultSortDesc == undefined || this.state().defaultSortDesc == false) && this.state().currentSort == 'asc') {
                this.props().fillTables(this.props().datas.sort((a: any, b: any) => { return a[this.props().keyDatas[this.state().sortIndice]] < b[this.props().keyDatas[this.state().sortIndice]] ? -1 : 1 }));
            } else if ((this.state().defaultSortDesc == undefined || this.state().defaultSortDesc == false) && this.state().currentSort == 'desc') {
                this.props().fillTables(this.props().datas.sort((a: any, b: any) => { return a[this.props().keyDatas[this.state().sortIndice]] > b[this.props().keyDatas[this.state().sortIndice]] ? -1 : 1 }));

            } else if ((this.state().defaultSortDesc == undefined || this.state().defaultSortDesc == false) && this.state().currentSort == 'default') {
                this.props().resetTable();
            }
        }

    }
}