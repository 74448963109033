import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import StepViewState from './StepViewState';
import StepViewProps from './StepViewProps';

export default class StepViewModel extends ContentViewModel<StepViewState, StepViewProps>
{
    constructor(view:ContentView)
    {
        super(view);
        this.initialState({currentPage : 0 , showing : true});

        this.onPress = this.onPress.bind(this);
        this.onPressPoint = this.onPressPoint.bind(this);
        this.goLeft = this.goLeft.bind(this);
        this.goRight = this.goRight.bind(this);
    }

    getCurrentPage()
    {
        if(this.state().currentPage != this.props().currentPage)
        {
            this.setField({currentPage : this.props().currentPage});
            return this.props().currentPage;
        }else
        {
            return this.state().currentPage;
        }
        
    }

    onPress(key : number)
    {

        if(this.state().currentPage != key || !this.state().showing)
        {

            this.setField({showing : true , currentPage : key}, ()=>{ this.props().handleChangingPage(this.state().currentPage)});
        }       
    }

    onPressPoint(value : string)
    {
        if(value == 'back')
        {
            this.setField({showing : false ,currentPage : this.state().currentPage - ((this.props().minimumPage * 2) + 1) });
        }else
        {
            this.setField({showing : false ,currentPage : this.state().currentPage + ((this.props().minimumPage * 2) + 1) });
        }

    }

    goLeft()
    {
        //Can't occur
        if(this.state().currentPage == 0)
        {
            return;
        }

        this.setField({currentPage : this.state().currentPage -1}, ()=>{ this.props().handleChangingPage(this.state().currentPage)});
    }


    goRight()
    {
        //Can't occur
        if(this.state().currentPage == this.props().numberDisplays -1)
        {
            return;
        }

        this.setField({currentPage : this.state().currentPage +1}, ()=>{ this.props().handleChangingPage(this.state().currentPage)});
        
    }


}