import React from 'react';
import { ContentView } from '@levelapp/softfabric';
import InvestementAddedPopUpViewState from './InvestementAddedPopUpViewState';
import InvestementAddedPopUpViewProps from './InvestementAddedPopUpViewProps';
import InvestementAddedPopUpViewStyle from './InvestementAddedPopUpViewStyle';
import InvestementAddedPopUpViewModel from './InvestementAddedPopUpViewModel';
import { TranslationView } from '@levelapp/softfabric-ui';
import { View, TouchableWithoutFeedback, Image } from 'react-native';

export default class InvestementAddedPopUpView extends ContentView<InvestementAddedPopUpViewProps, InvestementAddedPopUpViewState, InvestementAddedPopUpViewModel, InvestementAddedPopUpViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props: InvestementAddedPopUpViewProps) {
    super(props);

    // Binding
    this.bind(new InvestementAddedPopUpViewModel(this), new InvestementAddedPopUpViewStyle(this));
  }

  /* RENDERING */
  render() {
    return (
      <View style={{ marginHorizontal: 50, minWidth: 600 }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
          <TouchableWithoutFeedback onPress={this.binding.handleBackPressed} >
            <Image style={{ height: 23, width: 23, resizeMode: 'contain', marginTop: 25, marginLeft: 20, justifyContent: 'flex-end' }}
              source={{ uri: 'https://appstoragflyx.blob.core.windows.net/app/Console/exit.png' }}></Image>
          </TouchableWithoutFeedback>
        </View>

        <TranslationView style={this.style.title}>
          InvestmentAdded
         </TranslationView>
      </View>
    );
  }
}