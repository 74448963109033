import {ContentViewModel, ContentView} from '@levelapp/softfabric';
import TraductionViewState from './TraductionViewState';
import TraductionViewProps from './TraductionViewProps';

export default class TraductionViewModel extends ContentViewModel<TraductionViewState, TraductionViewProps>
{

    constructor(view:ContentView)
    {
        super(view);

        /* InitialState */

        this.initialState({
            headers : [],
            data : [],

        });

    }


    getHeaders()
    {
        return this.state().headers;
    };

    getRows()
    {
        return this.state().data;
    };


    getFile()
    {   
        this.setField({ data : ['test','test2','tets3'] , headers : ['header','header','header'] });
    }

}